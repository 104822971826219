import React from 'react'
import styles from './ErrorPage.module.css'

import Modal from '@material-ui/core/Modal';  

const ErrorPage = (props) => {   
    return ( 
        <Modal open={props.open} onClose={props.onClose} className={styles.modal}> 
            <div className={styles.container}>
                {props.children} 
            </div> 
        </Modal> 
    )
} 
export default ErrorPage;