import React from "react";
import styles from "./ViewTransactions.module.css";

import TextField from "@material-ui/core/TextField";
import TablePagination from "@material-ui/core/TablePagination";

import AppLoader from "../../utils/AppLoader/AppLoader";
import DataTable from "react-data-table-component";
import BtnLoader from "../../utils/BtnLoader/BtnLoader";
import ModalPage from "../ViewTransactions/transactionModal";
import { connect } from "react-redux";
import { showAlert } from "../../../containers/app/actions";
import { getAllTransactions } from "../../../containers/transactions/actions";
import { withRouter } from "react-router-dom";

import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import moment from "moment";

const ViewTransaction = (props) => {
    const [transactions, setTransactions] = React.useState(props.transactions);
    const [searchVal, setSearchVal] = React.useState("");
    const [checkList, setCheckList] = React.useState([]);
    const [checked, setChecked] = React.useState(false);
    const [ibanValidation, setIbanValidation] = React.useState();
    const [singleValidation, setSingleValidation] = React.useState(null);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [loading, setLoading] = React.useState(false);
    const [type, setType] = React.useState("ORDER");
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (!props.transactions) {
            props.getAllTransactions(rowsPerPage, page + 1, searchVal, type);
        }
        setTransactions(props.transactions);
    }, [props.transactions]);

    const postTransaction = (serviceProviderId) => {
        axios({
            method: "post",
            url: "/dashboard/transactions/transactionValidation",
            data: {
                serviceProviderId: serviceProviderId,
            },
        })
            .then((res) => {
                setIbanValidation(res.data.data);
            })
            .catch((err) => {
                if (err && err.response) props.showAlert(err.response.data.error);
                else props.showAlert("Something went wrong Try Again");

                setLoading(false);
            });
    };
    const handleChangePage = (event, newPage) => {
        setCheckList([]);
        props.getAllTransactions(rowsPerPage, newPage + 1, searchVal, type);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setCheckList([]);
        props.getAllTransactions(event.target.value, page + 1, searchVal, type);
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchChange = (e) => {
        setCheckList([]);
        setPage(0);
        props.getAllTransactions(rowsPerPage, 1, e.target.value, type);
        setSearchVal(e.target.value);
    };

    const selectAll = () => {
        let arr =
            transactions &&
            transactions.map((val) => {
                let lastDate = moment().endOf("month").format("YYYY-MM-DD");
                let lastMonth = moment(lastDate).subtract(1, "months").format("YYYY-MM-DD");
                let orderDate = moment(val.createdAt).format("YYYY-MM-DD");

                if (new Date().getDate() < 15) {
                    lastMonth = moment(lastDate).subtract(2, "months").format("YYYY-MM-DD");
                }
                if (val.paymentStatus == "PAID" && val.settlementStatus == "PENDING" && val.status != "REFUND") {
                    if (orderDate < lastMonth) {
                        return val._id;
                    }
                }
            });
        arr = arr.filter((val) => val && val);

        setCheckList([...arr]);

        let serviceProviderId =
            transactions &&
            transactions.map((val) => {
                let lastDate = moment().endOf("month").format("YYYY-MM-DD");
                let lastMonth = moment(lastDate).subtract(1, "months").format("YYYY-MM-DD");
                let orderDate = moment(val.createdAt).format("YYYY-MM-DD");

                if (new Date().getDate() < 15) {
                    lastMonth = moment(lastDate).subtract(2, "months").format("YYYY-MM-DD");
                }
                if (val.paymentStatus == "PAID" && val.settlementStatus == "PENDING" && val.status != "REFUND") {
                    if (orderDate < lastMonth) {
                        return val.serviceProvider._id;
                    }
                }
            });
        serviceProviderId = serviceProviderId.filter((val) => val && val);
        postTransaction([...serviceProviderId]);
    };

    const handleCheckList = (id, serviceProviderId) => {
        let arr = checkList;
        if (arr.includes(id)) arr = arr.filter((val) => val != id);
        else arr.push(id);

        postTransaction([serviceProviderId]);
        setCheckList([...arr]);
    };
    const onSubmit = () => {
        if (ibanValidation && ibanValidation.length > 0) {
            setOpen(true);
        }
        setLoading(true);
        let url = type == "ORDER" ? "/dashboard/transactions/payOut" : "/dashboard/transactions/payOutVoucher";
        axios({
            url,
            method: "post",
            data: {
                checkList: checkList.filter((val) => val && val),
            },
        })
            .then((res) => {
                setLoading(false);
                setCheckList([]);

                if (ibanValidation && ibanValidation.length === 0) {
                    props.showAlert("Orders Settled");
                }
            })
            .catch((err) => {
                setLoading(false);
                props.showAlert("Something went wrong!Try Again");
            });
    };
    let isLoading = !transactions;
    let showData = !isLoading;
    let rowData = [];

    !isLoading &&
        transactions.length > 0 &&
        transactions.forEach((order, index) => {
            let isValidForPayout = false;
            let isSettled = order.settlementStatus == "SETTLED" || order.status == "REFUND";

            // let lastDate = moment().endOf("month").format("YYYY-MM-DD");
            let orderDate = new Date(moment(order.createdAt).format("YYYY-MM-DD"));
            let currentDate = new Date();
            const differenceInDays = Math.floor((currentDate - orderDate) / (1000 * 60 * 60 * 24));
            let currentDay = currentDate.getDate();
            let currentMonth = currentDate.getMonth() + 1;
            let orderMonth = orderDate.getMonth();
            // console.log("--------------currentDatecurrentDate----------------", currentDate);
            // console.log("---------isValidForPayoutisValidForPayout-------------", isValidForPayout);
            // console.log("-----------isSettledisSettledisSettled-----------", isSettled);
            console.log("--------orderMonth--------------", orderMonth);
            console.log("--------currentMonthcurrentMonth--------------", currentMonth);

            // console.log("-------currentDaycurrentDay---------------", currentDay);
            console.log("------differenceInDays----------------", differenceInDays);

            if (!isSettled && currentMonth - 1 > orderMonth && differenceInDays >= 15) {
                isValidForPayout = true;
            }

            if (!isSettled && orderDate.getFullYear() < currentDate.getFullYear()) {
                isValidForPayout = true;
            }

            rowData.push({
                sl_no: index + 1,
                tag: (
                    <React.Fragment>
                        {isSettled ? (
                            <React.Fragment>
                                {order.status == "REFUND" ? (
                                    <p className={styles.grey}>REFUND</p>
                                ) : (
                                    <p className={styles.grey}>SETTLED</p>
                                )}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {isValidForPayout ? (
                                    <p className={styles.green}>CAN BE SETTLED</p>
                                ) : (
                                    <p className={styles.orange}>UPCOMING</p>
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ),
                customerEmail: order.userId ? order.userId.email : "NAN",
                customerName: order.userId
                    ? order.userId.verificationType == "EMAIL"
                        ? order.userId.username
                        : order.userProfile && order.userProfile.firstName
                    : "NAN",
                serviceProviderEmail: order.serviceProvider ? order.serviceProvider.email : "NAN",
                storeName: order.serviceProviderProfile ? order.serviceProviderProfile.storeName : "NAN",
                eventName: order.serviceId ? order.serviceId.eventName : "NAN",
                status: order.status,
                totalAmount: order.totalAmount + " €",
                paymentStatus: order.paymentStatus,
                orderPlaced: new Date(order.createdAt).toString().substring(0, 15),
                orderDate:
                    order.bookingType == "VOUCHER"
                        ? "Voucher"
                        : new Date(order.timeSlot.startDate).toString().substring(0, 15),
                id: order.uniqueId,
                action: (
                    <Checkbox
                        checked={checkList.includes(order._id)}
                        onChange={() => handleCheckList(order._id, order.serviceProvider._id)}
                        disabled={!isValidForPayout}
                    />
                ),
            });
        });
    return (
        <div className={styles.container}>
            <ModalPage open={open} onClose={() => setOpen(false)} ibanValidation={ibanValidation} />
            <div className={styles.header}>
                <div className={styles.leftHeader}>
                    <p>Select All</p>
                    <Checkbox
                        checked={checked}
                        onChange={() => {
                            checked ? setCheckList([]) : selectAll();

                            setChecked(!checked);
                        }}
                    />
                </div>

                <div className={styles.rightHeader}>
                    <div className={styles.switch}>
                        <p>Order</p>
                        <Switch
                            checked={type == "VOUCHER"}
                            onChange={() => {
                                setType(type == "ORDER" ? "VOUCHER" : "ORDER");
                                setCheckList([]);
                                setPage(0);
                                props.getAllTransactions(
                                    rowsPerPage,
                                    1,
                                    searchVal,
                                    type == "ORDER" ? "VOUCHER" : "ORDER",
                                );
                            }}
                        />
                        <p>Voucher</p>
                    </div>

                    <TextField
                        label="Search Here"
                        className={styles.search}
                        value={searchVal}
                        onChange={handleSearchChange}
                    />
                </div>
            </div>

            {isLoading && <AppLoader />}

            {showData && (
                <React.Fragment>
                    <DataTable
                        noHeader={true}
                        fixedHeader={true}
                        fixedHeaderScrollHeight={"calc(100vh - 380px)"}
                        columns={[
                            { name: "Action", selector: "action", sortable: true, wrap: true, minWidth: "50px" },
                            {
                                name: "Settlement Status",
                                selector: "tag",
                                sortable: true,
                                wrap: true,
                                minWidth: "150px",
                            },
                            { name: "Order Id", selector: "id", sortable: true, wrap: true, minWidth: "250px" },
                            { name: "EventName", selector: "eventName", sortable: true, wrap: true, minWidth: "300px" },
                            {
                                name: "Customer Name",
                                selector: "customerName",
                                sortable: true,
                                wrap: true,
                                minWidth: "180px",
                            },
                            {
                                name: "Customer Email",
                                selector: "customerEmail",
                                sortable: true,
                                wrap: true,
                                minWidth: "180px",
                            },
                            {
                                name: "Service Provider",
                                selector: "storeName",
                                sortable: true,
                                wrap: true,
                                minWidth: "180px",
                            },
                            {
                                name: "Service Provider Email",
                                selector: "serviceProviderEmail",
                                sortable: true,
                                wrap: true,
                                minWidth: "180px",
                            },
                            { name: "Status", selector: "status", sortable: true, wrap: true, minWidth: "180px" },
                            {
                                name: "Total Amount",
                                selector: "totalAmount",
                                sortable: true,
                                wrap: true,
                                minWidth: "180px",
                            },
                            {
                                name: "Payment Status",
                                selector: "paymentStatus",
                                sortable: true,
                                wrap: true,
                                minWidth: "180px",
                            },
                            {
                                name: "Order Placed On",
                                selector: "orderPlaced",
                                sortable: true,
                                wrap: true,
                                minWidth: "180px",
                            },
                            {
                                name: "Fulfilled On",
                                selector: "orderDate",
                                sortable: true,
                                wrap: true,
                                minWidth: "180px",
                            },
                        ]}
                        data={rowData}
                    />
                    <div className={styles.flexEnd}>
                        <TablePagination
                            component="div"
                            count={props.count}
                            page={page}
                            onChangePage={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </div>
                    {checkList.length > 0 && (
                        <div className={styles.btnCenter}>
                            {loading ? (
                                <BtnLoader className="btnGreen" />
                            ) : (
                                <button className="btnGreen" onClick={onSubmit}>
                                    Payout
                                </button>
                            )}
                        </div>
                    )}
                </React.Fragment>
            )}
        </div>
    );
};
const mapStateToProps = (state) => ({
    transactions: state.transaction.transactions,
    count: state.transaction.count,
});
export default withRouter(connect(mapStateToProps, { getAllTransactions, showAlert })(ViewTransaction));
