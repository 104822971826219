import React from 'react'; 
import styles from './Reject.module.css'

import Modal from '@material-ui/core/Modal'; 
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import SendRoundedIcon from '@material-ui/icons/SendRounded';

import axios from 'axios'
import {showAlert} from '../../../../containers/app/actions'
import {getAllRequestedData} from '../../../../containers/serviceProviderRequest/actions'
import {connect} from 'react-redux'

const Reject = (props) => {
  const [message,setMessage] = React.useState("")
  const [error,setError] = React.useState(false);
  const [loading,setLoading] = React.useState(false)

  const onSubmit = () => {
    if(message == "") {
      setError("Enter a valid message")
    } else {
      setLoading(true);
      axios({
        method: "post",
        url: "/dashboard/service-provider/rejectServiceProvider",
        data: {
          requestId: props.requestId,
          message: message
        }
      }).then(res => {
        props.showAlert("Message Sent Successfully")
        props.onClose();
        setLoading(false)
        setMessage("")
        props.getAllRequestedData()
      }).catch(err => {
        setLoading(false);
        if(err && err.response && err.response.data && err.response.data.error) {
            props.showAlert(err.response.data.error)
        } else {
            props.showAlert("Something went wrong ! Try Again")
        }
      })
    }
  }

  return (
    <Modal onClose={() => props.onClose()} aria-labelledby="customized-Modal-title" open={props.open} className={styles.Modal}> 
      <div className={styles.container}>
        <h1>Reject Service Provider</h1>
        <TextField
          variant="outlined"
          label="Your Message"
          multiline
          rows={4}
          value={message}
          error={error}
          helperText={error}
          onChange={e => setMessage(e.target.value)}
        />

        {loading
            ?
        <Button color="primary" variant="contained" startIcon={<CircularProgress color="inherit" size={20} />}>Loading ...</Button>
            :
        <Button color="primary" variant="contained" startIcon={<SendRoundedIcon />} onClick={onSubmit}>Reject Service Provider</Button>}
      </div>
    </Modal>
  );
}

export default connect(null,{showAlert,getAllRequestedData})(Reject);