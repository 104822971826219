import * as actionTypes from './actionTypes'
import axios from 'axios'
 
export const getAllRequestedData = () => dispatch => {
    dispatch({
        type: actionTypes.SET_REQUESTED_DATA,
        payload: false
    })

    axios({
        method: "get",
        url: "/dashboard/service-provider/getAllRequestedData"
    }).then(res => {
        dispatch({
            type: actionTypes.SET_REQUESTED_DATA,
            payload: res.data.requests
        })
    }).catch(err => {
        dispatch({
            type: actionTypes.SET_REQUESTED_DATA,
            payload: [] 
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}

export const onUpdateRequest = (userId) => dispatch => {
    axios({
        method: "delete",
        url: "/dashboard/conf-user/deleteUser",
        data: {
            userId
        }
    }).then(res => {
        dispatch(getAllRequestedData())
        dispatch({
            type: "SHOW_ALERT",
            payload: "User Deleted Successfully"
        })
    }).catch(err => {  
        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}