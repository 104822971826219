import firebase from "firebase";

export let backendUrl = "https://api1.21done.de/api";
// export let backendUrl = "http://localhost:6001/api";

if (process.env.REACT_APP_ENV === "dev") {
    backendUrl = "https://api1.21done.de/api";
    // backendUrl = "http://localhost:6001/api";
}

if (process.env.REACT_APP_ENV === "prod") {
    backendUrl = "https://server.21done.de/api";
    // backendUrl = "https://api1.21done.de/api";
    // backendUrl = "http://localhost:6001/api";
}
if (process.env.REACT_APP_ENV === "stage") {
    backendUrl = "https://node.21done.ml/api";
    // backendUrl = "http://localhost:9000/api"
}

export const s3Config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: process.env.REACT_APP_DIR_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    bucketUrl: process.env.REACT_APP_S3_BACKEND_URL,
};

export const firebaseConfig = {
    apiKey: "AIzaSyDcQBX5qIhKrf9jP3K6ocLrog3F6qxRY5M",
    authDomain: process.env.REACT_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_FIREBASE_DB_URL,
    projectId: process.env.REACT_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_FIREBASE_M_SENDER_ID,
    appId: process.env.REACT_FIREBASE_APP_ID,
    measurementId: process.env.REACT_FIREBASE_MESURE_ID,
};

if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const auth = firebase.auth();
export const globalGivingKey = process.env.REACT_APP_GLOBAL_GIVING_API_KEY;

export const mapApiKey = process.env.REACT_APP_MAP_API_KEY;
