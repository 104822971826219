import React from 'react' 
import styles from './MailSystem.module.css'
import ScrollableFeed from 'react-scrollable-feed'
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import {useLocation} from 'react-router-dom'
import {connect} from 'react-redux'
import {getChatList} from '../../../containers/mail/actions'
import renderHTML from 'react-render-html';
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from 'axios'

const ChatSystem = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [value,setValue] = React.useState("") 
    const [chatList,setChatList] = React.useState(false) 
    React.useEffect(() => {
        if(query.get("email")) { 
            setChatList(false);
            props.getChatList(query.get("email"));
        } 
    },[query.get("email")]); 

    React.useEffect(() => {
        setChatList(props.chatList)
    },[props.chatList])


    const checkIfSubmit = (e) => {
        if (e.keyCode === 13) {
            onSubmit();
        }
    }
    const onSubmit = () => {
        if(value == "") {
            console.log("Enter something")
        } else {
            let arr = chatList;
            arr.push({
                email: props.activeValue.email,
                name: props.activeValue.name,
                message: value,
                from: "21DONE",
                createdAt: new Date()
            })
            setChatList([...arr]);
            setValue("")
            axios({
                method: "post",
                url: "/dashboard/mail/sendEmail",
                data: {
                    email: props.activeValue.email ? props.activeValue.email : query.get("email"),
                    name: props.activeValue.name ? props.activeValue.name : " User",
                    message: value
                }
            }).then(res => {
                console.log(res)
            }).catch(err => {
                console.log(err)
            })
        }
    }   
    return (
        <React.Fragment>
            {query.get("email") 
                ?
            <React.Fragment> 
                <div className={styles.container}> 
                    <div className={styles.header}>
                        <p>{props.activeValue.name}</p>
                    </div>

                    <div className={styles.content}>
                        <ScrollableFeed forceScroll> 
                            {!chatList &&
                                <div className={styles.loader}>
                                    <CircularProgress />
                                </div>}

                            {chatList && chatList.map((val,index) => {
                                return (
                                    <p className={val.from == "21DONE" ? styles.rightContent : styles.leftContent} key={index} >
                                        {renderHTML(val.message)}

                                        <span className={styles.date}>
                                            {new Date(val.createdAt).toString().substr(0,24)}
                                        </span>
                                    </p>
                                )
                            })}
                        </ScrollableFeed>
                    </div>

                    <div className={styles.footer}>
                        <input type="text" value={value} onChange={e => setValue(e.target.value)} placeholder="Type Something" onKeyDown={checkIfSubmit}/>
                        <button onClick={onSubmit}>Send</button>
                    </div>
                </div>
            </React.Fragment>
                :
            <React.Fragment>
                <div className={styles.selectConvo}>
                    <p>Select a email to continue</p>
                    <EmailRoundedIcon  style={{fontSize: 120,color: "rgba(0, 0, 0, 0.5)"}} />
                </div>    
            </React.Fragment>}
        </React.Fragment>
    )
}
const mapStateToProps = state => ({
    chatList: state.email.chatList
})

export default connect(mapStateToProps,{getChatList})(ChatSystem);