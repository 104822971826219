import React from "react";
import styles from "./ConfCategory.module.css";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@material-ui/core/Tooltip";

import ImageRoundedIcon from "@material-ui/icons/ImageRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";

import { connect } from "react-redux";
import { getAllExperience } from "../../../../containers/category/actions";
import { showAlert } from "../../../../containers/app/actions";
import axios from "axios";
import { withRouter } from "react-router-dom";
import MediaHandler from "../../../Media/MediaHandler";

const ConfCategory = (props) => {
    const [formData, setFormData] = React.useState({
        categoryName: "",
        description: "",
        active: true,
        categoryIcon: "",
        categoryImage: "",
        coverImage: "",
        secondaryName: "",
        experienceId: props.activeExp,
    });

    const [error, setError] = React.useState({
        categoryName: false,
        description: false,
        categoryIcon: false,
        categoryImage: false,
        coverImage: false,
        secondaryName: false,
    });

    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [modal, setModal] = React.useState(() => ({ open: false, type: "" }));

    React.useEffect(() => {
        props.type == "EDIT" &&
            props.category &&
            setFormData({ ...formData, ...props.category, categoryId: props.category._id });
    }, [props.category]);

    const validate = () => {
        const err = {
            categoryName: false,
            description: false,
            categoryIcon: false,
            categoryImage: false,
            coverImage: false,
            secondaryName: false,
        };
        let validData = true;
        setError({ ...err });
        Object.keys(err).forEach((key) => {
            if (formData[key] == "") {
                err[key] = `${key} field cannot be empty`;
                validData = false;
            }
        });
        setError({ ...err });
        return validData;
    };

    const onSubmit = () => {
        if (validate()) {
            setLoading(true);
            const method = props.type == "ADD" ? "post" : "put";
            const url = props.type == "ADD" ? "/dashboard/category/addCategory" : "/dashboard/category/editCategory";
            axios({
                method,
                url,
                data: {
                    ...formData,
                    experienceId: props.activeExp,
                },
            })
                .then((res) => {
                    setLoading(false);
                    props.showAlert("Category Added Succesfully");
                    setFormData({
                        ...formData,
                        categoryName: "",
                        description: "",
                        categoryIcon: "",
                        categoryImage: "",
                        coverImage: "",
                        secondaryName: "",
                    });
                    props.getAllExperience();
                    setOpen(false);
                })
                .catch((err) => {
                    setLoading(false);
                    if (err && err.response && err.response.data && err.response.data.error) {
                        props.showAlert(err.response.data.error);
                    } else {
                        console.log(err);
                        props.showAlert("Something went wrong ! Try Again");
                    }
                });
        }
    };
    const name = props.type == "ADD" ? "Add Category" : "Update Category";

    return (
        <React.Fragment>
            {props.type == "ADD" ? (
                <Button startIcon={<AddRoundedIcon />} onClick={() => setOpen(true)}>
                    {name}
                </Button>
            ) : (
                <Tooltip title="Edit Category">
                    <IconButton onClick={() => setOpen(true)}>
                        <EditRoundedIcon />
                    </IconButton>
                </Tooltip>
            )}

            <MediaHandler
                open={modal.open}
                onClose={() => setModal((prev) => ({ open: !prev, type: "" }))}
                onSelectImage={(val) => {
                    setFormData((prev) => ({ ...prev, [modal.type]: val }));
                    setModal((prev) => ({ open: false, type: "" }));
                }}
                cropRatio={modal.type === "coverImage" && "none"}
                cropBoxResizable={modal.type === "coverImage" && true}
            />

            <Dialog className={styles.modal} open={open} onClose={() => setOpen(false)} scroll={"paper"} maxWidth="lg">
                <div className={styles.container}>
                    <IconButton className={styles.close} onClick={() => setOpen(false)}>
                        <CloseRoundedIcon />
                    </IconButton>

                    <Paper variant="outlined" className={styles.paper}>
                        <h1>{name}</h1>

                        <div className={styles.row}>
                            <div className={styles.imgMain}>
                                <div className={styles.imgContainer}>
                                    {error.categoryIcon && <span className={styles.error}>* {error.categoryIcon}</span>}
                                    <label htmlFor="">Category Icon</label>
                                    <Tooltip title="Click to Edit Category Icon">
                                        <div
                                            className={styles.imgContent}
                                            onClick={() =>
                                                setModal((prev) => ({
                                                    ...prev,
                                                    open: Math.random(),
                                                    type: "categoryIcon",
                                                }))
                                            }
                                        >
                                            {formData.categoryIcon ? (
                                                <img src={formData.categoryIcon} alt="" />
                                            ) : (
                                                <ImageRoundedIcon />
                                            )}
                                        </div>
                                    </Tooltip>
                                </div>

                                {/* <div className={styles.imgContainer}>
                                    {error.categoryImage && (
                                        <span className={styles.error}>* {error.categoryImage}</span>
                                    )}
                                    <label htmlFor="">Category Image</label>
                                    <Tooltip title="Click to Edit Category Image">
                                        <div
                                            className={styles.imgContent}
                                            onClick={() =>
                                                setModal((prev) => ({
                                                    ...prev,
                                                    open: Math.random(),
                                                    type: "categoryImage",
                                                }))
                                            }
                                        >
                                            {formData.categoryImage ? (
                                                <img src={formData.categoryImage} alt="" />
                                            ) : (
                                                <ImageRoundedIcon />
                                            )}
                                        </div>
                                    </Tooltip>
                                </div> */}
                            </div>

                            <div className={styles.imgContainerFull}>
                                {error.coverImage && <span className={styles.error}>* {error.coverImage}</span>}
                                <label htmlFor="">Cover Image</label>
                                <Tooltip title="Click to Edit Cover Image">
                                    <div
                                        className={styles.imgContent}
                                        onClick={() =>
                                            setModal((prev) => ({ ...prev, open: Math.random(), type: "coverImage" }))
                                        }
                                    >
                                        {formData.coverImage ? (
                                            <img src={formData.coverImage} alt="" />
                                        ) : (
                                            <ImageRoundedIcon />
                                        )}
                                    </div>
                                </Tooltip>
                            </div>

                            <TextField
                                label="Category Name"
                                className={styles.catName}
                                value={formData.categoryName}
                                onChange={(e) => setFormData({ ...formData, categoryName: e.target.value.trimStart() })}
                                error={error.categoryName}
                                helperText={error.categoryName}
                            />
                            <TextField
                                label="Secondary Name"
                                className={styles.catName}
                                value={formData.secondaryName}
                                onChange={(e) =>
                                    setFormData({ ...formData, secondaryName: e.target.value.trimStart() })
                                }
                                error={error.secondaryName}
                                helperText={error.secondaryName}
                            />
                            <TextField
                                label="Category Description"
                                className={styles.catName}
                                value={formData.description}
                                onChange={(e) => setFormData({ ...formData, description: e.target.value.trimStart() })}
                                error={error.description}
                                helperText={error.description}
                            />

                            <FormControlLabel
                                className={styles.switch}
                                control={
                                    <Switch
                                        checked={formData.active}
                                        onChange={(e) => setFormData({ ...formData, active: !formData.active })}
                                        color="primary"
                                    />
                                }
                                label="Category Active"
                            />
                        </div>

                        <div className={styles.row}>
                            {loading ? (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    startIcon={<CircularProgress color="inherit" size={20} />}
                                >
                                    Loading ...
                                </Button>
                            ) : (
                                <Button color="primary" variant="contained" onClick={onSubmit}>
                                    {name}
                                </Button>
                            )}
                        </div>
                    </Paper>
                </div>
            </Dialog>
        </React.Fragment>
    );
};

export default withRouter(connect(null, { getAllExperience, showAlert })(ConfCategory));
