import React from "react";
import styles from "./ViewServiceProvider.module.css";

import TextField from "@material-ui/core/TextField";

import DataTable from "react-data-table-component";
import AppLoader from "../../utils/AppLoader/AppLoader";

import { connect } from "react-redux";
import { getAllServiceProviders } from "../../../containers/serviceProvider/actions";
import { withRouter } from "react-router-dom";
import Action from "./Action/Action";
import moment from "moment";

const ViewServiceProvider = (props) => {
    const [users, setUsers] = React.useState(props.users);
    const [searchVal, setSearchVal] = React.useState("");

    React.useEffect(() => {
        if (!props.users) {
            props.getAllServiceProviders();
        }
        setUsers(props.users);
    }, [props.users]);

    let isLoading = !users;
    let showData = !isLoading;
    let rowData = [];

    !isLoading &&
        users.forEach((data, index) => {
            let user = data.userId;
            if (user) {
                if (
                    user.name.toLowerCase().includes(searchVal.toLowerCase()) ||
                    data?.generalTemp?.accountEmail.toLowerCase().includes(searchVal.toLowerCase()) ||
                    (data.storeName && data.storeName.toLowerCase().includes(searchVal.toLowerCase())) ||
                    user.userType.toLowerCase().includes(searchVal.toLowerCase())
                ) {
                    let displayDate = "";
                    if (new Date(user.updatedAt).getDate() == new Date().getDate()) {
                        if (new Date().getHours() - new Date(user.updatedAt).getHours() == 0) {
                            displayDate =
                                new Date().getMinutes() - new Date(user.updatedAt).getMinutes() + " minutes ago";
                        } else {
                            displayDate = new Date().getHours() - new Date(user.updatedAt).getHours() + " hour ago";
                        }
                    } else {
                        displayDate = new Date(user.updatedAt).toString().substring(0, 15);
                    }

                    rowData.push({
                        sl_no: index + 1,
                        name: user.name,
                        review: (
                            <div className={styles.newContent}>
                                {user.updateStatus == "REVIEWED" ? (
                                    <span className={styles.old}>REVIEWED</span>
                                ) : (
                                    user.updateStatus == "PENDING" && <span className={styles.new}>REVIEW</span>
                                )}
                            </div>
                        ),
                        email: data?.generalTemp?.accountEmail,
                        storeName: data.storeName ? data.storeName : "-",
                        userType: user.userType,
                        date: moment(user.createdAt).format("YYYY-MM-DD"),
                        reviewOn: displayDate,
                        actions: (
                            <div className="actions">
                                <Action userId={user} />
                            </div>
                        ),
                    });
                }
            }
        });

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.leftHeader}>&nbsp;</div>

                <div className={styles.rightHeader}>
                    <TextField
                        label="Search Here"
                        className={styles.search}
                        value={searchVal}
                        onChange={(e) => setSearchVal(e.target.value)}
                    />
                </div>
            </div>

            {isLoading && <AppLoader />}

            {showData && (
                <DataTable
                    noHeader={true}
                    fixedHeader={true}
                    pagination={true}
                    fixedHeaderScrollHeight={"calc(100vh - 320px)"}
                    columns={[
                        { name: "Sl No", selector: "sl_no", sortable: true },
                        { name: "Name", selector: "name", sortable: true },
                        { name: "Review", selector: "review", sortable: true },
                        { name: "Email", selector: "email", sortable: true },
                        { name: "StoreName", selector: "storeName", sortable: true },
                        { name: "User Type", selector: "userType", sortable: true },
                        { name: "Date of Sign Up", selector: "date", sortable: true, minWidth: "150px" },
                        { name: "For Review", selector: "reviewOn", sortable: true },
                        { name: "Action", selector: "actions" },
                    ]}
                    data={rowData}
                />
            )}
        </div>
    );
};
const mapStateToProps = (state) => ({
    users: state.serviceProvider.users,
});
export default withRouter(connect(mapStateToProps, { getAllServiceProviders })(ViewServiceProvider));
