import React from "react";
import styles from "./TranslateComp.module.css";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";

import { connect } from "react-redux";
import { showAlert } from "../../containers/app/actions";

import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import axios from "axios";
import AppLoader from "../utils/AppLoader/AppLoader";
import BtnLoader from "../utils/BtnLoader/BtnLoader";

import S3 from "react-aws-s3";
import { s3Config } from "../../config/config";

// let serverUrl = "http://localhost:6001/api";
let serverUrl = "https://server.21done.de/api";

let cancelToken;
const TranslateComp = (props) => {
    const [state, setState] = React.useState(false);
    const [tab, setTab] = React.useState("en");
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, [tab]);

    const getData = () => {
        setState(false);
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Cancelling the previours req");
        }
        cancelToken = axios.CancelToken.source();
        axios({
            method: "get",
            url: serverUrl + "/basic-auth/getTranslationFile?type=" + tab,
            externalUrl: true,
            auth: {
                username: process.env.REACT_APP_BA_USERNAME,
                password: process.env.REACT_APP_BA_PASSWORD,
            },
            cancelToken: cancelToken.token,
        })
            .then((res) => {
                setState(res.data);
            })
            .catch((err) => {
                console.log(err);
                props.showAlert("Error: file fetch error");
            });
    };

    const updateFileData = () => {
        setLoading(true);
        const textFile = new Blob([JSON.stringify(state)], { type: "text/json" });
        var formData = new FormData();
        formData.append("file", textFile);

        axios
            .post(serverUrl + "/basic-auth/updateTranslationFile?lang=" + tab, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                auth: {
                    username: process.env.REACT_APP_BA_USERNAME,
                    password: process.env.REACT_APP_BA_PASSWORD,
                },
                externalUrl: true,
            })
            .then((res) => {
                setLoading(false);
                props.showAlert("Updated Successfully");
            })
            .catch((err) => {
                setLoading(false);
                props.showAlert("Error: file fetch error");
            });
    };

    const handleChange = (e) => setState(e);
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Tabs value={tab}>
                    <Tab label="English" value="en" onClick={() => setTab("en")} />
                    <Tab label="German" value="de" onClick={() => setTab("de")} />
                </Tabs>

                {loading ? (
                    <BtnLoader className="btnGreen" />
                ) : (
                    <button className="btnGreen" onClick={updateFileData}>
                        Update
                    </button>
                )}
            </div>
            {!state && <AppLoader />}
            {state && (
                <Paper className={styles.content} variant="outlined">
                    {state && <Editor value={state} onChange={handleChange} />}
                </Paper>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.app.auth,
});
export default connect(mapStateToProps, { showAlert })(TranslateComp);
