import React from 'react'
import styles from './UserJourneyComp.module.css'

import {withRouter,useLocation} from 'react-router-dom'

import {getAllMyJourney,getAllSentJourney,updateMyJourney} from '../../../containers/endUser/actions'
import {showAlert} from '../../../containers/app/actions'
import {connect} from 'react-redux'
import axios from 'axios'

import Radio from '@material-ui/core/Radio'

import AppLoader from '../../utils/AppLoader/AppLoader'

import AddRoundedIcon from '@material-ui/icons/AddRounded';

import OneJourneyMap from './OneJourneyMap/OneJourneyMap'
import TwoJourneyMap from './TwoJourneyMap/TwoJourneyMap'

import BtnPopover from '../../utils/BtnPopover/BtnPopover'
import ErrorPage from '../../utils/ErrorPage/ErrorPage'
import EditPurposeJourney from './EditPurposeJourney/EditPurposeJourney'
import StartBeing from './StartBeing/StartBeing' 
import EditRoundedIcon from '@material-ui/icons/EditRounded';

const UserJourneyComp = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [journeyData,setJourneyData] = React.useState(false);
    const [modal,setModal] = React.useState(false);
    const [state,setState] = React.useState("USER-JOURNEY")
    const [radio,setRadio] = React.useState(false);
    const [type,setType] = React.useState("EDIT")

    React.useEffect(() => { 
        if(query.get("journeyId") && query.get("journeyType") == "MY_JOURNEY") {
            if(props.myJourney) {
                props.myJourney.forEach(journey => {
                    if(journey._id == query.get("journeyId")) {
                        setType("EDIT")
                        setJourneyData({...journey,globalGiving: props.selectedGlobalGiving ? props.selectedGlobalGiving : journey.globalGiving})
                    }
                })
            }  else {
                props.showAlert("Invalid Request");
                props.getAllMyJourney(query.get("userId"))
            }
        } else {
            setType("ADD")
            setState("START-BEING")
            setJourneyData({
                category: false,
                subCategoryList: [],
                journeyFor: "YOURSELF"
            });
        }
    },[props.myJourney]);
 
    const fetchDataForSubCategory = () => {
        let category = journeyData.category._id;
        let subCategory = journeyData.subCategoryList.map(val => val.subCategory._id);
        setModal("LOADING");

        axios({
            method: "get",
            url: "/user/utils/getPurposeJourneyDataForSubcategory",
            params: {
                category,
                subCategory
            }
        }).then(res => {
            let data = journeyData;
            data.subCategoryList = data.subCategoryList.map((val,index) => {
                return {
                    ...val,
                    challenge: res.data.challenges[index],
                    content: res.data.contents[index],
                    quote: res.data.quotes[index]
                }
            });
            data.doGood = res.data.doGood; 
            setJourneyData({...data});
            setTimeout(() => {
                type == "EDIT" ? updatePurposeJourney() : setModal(false);
            },1000)
        }).catch(err => {
            console.log(err) 
        })
    }


    const updatePurposeJourney = (status,journey) => {
        let journeyTemp = journeyData;
        setModal("LOADING");
        const url = type == "ADD" ? `/user/purpose-journey/addPurposeJourney?userId=${query.get("userId")}` : `/user/purpose-journey/updatePurposeJourney?userId=${query.get("userId")}`;
        const method = type == "ADD" ? "post" : "put";
        axios({
            method,
            url,
            data: {
                ...journeyTemp, 
                journeyId: query.get("journeyId")
            }
        }).then(res => {
            if(type == "ADD") {
                props.showAlert("Purpose Journey Added Succesfully") 
                setModal(false);
                if(query.get("bookingId")) {
                    props.history.push(`/user/life-coach?bookingId=${query.get("bookingId")}&updateType=CREATED`)
                } else {
                    props.getAllMyJourney(query.get("userId"))
                    props.history.push(`/user/end-users/PURPOSE-JOURNEY?userId=${query.get("userId")}`)
                }
            } else {
                props.showAlert("Purpose Journey Updated Succesfully") 
                props.updateMyJourney(res.data.journey)
                setModal(false);
            }
        }).catch(err => {
            if(err && err.response) 
                props.showAlert(err.response.data.error)
            else 
                props.showAlert("Something went wrong Try Again")

            setModal(false);
        })  
    }
 
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {!journeyData && <AppLoader />}

                {journeyData && 
                    <React.Fragment>
                        {state == "EDIT-PURPOSE-JOURNEY" &&
                            <EditPurposeJourney 
                                journeyData={journeyData} 
                                setState={(val) => setState(val)} 
                                handleEditChanges={val => setJourneyData(val)} 
                                updatePurposeJourney={fetchDataForSubCategory}
                            />}

                        {state == "START-BEING" && 
                            <StartBeing 
                                journeyData={journeyData} 
                                setState={(val) => setState(val)} 
                                handleEditChanges={val => setJourneyData(val)}
                            />}


                        {state == "USER-JOURNEY" &&
                            <React.Fragment> 
                                {journeyData.status == "ACCEPTED" && query.get("journeyType") == "MY_JOURNEY" &&
                                    <button className={`btnLight ${styles.btnEditTop}`} onClick={() => setState("EDIT-PURPOSE-JOURNEY")}>
                                        <EditRoundedIcon />
                                        Edit
                                    </button>}

                                {type == "ADD" &&
                                    <button className={`btnLight ${styles.btnEditTop}`} onClick={updatePurposeJourney}>
                                        <AddRoundedIcon />
                                        Create Journey
                                    </button>}

                                <div className={styles.journeyMap}>
                                    {journeyData.subCategoryList.length == 1
                                        ?
                                    <OneJourneyMap 
                                        journeyData={journeyData} 
                                        redirect={true}
                                    />
                                        :
                                    <TwoJourneyMap 
                                        journeyData={journeyData} 
                                        redirect={true}
                                    />}
                                </div>

                                {query.get("journeyType") == "MY_JOURNEY" && 
                                    <div className={styles.btnContainer}>
                                        {journeyData.status == "PENDING" &&
                                            <React.Fragment>
                                                <BtnPopover className="btnBorderGreen" btnText="Accept" onClick={() => setModal("ACCEPT")} 
                                                    message="Click to start your journey! Don’t worry if the journey doesn’t fit perfectly, you can still make adjustments" 
                                                />

                                                <button className="btnBorderGreen" onClick={() => setState("EDIT-PURPOSE-JOURNEY")}>Edit</button>
    
                                                <BtnPopover className="btnBorderGreen" btnText="Decline" onClick={() => setModal("DECLINE")} 
                                                    message="Click if you think the journey doesn't fit you. You can then choose to define on your own, have your friend redefine it or take a pass!" 
                                                />
                                            </React.Fragment>}
                                    </div>}
                                    
                            </React.Fragment>}
                    </React.Fragment>}


                {modal &&
                    <ErrorPage open={modal} onClose={() => setModal(false)}>
                        <div className={styles.modalContent}>
                            {modal == "ACCEPT" &&
                                <React.Fragment>
                                    <h4>It's amazing that you've accepted the purpose journey your friend has specially created for you.</h4>
                                    <p><b>Would you like to:</b></p>

                                    <div className={styles.option}>
                                        <p>Select Charitable Project to Support:
                                            {journeyData.globalGiving && <span style={{fontSize: 22}}>&nbsp; &#10004;</span>}
                                        </p>
                                        <Radio type="radio" name="accept" checked={radio == "SELECT-CHARITY-PROJECT"} onClick={() => setRadio("SELECT-CHARITY-PROJECT")}/>
                                    </div>

                                    <div className={styles.option}>
                                        <p>Start My Journey:</p>
                                        <Radio type="radio" name="accept" checked={radio == "START-JOURNEY"} onClick={() => setRadio("START-JOURNEY")}/>
                                    </div>

                                    <div className={styles.btnCenter} disabled>
                                        <button className="btnBorderGreen" onClick={() => {
                                            if(radio == "SELECT-CHARITY-PROJECT")
                                                props.history.push("/21-tage-plan?type=WHAT-THE-WORLD-NEEDS&journeyType=" + query.get("journeyType") +"&journeyId=" + query.get("journeyId"))
                                            else
                                                updatePurposeJourney("ACCEPTED")
                                        }}>Confirm</button>
                                    </div>
                                </React.Fragment>}

                            {modal == "DECLINE" &&
                                <React.Fragment>
                                    <h4>To decline the challenge by your friend, please select one of the following options.</h4>
                                    <p><b>I’d like to:</b></p>

                                    <div className={styles.option}>
                                        <p>Define my own purpose:</p>
                                        <Radio type="radio" checked={radio == "DEFINE_OWN_PURPOSE"} onClick={() => setRadio("DEFINE_OWN_PURPOSE")}/>
                                    </div>

                                    <div className={styles.option}>
                                        <p>Take a pass:</p>
                                        <Radio type="radio" checked={radio == "PASS"} onClick={() => setRadio("PASS")}/>
                                    </div> 

                                    <div className={styles.btnCenter}>
                                        <button className="btnBorderGreen" onClick={() => setModal(radio)}>Confirm</button>
                                    </div>
                                </React.Fragment>}

                            {modal == "PASS" &&
                                <React.Fragment>
                                    <h4>We’re sorry to see you go this time, but we always welcome you to create a purpose journey or challenge your friend to one!</h4>
                                    <h6 className={styles.alignCenter}>Life is one hell of an adventure, have fun!</h6> 

                                    <div className={styles.btnCenter}>
                                        <button className="btnBorderGreen" onClick={() => updatePurposeJourney("REJECTED")}>Confirm</button>
                                    </div>
                                </React.Fragment>}

                            {modal == "DEFINE_OWN_PURPOSE" &&
                                <React.Fragment>
                                    <h4>We’ve informed your friend that you’d like to define your own purpose! Now, let’s get started on defining your own purpose</h4>

                                    <div className={styles.btnCenter}>
                                        <button className="btnBorderGreen" onClick={() => updatePurposeJourney("REJECTED")}>Confirm</button>
                                    </div>
                                </React.Fragment>}

                            {modal == "LOADING" &&
                                <AppLoader />
                            }

                                
                        </div>
                    </ErrorPage>}
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    myJourney: state.endUser.myJourney, 
})
export default withRouter(connect(mapStateToProps, {getAllMyJourney,getAllSentJourney,updateMyJourney,showAlert})(UserJourneyComp));