import React from 'react'
import styles from './ViewDiscountCode.module.css';
 
import Button from '@material-ui/core/Button' 
import TextField from '@material-ui/core/TextField'

import AddRoundedIcon from '@material-ui/icons/AddRounded';

import AppLoader from '../../utils/AppLoader/AppLoader';
import DataTable from 'react-data-table-component';
import Action from './Action/Action'

import {connect} from 'react-redux'
import {getAllDiscountCodes} from '../../../containers/discountCode/actions'
import {withRouter} from 'react-router-dom'


const ViewDiscountCode = (props) => { 
    const [codes,setCodes] = React.useState(props.codes); 
    const [searchVal,setSearchVal] = React.useState("");

    React.useEffect(() => {
        if(!props.codes) {
            props.getAllDiscountCodes();
        }
        setCodes(props.codes);
    },[props.codes]);

    let isLoading = !codes;
    let showData = !isLoading;
    let rowData = [];

    !isLoading && codes.forEach((code,index) => { 
        if(code.discountName.toLowerCase().includes(searchVal.toLowerCase()) || code.discountType.toLowerCase().includes(searchVal.toLowerCase())) {
            rowData.push({
                sl_no: index + 1,
                actions: <Action code={code} getAllDiscountCodes={props.getAllDiscountCodes} />,
                discountName: code.discountName,
                discountType: code.discountType,  
                codeUsed: props.codeUsed && props.codeUsed[code._id] ? props.codeUsed[code._id] : "NAN",
                discountTypeValue: code.discountTypeValue,
                selectedCountry: code.discountTypeValue == "SELECTED-COUNTRIES" ? code.selectedCountry.map(e => e.label).join(", ") : <span className="text-orange">NAN</span>,
                excludeShippingTraffic: code.excludeShippingTraffic ? <span className="text-green">YES</span> : <span className="text-red">NO</span>,
                excludeShippingTrafficValue: code.excludeShippingTraffic ? code.excludeShippingTrafficValue : <span className="text-orange">NAN</span>,
                applicableFor: code.applicableFor,
                applicaleProductList: code.applicableFor == "SPECIFIC-PRODUCTS" ? code.applicaleProductList.map(e => e.eventName).join(", ") : <span className="text-orange">NAN</span>,
                applicableCategoryList: code.applicableFor == "SPECIFIC-CATEGORY" ? code.applicableCategoryList.map(e => e.categoryName).join(", ") : <span className="text-orange">NAN</span>,
                minimumRequirement: code.minimumRequirement,
                minimumRequirementValue: code.minimumRequirement != "NO-REQUIREMENT" ? code.minimumRequirementValue : <span className="text-orange">NAN</span>,
                customerType: code.customerType,
                customerGroup: code.customerType == "CUSTOMER-GROUP" ? code.customerGroup.map(e => e).join(", ") : <span className="text-orange">NAN</span>,
                customerList: code.customerType == "CUSTOMERS" ? code.customerList.map(e => e.email).join(", ") : <span className="text-orange">NAN</span>,
                isThereCountLimit: code.isThereCountLimit ? <span className="text-green">YES</span> : <span className="text-red">NO</span>,
                discountCountLimit: code.isThereCountLimit ? code.discountCountLimit : <span className="text-orange">NAN</span>,
                isThereCustomerCountLimit: code.isThereCustomerCountLimit ? <span className="text-green">YES</span> : <span className="text-red">NO</span>,
                discountCustomerCountLimit: code.isThereCustomerCountLimit ? code.discountCustomerCountLimit : <span className="text-orange">NAN</span>,
                start: code.startDate.substr(0,10) + "/" + code.startTime,
                end: code.endDate.substr(0,10) + "/" +code.endTime,
                date: new Date(code.createdAt).toString().substring(0,15), 
            })
        } 
    }); 
 
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.leftHeader}>
                    &nbsp;
                </div>

                <div className={styles.rightHeader}>
                    <TextField
                        label="Search Here"
                        className={styles.search}
                        value={searchVal}
                        onChange={e => setSearchVal(e.target.value)}
                    />
                    <Button color="primary" variant="contained" endIcon={<AddRoundedIcon />} onClick={() => props.history.push("/user/discount-codes/ADD-DISCOUNT-CODE")}>Add Discount Code</Button>
                </div>
            </div>

            {isLoading && <AppLoader />}

            {showData &&
            <DataTable 
                noHeader={true} 
                fixedHeader={true}
                pagination={true}
                fixedHeaderScrollHeight={"calc(100vh - 320px)"}
                columns={[
                    {name: "Action",selector: "actions",minWidth: '200px'},
                    {name: "Sl No",selector: "sl_no",sortable: true,wrap: true,maxWidth: '30px'},
                    {name: "Discount Code",selector: "discountName",sortable: true,wrap: true,minWidth: '120px'},
                    {name: "Code Used",selector: "codeUsed",sortable: true,wrap: true,minWidth: '160px'},
                    {name: "Discount Type",selector: "discountType",sortable: true,wrap: true,minWidth: '160px'},
                    {name: "Discount Type Value",selector: "discountTypeValue", sortable: true,wrap: true,minWidth: '120px'},
                    {name: "Selected Country",selector: "selectedCountry", sortable: true,wrap: true,minWidth: '250px'},
                    {name: "Exclude Shiping Tarrif",selector: "excludeShippingTraffic", sortable: true,wrap: true,minWidth: '140px'},
                    {name: "Exclude Shiping Tarrif Value",selector: "excludeShippingTrafficValue", sortable: true,wrap: true,minWidth: '140px'},
                    {name: "Applicable For",selector: "applicableFor", sortable: true,wrap: true,minWidth: '180px'},
                    {name: "Applicable Product List",selector: "applicaleProductList", sortable: true,wrap: true,minWidth: '250px'},
                    {name: "Applicable Category List",selector: "applicableCategoryList", sortable: true,wrap: true,minWidth: '250px'},
                    {name: "Minimum Requirement",selector: "minimumRequirement", sortable: true,wrap: true,minWidth: '240px'},
                    {name: "Minimum Purchase / Number ",selector: "minimumRequirementValue", sortable: true,wrap: true,minWidth: '140px'},
                    {name: "Customer Type",selector: "customerType", sortable: true,wrap: true,minWidth: '140px'},
                    {name: "Customer Group",selector: "customerGroup", sortable: true,wrap: true,minWidth: '250px'},
                    {name: "Customers ",selector: "customerList", sortable: true,wrap: true,minWidth: '400px'},
                    {name: "Discount Code Limit ",selector: "isThereCountLimit", sortable: true,wrap: true,minWidth: '140px'},
                    {name: "Discount Code Limit Value",selector: "discountCountLimit", sortable: true,wrap: true,minWidth: '140px'},
                    {name: "Discount Code Customer Limit ",selector: "isThereCustomerCountLimit", sortable: true,wrap: true,minWidth: '140px'},
                    {name: "Discount Code Customer Limit Value ",selector: "discountCustomerCountLimit", sortable: true,wrap: true,minWidth: '140px'},

                    {name: "Start Date/Time",selector: "start", sortable: true,wrap: true,minWidth: '160px'},
                    {name: "End Date/Time",selector: "end", sortable: true,wrap: true,minWidth: '160px'},

                    {name: "Created On",selector: "date",sortable: true, wrap: true,minWidth: '160px'},
                ]}
                data={rowData}
            />}

        </div>
    )
}
const mapStateToProps = state => ({
    codes: state.discountCode.codes,
    codeUsed: state.discountCode.codeUsed
})
export default withRouter(connect(mapStateToProps,{getAllDiscountCodes})(ViewDiscountCode));