export const SET_END_USERS = "SET_END_USERS";
export const SET_REFERRALS = "SET_REFERRALS";
export const SET_SENT_JOURNEYS = "SET_SENT_JOURNEYS";
export const SET_MY_JOURNEYS = "SET_MY_JOURNEYS";

export const UPDATE_MY_JOURNEY = "UPDATE_MY_JOURNEY";
export const SET_USER_BOOKINGS = "SET_USER_BOOKINGS";
export const SET_USER_BOOKING = "SET_USER_BOOKING";
export const SET_SERVICES = "SET_SERVICES";
export const SET_SP_SERVICE_STORE_PROFILE = "SET_SP_SERVICE_STORE_PROFILE";
export const SET_CART_ITEMS = "SET_CART_ITEMS";
export const MANAGE_CART_QUANTITY = "MANAGE_CART_QUANTITY";

export const SET_GLOBAL_GIVING_PROJECTS = "SET_GLOBAL_GIVING_PROJECTS";

export const MANAGE_CART_DELETE = "MANAGE_CART_DELETE";
export const SET_BILLING_ADDRESS = "SET_BILLING_ADDRESS";
export const SET_GLOBAL_GIVING = "SET_GLOBAL_GIVING";

export const SET_BASIC_CART_ITEMS = "SET_BASIC_CART_ITEMS";

export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION";
export const SET_SUBSCRIPTION_COUNT = "SET_SUBSCRIPTION_COUNT";
export const SET_SURVEY = "SET_SURVEY";
export const SET_SURVEY_DROP_DOWN = "SET_SURVEY_DROP_DOWN";
export const SET_SURVEY_COUNT = "SET_SURVEY_COUNT";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_NOTIFICATIONS_COUNT = "SET_NOTIFICATIONS_COUNT";
export const SET_GRAPH_DATA = "SET_GRAPH_DATA";
export const SET_RATINGS = "SET_RATINGS";
export const SET_RATINGS_COUNT = "SET_RATINGS_COUNT";

export const SET_PROMO = "SET_PROMO";
export const SET_PROMO_COUNT = "SET_PROMO_COUNT";

export const SET_COMPANY_ID = "SET_COMPANY_ID";
export const SET_COMPANY_ID_COUNT = "SET_COMPANY_ID_COUNT";
export const SET_B2B_USERS = "SET_B2B_USERS";
