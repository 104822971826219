import * as actionTypes from './actionTypes'

const initialState = {
    scheduledData: false,
    bookings:false
}
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.GET_SCHEDULE_DATES:
            return { ...state, scheduledData: action.payload }
            break;
        case actionTypes.GET_ALL_BOOKINGS:
            return {...state,bookings:action.payload}
        default:
            return state
    }
}

export default reducer;