import React from "react";
import styles from "./MeetingInfo.module.css";

import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { TextField } from "@material-ui/core";
import axios from "axios";
import BtnLoader from "../../../utils/BtnLoader/BtnLoader";

const MeetingInfo = (props) => {
    const [formData, setFormData] = React.useState({
        meetingLink: "",
        meetingInfo: "",
    });
    const [error, setError] = React.useState({
        meetingLink: false,
        // meetingInfo: false,
    });

    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        setFormData({
            meetingLink: props.open[0] && props.open[0].meetingLink ? props.open[0].meetingLink : "",
            meetingInfo: props.open[0] && props.open[0].meetingInfo ? props.open[0].meetingInfo : "",
        });
    }, [props.open]);
    const validate = () => {
        const err = {
            meetingLink: false,
            // meetingInfo: false,
        };
        let validData = true;
        let isWebsite = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

        setError({ ...err });
        Object.keys(err).forEach((key) => {
            if (formData[key] == "") {
                err[key] = `Field cannot be empty`;
                validData = false;
            }
        });
        if (!isWebsite.test(formData.meetingLink)) {
            err["meetingLink"] = "Enter valid url";
            validData = false;
        }
        setError({ ...err });

        return validData;
    };
    const onSubmit = () => {
        if (validate()) {
            setLoading(true);
            let bookingId = props.open.map((val) => val._id);
            axios({
                method: "put",
                url: "/dashboard/life-coaches/updateMeetingInfo",
                data: {
                    ...formData,
                    bookingId,
                    bookings: props.booking,
                },
            })
                .then((res) => {
                    setLoading(false);
                    props.showAlert("Meeting Info Updated");
                    props.getBookings();
                    props.onClose();
                    setFormData({
                        meetingInfo: "",
                        // meetingLink: "",
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                    if (err && err.response && err.response.data && err.response.data.error) {
                        props.showAlert(err.response.data.error);
                    } else {
                        props.showAlert("Something went wrong ! Try Again");
                    }
                });
        }
    };
    return (
        <Modal open={props.open} onClose={props.onClose} className={styles.container}>
            <div className={styles.content}>
                <IconButton className={styles.close} onClick={props.onClose}>
                    <CloseRoundedIcon />
                </IconButton>

                <h1>Meeting Information</h1>

                <TextField
                    variant="outlined"
                    className={styles.textField}
                    fullWidth
                    value={formData.meetingLink}
                    onChange={(e) => setFormData({ ...formData, meetingLink: e.target.value })}
                    label="Meeting Link"
                    placeholder="https://example.com"
                    error={error.meetingLink}
                    helperText={error.meetingLink}
                />

                {/* <TextField
                    variant="outlined"
                    className={styles.textField}
                    multiline
                    fullWidth
                    rows={6}
                    value={formData.meetingInfo}
                    // error={error.meetingInfo}
                    // helperText={error.meetingInfo}
                    onChange={e => setFormData({ ...formData, meetingInfo: e.target.value })}
                    placeholder="Please leave any meeting information including passcode to enter the meeting session or other remarks. (Optional)"
                /> */}

                {loading ? (
                    <div className="btnCenter">
                        <BtnLoader className="btnGreen" />
                    </div>
                ) : (
                    <div className="btnCenter">
                        <button className={"btnGreen"} onClick={onSubmit}>
                            Update{" "}
                        </button>
                    </div>
                )}
            </div>
        </Modal>
    );
};
export default MeetingInfo;
