import React from 'react'
import styles from './Duration.module.css'
 
import TextField from '@material-ui/core/TextField'

import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';

import moment from 'moment'

const Duration = (props) => { 

    const formData = props.formData;
    const error = props.error;
    let minDate = new Date().toISOString().slice(0,10);
    let minTime = new Date().getHours() + ":" + new Date().getMinutes();
 
    return (
        <div className={styles.compContent}>
            <h3>Discount Code Duration: </h3>

            <div className={styles.formGroup}>
                <div className={styles.formContent}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker 
                            label="Start Date"
                            variant="inline" 
                            autoOk={true}
                            InputLabelProps={{ shrink: true, required: true }}
                            InputProps={{inputProps: { min: minDate}}}
                            value={formData.startDate}
                            onChange={date => props.setFormData({...formData,startDate: moment(date).format("yyyy-MM-DD")})}
                            error={error.startDate}
                            helperText={error.startDate}
                            format="yyyy-MM-dd"
                            inputVariant="outlined" 
                            className={styles.textField}
                            minDate={minDate}
                        />
                    </MuiPickersUtilsProvider>
                    <TextField
                        type="time"
                        label="Start Time"
                        variant="outlined" 
                        InputLabelProps={{ shrink: true, required: true }}
                        InputProps={{inputProps: { min: minTime}}}
                        value={formData.startTime}
                        minDate={minTime}
                        onChange={e => props.setFormData({...formData,startTime: e.target.value})}
                        error={error.startTime}
                        helperText={error.startTime}
                        className={styles.textField}
                    />
                </div>

                <div className={styles.formContent}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker 
                            label="End Date"
                            variant="inline"   
                            autoOk={true}
                            InputLabelProps={{ shrink: true, required: true}}
                            InputProps={{inputProps: { min: formData.startDate}}}
                            value={formData.endDate}
                            format="yyyy-MM-dd"
                            minDate={formData.startDate}
                            onChange={date =>  props.setFormData({...formData,endDate:  moment(date).format("yyyy-MM-DD")})}
                            error={error.endDate}
                            inputVariant="outlined"
                            helperText={error.endDate}
                            // className={styles.textField}
                        />
                    </MuiPickersUtilsProvider>

                    <TextField
                        type="time"
                        label="End time"
                        variant="outlined"   
                        InputLabelProps={{ shrink: true, required: true}}
                        value={formData.endTime}
                        onChange={e =>  props.setFormData({...formData,endTime: e.target.value})}
                        error={error.endTime}
                        helperText={error.endTime}
                        className={styles.textField}
                    />
                </div>
            </div>
        </div>
    )
} 
export default Duration;