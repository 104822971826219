import React from 'react'; 
import styles from './BtnPopover.module.css'
 
export default function BtnPopover(props) { 
    return (
        <div className={styles.btnPoper}>
            <button 
                className={props.className} 
                onClick={props.onClick} 
            >{props.btnText}</button>
             
            <p>{props.message}</p> 
        </div>
    );
}
