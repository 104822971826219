import React from 'react'
import styles from './Biller.module.css'
 
import TextField from '@material-ui/core/TextField' 
import InputAdornment from '@material-ui/core/InputAdornment'

import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';

import axios from 'axios' 
import {connect} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {manageCartQuantity} from '../../../../containers/endUser/actions'

import AppLoader from '../../../utils/AppLoader/AppLoader'
import BtnLoader from '../../../utils/BtnLoader/BtnLoader'
import ModalPage from '../../../utils/ModalPage/ModalPage'
import Checkout from './Checkout/Checkout'

import globalIcon from '../../../../assets/svg/whatworldneeds.svg'


const Biller = (props) => { 
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [cartItems,setCartItems] = React.useState(false);
    const [code,setCode] = React.useState("")
    const [err,setErr] = React.useState(false)
    const [loading,setLoading] = React.useState(false);
    const [status,setStatus] = React.useState(false);
    const [discount,setDiscount] = React.useState(0)
    const [errorModal,setErrorModal] = React.useState(false); 
    const [open,setOpen] = React.useState(false) 

    React.useEffect(() => {
        setCartItems(props.cartItems)
    },[props.cartItems]);

 

    const onSubmit = () => {
        setErr(false);
        if(code.length < 3) {
            setErr("Enter valid Discount Code")
        } else {
            setLoading(true);
            let services = {}
            props.cartItems.forEach(val => {
                if(val.serviceId) {
                    services[val.serviceId._id] = val.quantity;
                }
            });
            axios({
                method: "post",
                url: "/user/cart/checkDiscountCode",
                data: {
                    services,
                    discountCode: code
                }
            }).then(res => {
                setStatus("CORRECT")
                setLoading(false)
                setDiscount(res.data.discount);
            }).catch(err => {
                setStatus("WRONG")
                setLoading(false)
                if(err && err.response) 
                    setErr(err.response.data.error)
                else 
                    setErr("Invalid Discount Code")
            })
        }
    }

    const onClickConfirm = () => {
        let valid = true;
        Object.keys(props.billingAddress).forEach(key => {
            if(props.billingAddress[key] == "" && key != "building") {
                console.log(props.billingAddress)
                valid = false;
            }
        });

        if(!props.globalGiving) {
            setErrorModal("GLOBAL_GIVING")
        } else if(!valid) {
            setErrorModal("BILLING_ADDRESS")
        } else {
            setOpen(true)
        }
    }

    let totalAmount = 0;
    const renderInfoBox = cartItems && cartItems.map((val,index) => {
        if(val.serviceId) {
            totalAmount += val.quantity * val.serviceId.pricePerPerson;
            let service = val.serviceId;
            let quantity = val.quantity;
            return (
                <div className={styles.qtyContent}>
                    <h2>{service.eventName}</h2>

                    {!val.journeyId &&
                        <div className={styles.qtyController}>
                            <RemoveRoundedIcon onClick={() => quantity > 1 && props.manageCartQuantity(val._id,quantity - 1,query.get("userId"))} />
                            <input value={quantity} onChange={(e) => e.target.value > 0 && props.manageCartQuantity(val._id,e.target.value.replace(/\D/,''),query.get("userId"))} type="number" />
                            <AddRoundedIcon  onClick={() => props.manageCartQuantity(val._id,quantity + 1,query.get("userId"))}/> 
                        </div>}
                    
                    <h3>{service.pricePerPerson * quantity} €</h3>
                </div>
            )
        } else return;
    }) 
 
    
    return (
        <React.Fragment>
            <ModalPage open={errorModal} onClose={() => setErrorModal(false)}>
                <div className={styles.errorContent}>
                    {errorModal == "GLOBAL_GIVING" &&
                        <React.Fragment>
                            <h1>Hey there, you have yet to select your charitable project!</h1>
                            <span>No additional payment required. 3% of the booking amount goes to helping others.</span>

                            <img src={globalIcon} />

                            <div className="btnCenter">
                                <button className="btnBorderGreen" onClick={() => setErrorModal(false)}>Got it</button>
                            </div>

                        </React.Fragment>  
                    }
                    {errorModal == "BILLING_ADDRESS" &&
                        <React.Fragment>
                            <h1>Hey there, you have yet to select your billing address!</h1> 

                            <img src={globalIcon} />

                            <div className="btnCenter">
                                <button className="btnBorderGreen" onClick={() => setErrorModal(false)}>Got it</button>
                            </div>

                        </React.Fragment>  
                    }
                </div>
            </ModalPage>

            <div className={styles.container}>
                <div className={styles.content}>
                    <h1>Order Summary</h1>

                    {!cartItems && 
                        <AppLoader />
                    }

                    {cartItems && 
                        <React.Fragment>
                            {renderInfoBox} 

                            <div className={styles.discountCode}>
                                <p>Promocode</p>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    value={code}
                                    onChange={e => {
                                        if(e.target.value.length <=10) {
                                            setCode(e.target.value.toUpperCase())
                                            setStatus(false)
                                            setErr(false)
                                        }
                                    }}
                                    className={styles.textField} 
                                    error={err}
                                    helperText={err}
                                    InputProps={{
                                        startAdornment: 
                                        <InputAdornment position="start">
                                            {status == "CORRECT" && <CheckCircleOutlineRoundedIcon style={{color: "green"}} />}    
                                            {status == "WRONG" && <CancelRoundedIcon style={{color: "red"}} onClick={() => {
                                                setCode("")
                                                setStatus(false)
                                                setErr(false);
                                            }}/>}    
                                        </InputAdornment>,
                                    }} 
                                />
                                {loading 
                                    ?
                                <BtnLoader className="btnGreen" onlyLoader={true} />
                                    :
                                <button className="btnGreen" onClick={onSubmit}>Apply</button>}
                            </div>
                            
                            <div className={styles.listItem}>
                                <p>Sub Total</p>
                                <p>{totalAmount} €</p>
                            </div>

                            {status == "CORRECT" &&
                                <div className={styles.listItem}>
                                    <p>Promocode</p>
                                    <p className={styles.code}>
                                        <span>{code} &#10004;</span>
                                        <span>-{discount} €</span>
                                    </p>
                                </div>}

                            <div className={styles.listItem}>
                                <p><b>Total</b> (incl. MwSt.)</p>
                                <p><b>{totalAmount - discount} €</b></p>
                            </div> 

                        </React.Fragment>}
                </div> 
 
                <div className={styles.btnContainer}>
                    <button className="btnLight" onClick={onClickConfirm}>Confirm</button>
                </div> 
                    
                {open && 
                    <Checkout 
                        totalAmount={totalAmount} 
                        open={open} 
                        setOpen={val => setOpen(val)} 
                        cartItems={props.cartItems} 
                        discountCode={code} 
                        billingAddress={props.billingAddress}
                        onDone={() => {
                            setCode("")
                            setDiscount(false)
                            setErr(false)
                            setStatus(false)
                        }}
                    />} 
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = state => ({
    cartItems: state.endUser.cartItems,
    billingAddress: state.endUser.billingAddress,
    globalGiving: state.endUser.globalGiving
})
export default connect(mapStateToProps,{manageCartQuantity})(Biller);