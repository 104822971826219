import React from "react";
import styles from "./Service.module.css";
import AppLoader from "../utils/AppLoader/AppLoader";
import { ServiceSpecs } from "./ServiceSpecs/ServiceSpecs";
import { ServiceBenefits } from "./ServiceBenefits/ServiceBenefits";
import { Ratings } from "./Ratings/Ratings";
import AboutShop from "./AboutShop/AboutShop";

const ServicePreview = (props) => {
    const [service, setService] = React.useState(props.service);
    const [serviceProvider, setServiceProvider] = React.useState(props.serviceProvider);
    const [preview, setPreview] = React.useState(true);

    React.useEffect(() => {
        setService(JSON.parse(localStorage.getItem("serviceData")));
        setServiceProvider(JSON.parse(localStorage.getItem("serviceProviderData")));
    }, []);

    const isLoading = !service || !serviceProvider;
    const showData = service && serviceProvider;

    return (
        <div className={styles.container}>
            <div className={styles.bgContainer}>
                <div className={styles.content}>
                    {isLoading && <AppLoader color="light" />}
                    {showData && (
                        <React.Fragment>
                            <ServiceSpecs service={service} serviceProvider={serviceProvider} preview={preview} />
                        </React.Fragment>
                    )}
                </div>
            </div>
            {showData && (
                <div>
                    <ServiceBenefits service={service} serviceProvider={serviceProvider} preview={preview} />
                    <Ratings />
                </div>
            )}
            {serviceProvider && <AboutShop serviceProvider={serviceProvider} />}
        </div>
    );
};
export default ServicePreview;
