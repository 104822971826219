import * as actionTypes from './actionTypes'
import axios from 'axios'

export const getAllSchedules = (categoryId) => dispatch => {
    dispatch({
        type: actionTypes.GET_SCHEDULE_DATES,
        payload: false
    })
    axios({
        method: "get",
        url: "/dashboard/life-coaches/getSchedule/"+categoryId
    }).then(res => {
        let fetchedDates = res.data;
        if(!fetchedDates) fetchedDates=[];
        dispatch({
            type: actionTypes.GET_SCHEDULE_DATES,
            payload: fetchedDates
        })
    }).catch(err => {
        dispatch({
            type: actionTypes.GET_SCHEDULE_DATES,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}

export const getAllBookings = (id) => dispatch => {
    dispatch({
        type: actionTypes.GET_ALL_BOOKINGS,
        payload: false
    })
    axios({
        method: "get",
        url: "/dashboard/life-coaches/getAllBookings/"+id
    }).then(res => {
        let fetchedDates = res.data;
        if(!fetchedDates) fetchedDates=[];
        dispatch({
            type: actionTypes.GET_ALL_BOOKINGS,
            payload: fetchedDates
        })
    }).catch(err => {
        dispatch({
            type: actionTypes.GET_ALL_BOOKINGS,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}