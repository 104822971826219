import React from 'react'; 

import BreadCrump from '../../components/utils/BreadCrump/BreadCrump'; 
import TopBar from '../../components/utils/TopBar/TopBar';
import ConfDiscountCode from '../../components/DiscountCode/ConfDiscountCode/ConfDiscountCode';
import ViewDiscountCode from '../../components/DiscountCode/ViewDiscountCodes/ViewDiscountCode';
 

const DiscountCode = (props) => { 

    const [state,setState] = React.useState("VIEW-DISCOUNT-CODES");   

    React.useEffect(() => {
        if(Object.keys(navData).includes(props.match.params.type)) {
            setState(props.match.params.type)
        }
    },[props.match.params.type]);

    const navData = {
        "VIEW-DISCOUNT-CODES": {
            name: "View Discount Codes",
            path: "/user/discount-codes/VIEW-DISCOUNT-CODES"
        }, 
        "ADD-DISCOUNT-CODE": {
            name: "Add Discount Code",
            path: "/user/discount-codes/ADD-DISCOUNT-CODE"
        },
        "EDIT-DISCOUNT-CODE": {
            name: "Update Discount Code",
            path: "/user/discount-codes/EDIT-DISCOUNT-CODE"
        }, 
    }
    return (
        <div>
            <TopBar head="Discount Codes" />
            <BreadCrump 
                navItems={[
                    {name:"Discount Codes",path: "/user/discount-codes/VIEW-DISCOUNT-CODES"},
                    ,navData[state]]}
            /> 

            {state == "VIEW-DISCOUNT-CODES" && <ViewDiscountCode />}
            {state == "ADD-DISCOUNT-CODE" && <ConfDiscountCode type="ADD" />}
            {state == "EDIT-DISCOUNT-CODE" && <ConfDiscountCode type="EDIT" />}
        </div>
    )
}

export default DiscountCode;