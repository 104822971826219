import React from "react";
import styles from "./Modal.module.css";
import Modal from "@material-ui/core/Modal";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import { getAllEndUsers, disableAccount, deactivateAccount } from "../../../../containers/endUser/actions";
import { connect } from "react-redux";
import { auth } from "../../../../config/config";
import { showAlert } from "../../../../containers/app/actions";

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: 10,
    },
    password: {
        width: "300px",
        marginBottom: "95px",
        marginTop: "15px",
        "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
            height: "44px",
        },
    },
}));

const ModalPage = ({ open, onClose, tab, user, type, ...props }) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [newpassword, setNewPassword] = React.useState();

    let token = localStorage.getItem("token");

    function updatePassword() {
        axios({
            method: "get",
            url: `/dashboard/user/updatepassword?email=${user.email}&newPassword=${newpassword}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                setLoading(false);
                props.showAlert("password is successfully changed");
                onClose();
            })
            .catch((err) => {
                setLoading(false);
            });
    }

    return (
        <Modal open={open ? true : false} onClose={onClose} className={styles.modal}>
            <div className={styles.container}>
                <div className={styles.closeButton}>
                    <IconButton onClick={onClose}>
                        <CloseRoundedIcon />
                    </IconButton>
                </div>

                <div className={styles.content}>
                    {!type && (
                        <div className={styles.infoHeader}>
                            <h5>{tab && tab}</h5>
                        </div>
                    )}
                    {tab === "Change password" && (
                        <div>
                            <TextField
                                variant="outlined"
                                placeholder="Please enter new password"
                                className={classes.password}
                                value={newpassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <div className={styles.Button}>
                                <button onClick={() => updatePassword()}>Update</button>
                            </div>
                        </div>
                    )}
                    {!tab && type === "DISABLE" && (
                        <div className={styles.subscription}>
                            <h2>
                                Are you sure you want to {user && user.blocked ? "enable" : "disable"} <br /> this user?
                            </h2>
                            <p>
                                By disabling, this user will no longer
                                <br /> be able to access his/her account
                            </p>
                            <div className={styles.Button}>
                                <button
                                    onClick={() => {
                                        props.disableAccount(!user.blocked, user.userId);
                                    }}
                                >
                                    {loading ? "...Deactivating" : "Yes"}
                                </button>{" "}
                                <br />
                                <br />
                                <span
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    Cancel
                                </span>
                            </div>
                        </div>
                    )}
                    {/* Deactivate Modal */}

                    {!tab && type === "DEACTIVATE" && (
                        <div className={styles.subscription}>
                            <h2>
                                Are you sure you want to {props.isDeactivated ? "activate" : "deactivate"} <br /> this
                                user?
                            </h2>
                            {props.isDeactivated ? (
                                <p>
                                    By activating, this user will be able to
                                    <br /> access the premium features again
                                </p>
                            ) : (
                                <p>
                                    By deactivating, this user will no longer
                                    <br /> be able to access the premium features
                                </p>
                            )}

                            <div className={styles.Button}>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.deactivateAccount(user.userId);
                                        onClose();
                                    }}
                                >
                                    {loading ? "...Deactivating" : "Yes"}
                                </button>{" "}
                                <br />
                                <br />
                                <span
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    Cancel
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};
export default connect(null, {
    getAllEndUsers,
    showAlert,
    disableAccount,
    deactivateAccount,
})(ModalPage);
