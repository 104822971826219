import React from 'react'
import styles from './Security.module.css'

import CircularProgress from '@material-ui/core/CircularProgress'
import Checkbox from '@material-ui/core/Checkbox'

import ChangePassword from './ChangePassword/ChangePassword'
import ConfirmAlert from '../../../utils/ConfirmAlert/ConfirmAlert'

import {connect} from 'react-redux'
import {showAlert} from '../../../../containers/app/actions'
import axios from 'axios'
import ErrorPage from '../../../utils/ErrorPage/ErrorPage'

import {useLocation} from 'react-router-dom'

const Security = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [modal,setModal] = React.useState(false);
    const [loading,setLoading] = React.useState(false);
    const [err,setErr] = React.useState(false)

    const onDeleteAccount = (bool) => { 
        setLoading("DELETE");
        setErr(false)

        axios({
            method: "delete",
            url: `/user/user/deleteUserAccount?userId=${query.get("userId")}`, 
            data: {
                saveEmail: bool
            }
        }).then(res => {
            setLoading(false)    
            props.history.push("/user/end-user/VIEW-END-USERS")
        }).catch(err => {
            setLoading(false);
            if(err && err.response && err.response.data && err.response.data.error) {
                props.showAlert(err.response.data.error)
            } else {
                props.showAlert('Something went wrong Try Again')
            }
        }) 
    } 
    const onLogoutAllSessions = () => { 
        setLoading("LOGOUT");

        axios({
            method: "delete",
            url: `/user/user/logoutAllSessions?userId=${query.get("userId")}`, 
        }).then(res => {
            setLoading(false)  
            props.showAlert("Logged out from all devices")
        }).catch(err => {
            setLoading(false);
            if(err && err.response && err.response.data && err.response.data.error) {
                props.showAlert(err.response.data.error)
            } else {
                props.showAlert('Something went wrong Try Again')
            }
        }) 
    }  
    return (
        <React.Fragment>
            <ErrorPage open={err} onClose={() => setErr(false)}>
                <p>Are your sure you want to delete your account ?</p>

                <div className={styles.alignCenter}>
                    <Checkbox color="primary" />
                    <span className={styles.spanText}>I want my email address to be saved for future offers/promotional updates</span>
                </div>

                <div className={styles.btnContainer}>
                    <button onClick={() => setErr(false)}>Cancel</button>
 
                    <button onClick={() => onDeleteAccount(true)}>Yes, Delete</button>
                </div>
            </ErrorPage>
            <div className={styles.container}>
                <div className={styles.content}>
                    <ChangePassword open={modal} onClose={() => setModal(false)}/>
                    
                    <h1>Security</h1>
                    <p>Manage your activity settings</p>


                    <div className={styles.rowContent}>
                        <div className={styles.infoContent}>
                            <h4>Password</h4>
                            <p>Reset Your Password to keep your account secure</p>
                        </div>
                        
                        <button onClick={() => setModal(true)} variant="contained" color="secondary">Reset</button>
                    </div>

                    <div className={styles.rowContent}>
                        <div className={styles.infoContent}>
                            <h4>Active Session</h4>
                            <p>Selecting Sign out will delete your session</p>
                        </div>
                        
                        <ConfirmAlert msg="Sure you want to logout from all devices" onClickEvent={onLogoutAllSessions}>
                            <button 
                                variant="contained" 
                                color="secondary" 
                                endIcon={loading == "LOGOUT" && <CircularProgress size={16}/>}
                                disabled={loading == "LOGOUT"} 
                            >{loading == "LOGOUT" ? "Loading..." : "Sign out"}</button>
                        </ConfirmAlert>
                        
                    </div>

                    <div className={styles.rowContent}>
                        <div className={styles.infoContent}>
                            <h4>Delete Account</h4>
                            <p>Deleting the account  will delete all data and saved information</p>
                        </div>
                         
                        <button 
                            variant="contained" 
                            onClick={() => setErr(true)}
                            color="secondary" 
                            endIcon={loading == "DELETE" && <CircularProgress size={16}/>}
                            disabled={loading == "DELETE"} 
                        >{loading == "DELETE" ? "Loading..." : "Delete Account"}</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default connect(null,{showAlert})(Security);