import * as actionTypes from './actionTypes'
import axios from 'axios'

export const getAllSchedules = () => dispatch => {
    dispatch({
        type: actionTypes.SET_ADMIN_SCHEDULE_DATES,
        payload: false
    })
    axios({
        method: "get",
        url: "/dashboard/life-coach-admin/getSchedule"
    }).then(res => { 
        dispatch({
            type: actionTypes.SET_ADMIN_SCHEDULE_DATES,
            payload: res.data
        })
    }).catch(err => {
        dispatch({
            type: actionTypes.SET_ADMIN_SCHEDULE_DATES,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}

export const getAllBookings = () => dispatch => {
    dispatch({
        type: actionTypes.SET_ALL_BOOKING_DATA,
        payload: false
    })
    axios({
        method: "get",
        url: "/dashboard/life-coach-admin/getAllBookings"
    }).then(res => { 
        console.log(res.data)
        dispatch({
            type: actionTypes.SET_ALL_BOOKING_DATA,
            payload: res.data
        })
    }).catch(err => {
        dispatch({
            type: actionTypes.SET_ALL_BOOKING_DATA,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}