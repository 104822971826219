import {
    Avatar,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    Switch,
    TextField,
} from "@material-ui/core";
import React from "react";
import styles from "./EventPopup.module.css";
import Draggable from "react-draggable";
import { Close } from "@material-ui/icons";
import moment from "moment";
import { getMonthsShort } from "../../../../hooks/getMonths";
import { getWeek } from "../../../../hooks/getWeeks";


function getID(params) {
    return Math.random().toString(16).slice(2);
}

export default function EventPopup({
    open,
    close,
    formData,
    setEventInfo,
    eventObject,
    error,
    setError,
    setStartValidation,
    duplicate,
    setDuplicate,
    update,
    setUpdate,
    // updateEventMeetingLink,
    showButtons,
    setEventObject,
    addEvents,
    updateEvent,
    deleteEvent,
    checkBookings,
}) {
   
    const months = getMonthsShort();
    const weeks = getWeek();
    const [repeat, setRepeat] = React.useState(false);
    const [advanceOption, setAdvanceOption] = React.useState(false);
    // const [updateMeetingLink, setUpdateMeetingLink] = React.useState(false);
    const [btnLoading, setBtnLoading] = React.useState(null);

    React.useEffect(() => {
        /**
         * *! if byweekday or bymonth array length is 0 it removes the property
         */
        if (
            (eventObject.rrule.byweekday && eventObject.rrule?.byweekday.length == 0) ||
            (eventObject.rrule.bymonth && eventObject.rrule?.bymonth.length == 0)
        ) {
            setEventObject((val) => ({
                ...val,
                id: val.id,
                title: val.title,
                slotEndDate: val.slotEndDate,
                // meetingLink: val.meetingLink,
                duration: { days: val.duration.days, hours: val.duration.hours, minutes: val.duration.minutes },
                rrule: {
                    freq: val.rrule.freq,
                    dtstart: val.rrule.dtstart,
                    count: val.rrule.count,
                    until: val.rrule.until,
                },
                exdate: val.exdate,
            }));
        }
    }, [eventObject]);

    const handleAdd = () => {
        const success = addEvents();
        success && handleClose();
    };

    const handleDelete = async () => {
        /**
         * *! delete event
         */
        setBtnLoading("DELETE");
        const bookingPresent = await checkBookings();
        /**
         * *! if booking is not present then only it will call other functions
         */
        setBtnLoading(null);
        if (!bookingPresent) {
            deleteEvent();
            handleClose();
        }
    };

    const handleUpdate = async () => {
        /**
         * *! update event
         */
        setBtnLoading("UPDATE");
        const bookingPresent = await checkBookings();
        /**
         * *! if booking is not present then only it will call other functions
         */
        setBtnLoading(null);
        if (!bookingPresent) {
            const success = updateEvent();
            if (success) {
                handleClose();
            }
        }
    };

    // const handleUpdateMeetingLink = () => {
    //     /**
    //      * *! update meeting link
    //      */
    //     updateEventMeetingLink();
    //     handleClose();
    //     setUpdateMeetingLink(false);
    // };

    const handleClose = () => {
        /**
         * *! resets all the state
         */
        setRepeat(false);
        setAdvanceOption(false);
        duplicate && setDuplicate(false);
        update && setUpdate(false);
        setStartValidation(false);
        setEventInfo(null);
        setEventObject({
            id: getID(),
            title: "",
            slotEndDate: "",
            // meetingLink: "",
            duration: { days: "", hours: "", minutes: "" },
            rrule: {
                freq: "daily",
                dtstart: "",
                count: 1,
                until: "",
            },
            exdate: [],
        });
        setError((val) => ({
            slotEndDate: false,
            // meetingLink: false,
            rrule: {
                dtstart: false,
            },
        }));
        close();
    };

    return (
        <>
            {/* <Dialog
                open={updateMeetingLink}
                maxWidth="sm"
                disableEnforceFocus
                hideBackdrop
                onClose={() => setUpdateMeetingLink(false)}
                className={styles.dialog}
            >
                <DialogContent className={styles.meetingContainer}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t("serviceProviderFullCalender.Meeting Link")}
                                value={eventObject?.meetingLink}
                                variant="outlined"
                                size="small"
                                type="text"
                                placeholder="https://example.com"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => setEventObject((val) => ({ ...val, meetingLink: e.target.value }))}
                                error={error.meetingLink}
                                helperText={error.meetingLink}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div className={styles.actions}>
                        <Button size="small" variant="contained" color="primary" onClick={handleUpdateMeetingLink}>
                            {t("serviceProviderFullCalender.UPDATE")}
                        </Button>

                        <Button
                            size="small"
                            variant="text"
                            onClick={() => {
                                setUpdateMeetingLink(false);
                                handleClose();
                            }}
                        >
                            {t("serviceProviderFullCalender.CANCEL")}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog> */}
{console.log(duplicate)}
            <Draggable handle=".handle">
                <Dialog
                    open={open}
                    maxWidth="sm"
                    disableEnforceFocus
                    hideBackdrop
                    onClose={handleClose}
                    className={styles.dialog}
                >
                    {duplicate && (
                        <div className={styles.showError}>
                            <IconButton size="small" className={styles.errorClose} onClick={() => setDuplicate(false)}>
                                <Close color="primary" />
                            </IconButton>
                            <p>Event Exists in this particular date!</p>
                        </div>
                    )}
                    <DialogContent className={styles.container}>
                        <div className={styles.header}>
                            <h3 id="draggable" className={`handle ${styles.headerTitle}`}>
                                Add a Slot
                            </h3>
                            <IconButton size="small" onClick={handleClose}>
                                <Close color="primary" />
                            </IconButton>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Slot Date Start"
                                    value={eventObject?.rrule?.dtstart}
                                    variant="outlined"
                                    size="small"
                                    type="datetime-local"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ min: moment().format("YYYY-MM-DDTHH:mm") }}
                                    onChange={(e) =>
                                        setEventObject((val) => ({
                                            ...val,
                                            rrule: {
                                                ...val.rrule,
                                                dtstart: e.target.value,
                                            },
                                        }))
                                    }
                                    error={error.rrule?.dtstart}
                                    helperText={error.rrule?.dtstart}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Slot Date End"
                                    value={eventObject?.slotEndDate}
                                    variant="outlined"
                                    size="small"
                                    type="datetime-local"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ min: moment().format("YYYY-MM-DDTHH:mm") }}
                                    onChange={(e) => setEventObject((val) => ({ ...val, slotEndDate: e.target.value }))}
                                    error={error.slotEndDate}
                                    helperText={error.slotEndDate}
                                />
                            </Grid>

                            {/* {formData.locationType === "ONLINE" && !update && (
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t("serviceProviderFullCalender.Meeting Link")}
                                        value={eventObject?.meetingLink}
                                        variant="outlined"
                                        size="small"
                                        type="text"
                                        placeholder="https://example.com"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) =>
                                            setEventObject((val) => ({ ...val, meetingLink: e.target.value }))
                                        }
                                        error={error.meetingLink}
                                        helperText={error.meetingLink}
                                    />
                                </Grid>
                            )} */}

                            <Grid item xs={12}>
                                <FormControl fullWidth size="small">
                                    <Select
                                        variant="outlined"
                                        value={repeat}
                                        onChange={(e) => {
                                            setRepeat(e.target.value);
                                            if (e.target.value === false) {
                                                setRepeat(e.target.value);

                                                setEventObject((val) => ({
                                                    ...val,
                                                    slotEndDate: val.slotEndDate,
                                                    rrule: {
                                                        ...val.rrule,
                                                        freq: "daily",
                                                        dtstart: val.rrule.dtstart,
                                                        count: 1,
                                                    },
                                                }));
                                            } else {
                                                setEventObject((val) => ({
                                                    ...val,
                                                    rrule: {
                                                        ...val.rrule,
                                                        freq: e.target.value,
                                                        count: "",
                                                    },
                                                }));
                                            }
                                        }}
                                    >
                                        <MenuItem value={false}>Don't Repeat</MenuItem>
                                        <MenuItem value="daily">DAILY</MenuItem>
                                        <MenuItem value="weekly">WEEKLY"</MenuItem>
                                        <MenuItem value="monthly">MONTHLY</MenuItem>
                                        <MenuItem value="yearly">YEARLY</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {repeat && (
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Repeat Untill"
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            type="datetime-local"
                                            value={eventObject?.rrule?.until}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(e) =>
                                                setEventObject((val) => ({
                                                    ...val,
                                                    rrule: {
                                                        ...val.rrule,
                                                        until: e.target.value,
                                                    },
                                                }))
                                            }
                                            error={error.rrule?.until}
                                            helperText={error.rrule?.until}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    color="primary"
                                                    checked={advanceOption}
                                                    onChange={() => setAdvanceOption((val) => !val)}
                                                />
                                            }
                                            label="Advance options"
                                        />
                                    </Grid>
                                    {advanceOption ? (
                                        <>
                                            {/* <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    type="number"
                                                    value={eventObject?.rrule?.count}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                {t("serviceProviderFullCalender.Repeat For")}
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {t("serviceProviderFullCalender.Times")}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onChange={(e) =>
                                                        setEventObject((val) => ({
                                                            ...val,
                                                            rrule: {
                                                                ...val.rrule,
                                                                count: e.target.value,
                                                            },
                                                        }))
                                                    }
                                                />
                                            </Grid> */}

                                            <Grid item xs={12}>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <FormLabel>Repeat By Month</FormLabel>
                                                    </Grid>
                                                    {months.map((item, index) => (
                                                        <Grid key={item} item xs={4} sm={3}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        value={
                                                                            eventObject?.rrule?.bymonth &&
                                                                            eventObject?.rrule?.bymonth.includes(
                                                                                index + 1,
                                                                            )
                                                                        }
                                                                        color="primary"
                                                                        size="small"
                                                                        onChange={() => {
                                                                            let arr = eventObject?.rrule?.bymonth
                                                                                ? eventObject?.rrule?.bymonth
                                                                                : [];

                                                                            if (arr.includes(index + 1)) {
                                                                                arr = arr.filter(
                                                                                    (val) => val != index + 1,
                                                                                );
                                                                            } else {
                                                                                arr.push(index + 1);
                                                                            }
                                                                            setEventObject((val) => ({
                                                                                ...val,
                                                                                rrule: {
                                                                                    ...val.rrule,
                                                                                    bymonth: arr,
                                                                                },
                                                                            }));
                                                                        }}
                                                                    />
                                                                }
                                                                label={item}
                                                            />
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <FormLabel>Repeat By Week Day</FormLabel>
                                                    </Grid>
                                                    {weeks.map((item) => (
                                                        <Grid key={item.full} item xs={2} sm={1}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        value={
                                                                            eventObject?.rrule?.byweekday &&
                                                                            eventObject?.rrule?.byweekday.includes(
                                                                                item.half,
                                                                            )
                                                                        }
                                                                        icon={
                                                                            <Avatar
                                                                                className={styles.checkboxWeekLabel}
                                                                            >
                                                                                {item.half.charAt(0)}
                                                                            </Avatar>
                                                                        }
                                                                        checkedIcon={
                                                                            <Avatar
                                                                                className={
                                                                                    styles.checkboxWeekLabelActive
                                                                                }
                                                                            >
                                                                                {item.half.charAt(0)}
                                                                            </Avatar>
                                                                        }
                                                                        size="small"
                                                                        onChange={() => {
                                                                            let arr = eventObject?.rrule?.byweekday
                                                                                ? eventObject?.rrule?.byweekday
                                                                                : [];

                                                                            if (arr.includes(item.half)) {
                                                                                arr = arr.filter(
                                                                                    (val) => val != item.half,
                                                                                );
                                                                            } else {
                                                                                arr.push(item.half);
                                                                            }
                                                                            setEventObject((val) => ({
                                                                                ...val,
                                                                                rrule: {
                                                                                    ...val.rrule,
                                                                                    byweekday: arr,
                                                                                },
                                                                            }));
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : null}
                                </>
                            )}
                        </Grid>

                        <div className={styles.actions}>
                            {!showButtons && (
                                <Button size="small" variant="contained" color="primary" onClick={handleAdd}>
                                    ADD
                                </Button>
                            )}
                            {showButtons && (
                                <>
                                    {/* {formData.locationType === "ONLINE" && (
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                setUpdateMeetingLink(true);
                                                setUpdate(false);
                                                close();
                                            }}
                                        >
                                            {t("serviceProviderFullCalender.UPDATE MEETING LINK")}
                                        </Button>
                                    )} */}
                                    <Button size="small" variant="contained" color="primary" onClick={handleUpdate}>
                                        {btnLoading === "UPDATE" ? (
                                            <CircularProgress color="secondary" size={15} />
                                        ) : (
                                            "UPDATE"
                                        )}
                                    </Button>
                                    <Button size="small" variant="contained" color="secondary" onClick={handleDelete}>
                                        {btnLoading === "DELETE" ? <CircularProgress size={15} /> : "DELETE"}
                                    </Button>
                                </>
                            )}
                            <Button size="small" variant="text" onClick={handleClose}>
                                CANCEL
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </Draggable>
        </>
    );
}
