import React from "react";
import styles from "./Specifications.module.css";

import CheckBox from "@material-ui/core/Checkbox";

import englishIcon from "../../../../assets/svg/english.svg";
import germanyIcon from "../../../../assets/svg/germany.svg";

import maleIcon from "../../../../assets/svg/male.svg";
import femaleIcon from "../../../../assets/svg/female.svg";
import notappliIcon from "../../../../assets/svg/not_applicable.svg";

import childrenIcon from "../../../../assets/svg/children.svg";
import plusIcon from "../../../../assets/svg/18.svg";
import seniourIcon from "../../../../assets/svg/seniour.svg";
import allAgesIcon from "../../../../assets/svg/allAges.svg";

import singleIcon from "../../../../assets/svg/single.svg";
import pairIcon from "../../../../assets/svg/pair.svg";
import groupIcon from "../../../../assets/svg/group.svg";

import beginnerIcon from "../../../../assets/svg/beginner.svg";
import intermediateIcon from "../../../../assets/svg/intermediate.svg";
import advancedIcon from "../../../../assets/svg/advanced.svg";
import notAppIcon from "../../../../assets/svg/not_app.svg";

import publicIcon from "../../../../assets/svg/public.svg";
import freeIcon from "../../../../assets/svg/free.svg";
import paidIcon from "../../../../assets/svg/paid.svg";
import wheelchairIcon from "../../../../assets/svg/wheelchair.svg";
import familyIcon from "../../../../assets/svg/family.svg";
import pregnantIcon from "../../../../assets/svg/pregnant.svg";
import petIcon from "../../../../assets/svg/pet.svg";

const langData = [
  { name: "Deutsch", icon: germanyIcon, value: "DEUTSCH" },
  { name: "English", icon: englishIcon, value: "ENGLISH" },
];

const genData = [
  { name: "Female", icon: maleIcon, value: "FEMALE" },
  { name: "Male", icon: femaleIcon, value: "MALE" },
  { name: "All genders", icon: notappliIcon, value: "NOT_APPLICABLE" },
];

const ageData = [
  { name: "Children", icon: childrenIcon, value: "CHILDREN" },
  { name: "18+", icon: plusIcon, value: "18_PLUS" },
  { name: "Seniors", icon: seniourIcon, value: "SENIORS" },
];

const groupData = [
  { name: "Single", icon: singleIcon, value: "SINGLE" },
  { name: "Pair", icon: pairIcon, value: "PAIR" },
  { name: "Group", icon: groupIcon, value: "GROUP" },
];
const diffData = [
  { name: "Beginner", icon: beginnerIcon, value: "BEGINNER" },
  { name: "Intermediate", icon: intermediateIcon, value: "INTERMEDIATE" },
  { name: "Advanced", icon: advancedIcon, value: "ADVANCED" },
];

const accessData = [
  { name: "Public Transport", icon: publicIcon, value: "PUBLIC_TRANSPORT" },
  { name: "Free Parking", icon: freeIcon, value: "FREE_PARKING" },
  { name: "Paid Parking", icon: paidIcon, value: "PAID_PARKING" },
  {
    name: "Wheelchair Friendly",
    icon: wheelchairIcon,
    value: "WHEELCHAIR_FRIENDLY",
  },
  { name: "Family Friendly", icon: familyIcon, value: "FAMILY_FRIENDLY" },
  {
    name: "Pregnancy Friendly",
    icon: pregnantIcon,
    value: "PREGNANCY_FRIENDLY",
  },
  { name: "Pet Friendly", icon: petIcon, value: "PET_FRIENDLY" },
];

const Specifications = (props) => {
  const formData = props.formData;
  const error = props.error;
  const specifications = formData.specifications;
  return (
    <div className={styles.contentContainer}>
      <h2> Service Specifications</h2>

      <div className={styles.cards}>
        <div className={styles.card}>
          <h4>* Language <span className={styles.errorMessage}>{error&&(error.specifications.language)}</span></h4>
          {error.language && (
            <span className={styles.error}>{error.language}</span>
          )}

          {langData.map((val, key) => {
            return (
              <div className={styles.item} key={key}>
                <img src={val.icon} alt="" />
              
                <p>{val.name } </p>

                <CheckBox
                  checked={specifications.language.includes(val.value)}
                  onChange={() => {
                    let arr = specifications.language;
                    if (specifications.language.includes(val.value))
                      arr.splice(arr.indexOf(val.value), 1);
                    else arr.push(val.value);

                    props.setFormData({
                      ...formData,
                      specifications: { ...specifications, language: arr },
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className={styles.card}>
          <h4>Gender <span className={styles.errorMessage}>{error&&(error.specifications.gender)}</span></h4>

          {genData.map((val, key) => {
            return (
              <div className={styles.item} key={key}>
                <img src={val.icon} alt="" />
                <p>{val.name}</p>

                <CheckBox
                  checked={specifications.gender == val.value}
                  onChange={() => {
                    if (specifications.gender === val.value) {
                      props.setFormData({
                        ...formData,
                        specifications: {
                          ...specifications,
                          gender: "",
                        },
                      });
                    } else {
                      props.setFormData({
                        ...formData,
                        specifications: {
                          ...specifications,
                          gender: val.value,
                        },
                      });
                    }
                  }}
                />
              </div>
            );
          })}
        </div>

        <div className={styles.card}>
          <h4>* Group Size <span className={styles.errorMessage}>{error&&(error.specifications.size)}</span></h4>
          {error.size && <span className={styles.error}>{error.size}</span>}

          {groupData.map((val, key) => {
            return (
              <div className={styles.item} key={key}>
                <img src={val.icon} alt="" />
                <p>{val.name}</p>

                <CheckBox
                  checked={specifications.size.includes(val.value)}
                  onChange={() => {
                    let arr = specifications.size;
                    if (specifications.size.includes(val.value))
                      arr.splice(arr.indexOf(val.value), 1);
                    else arr.push(val.value);

                    props.setFormData({
                      ...formData,
                      specifications: { ...specifications, size: arr },
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className={styles.card}>
          <h4>Difficulty Level  <span className={styles.errorMessage}>{error&&(error.specifications.difficultyLevel)}</span></h4>
          {error.difficultyLevel && (
            <span className={styles.error}>{error.difficultyLevel}</span>
          )}

          {diffData.map((val, index) => {
            return (
              <div className={styles.item} key={index}>
                <img src={val.icon} alt="" />
                <p>{val.name}</p>

                <CheckBox
                  checked={specifications.difficultyLevel.includes(val.value)}
                  onChange={() => {
                    let arr = specifications.difficultyLevel;
                    if (specifications.difficultyLevel.includes(val.value))
                      arr.splice(arr.indexOf(val.value), 1);
                    else arr.push(val.value);

                    arr = arr.filter((name) => name != "NOT_APPLICABLE");
                    props.setFormData({
                      ...formData,
                      specifications: {
                        ...specifications,
                        difficultyLevel: arr,
                      },
                    });
                  }}
                />
              </div>
            );
          })}

          <div className={styles.item}>
            <img src={notAppIcon} alt="" />
            <p>All levels of difficulty</p>

            <CheckBox
              checked={specifications.difficultyLevel.includes(
                "NOT_APPLICABLE"
              )}
              onChange={() => {
                let arr = [];
                if (
                  !specifications.difficultyLevel.includes("NOT_APPLICABLE")
                ) {
                  arr.push("NOT_APPLICABLE");
                }

                props.setFormData({
                  ...formData,
                  specifications: { ...specifications, difficultyLevel: arr },
                });
              }}
            />
          </div>
        </div>
      </div>

     
    </div>
  );
};

export default Specifications;
