import { createMuiTheme } from "@material-ui/core";

const defaultMaterialTheme = createMuiTheme({
    spacing: 0,
    overrides: {

        MuiPickersToolbar: {
          toolbar: {
            display: "none"
          },
        }, 
        MuiPickersCalendarHeader: {
            switchHeader: {
                backgroundColor: "#2D4141",
                color: "white", 

                "& button": {
                    backgroundColor: "#2D4141" ,
                    color: "white"
                }, 
                "& p": { 
                    color: "white"
                }, 
                "& svg": {
                  color: "white"
                }
            }, 
            daysHeader: {
                "& span": {
                    color: "#2D4141"
                }
            }
        },
        MuiPickersDay: {
          day: {
            color: "#2D4141",
          },
          daySelected: {
            backgroundColor: "#2D4141",
            
            "& p": {
                color: "white"
            },

            "&:hover": {
                backgroundColor: "#2D4141",
                color: "white"
            }
          },
          dayDisabled: {
            color: "#2D4141",
            opacity: '0.5'
          },
          hover: {
            backgroundColor: "#2D4141",
            color: "white"
          },
        }, 
    },
});

export default defaultMaterialTheme;