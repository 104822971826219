import React from "react";
import styles from "./AddServiceProvider.module.css";

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ChipInput from "material-ui-chip-input";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { FormHelperText } from "@material-ui/core";

import FileUploader from "../../ServiceProvider/UpdateGeneral/FileUploader/FileUploader";
import PanoramaRoundedIcon from "@material-ui/icons/PanoramaRounded";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

import MediaHandler from "../../Media/MediaHandler";
import ChooseCategory from "../../ServiceProvider/SingleEvent/ChooseCategory/ChooseCategory";
import BtnLoader from "../../utils/BtnLoader/BtnLoader";

import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import { showAlert } from "../../../containers/app/actions";
import { getAllServiceProviders } from "../../../containers/partner/actions";
import axios from "axios";

import Specifications from "./Specifications/Specifications";
import Fulfilment from "./Fulfilment/Fulfilment";
import getPreviewUrl from "../../../hooks/getPreviewUrl";

const temp = {
    Mon: {
        active: true,
        timeSlots: [{ start: "09:00", end: "10:30" }],
    },
    Tue: {
        active: true,
        timeSlots: [{ start: "09:00", end: "10:30" }],
    },
    Wed: {
        active: true,
        timeSlots: [{ start: "09:00", end: "10:30" }],
    },
    Thu: {
        active: true,
        timeSlots: [{ start: "09:00", end: "10:30" }],
    },
    Fri: {
        active: true,
        timeSlots: [{ start: "09:00", end: "10:30" }],
    },
    Sat: {
        active: true,
        timeSlots: [{ start: "09:00", end: "10:30" }],
    },
    Sun: {
        active: true,
        timeSlots: [{ start: "09:00", end: "10:30" }],
    },
};

const EditSingleEvent = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    let serviceIdCheck = query.get("serviceId");
    let typeCheck = query.get("type");

    const [formData, setFormData] = React.useState({
        eventName: "",
        description: "",
        others: "",
        legalBusinessName: "",
        headers: {
            "What Is Included": {
                active: false,
                value: "",
            },
            Requirements: {
                active: false,
                value: "",
            },
            "Good to Know": {
                active: false,
                value: "",
            },
            "Service Terms and Conditions": {
                active: false,
                value: "",
            },
        },
        images: [],
        tags: [],
        NoofSession: 0,
        coverimage: "",
        pricePerPerson: "",
        catList: [],
        specifications: {
            language: [],
            gender: "",
            size: [],
            difficultyLevel: [],
        },
        locationType: "",
        coordinates: { lat: "", lng: "" },
        location: "",
        Competitorname: "",
        Competitorsite: "",
        bookingType: ["VOUCHER"],
        serviceDuration: {
            days: 0,
            minutes: 0,
            hours: 0,
        },
        minQuota: "",
        maxQuota: "",
        scheduleType: "MULTIPLE_DATES",

        additionalRequired: "",
        confirmedBooking: "",
        remainingAvailability: "",
        priceWithoutVat: "",
        vat: 0,
        oneToOneBooking: false,
        name: "",
        dates: [],
        rruleData: [],
        daySlot: {},
        triggers: [],
        storeImage: "",
        previewImage: "",
        introductionVideo: "",
        storeDescription: "",
        storeName: "",
        eventId: "",
        status: "",
        serviceProviderEmail: "",
        competitorName: "",
    });
    const [error, setError] = React.useState({
        eventName: false,
        description: false,

        images: false,
        tags: false,
        specifications: {
            language: false,
            gender: false,
            size: false,
            difficultyLevel: false,
        },
        pricePerPerson: false,
        catList: false,
        locationType: false,
        NoofSession: false,
        location: false,
        others: false,

        triggers: false,
        additionalRequired: false,
        confirmedBooking: false,
        remainingAvailability: false,
        storeImage: false,

        introductionVideo: false,
        storeDescription: false,
        storeName: false,
        name: false,
        serviceProviderEmail: false,
        competitorName: false,
    });

    const [opencoverimage, setOpenCoverImage] = React.useState(false);
    const [allowcover, setAllowCover] = React.useState(false);
    const [randomcover, setRandomCover] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [categoryModal, setCategoryModal] = React.useState(false);
    const [random, setRandom] = React.useState(false);
    const [openCover, setOpenCover] = React.useState(false);
    const [allow, setAllow] = React.useState(false);

    const [serviceId, setServiceId] = React.useState(false);
    const [serviceProviders, setRequests] = React.useState(props.serviceProviders);
    const editorRef = React.useRef();
    const [editorLoaded, setEditorLoaded] = React.useState(false);
    const { CKEditor, ClassicEditor } = editorRef.current || {};

    React.useEffect(() => {
        editorRef.current = {
            // CKEditor: require('@ckeditor/ckeditor5-react'), // depricated in v3
            CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
            ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
        };
        setEditorLoaded(true);
    }, []);

    React.useEffect(() => {
        if (!props.serviceProviders) {
            props.getAllServiceProviders();
        }
        setRequests(props.serviceProviders);
        if (typeCheck == "ADD") {
            if (serviceProviders) {
                serviceProviders.forEach((event) => {
                    if (event._id == query.get("eventId")) {
                        setFormData({ ...formData, ...event, eventId: event._id });
                    }
                });
            }
        }
    }, [props.serviceProviders, serviceProviders]);

    React.useEffect(() => {
        if (typeCheck == "EDIT" && serviceIdCheck) {
            if (serviceProviders) {
                serviceProviders.forEach((event) => {
                    if (event._id == serviceIdCheck) {
                        setFormData({ ...formData, ...event, eventId: event._id });
                        setServiceId(event._id);
                    }
                });
            } else {
                console.log("...");
            }
        }
    }, [serviceProviders]);

    const validate = () => {
        const err = {
            eventName: false,
            description: false,
            images: false,
            NoofSession: false,
            serviceDuration: false,
            specifications: {
                language: false,
                gender: false,
                size: false,
                difficultyLevel: false,
            },
            tags: false,
            pricePerPerson: false,
            catList: false,
            locationType: false,
            location: false,
            bookingType: false,
            others: false,
            dates: false,
            language: false,
            age: false,
            size: false,
            name: false,
            difficultyLevel: false,
            accessibility: false,
            storeImage: false,

            storeDescription: false,
            storeName: false,
            serviceProviderEmail: false,
            competitorName: false,
        };

        let validData = true;
        let notValidate1 = ["tags", "images", "catList", "dates", "bookingType"];
        let notValidate2 = ["serviceDuration", "headers"];

        setError({ ...err });
        Object.keys(err).forEach((key) => {
            if (
                formData[key] == "" &&
                !notValidate1.includes(key) &&
                !notValidate2.includes(key) &&
                key != "others" &&
                key != "location"
            ) {
                err[key] = `Field cannot be empty`;

                validData = false;
            }
        });

        let isEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        notValidate1.forEach((key) => {
            if (formData[key].length == 0 && key != "dates") {
                err[key] = `Field cannot be empty`;
                validData = false;
            }
        });
        console.log(err);
        notValidate2.forEach((key) => {
            if (formData[key].length == 0) {
                err[key] = `Field cannot be empty`;
                validData = false;
            }
        });
        if (formData.locationType == "ONLINE" && formData.onlineMeetingLink == "") {
            err["onlineMeetingLink"] = `Field cannot be empty`;
            validData = false;
        }

        if (
            formData.serviceDuration.days == 0 &&
            formData.serviceDuration.minutes == 0 &&
            formData.serviceDuration.hours == 0
        ) {
            err["serviceDuration"] = `Please Enter Valid Service Duration`;
            validData = false;
        }

        if (formData.locationType === "Others" && formData.others === "") {
            err["others"] = `Field cannot be empty`;
            validData = false;
        }
        if (formData.NoofSession == 0) {
            err.NoofSession = `* Field cannot be empty`;
            validData = false;
        }
        if (formData.specifications.language.length == 0) {
            err.specifications.language = `* Please Select Option`;
            validData = false;
        }

        if (formData.specifications.size.length == 0) {
            err.specifications.size = `* Please Select Option`;
            validData = false;
        }

        if (formData.locationType != "ONLINE" && formData.locationType != "Others" && formData.location == "") {
            err["location"] = `Enter Valid Location`;
            validData = false;
        }
        if (!isEmail.test(formData.serviceProviderEmail)) {
            err["serviceProviderEmail"] = "Enter valid email";
            validData = false;
        }
        setError({ ...err });
        return validData;
    };
    const onPreview = () => {
        if (validate()) {
            let backUrl = "&preview=PREVIEW&source=service-provider-creator&displayFooter=NO";
            const link = getPreviewUrl(formData.eventName, serviceId, backUrl);
            window.open(link, "_blank");
            // window.open(
            //     `${process.env.REACT_APP_CLIENT_URL}/product/${formData.eventName
            //         .split(" ")
            //         .join(
            //             "-",
            //         )}?serviceId=${serviceId}&preview=PREVIEW&source=service-provider-creator&displayFooter=NO`,
            //     "_blank",
            // );
        }
    };
    console.log(formData);
    const onSend = (actionType) => {
        if (validate()) {
            setLoading(actionType);

            axios({
                method: "post",
                url: "/dashboard/create-service-provider/addServiceProvider",
                data: {
                    ...formData,
                    actionType,
                    status: actionType === "SAVE" ? "DRAFT" : "SENT",
                },
            })
                .then((res) => {
                    setLoading(false);
                    if (actionType == "SAVE" && typeCheck === "ADD") {
                        props.showAlert("Service Provider Saved Succesfully");
                        props.history.push(
                            "/user/add-service-provider/ADD-SERVICE-PROVIDER?eventId=" +
                                res.data.event._id +
                                "&type=ADD",
                        );
                        setServiceId(res.data.event._id);
                    } else {
                        props.showAlert("Service Provider Added Succesfully");
                        props.getAllServiceProviders();
                        props.history.push("/user/add-service-provider/VIEW-ADDED-SERVICE-PROVIDERS");
                    }
                })
                .catch((err) => {
                    if (err && err.response) props.showAlert(err.response.data.error);
                    else props.showAlert("Something went wrong Try Again");

                    setLoading(false);
                });
        }
    };

    return (
        <React.Fragment>
            <MediaHandler
                open={random}
                onClose={() => {
                    setOpen(false);
                    setRandom(false);
                    setAllow(false);
                }}
                onSelectImage={(val) => {
                    if (open == "images") {
                        let arr = formData.images;
                        arr.push(val);
                        setFormData({ ...formData, images: arr });
                    } else {
                        setFormData({ ...formData, [open]: val });
                    }
                    setOpen(false);
                    setAllow(false);
                    setRandom(false);
                }}
                allowedList={allow}
                cropRatio={open === "previewImage" && 16 / 9}
            />

            <MediaHandler
                open={randomcover}
                onClose={() => {
                    setOpenCoverImage(false);
                    setRandomCover(false);
                    setAllowCover(false);
                }}
                onSelectImage={(val) => {
                    if (opencoverimage == "images") {
                        setFormData({ ...formData, coverimage: val });
                    }
                    setOpenCoverImage(false);
                    setRandomCover(false);
                    setAllowCover(false);
                }}
                allowedList={allowcover}
                cropRatio={opencoverimage === "previewImage" && 16 / 9}
            />

            {categoryModal && (
                <ChooseCategory
                    onAdd={(val) => {
                        let arr = formData?.catList || [];
                        arr = arr
                            ?.filter((oldVal) => oldVal?.categoryId?._id)
                            .filter((oldVal) => {
                                return oldVal?.categoryId?._id != val.categoryId._id;
                            });
                        arr.push(val);
                        setFormData({ ...formData, catList: [...arr] });
                    }}
                    open={categoryModal}
                    onClose={() => setCategoryModal(false)}
                />
            )}

            <div className={styles.container}>
                <div className={styles.content}>
                    <h2>Store Profile</h2>

                    <div className={styles.row}>
                        <div className={styles.leftContent}>
                            <FormControl className={styles.textField}>
                                <FormLabel>* Name</FormLabel>
                                <TextField
                                    variant="outlined"
                                    value={formData.name}
                                    onChange={(e) => {
                                        setFormData({ ...formData, name: e.target.value });
                                    }}
                                    error={error.name}
                                    helperText={error.name}
                                />
                            </FormControl>
                            <FormControl className={styles.textField}>
                                <FormLabel>* Store Name</FormLabel>
                                <TextField
                                    variant="outlined"
                                    value={formData.storeName}
                                    onChange={(e) => {
                                        setFormData({ ...formData, storeName: e.target.value });
                                    }}
                                    error={error.storeName}
                                    helperText={error.storeName}
                                />
                            </FormControl>

                            <FormControl className={styles.textField}>
                                <FormLabel>* Store Description</FormLabel>
                                {editorLoaded && (
                                    <div className={styles.styleCK}>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            config={{
                                                placeholder: `${"Please describe"}`,
                                                toolbar: [
                                                    "Heading",
                                                    "|",
                                                    "Italic",
                                                    "NumberedList",
                                                    "BulletedList",
                                                    "|",
                                                    "Undo",
                                                    "Redo",
                                                ],
                                            }}
                                            data={formData.storeDescription}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setFormData((val) => ({ ...val, storeDescription: data }));
                                            }}
                                        />
                                    </div>
                                )}
                                {/* <TextField
                                    variant="outlined"
                                    value={formData.storeDescription}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            storeDescription: e.target.value,
                                        });
                                    }}
                                    className={styles.textField}
                                    error={error.storeDescription}
                                    helperText={error.storeDescription}
                                    multiline
                                    rows={10}
                                /> */}
                                <FormHelperText className={styles.errorMessage}>
                                    {error.storeDescription}
                                </FormHelperText>
                            </FormControl>

                            <FormControl className={styles.textField}>
                                <FormLabel>Legal Business Name</FormLabel>

                                <TextField
                                    variant="outlined"
                                    value={formData.legalBusinessName}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            legalBusinessName: e.target.value,
                                        })
                                    }
                                />
                            </FormControl>
                        </div>

                        <div className={styles.leftContent}>
                            <FormControl className={styles.textField}>
                                <FormLabel>* Service Provider Email</FormLabel>
                                <TextField
                                    variant="outlined"
                                    value={formData.serviceProviderEmail}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            serviceProviderEmail: e.target.value,
                                        });
                                    }}
                                    error={error.serviceProviderEmail}
                                    helperText={error.serviceProviderEmail}
                                />
                            </FormControl>

                            <FormControl className={styles.textField}>
                                <FormLabel>* Competitor Name</FormLabel>
                                <TextField
                                    variant="outlined"
                                    value={formData.competitorName}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            competitorName: e.target.value,
                                        });
                                    }}
                                    className={styles.textField}
                                    error={error.competitorName}
                                    helperText={error.competitorName}
                                />
                            </FormControl>

                            <FileUploader
                                value={formData.storeImage}
                                onRemove={() => setFormData({ ...formData, storeImage: "" })}
                                onUpload={() => {
                                    setOpen("storeImage");
                                    setRandom(Math.random());
                                    setAllow("image/png, image/jpeg");
                                }}
                                name={"Store Image"}
                                styles={styles}
                                type="PNG | JPEG"
                                error={error.storeImage}
                                helperText={error.storeImage}
                                title="* Store Image"
                            />
                            <FileUploader
                                value={formData.introductionVideo}
                                onRemove={() => setFormData({ ...formData, introductionVideo: "" })}
                                onUpload={() => {
                                    setOpen("introductionVideo");
                                    setRandom(Math.random());
                                    setAllow("video/mp4");
                                }}
                                name={"Introduction Video"}
                                styles={styles}
                                type="MP4 | VIDEO"
                                error={error.introductionVideo}
                                title="Introduction Video"
                            />
                            <FileUploader
                                value={formData.previewImage}
                                onRemove={() => setFormData({ ...formData, previewImage: "" })}
                                onUpload={() => {
                                    setOpen("previewImage");
                                    setRandom(Math.random());
                                    setAllow("image/png, image/jpeg");
                                }}
                                name={"Preview Image"}
                                styles={styles}
                                type="PNG | JPEG"
                                title="Preview Image"
                                cropRatio="16 / 9"
                            />
                        </div>
                    </div>

                    <div className={styles.contentContainer}>
                        <h2>Service Information</h2>

                        <div className={styles.row}>
                            <FormControl className={styles.halfField}>
                                <FormLabel>* Service Name</FormLabel>

                                <TextField
                                    variant="outlined"
                                    value={formData.eventName}
                                    onChange={(e) => setFormData({ ...formData, eventName: e.target.value })}
                                    error={error.eventName}
                                    helperText={error.eventName}
                                />
                            </FormControl>

                            <div className={styles.dFlexComp}>
                                <div className={styles.catContent}>
                                    <label>* Select Category</label>

                                    <div className={styles.catBox}>
                                        {formData.catList.map((val) => {
                                            if (val.categoryId && val.subCategoryId) {
                                                return (
                                                    <span className={styles.tag}>
                                                        {val.subCategoryId.serviceSubCatName}

                                                        <CancelRoundedIcon
                                                            color="white"
                                                            onClick={() => {
                                                                let arr = formData.catList;
                                                                arr = arr.filter((cat) => val != cat);
                                                                setFormData({ ...formData, catList: [...arr] });
                                                            }}
                                                        />
                                                    </span>
                                                );
                                            }
                                        })}
                                    </div>
                                    {error.catList && <span className={styles.error}>{error.catList}</span>}
                                </div>

                                <IconButton onClick={() => setCategoryModal(true)}>
                                    <AddCircleOutlineRoundedIcon />
                                </IconButton>
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={styles.col}>
                                <FormControl className={styles.fullWidth}>
                                    <FormLabel>* General Description</FormLabel>

                                    {editorLoaded && (
                                        <div className={styles.styleCK}>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={{
                                                    placeholder: `${"Please describe"}`,
                                                    toolbar: [
                                                        "Heading",
                                                        "|",
                                                        "Italic",
                                                        "NumberedList",
                                                        "BulletedList",
                                                        "|",
                                                        "Undo",
                                                        "Redo",
                                                    ],
                                                }}
                                                data={formData.description}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setFormData((val) => ({ ...val, description: data }));
                                                }}
                                            />
                                        </div>
                                    )}
                                    <FormHelperText className={styles.errorMessage}>{error.description}</FormHelperText>
                                </FormControl>
                            </div>{" "}
                            <div className={styles.imgContainer}>
                                <div
                                    className={styles.centerbbs}
                                    onClick={() => {
                                        if (formData.coverimage == "") {
                                            setOpenCoverImage("images");
                                            setAllowCover("image/png , image/jpeg");
                                            setRandomCover(Math.random());
                                        }
                                    }}
                                >
                                    <PanoramaRoundedIcon />
                                    <span>
                                        *Add Cover image.
                                        <br />
                                        Maximum image size of 2MB <br />
                                    </span>
                                </div>

                                {formData.coverimage && (
                                    <div className={styles.imgContent}>
                                        <div className={styles.imgComp}>
                                            <span
                                                onClick={() => {
                                                    setFormData({ ...formData, coverimage: "" });
                                                }}
                                            >
                                                x
                                            </span>

                                            <img
                                                src={formData.coverimage}
                                                className={styles.img}
                                                width={100}
                                                height={100}
                                            />
                                        </div>
                                    </div>
                                )}
                                {error.images && <span className={styles.error}>{error.images}</span>}
                            </div>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.col}>
                            {Object.keys(formData.headers).map((key) => {
                                let active = formData.headers[key].active;
                                return (
                                    <div className={styles.headerFormContent}>
                                        <FormControl className={styles.fullWidth}>
                                            <div className={styles.dFlex}>
                                                <FormLabel>{key}</FormLabel>
                                                <Switch
                                                    checked={active}
                                                    onChange={() => {
                                                        let newHeaders = formData.headers;
                                                        newHeaders[key].active = !active;
                                                        setFormData({ ...formData, headers: newHeaders });
                                                    }}
                                                />{" "}
                                            </div>
                                            {active && (
                                                <TextField
                                                    variant="outlined"
                                                    multiline
                                                    rows={2}
                                                    disabled={!active}
                                                    value={formData.headers[key].value}
                                                    onChange={(e) => {
                                                        let newHeaders = formData.headers;
                                                        newHeaders[key].value = e.target.value;
                                                        setFormData({ ...formData, headers: newHeaders });
                                                    }}
                                                />
                                            )}
                                        </FormControl>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={styles.imgContainer}>
                            <div
                                className={styles.centerbbs}
                                onClick={() => {
                                    if (formData.images.length < 5) {
                                        setOpen("images");
                                        setAllow("image/png , image/jpeg");
                                        setRandom(Math.random());
                                    }
                                }}
                            >
                                <PanoramaRoundedIcon />
                                <span>
                                    * Add up to 5 images.
                                    <br />
                                    Minimum image ratio recommended is 500 * 500 Pixel <br />
                                    Maximum image size of 2MB <br />
                                </span>
                            </div>

                            <div className={styles.imgContent}>
                                {formData.images.map((img, index) => {
                                    return (
                                        <div className={styles.imgComp}>
                                            <span
                                                onClick={() => {
                                                    let arr = formData.images;
                                                    arr = arr.filter((url, i) => index != i);
                                                    setFormData({ ...formData, images: [...arr] });
                                                }}
                                            >
                                                x
                                            </span>
                                            <img src={img} className={styles.img} />
                                        </div>
                                    );
                                })}
                            </div>

                            {error.images && <span className={styles.error}>{error.images}</span>}
                        </div>
                    </div>

                    <div className={styles.contentContainer}>
                        <div className={styles.row}>
                            <FormControl className={styles.halfField}>
                                <FormLabel>* No of Session</FormLabel>

                                <TextField
                                    variant="outlined"
                                    value={formData.NoofSession}
                                    onChange={(e) => setFormData({ ...formData, NoofSession: e.target.value })}
                                    error={error.NoofSession}
                                    helperText={error.NoofSession}
                                />
                            </FormControl>

                            <div className={styles.duration}>
                                <label htmlFor="">* Service Duration</label>
                                <div className={styles.inputs}>
                                    <TextField
                                        variant="outlined"
                                        label="Days"
                                        type="number"
                                        className={styles.smallInput}
                                        value={formData.serviceDuration.days}
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                serviceDuration: {
                                                    ...formData.serviceDuration,
                                                    days: e.target.value,
                                                },
                                            })
                                        }
                                    />
                                    <TextField
                                        variant="outlined"
                                        label="Hours"
                                        type="number"
                                        className={styles.smallInput}
                                        value={formData.serviceDuration.hours}
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                serviceDuration: {
                                                    ...formData.serviceDuration,
                                                    hours: e.target.value,
                                                },
                                            })
                                        }
                                    />
                                    <TextField
                                        variant="outlined"
                                        label="Minutes"
                                        type="number"
                                        className={styles.smallInput}
                                        value={formData.serviceDuration.minutes}
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                serviceDuration: {
                                                    ...formData.serviceDuration,
                                                    minutes: e.target.value,
                                                },
                                            })
                                        }
                                    />
                                </div>
                                {error.serviceDuration && <span className={styles.error}>{error.serviceDuration}</span>}
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.duration}>
                                <label htmlFor="">* Service Price</label>
                                <div className={styles.inputs}>
                                    <TextField
                                        variant="outlined"
                                        label="Price (incl.VAT)"
                                        type="number"
                                        className={styles.smallInput}
                                        value={formData.pricePerPerson}
                                        error={error.pricePerPerson}
                                        onChange={(e) => {
                                            if (
                                                (e.target.value >= 0 &&
                                                    !e.target.value.includes(".") &&
                                                    !e.target.value.includes("+")) ||
                                                ((e.target.value.includes(",") && e.target.value.match(/,/g)) || [])
                                                    .length == 1
                                            ) {
                                                let val = e.target.value.replace(",", ".");
                                                // val = parseInt(val);
                                                setFormData({
                                                    ...formData,
                                                    priceWithoutVat: ((100 / (100 + formData.vat)) * val).toFixed(2),
                                                    pricePerPerson: val,
                                                });
                                            } else {
                                                setFormData({
                                                    ...formData,
                                                    priceWithoutVat: "",
                                                });
                                            }
                                        }}
                                    />
                                    <Select
                                        variant="outlined"
                                        label="VAT %"
                                        value={formData.vat}
                                        onChange={(e) => {
                                            let val = parseInt(e.target.value);
                                            setFormData({
                                                ...formData,
                                                vat: val,
                                                priceWithoutVat: (100 / (100 + formData.vat)) * formData.pricePerPerson,
                                            });
                                        }}
                                    >
                                        <MenuItem value={formData.vat}>0%</MenuItem>
                                    </Select>
                                    {error.vat && <FormHelperText>{error.vat}</FormHelperText>}{" "}
                                    <TextField
                                        variant="outlined"
                                        label="Price (excl.VAT)"
                                        className={styles.smallInput}
                                        value={formData.priceWithoutVat}
                                    />
                                </div>
                                {error.pricePerPerson && <span className={styles.error}>{error.pricePerPerson}</span>}
                            </div>
                            <FormControl className={styles.halfField}>
                                <FormLabel>* Keywords/Tags</FormLabel>
                                <ChipInput
                                    value={formData.tags}
                                    onAdd={(chip) => setFormData({ ...formData, tags: [...formData.tags, chip] })}
                                    onDelete={(chip, index) => {
                                        let arr = formData.tags;
                                        arr = arr.filter((val, i) => index != i);
                                        setFormData({ ...formData, tags: [...arr] });
                                    }}
                                    variant="outlined"
                                    error={error.tags}
                                    helperText={error.tags || "Please press enter after each tag"}
                                />
                            </FormControl>
                        </div>
                    </div>

                    <div className={styles.contentContainer}>
                        <Specifications formData={formData} setFormData={(val) => setFormData(val)} error={error} />
                    </div>

                    <div className={styles.contentContainer}>
                        <Fulfilment
                            formData={formData}
                            setFormData={(val) => setFormData(val)}
                            error={error}
                            storeProfile={props.general}
                        />
                    </div>

                    <div className={styles.contentContainer}>
                        <div className={styles.btnContainer}>
                            {loading == "SAVE" ? (
                                <BtnLoader className="btnBorderGreen" />
                            ) : (
                                <button className="btnBorderGreen" onClick={() => onSend("SAVE")}>
                                    SAVE
                                </button>
                            )}

                            {serviceId ? (
                                <button className="btnBorderGreen" onClick={onPreview}>
                                    PREVIEW
                                </button>
                            ) : (
                                <button className="btnBorderDisabled" disabled={true}>
                                    PREVIEW
                                </button>
                            )}

                            {loading == "SEND" ? (
                                <BtnLoader className="btnBorderGreen" />
                            ) : formData.previewImage !== "" ? (
                                <button className="btnBorderGreen" onClick={() => onSend("SEND")}>
                                    SEND
                                </button>
                            ) : (
                                <button className="btnBorderDisabled" disabled={true}>
                                    SEND
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
const mapStateToProps = (state) => ({
    serviceProviders: state.createdServiceProvider.serviceProviders,
});
export default withRouter(connect(mapStateToProps, { showAlert, getAllServiceProviders })(EditSingleEvent));
