import * as actionTypes from "./actionTypes";
import axios from "axios";
export const getAllLifeCoahes = () => (dispatch) => {
    dispatch({
        type: actionTypes.SET_ALL_LIFE_COACHES,
        payload: false,
    });
    axios({
        method: "get",
        url: "/dashboard/life-coaches-admin/getAlllifeCoaches",
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_ALL_LIFE_COACHES,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.SET_ALL_LIFE_COACHES,
                payload: [],
            });

            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};
export const getLifeCoach = (coach) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_LIFECOACH,
        payload: false,
    });
    axios({
        method: "get",
        url: "/dashboard/life-coaches-admin/getlifeCoach/" + coach,
    })
        .then((res) => {
            console.log("--------------->>>>>", res.data);
            dispatch({
                type: actionTypes.SET_LIFECOACH,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.SET_LIFECOACH,
                payload: [],
            });

            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};
export const getLifeCoachGeneral = (coach) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_LIFECOACH_GENERAL,
        payload: false,
    });
    axios({
        method: "get",
        url: "/dashboard/life-coaches-admin/getlifeCoachgeneral/" + coach,
    })
        .then((res) => {
            console.log(res.data);
            dispatch({
                type: actionTypes.SET_LIFECOACH_GENERAL,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.SET_LIFECOACH_GENERAL,
                payload: [],
            });

            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};
export const getAllBookings = (coach, category) => (dispatch) => {
    console.log("HI");
    dispatch({
        type: actionTypes.SET_BOOKINGS,
        payload: false,
    });
    axios({
        method: "get",
        url: "/dashboard/life-coaches-admin/getAllbookings/" + coach + "/" + category,
    })
        .then((res) => {
            console.log(res.data);
            dispatch({
                type: actionTypes.SET_BOOKINGS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.SET_BOOKINGS,
                payload: [],
            });
        });
};
export const getAllCoachBookings = (coach, category) => (dispatch) => {
    console.log("HI");
    dispatch({
        type: actionTypes.SET_BOOKINGS,
        payload: false,
    });
    axios({
        method: "get",
        url: "/dashboard/life-coaches-admin/getAllCoachBookings/" + coach + "/" + category,
    })
        .then((res) => {
            console.log(res.data);
            dispatch({
                type: actionTypes.SET_BOOKINGS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.SET_BOOKINGS,
                payload: [],
            });
        });
};
export const getAllSchedules = (coachId, categoryId) => (dispatch) => {
    dispatch({
        type: actionTypes.GET_SCHEDULE_DATES,
        payload: false,
    });
    axios({
        method: "get",
        url: "/dashboard/life-coaches-admin/getSchedule/" + coachId + "/" + categoryId,
    })
        .then((res) => {
            let fetchedDates = res.data;
            if (!fetchedDates) fetchedDates = [];
            dispatch({
                type: actionTypes.GET_SCHEDULE_DATES,
                payload: fetchedDates,
            });
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.GET_SCHEDULE_DATES,
                payload: [],
            });

            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};
export const updateLifeCoach = (data, coachId) => (dispatch) => {
    dispatch({
        type: actionTypes.UPDATE_DATA,
        payload: false,
    });
    axios({
        method: "post",
        url: "/dashboard/life-coaches-admin/update/" + coachId,
        data: {
            data,
        },
    })
        .then((res) => {
            let fetchedDates = res.data;
            if (!fetchedDates) fetchedDates = [];
            dispatch({
                type: actionTypes.UPDATE_DATA,
                payload: fetchedDates,
            });
            dispatch({
                type: "SHOW_ALERT",
                payload: "Data Updated Succesfully",
            });
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.UPDATE_DATA,
                payload: [],
            });

            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};
