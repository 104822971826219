import React from 'react'
import styles from './ContentSlider.module.css'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 
 
import IconButton from '@material-ui/core/IconButton'

import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded'; 
// import Rating from '../../../utils/Rating/Rating'

const NextButton = (props) => {
    return (
        <IconButton {...props} className={styles.nextBtn}>
            <ArrowForwardIosRoundedIcon />
        </IconButton>
    )
}

const PrevButton = (props) => {
    return (
        <IconButton {...props} className={styles.prevBtn}>
            <ArrowBackIosRoundedIcon />
        </IconButton>
    )
}
let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1, 
    slidesToScroll: 1,  
    accessibility: true,
    arrows: true,  
    nextArrow: <NextButton />,
    prevArrow: <PrevButton />, 
};

const ContentSlider = (props) => {  
    return (
        <div className={styles.container}>   
            <Slider {...settings}>   
                {props.service && props.service.images.map(imgUrl => {
                    return (
                        <img src={imgUrl} />
                    )
                })}
                {props.service && props.service.images.map(imgUrl => {
                    return (
                        <img src={imgUrl} />
                    )
                })}
            </Slider>    
        </div>
    )
} 
export default ContentSlider;