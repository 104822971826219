import * as actionTypes from "./actionTypes";

const initialState = {
    users: false,
    b2bUser: false,
    referrals: false,
    userSubscription: {
        subscription: false,
        count: 0,
    },
    survey: false,
    surveyCount: 0,
    notifications: false,
    notificationsCount: 0,
    ratings: false,
    ratingsCount: 0,
    promos: false,
    promoCount: 0,
    dropDown: false,
    myJourney: false,
    sentJourney: false,
    bookings: false,
    services: false,
    serviceProviderProfile: false,
    cartItems: false,
    billingAddress: {
        street: "",
        building: "",
        city: "",
        zipcode: "",
        country: "",
        phone: "",
    },
    globalGivingProjects: false,
    globalGiving: false,
    basicCart: false,
    kpi: false,
    companyId: false,
    companyIdCount: 0,
};
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.SET_BASIC_CART_ITEMS:
            return { ...state, basicCart: action.payload };

        case actionTypes.SET_GLOBAL_GIVING:
            return { ...state, globalGiving: action.payload };

        case actionTypes.SET_GRAPH_DATA:
            return { ...state, kpi: action.payload };
        case actionTypes.SET_NOTIFICATIONS:
            return { ...state, notifications: action.payload };
        case actionTypes.SET_RATINGS:
            return { ...state, ratings: action.payload };
        case actionTypes.SET_RATINGS_COUNT:
            return { ...state, ratingsCount: action.payload };
        case actionTypes.SET_COMPANY_ID:
            return { ...state, companyId: action.payload };
        case actionTypes.SET_COMPANY_ID_COUNT:
            return { ...state, companyIdCount: action.payload };
        case actionTypes.SET_PROMO:
            return { ...state, promos: action.payload };
        case actionTypes.SET_PROMO_COUNT:
            return { ...state, promoCount: action.payload };
        case actionTypes.SET_NOTIFICATIONS_COUNT:
            return { ...state, notificationsCount: action.payload };
        case actionTypes.SET_BILLING_ADDRESS:
            return { ...state, billingAddress: action.payload };

        case actionTypes.MANAGE_CART_DELETE:
            let deleteArr = state.cartItems;
            deleteArr = deleteArr.filter((val) => val.serviceId._id != action.payload.serviceId);

            return { ...state, cartItems: [...deleteArr] };

        case actionTypes.MANAGE_CART_QUANTITY:
            let updateArr = state.cartItems;
            updateArr = updateArr.map((val) => {
                if (val._id == action.payload.cartId) {
                    val.quantity = action.payload.quantity;
                    return val;
                }
                return val;
            });

            return { ...state, cartItems: [...updateArr] };
        case actionTypes.SET_GLOBAL_GIVING_PROJECTS:
            return { ...state, globalGivingProjects: action.payload };

        case actionTypes.SET_CART_ITEMS:
            return { ...state, cartItems: action.payload };

        case actionTypes.SET_SP_SERVICE_STORE_PROFILE:
            return { ...state, serviceProviderProfile: action.payload };

        case actionTypes.SET_SERVICES:
            return { ...state, services: action.payload };

        case actionTypes.SET_USER_BOOKINGS:
            return { ...state, bookings: action.payload };

        case actionTypes.UPDATE_MY_JOURNEY:
            let arr1 = state.myJourney;
            arr1 = arr1.map((val) => {
                if (val._id == action.payload._id) return action.payload;
                return val;
            });
            return { ...state, myJourney: [...arr1] };

        case actionTypes.SET_SENT_JOURNEYS:
            return { ...state, sentJourney: action.payload };

        case actionTypes.SET_MY_JOURNEYS:
            return { ...state, myJourney: action.payload };

        case actionTypes.SET_REFERRALS:
            return { ...state, referrals: action.payload };

        case actionTypes.SET_END_USERS:
            return { ...state, users: action.payload };
        case actionTypes.SET_B2B_USERS:
            return { ...state, b2bUser: action.payload };

        case actionTypes.SET_SURVEY:
            return { ...state, survey: action.payload };
        case actionTypes.SET_SURVEY_COUNT:
            return { ...state, surveyCount: action.payload };
        case actionTypes.SET_SURVEY_DROP_DOWN:
            return { ...state, dropDown: action.payload };
        case actionTypes.SET_SUBSCRIPTION:
            return {
                ...state,
                userSubscription: {
                    ...state.userSubscription,
                    subscription: action.payload.data,
                    count: action.payload.totalData,
                },
            };
        default:
            return state;
    }
};

export default reducer;
