import * as actionTypes from "./actionTypes";
import axios from "axios";

let cancelToken;

export const getAllTransactions = (limit, page, search, type) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_TRANSACTIONS,
        payload: false,
    });
    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Cancelling the previours req");
    }
    cancelToken = axios.CancelToken.source();
    const typeUrl = type == "ORDER" ? "getAllOrders" : "getAllVouchers";

    axios({
        method: "get",
        url: `/dashboard/transactions/${typeUrl}?limit=${limit}&page=${page}&search=${search}`,
        cancelToken: cancelToken.token,
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_TRANSACTIONS,
                payload: res.data.transactions,
            });
            dispatch({
                type: actionTypes.SET_TRANSACTION_COUNT,
                payload: res.data.totalData,
            });
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.SET_TRANSACTIONS,
                payload: false,
            });
            console.log(err);
        });
};
