import React from "react";
import styles from "./UpdateStoreProfile.module.css";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Switch from "@material-ui/core/Switch";
import InputAdornment from "@material-ui/core/InputAdornment";

import ImageRoundedIcon from "@material-ui/icons/ImageRounded";
import UpdateRoundedIcon from "@material-ui/icons/UpdateRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import InsertDriveFileRoundedIcon from "@material-ui/icons/InsertDriveFileRounded";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";

import { connect } from "react-redux";
import { getSPStoreProfile } from "../../../containers/serviceProvider/actions";
import { showAlert } from "../../../containers/app/actions";
import { withRouter, useLocation } from "react-router-dom";

import axios from "axios";
import MediaHandler from "../../Media/MediaHandler";

import TimeSlot from "./TimeSlot/TimeSlot";
import FileUploader from "../UpdateGeneral/FileUploader/FileUploader";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { getNameFromImage } from "../../../hooks/getNameFromImage";
import Header from "../Header/Header";

const UpdateStoreProfile = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [formData, setFormData] = React.useState({
        storeImage: "",
        storeName: "",
        introductionVideo: "",
        description: "",
        maxQuota: false,
        maxQuotaValue: "",
        qualificationAndExperiences: [],
        qualificationAndExperiencesText: "",
        physicalOutlet: [
            {
                outletName: "",
                active: false,
                days: [
                    { day: "Sun", active: true, timeSlots: [{ start: "00:00", end: "23:59" }] },
                    { day: "Mon", active: true, timeSlots: [{ start: "00:00", end: "23:59" }] },
                    { day: "Tue", active: true, timeSlots: [{ start: "00:00", end: "23:59" }] },
                    { day: "Wed", active: true, timeSlots: [{ start: "00:00", end: "23:59" }] },
                    { day: "Thu", active: true, timeSlots: [{ start: "00:00", end: "23:59" }] },
                    { day: "Fri", active: true, timeSlots: [{ start: "00:00", end: "23:59" }] },
                    { day: "Sat", active: true, timeSlots: [{ start: "00:00", end: "23:59" }] },
                ],
            },
        ],
    });
    const [formError, setFormError] = React.useState({
        storeImage: false,
        introductionVideo: false,
        description: false,
        storeName: false,
        // physicalOutlet: [],
        qualificationAndExperiences: false,
        maxQuotaValue: false,
    });
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const [loader, setLoader] = React.useState(true);
    const [allowedList, setAllowedList] = React.useState(false);
    const [mode, setMode] = React.useState(props.type == "HEADER" ? "WRITE" : "READ");
    const editorRef = React.useRef();
    const [editorLoaded, setEditorLoaded] = React.useState(false);
    const { CKEditor, ClassicEditor } = editorRef.current || {};

    React.useEffect(() => {
        editorRef.current = {
            // CKEditor: require('@ckeditor/ckeditor5-react'), // depricated in v3
            CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
            ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
        };
        setEditorLoaded(true);
    }, []);

    React.useEffect(() => {
        if (props.storeProfile) {
            setFormData({ ...formData, ...props.storeProfile });
            setLoader(false);
        }
    }, [props.storeProfile]);

    React.useEffect(() => {
        if (query.get("userId")) {
            props.getSPStoreProfile(query.get("userId"));
        } else {
            props.showAlert("Data Not Found");
            props.history.push("/admin/users/VIEW-USERs");
        }
    }, []);

    const validate = () => {
        const err = { companyLogo: false, storeImage: false, description: false, storeName: false };
        // Array(formData.physicalOutlet.length).fill(" ").forEach(val => {
        //     err.physicalOutlet.push({outletName: false})
        // })

        let validData = true;
        setFormError({ ...err });
        Object.keys(err).forEach((key) => {
            if (formData[key] == "" && key != "maxQuotaValue") {
                err[key] = "Field cannot be empty";
                validData = false;
            }
        });

        // formData.physicalOutlet.forEach((val, index) => {
        //     if (val.outletAddress == "") {
        //         err.physicalOutlet[index].outletAddress = 'Field cannot be empty';
        //         validData = false;
        //     }
        // });

        if (formData.maxQuota && formData.maxQuotaValue == "") {
            err["maxQuotaValue"] = "Field cannot be empty";
            validData = false;
        }
        setFormError({ ...err });
        return validData;
    };

    const onSubmit = () => {
        if (validate()) {
            setLoading(true);

            axios({
                method: "put",
                url: "/dashboard/service-provider/updateSPStoreProfile",
                data: {
                    ...formData,
                    userId: query.get("userId"),
                },
            })
                .then((res) => {
                    setLoading(false);
                    props.showAlert("Details Updated Succesfully");
                    props.getAllServiceProviders();
                })
                .catch((err) => {
                    if (err && err.response) props.showAlert(err.response.data.error);
                    setLoading(false);
                });
        }
    };
    let ext = formData.storeImage.split(".")[formData.storeImage.split(".").length - 1];
    return (
        <div className={styles.container}>
            <Header type="PROFILE" />
            <MediaHandler
                // open={open}
                // onClose={() => setOpen(false)}
                // nameRequired={open == "qualificationAndExperiences"}
                // onSelectImage={(val) => {
                //     if (open == "qualificationAndExperiences") {
                //         let arr = formData.qualificationAndExperiences;
                //         arr.push(val)
                //         setFormData({ ...formData, qualificationAndExperiences: [...arr] })
                //     } else {
                //         setFormData({ ...formData, [open]: "" });
                //         setTimeout(() => {
                //             setFormData({ ...formData, [open]: val });
                //         }, 300);
                //     }
                //     setOpen(false);

                // }}
                open={modal}
                allowedList={allowedList}
                onClose={() => setModal(false)}
                nameRequired={open == "qualificationAndExperiences"}
                onSelectImage={(val) => {
                    if (open == "qualificationAndExperiences") {
                        let arr = formData.qualificationAndExperiences;
                        arr.push(val);
                        setFormData({
                            ...formData,
                            qualificationAndExperiences: [...arr],
                        });
                    } else {
                        setFormData({ ...formData, [open]: "" });
                        setTimeout(() => {
                            setFormData({ ...formData, [open]: val });
                        }, 300);
                    }
                    setAllowedList(false);
                    setModal(false);
                }}
                folderName="service-provider/store-profile/"
            />

            {loader ? (
                <div className={styles.loader}>
                    <CircularProgress />
                </div>
            ) : (
                <div className={styles.content}>
                    <h1>Store / Business Profile</h1>
                    <p>View your store status and update store profile</p>

                    <div className={styles.infoContent}>
                        <div className={styles.leftContent}>
                            <FormControl className={styles.textField}>
                                <FormLabel>Store Name</FormLabel>
                                <TextField
                                    variant="outlined"
                                    value={formData.storeName}
                                    onChange={(e) => {
                                        setFormData({ ...formData, storeName: e.target.value });
                                    }}
                                    error={formError.storeName}
                                    helperText={formError.storeName}
                                />
                            </FormControl>

                            <FormControl className={styles.textField}>
                                <FormLabel>Store Description</FormLabel>
                              
                        {editorLoaded ? (
                            <div className={styles.styleCK}>
                                <CKEditor
                                    editor={ClassicEditor}
                                    // disabled={mode == "READ"}
                                    config={{
                                        placeholder:
                                            "Start typing your description here                                                                     .",

                                        toolbar: [
                                            "Heading",
                                            "|",
                                            "Italic",
                                            "NumberedList",
                                            "BulletedList",
                                            "|",
                                            "Undo",
                                            "Redo",
                                        ],
                                    }}
                                    data={formData.description}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setFormData((val)=>({ ...val, description: data }));
                                    }}
                                />
                            </div>
                        ) : (
                            <div>Editor loading</div>
                        )}
                            </FormControl>
                        </div>

                        <div className={styles.rightContent}>
                            <div className={styles.imgRow}>
                                <div className={styles.imgMainContainer}>
                                    <p>{"Select Image or Video"}</p>

                                    {formData.storeImage ? (
                                        <div className={styles.imgContainer}>
                                            <Tooltip title={"Change Picture"}>
                                                <IconButton
                                                    className={styles.edit}
                                                    onClick={() => {
                                                        setModal(Math.random());
                                                        setOpen("storeImage");
                                                        setAllowedList("image/png, image/jpeg");
                                                    }}
                                                >
                                                    <EditRoundedIcon />
                                                </IconButton>
                                            </Tooltip>

                                            <Tooltip title={"Remove Picture"}>
                                                <IconButton
                                                    className={styles.delete}
                                                    onClick={() => setFormData({ ...formData, storeImage: "" })}
                                                >
                                                    <DeleteRoundedIcon />
                                                </IconButton>
                                            </Tooltip>
                                            {formData.storeImage && (
                                                <Tooltip title={"View"}>
                                                    <IconButton
                                                        className={styles.view}
                                                        onClick={() => window.open(formData.storeImage)}
                                                    >
                                                        <GetAppRoundedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}

                                            {["jpeg", "png", "jpg"].includes(ext) && (
                                                <img src={formData.storeImage} className={styles.imgContent} />
                                            )}

                                            {["mov", "mp4", "jpg", "wmv", "flv", "avi", "mkv", "quicktime"].includes(
                                                ext,
                                            ) && (
                                                <video className={styles.imgContent}>
                                                    <source src={formData.storeImage} />
                                                </video>
                                            )}
                                        </div>
                                    ) : (
                                        <div
                                            className={styles.imgUploader}
                                            onClick={() => {
                                                setModal(Math.random());
                                                setOpen("storeImage");
                                                setAllowedList("image/png, image/jpeg");
                                            }}
                                        >
                                            <ImageRoundedIcon
                                                style={{ fontSize: 80, color: "rgba(51, 51, 51, 0.37)" }}
                                            />

                                            <p>{"Click to Upload a Store Image"}</p>

                                            {formError.storeImage && (
                                                <span className={styles.error}>{"Store Image is required"}</span>
                                            )}
                                        </div>
                                    )}
                                </div>

                                <div className={styles.imgMainContainer}>
                                    <p>{"Introductory Video"}</p>
                                    {formData.introductionVideo ? (
                                        <div className={styles.imgContainer}>
                                            <Tooltip title={"Change Video"}>
                                                <IconButton
                                                    className={styles.edit}
                                                    onClick={() => {
                                                        setModal(Math.random());
                                                        setOpen("introductionVideo");
                                                        setAllowedList("video/*");
                                                    }}
                                                >
                                                    <EditRoundedIcon />
                                                </IconButton>
                                            </Tooltip>

                                            <Tooltip title={"Remove Video"}>
                                                <IconButton
                                                    className={styles.delete}
                                                    onClick={() => setFormData({ ...formData, introductionVideo: "" })}
                                                >
                                                    <DeleteRoundedIcon />
                                                </IconButton>
                                            </Tooltip>

                                            {formData.introductionVideo && (
                                                <Tooltip title={"View"}>
                                                    <IconButton
                                                        className={styles.view}
                                                        onClick={() => window.open(formData.introductionVideo)}
                                                    >
                                                        <GetAppRoundedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}

                                            <video>
                                                <source src={formData.introductionVideo} />
                                            </video>
                                        </div>
                                    ) : (
                                        <div
                                            className={styles.imgUploader}
                                            onClick={() => {
                                                setModal(Math.random());
                                                setOpen("introductionVideo");
                                                setAllowedList("video/*");
                                            }}
                                        >
                                            <ImageRoundedIcon
                                                style={{ fontSize: 80, color: "rgba(51, 51, 51, 0.37)" }}
                                            />

                                            <p>{"Click to Upload a Video"}</p>

                                            {formError.introductionVideo && (
                                                <span className={styles.error}>{"Video is required"}</span>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className={styles.maxQuota}>
                                <p>Max. Monthly Booking Quota</p>

                                <Switch
                                    checked={formData.maxQuota}
                                    onChange={() => setFormData({ ...formData, maxQuota: !formData.maxQuota })}
                                />

                                <TextField
                                    value={formData.maxQuotaValue}
                                    onChange={(e) => setFormData({ ...formData, maxQuotaValue: e.target.value })}
                                    disabled={!formData.maxQuota}
                                    variant="outlined"
                                    error={formError.maxQuotaValue}
                                    helperText={formError.maxQuotaValue}
                                />
                            </div>

                            <div className={styles.formGroup}>
                                <p>Qualification &amp; Experiences</p>
                                {editorLoaded ? (
                                <div className={styles.styleCK}>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        // disabled={mode == "READ"}
                                        config={{
                                            placeholder: "Start typing your blog post here...",
                                            toolbar: [
                                                "Heading",
                                                "|",
                                                "Italic",
                                                "NumberedList",
                                                "BulletedList",
                                                "|",
                                                "Undo",
                                                "Redo",
                                            ],
                                        }}
                                        data={formData.qualificationAndExperiencesText}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setFormData((val)=>({ ...val, qualificationAndExperiencesText: data }));
                                        }}
                                    />
                                </div>
                            ) : (
                                <div>Editor loading</div>
                            )}
{/* 
                                <TextField
                                    multiline
                                    variant="outlined"
                                    fullWidth
                                    className={styles.textField}
                                    rows={4}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => {
                                                        setOpen("qualificationAndExperiences");
                                                        setModal(Math.random());
                                                        setAllowedList("image/png, image/jpeg, application/pdf");
                                                    }}
                                                >
                                                    <InsertDriveFileRoundedIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder="Tips: Upload your qualifications and describe your years of experiences in the field to increase the credibility of your store and services!"
                                    value={formData.qualificationAndExperiencesText}
                                    onChange={(e) =>
                                        setFormData({ ...formData, qualificationAndExperiencesText: e.target.value })
                                    }
                                /> */}
                                {formData.qualificationAndExperiences.map((val, index) => {
                                    return (
                                        <FileUploader
                                            noBtn={true}
                                            value={val}
                                            onRemove={() => {
                                                let arr = formData.qualificationAndExperiences;
                                                arr = arr.filter((value) => value != val);
                                                setFormData({ ...formData, qualificationAndExperiences: [...arr] });
                                            }}
                                            upload={false}
                                            styles={styles}
                                            notPrivateDoc={true}
                                            name={val ? getNameFromImage(val) : "Document"}
                                        />
                                    );
                                })}

                                {formError.qualificationAndExperiences && (
                                    <span className={styles.error}>{formError.qualificationAndExperiences}</span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="borderSpacer">&nbsp;</div>

                    <div className={styles.buttonRow}>
                        {loading ? (
                            <Button
                                color="primary"
                                variant="contained"
                                startIcon={<CircularProgress color="inherit" size={20} />}
                            >
                                Loading ...
                            </Button>
                        ) : (
                            <Button
                                color="primary"
                                variant="contained"
                                startIcon={<UpdateRoundedIcon />}
                                onClick={onSubmit}
                            >
                                Update Details
                            </Button>
                        )}

                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => props.history.push("/user/service-provider/VIEW-SERVICE-PROVIDERS")}
                        >
                            Cancel
                        </Button>

                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() =>
                                props.history.push(
                                    `/user/service-provider/VIEW-STORE-SERVICES?userId=${query.get("userId")}`,
                                )
                            }
                        >
                            Next
                        </Button>
                    </div>
                    <br />
                    <br />
                    <br />
                </div>
            )}
        </div>
    );
};
const mapStateToProps = (state) => ({
    storeProfile: state.serviceProvider.storeProfile,
    category: state.app.category,
});

export default withRouter(connect(mapStateToProps, { showAlert, getSPStoreProfile })(UpdateStoreProfile));
