import React from "react";
import styles from "./Modal.module.css";
import Modal from "@material-ui/core/Modal";
import { RadioGroup, FormControlLabel } from "@material-ui/core";
import { connect } from "react-redux";
import { getAllBookings } from "../../../../containers/endUser/actions";
import { showAlert } from "../../../../containers/app/actions";
import axios from "axios";
import Radio from "@material-ui/core/Radio";


const ModalComp = (props) => {
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState("");
    const [error, setError] = React.useState(false);

 
    let token = localStorage.getItem("token");

    const onRefundSelection = (mode) => {
        if (props.bookingId) {
            axios({
                method: "put",
                url: "/dashboard/service-provider/updateRefund",
                data: {
                    orderId: props.bookingId,
                    type:props.type,
                    mode: mode,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    if (res) {
                        window.location.reload();
                        props.setBookings("");
                        axios({
                            method: "get",
                            url: "/dashboard/service-provider/getAllBooking",

                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }).then((res) => {
                            props.setBookings(res.data.bookings);
                        });
                    }
                })
                .catch((err) => {
                    if (err && err.response) {
                    }
                });
        }
    };

    const handleChange = (e, id) => {
        if (e.target.value) {
            onRefundSelection(e.target.value);
            props.onClose();
        }
    };

    return (
        <Modal className={styles.modal} open={props.open} onClose={props.onClose}>
            <div className={styles.container}>
                <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                    <FormControlLabel
                        value="ORIGINAL"
                        control={<Radio onChange={(e) => handleChange(e)} />}
                        label="Refund to Original Mode"
                    />
                    <FormControlLabel
                        value="PROMO"
                        control={<Radio onChange={(e) => handleChange(e)} />}
                        label="Refund to PromoCode"
                    />
                </RadioGroup>
            </div>
        </Modal>
    );
};

export default connect(null, { getAllBookings, showAlert })(ModalComp);
