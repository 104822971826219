import React from "react";
import styles from "./SideBar.module.css";

import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import TuneRoundedIcon from "@material-ui/icons/TuneRounded";
import SecurityRoundedIcon from "@material-ui/icons/SecurityRounded";
import LinkRoundedIcon from "@material-ui/icons/LinkRounded";

const SideBar = (props) => {
    const state = props.state;
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div
                    className={state == "PERSONAL_DETAILS" ? styles.activeListItem : styles.listItem}
                    onClick={() => props.setState("PERSONAL_DETAILS")}
                >
                    <AccountCircleRoundedIcon style={{ color: "var(--primary-color" }} />
                    <p>Personal Details</p>
                </div>

                <div
                    className={state == "PREFERENCES" ? styles.activeListItem : styles.listItem}
                    onClick={() => props.setState("PREFERENCES")}
                >
                    <TuneRoundedIcon style={{ color: "var(--primary-color" }} />
                    <p>Preferences</p>
                </div>

                {/* <div 
                    className={state == "PAYMENT" ? styles.activeListItem : styles.listItem}
                    onClick={() => props.setState("PAYMENT")}
                >
                    <PaymentRoundedIcon style={{color: 'var(--primary-color'}} />
                    <p>Payment</p>
                </div> */}

                <div
                    className={state == "SECURITY" ? styles.activeListItem : styles.listItem}
                    onClick={() => props.setState("SECURITY")}
                >
                    <SecurityRoundedIcon style={{ color: "var(--primary-color" }} />
                    <p>Security</p>
                </div>

                <div
                    className={state == "REFERRAL" ? styles.activeListItem : styles.listItem}
                    onClick={() => props.setState("REFERRAL")}
                >
                    <LinkRoundedIcon style={{ color: "var(--primary-color" }} />
                    <p>Referral</p>
                </div>
            </div>
        </div>
    );
};
export default SideBar;
