import React from 'react' 
import styles from './PurposeJourney.module.css'

import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CircularProgress from '@material-ui/core/CircularProgress'
 
import {getAllMyJourney,getAllSentJourney} from '../../../containers/endUser/actions'
import {connect} from 'react-redux'
import {withRouter, useLocation} from 'react-router-dom'

import AppLoader from '../../utils/AppLoader/AppLoader'
import HeaderTab from './HeaderTab/HeaderTab'


const PurposeJourney = (props) => {  
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [myJourney,setMyJourney] = React.useState(false);
    const [sentJourney,setSentJourney] = React.useState(false);

    const [sentFilter,setSentFilter] = React.useState("ALL");

    React.useEffect(() => {
        props.getAllMyJourney(query.get("userId")) 
    },[])

    React.useEffect(() => { 
        setMyJourney(props.myJourney) 
    },[props.myJourney,props.sentJourney]);

    React.useEffect(() => {
        if(props.sentJourney) {
            let arr = props.sentJourney;
            arr = arr.filter(val => val.status == sentFilter || sentFilter == "ALL");
            setSentJourney([...arr]);
        }
    },[sentFilter])

    return (
        <div className={styles.container}>
            <div className={styles.content}> 

                <div className={styles.tabContent}>
                    <h4>My Journeys</h4> 
                </div>

                <div className={styles.journeyContents}> 
                    {!myJourney && <AppLoader />}

                    {myJourney &&
                        <div className={styles.createAJourney} onClick={() => props.history.push(`/user/end-users/PURPOSE-JOURNEY-MAP?userId=${query.get("userId")}`)}>
                            <AddRoundedIcon size={120} />
                            <p>Create A Journey</p>
                        </div>
                    }
                    {myJourney && myJourney.map(journey => {
                        return (
                            <div key={journey._id} className={styles.journeyBox} onClick={() => props.history.push(`/user/end-users/PURPOSE-JOURNEY-MAP?journeyType=MY_JOURNEY&journeyId=${journey._id}&userId=${query.get("userId")}`)}>
                                <div className={styles.imgContent}>
                                    {journey.category && <img src={journey.category.categoryImage} />}
                                    {journey.status == "PENDING" && 
                                        <div className={styles.infoCircle}>
                                            <p>Pending Acceptance</p>
                                        </div>}

                                    {journey.status == "REJECTED" && 
                                        <div className={styles.infoCircle}>
                                            <p>Rejected</p>
                                        </div>}

                                    {journey.status == "ACCEPTED" &&
                                        <div className={styles.progressContent}>
                                            <CircularProgress variant="determinate" value={0} thickness={6} className={styles.progressBar} />
                                            <span>0%</span>
                                        </div>} 
                                </div>
                                <div className={styles.headContent}>
                                    <p>
                                        Start Being&nbsp;
                                        {journey.category && "Start Being " + journey.category.secondaryName}
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </div> 
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    myJourney: state.endUser.myJourney,
    sentJourney: state.endUser.sentJourney
})
export default withRouter(connect(mapStateToProps, {getAllMyJourney, getAllSentJourney})(PurposeJourney));