import React from "react";
import styles from "./ServiceProviderRequested.module.css";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { makeSubString } from "../../hooks/makeSubString";
import DataTable from "react-data-table-component";
import AppLoader from "../utils/AppLoader/AppLoader";

import { connect } from "react-redux";
import { showAlert } from "../../containers/app/actions";
import { getAllRequestedData } from "./../../containers/serviceProviderRequest/actions";
import { withRouter } from "react-router-dom";
import Action from "./Action/Action";

const ServiceProviderRequested = (props) => {
    const [requests, setRequests] = React.useState(props.requests);
    const [searchVal, setSearchVal] = React.useState("");
    const [filterVal, setFilterVal] = React.useState("ALL");

    React.useEffect(() => {
        if (!props.requests) {
            props.getAllRequestedData();
        }
        setRequests(props.requests);
    }, [props.requests]);

    let isLoading = !requests;
    let showData = !isLoading;
    let rowData = [];

    !isLoading &&
        requests.forEach((request, index) => {
            if (
                request.companyName.toLowerCase().includes(searchVal.toLowerCase()) ||
                request.email.toLowerCase().includes(searchVal.toLowerCase()) ||
                request.status.toLowerCase().includes(searchVal.toLowerCase()) ||
                request.firstName.toLowerCase().includes(searchVal.toLowerCase())
            ) {
                if (request.status == filterVal || filterVal == "ALL") {
                    let displayDate = "";
                    if (new Date(request.createdAt).getDate() == new Date().getDate()) {
                        if (new Date().getHours() - new Date(request.createdAt).getHours() == 0) {
                            displayDate =
                                new Date().getMinutes() - new Date(request.createdAt).getMinutes() + " minutes ago";
                        } else {
                            displayDate = new Date().getHours() - new Date(request.createdAt).getHours() + " hour ago";
                        }
                    } else {
                        displayDate = new Date(request.createdAt).toString().substring(0, 15).slice(3);
                    }
                    rowData.push({
                        companyName: request.companyName,
                        review: (
                            <div className={styles.newContent}>
                                {request.status == "PENDING" ? (
                                    <span className={styles.new}>NEW</span>
                                ) : (
                                    <span className={styles.old}>REVIEWED</span>
                                )}
                            </div>
                        ),
                        firstName: request.firstName,
                        lastName: request.lastName,
                        email: request.email,
                        contactNumber: request.contactNumber,
                        category: request.category && request.category.categoryName,
                        zipcode: request.zipcode,
                        status: request.status,
                        address: (
                            <Tooltip title={request.address}>
                                <p className="hover">
                                    {request.address && request.address.length > 20
                                        ? request.address.substr(0, 20) + ".."
                                        : request.address}
                                </p>
                            </Tooltip>
                        ),
                        companyDescription: (
                            <Tooltip title={request.companyDescription}>
                                <p className="hover">
                                    {request.companyDescription && request.companyDescription.length > 20
                                        ? request.companyDescription.substr(0, 25) + ".."
                                        : request.companyDescription}
                                </p>
                            </Tooltip>
                        ),
                        website: request.website ? makeSubString(request.website, 30) : "NaN",
                        city: request.city ? request.city : "NaN",
                        date: displayDate,
                        actions: <Action request={request} />,
                    });
                }
            }
        });
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.leftHeader}>&nbsp;</div>

                <div className={styles.rightHeader}>
                    <Select
                        className={styles.search}
                        label="Status Filter"
                        value={filterVal}
                        onChange={(e) => setFilterVal(e.target.value)}
                    >
                        <MenuItem value="ALL">ALL</MenuItem>
                        <MenuItem value="PENDING">PENDING</MenuItem>
                        <MenuItem value="APPROVED">APPROVED</MenuItem>
                        <MenuItem value="REJECTED">DENIED REQUEST</MenuItem>
                    </Select>

                    <TextField
                        label="Search Here"
                        className={styles.search}
                        value={searchVal}
                        onChange={(e) => setSearchVal(e.target.value)}
                    />
                </div>
            </div>

            {isLoading && <AppLoader />}

            {showData && (
                <DataTable
                    noHeader={true}
                    fixedHeader={true}
                    pagination={true}
                    fixedHeaderScrollHeight={"calc(100vh - 320px)"}
                    columns={[
                        {
                            name: "Company Name",
                            selector: "companyName",
                            sortable: true,
                            wrap: true,
                            minWidth: "200px",
                        },
                        { name: "Review", selector: "review", sortable: true, wrap: true, minWidth: "100px" },
                        { name: "First Name", selector: "firstName", sortable: true, wrap: true, minWidth: "150px" },
                        { name: "Last Name", selector: "lastName", sortable: true, wrap: true, minWidth: "150px" },
                        { name: "Email", selector: "email", sortable: true, wrap: true, minWidth: "300px" },
                        {
                            name: "Contact Number",
                            selector: "contactNumber",
                            sortable: true,
                            wrap: true,
                            minWidth: "150px",
                        },
                        { name: "Category", selector: "category", sortable: true, wrap: true, minWidth: "150px" },
                        { name: "Zipcode", selector: "zipcode", sortable: true },
                        { name: "Status", selector: "status", sortable: true },
                        { name: "Adddress", selector: "address", sortable: true, wrap: true, minWidth: "300px" },
                        {
                            name: "Company Description",
                            selector: "companyDescription",
                            sortable: true,
                            wrap: true,
                            minWidth: "300px",
                        },
                        { name: "Website", selector: "website", sortable: true, wrap: true, minWidth: "230px" },
                        { name: "City", selector: "city", sortable: true, wrap: true, minWidth: "150px" },
                        { name: "Requested On", selector: "date", sortable: true, wrap: true, minWidth: "150px" },
                        { name: "Action", selector: "actions", wrap: true, minWidth: "250px" },
                    ]}
                    data={rowData}
                />
            )}
        </div>
    );
};
const mapStateToProps = (state) => ({
    requests: state.serviceProviderRequest.requests,
});
export default withRouter(connect(mapStateToProps, { getAllRequestedData, showAlert })(ServiceProviderRequested));
