import React from "react";
import styles from "./Claim.module.css";

import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";
import {useLocation} from 'react-router-dom'
import { connect } from "react-redux";
import { getAllVouchers } from "../../../../../containers/serviceProvider/actions";
import { showAlert } from "../../../../../containers/app/actions";
import axios from "axios";

import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import BtnLoader from "../../../../utils/BtnLoader/BtnLoader";

const ClaimComp = (props) => {
    const [loading, setLoading] = React.useState(false);
    const [code, setCode] = React.useState("");
    const [error, setError] = React.useState(false);
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const onSubmit = () => {
        if (code == "") {
            setError("Enter valid voucher");
        } else {
          console.log(props.voucher,code)
            setError(false);
            setLoading(true);
            axios({
                method: "put",
                url: `/dashboard/service-provider/claimVoucherCode?serviceProvider=${query.get("userId")}`,
                data: {
                    voucherId: props.voucher,
                    voucherCode: code,
                },
            })
                .then((res) => {
                    setLoading(false);
                    props.showAlert("Voucher Claimed Successfully");
                    props.getAllVouchers();
                    props.onClose();
                })
                .catch((err) => {
                    if (err && err.response) props.showAlert(err.response.data.error);
                    else props.showAlert("Something went wrong Try Again");

                    setLoading(false);
                });
        }
    };

    return (
        <Modal className={styles.modal} open={props.open} onClose={props.onClose}>
            <div className={styles.container}>
                <CloseRoundedIcon className={styles.close} onClick={props.onClose} />
                <h1>Please Enter voucher code</h1>
                <TextField
                    className={styles.textField}
                    variant="outlined"
                    fullWidth
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    error={error}
                    helperText={error}
                />

                <div className="btnCenter">
                    {loading ? (
                        <BtnLoader className="btnGreen" />
                    ) : (
                        <button className="btnGreen" onClick={onSubmit}>
                            Claim Now
                        </button>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default connect(null, { getAllVouchers, showAlert })(ClaimComp);
