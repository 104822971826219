import React from "react";
import styles from "./ManageSchedule.module.css";
import axios from "axios";

import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import SkipPreviousRoundedIcon from "@material-ui/icons/SkipPreviousRounded";
import SkipNextRoundedIcon from "@material-ui/icons/SkipNextRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { DatePicker, TimePicker } from "@material-ui/pickers";
import { showAlert } from "../../../containers/app/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import moment from "moment";
import { extendMoment } from "moment-range";

import defaultMaterialTheme from "./dataTheme";
import { ThemeProvider } from "@material-ui/styles";

const momentRange = extendMoment(moment);

const ManageSchedule = (props) => {
    const _id = Math.random();
    const [dates, setDates] = React.useState([
        {
            _id: _id,
            startDate: null,
            startTime: null,

            endDate: null,
            endTime: null,

            populateType: "",
        },
    ]);

    const [error, setError] = React.useState([
        {
            _id: _id,
            startTime: false,
            endTime: false,
            startDate: false,
            endDate: false,
            populateType: "",
        },
    ]);

    const [loading, setLoading] = React.useState(false);
    const [activePage, setActivePage] = React.useState(1);
    const [formData, setFormData] = React.useState(props.formData);
    const [state, setState] = React.useState(true);

    const validate = () => {
        let validData = true;

        let newError = error.map((err, index) => {
            const _id = dates[index]._id;
            err = {
                _id: _id,
                startTime: false,
                endTime: false,
                startDate: false,
                endDate: false,
                populateType: "",
            };
            Object.keys(err).forEach((key) => {
                if (dates[index][key] == "" || dates[index][key] == null) {
                    err[key] = `Field cannot be empty`;
                    validData = false;
                }
            });
            return { ...err };
        });

        setError([...newError]);
        return validData;
    };

    const onSubmit = () => {
        if (validate()) {
            setLoading(true);
            const url = "/dashboard/life-coaches-admin/addSchedule/" + props.coach + "/" + props.category;
            const method = "post";
            const dateArr = formData.dates.map((singleDate) => {
                const newDate = {
                    ...singleDate,
                };
                delete newDate._id;
                return newDate;
            });
            axios({
                method,
                url,
                data: {
                    dateArr,
                },
            })
                .then((res) => {
                    setLoading(false);
                    setFormData({
                        dates: [],
                    });
                    props.setFormData({ dates: [] });
                    setDates([
                        {
                            _id: _id,
                            startDate: null,
                            startTime: null,

                            endDate: null,
                            endTime: null,

                            populateType: "",
                        },
                    ]);
                    props.showAlert("Schedule Added Succesfully");

                    props.history.push(`/user/life-coaches-admin/view-coach-details?coachId=${props.coach}`);
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                    if (err && err.response && err.response.data && err.response.data.error) {
                        props.showAlert(err.response.data.error);
                    } else {
                        props.showAlert("Something went wrong ! Try Again");
                    }
                });
        }
    };

    React.useEffect(() => {
        setFormData(props.formData);
    }, [props.formData, props.coach, props.category]);

    const populateDates = (dayIndex, _id) => {
        const { startDate, endDate, endTime, startTime, populateType } = dates[dayIndex];

        if (startDate && startTime && endDate && endTime && populateType) {
            const range = momentRange.range(startDate, moment(startDate).add(3, "M"));
            const diffDays = moment(endDate).diff(moment(startDate), "days");

            let arr = Array.from(range.by("day")).map((x) => {
                return {
                    _id: _id,
                    startDate: x.format("YYYY-MM-DD"),
                    endDate: moment(x).add(diffDays, "days").format("YYYY-MM-DD"),
                    startTime: moment(startTime).format("HH:mm"),
                    endTime: moment(endTime).format("HH:mm"),
                    dayIndex: dayIndex,
                };
            });
            let oldArr = formData.dates.filter((val) => val._id != _id);

            if (populateType == "DOES_NOT_REPEAT") {
                props.setFormData({
                    ...formData,
                    dates: [
                        ...oldArr,
                        {
                            _id,
                            startDate,
                            startTime: moment(startTime).format("HH:mm"),
                            endDate,
                            endTime: moment(endTime).format("HH:mm"),
                            dayIndex,
                        },
                    ],
                });
            } else if (populateType == "DAILY") {
                props.setFormData({ ...formData, dates: [...oldArr, ...arr] });
            } else if (populateType == "WEEKLY_ON_MONDAY") {
                let weekData = arr.filter((val) => new Date(val.startDate).getDay() == new Date(startDate).getDay());
                props.setFormData({ ...formData, dates: [...oldArr, ...weekData] });
            } else if (populateType == "MONTHLY_ON_SPECIFIC_DAY") {
                let weekNumber = moment(startDate).week() - moment(moment(startDate)).startOf("month").week() + 1;
                let monData = arr.filter((val) => {
                    return (
                        moment(val.startDate).week() - moment(moment(val.startDate)).startOf("month").week() + 1 ==
                            weekNumber && new Date(startDate).getDay() == new Date(val.startDate).getDay()
                    );
                });

                props.setFormData({ ...formData, dates: [...oldArr, ...monData] });
            } else if (populateType == "ANNUALLY_ON_FIRST") {
                let anData = arr.filter((val) => {
                    let month = new Date(startDate).getMonth();
                    return (
                        new Date(val.startDate).getDate() == new Date(startDate).getDate() &&
                        new Date(val.startDate).getMonth() == month
                    );
                });
                props.setFormData({ ...formData, dates: [...oldArr, ...anData] });
            } else if (populateType == "EVERYDAY_MONDAY_FRIDAY") {
                let mfData = arr.filter(
                    (val) => new Date(val.startDate).getDay() != 6 && new Date(val.startDate).getDay() != 0,
                );
                props.setFormData({ ...formData, dates: [...oldArr, ...mfData] });
            }
        }
    };

    const onAddDateMaker = () => {
        let arr = dates;
        let _id = Math.random();
        arr.push({ _id, startDate: null, startTime: null, endDate: null, endTime: null, populateType: "" });
        setDates([...arr]);
        let err = error;
        err.push({
            _id,
            startTime: false,
            endTime: false,
            startDate: false,
            endDate: false,
            populateType: "",
        });
        setError(err);
    };

    // const resetData = () => {
    //     let _id = Math.random();
    //     setDates([{_id,startDate: null,endDate: null,startTime: null,endTime: null,populateType: null,}])
    // }

    const onDeletePopulator = (_id) => {
        let arr = formData.dates.filter((val) => val._id != _id);
        let arrDates = dates.filter((val) => val._id != _id);
        props.setFormData({ ...formData, dates: [...arr] });
        setDates([...arrDates]);

        let newError = error.filter((err) => err._id != _id);
        setError([...newError]);
    };

    let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    let helperText = { 1: "st", 2: "nd", 3: "rd", 4: "th", 5: "th" };
    var month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    console.log("------------TEST----------->>>>>>", state);

    return (
        <div className={styles.container}>
            <div className={styles.cards}>
                <div className={styles.card}>
                    <ThemeProvider theme={defaultMaterialTheme}>
                        <DatePicker
                            variant="static"
                            openTo="date"
                            color="primary"
                            onChange={(date) => {
                                let arr = dates;
                                state
                                    ? arr[arr.length - 1].endDate < moment(date).format("yyyy-MM-DD")
                                        ? props.showAlert("Start Date cannot be greator then End Date")
                                        : (arr[arr.length - 1] = {
                                              ...arr[arr.length - 1],
                                              startDate: moment(date).format("yyyy-MM-DD"),
                                          })
                                    : (arr[arr.length - 1] = {
                                          ...arr[arr.length - 1],
                                          endDate: moment(date).format("yyyy-MM-DD"),
                                      });
                                // setState(!state);
                                setDates([...arr]);
                                populateDates(arr.length - 1, arr[arr.length - 1]._id);
                            }}
                            className={styles.dateField}
                            minDate={state ? null : dates[dates.length - 1].startDate}
                            value={state ? dates[dates.length - 1].startDate : dates[dates.length - 1].endDate}
                            disablePast
                        />
                    </ThemeProvider>
                </div>

                <div className={styles.card}>
                    {dates.map((val, dayIndex) => {
                        return (
                            <div className={styles.cardComp} key={dayIndex}>
                                {dayIndex != 0 && (
                                    <div className={styles.alignEnd}>
                                        <CloseRoundedIcon onClick={() => onDeletePopulator(val._id)} />
                                    </div>
                                )}

                                <div className={styles.joinedContent}>
                                    <p>Start</p>
                                    <DatePicker
                                        disablePast
                                        onFocus={() => setState(true)}
                                        onChange={(date) => {
                                            let arr = dates;
                                            arr[dayIndex] = {
                                                ...arr[dayIndex],
                                                startDate: moment(date).format("yyyy-MM-DD"),
                                            };
                                            setDates([...arr]);
                                            setState(true);
                                        }}
                                        error={error[dayIndex].startDate}
                                        open={false}
                                        defaultValue=""
                                        helperText={error[dayIndex].startDate}
                                        variant="inline"
                                        className={styles.datePicker}
                                        value={val.startDate}
                                        format="dd/MM/yyyy"
                                    />

                                    <TimePicker
                                        variant="inline"
                                        error={error[dayIndex].startTime}
                                        helperText={error[dayIndex].startTime}
                                        defaultValue={null}
                                        value={val.startTime}
                                        onChange={(date) => {
                                            let arr = dates;
                                            let startDate = arr[dayIndex].startDate;
                                            let splitedDate = startDate.split("-");
                                            let dateSelected = new Date(
                                                Number(splitedDate[0]),
                                                Number(splitedDate[1]),
                                                Number(splitedDate[2]),
                                                date.getHours(),
                                                date.getMinutes(),
                                                date.getSeconds(),
                                            );
                                            if (!arr[dayIndex].endTime) {
                                                arr[dayIndex] = { ...arr[dayIndex], startTime: dateSelected };
                                                setDates([...arr]);
                                                populateDates(dayIndex, val._id);
                                                return;
                                            }
                                            let endTime = new Date(arr[dayIndex].endTime);
                                            if (endTime.getTime() > dateSelected.getTime()) {
                                                arr[dayIndex] = { ...arr[dayIndex], startTime: dateSelected };
                                                setDates([...arr]);
                                                populateDates(dayIndex, val._id);
                                            } else {
                                                props.showAlert("Start Time cannot be less then End Time");
                                            }
                                        }}
                                        className={styles.timePicker}
                                    />
                                </div>

                                <div className={styles.joinedContent}>
                                    <p>End</p>
                                    <DatePicker
                                        disablePast
                                        onFocus={() => setState(false)}
                                        open={false}
                                        onChange={(date) => {
                                            let arr = dates;
                                            arr[dayIndex] = {
                                                ...arr[dayIndex],
                                                endDate: moment(date).format("yyyy-MM-DD"),
                                            };
                                            setDates([...arr]);
                                            setState(false);
                                        }}
                                        error={error[dayIndex].endDate}
                                        helperText={error[dayIndex].endDate}
                                        variant="inline"
                                        defaultValue=""
                                        value={val.endDate}
                                        format="dd/MM/yyyy"
                                        className={styles.datePicker}
                                    />

                                    <TimePicker
                                        variant="inline"
                                        error={error[dayIndex].endTime}
                                        helperText={error[dayIndex].endTime}
                                        defaultValue=""
                                        value={val.endTime}
                                        onChange={(date) => {
                                            let arr = dates;
                                            let endDate = arr[dayIndex].endDate;
                                            let splitedDate = endDate.split("-");
                                            let dateSelected = new Date(
                                                Number(splitedDate[0]),
                                                Number(splitedDate[1]),
                                                Number(splitedDate[2]),
                                                date.getHours(),
                                                date.getMinutes(),
                                                date.getSeconds(),
                                            );
                                            if (!arr[dayIndex].startTime) {
                                                arr[dayIndex] = { ...arr[dayIndex], endTime: dateSelected };
                                                setDates([...arr]);
                                                populateDates(dayIndex, val._id);
                                                return;
                                            }
                                            let starTime = new Date(arr[dayIndex].startTime);
                                            if (starTime.getTime() < dateSelected.getTime()) {
                                                arr[dayIndex] = { ...arr[dayIndex], endTime: dateSelected };
                                                setDates([...arr]);
                                                populateDates(dayIndex, val._id);
                                            } else {
                                                props.showAlert("EndTime cannot be less then start Time");
                                            }
                                        }}
                                        className={styles.timePicker}
                                    />
                                </div>

                                <React.Fragment>
                                    <div className={styles.formGroup}>
                                        <p>Repeats</p>
                                        <Select
                                            className={styles.date}
                                            value={val.populateType}
                                            onChange={(e) => {
                                                let arr = dates;
                                                arr[dayIndex] = { ...arr[dayIndex], populateType: e.target.value };
                                                setDates([...arr]);
                                                populateDates(dayIndex, val._id);
                                            }}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                },
                                                getContentAnchorEl: null,
                                                className: styles.menuItems,
                                            }}
                                            error={error[dayIndex].populateType}
                                            helperText={error[dayIndex].populateType}
                                        >
                                            <MenuItem value="DOES_NOT_REPEAT">Doesn't Repeat</MenuItem>
                                            <MenuItem value="DAILY">Daily</MenuItem>
                                            <MenuItem value="WEEKLY_ON_MONDAY">
                                                Weekly on {days[new Date(val.startDate).getDay()]}
                                            </MenuItem>
                                            <MenuItem value="MONTHLY_ON_SPECIFIC_DAY">
                                                Monthly on the &nbsp;
                                                {Math.ceil(new Date(val.startDate).getDate() / 7 + 1)}
                                                {helperText[Math.ceil(new Date(val.startDate).getDate() / 7 + 1)]}{" "}
                                                &nbsp;
                                                {days[new Date(val.startDate).getDay()]}
                                            </MenuItem>

                                            <MenuItem value="ANNUALLY_ON_FIRST">
                                                Annually on {month[new Date(val.startDate).getMonth()]}{" "}
                                                {new Date(val.startDate).getDate()}
                                            </MenuItem>
                                            <MenuItem value="EVERYDAY_MONDAY_FRIDAY">
                                                Every Day(Monday to Friday)
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </React.Fragment>
                            </div>
                        );
                    })}

                    <div className="btnCenter">
                        <button className="btnGreen" onClick={onAddDateMaker}>
                            Add More
                        </button>
                    </div>
                </div>

                <div className={styles.card}>
                    {formData && formData.dates && formData.dates.length > 0 && (
                        <React.Fragment>
                            <h3>Booking Schedule</h3>

                            {formData.dates.map((date, index) => {
                                if (index >= (activePage - 1) * 8 && index < activePage * 8) {
                                    return (
                                        <div className={styles.dateItem}>
                                            <span>
                                                {new Date(date.startDate).toString().substr(0, 15)} &nbsp;{" "}
                                                {date.startTime}-{date.endTime}
                                            </span>
                                            <HighlightOffRoundedIcon
                                                onClick={() => {
                                                    let arr = formData.dates;
                                                    if (arr.length > 0) {
                                                        arr = arr.filter((val, i) => i != index);
                                                        props.setFormData({ ...formData, dates: [...arr] });
                                                    }
                                                }}
                                            />
                                        </div>
                                    );
                                }
                            })}

                            {formData.dates.length > 8 && (
                                <React.Fragment>
                                    <div className={styles.pagination}>
                                        <SkipPreviousRoundedIcon
                                            onClick={() => {
                                                activePage > 1 && setActivePage(activePage - 1);
                                            }}
                                        />

                                        {Array(Math.ceil(formData.dates.length / 8))
                                            .fill("")
                                            .map((val, index) => {
                                                let currentValue = index + 1;
                                                return (
                                                    <span
                                                        onClick={() => setActivePage(currentValue)}
                                                        className={activePage == currentValue && styles.activeSpan}
                                                    >
                                                        {currentValue}
                                                    </span>
                                                );
                                            })}
                                        <SkipNextRoundedIcon
                                            onClick={() => {
                                                activePage < Math.ceil(formData.dates.length / 8) &&
                                                    setActivePage(activePage + 1);
                                            }}
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </div>
            </div>
            <div className={styles.addScheduleButton}>
                {loading ? (
                    <Button startIcon={<CircularProgress color="inherit" size={20} />}>Loading ...</Button>
                ) : (
                    <Button startIcon={<CalendarTodayIcon />} onClick={onSubmit}>
                        Add Schedule
                    </Button>
                )}
            </div>
        </div>
    );
};

export default withRouter(connect(null, { showAlert })(ManageSchedule));
