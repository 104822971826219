import styles from "./Ratings.module.css";
import { Ratings as RatingStars } from "../ServiceSpecs/Ratings/Ratings";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
const RatingsBar = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 4,
  },
  bar: {
    borderRadius: 4,
    backgroundColor: "var(--secondary-color)",
  },
}))(LinearProgress);

const normalise = (value) => (value * 100) / 5;

export function Ratings() { 
  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              <h1>{("Bewertungen")}</h1>
              <div className={styles.headerTitle}>
                {/* <RatingStars /> */}
                ({"Keine Bewertungen"})
              </div>
            </div>
            <div> 
            </div>
          </div>
        </div>
      </div> 
    </>
  );
}
