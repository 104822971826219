import React from 'react'
import styles from './Fulfil.module.css'

import TextField from '@material-ui/core/TextField'
import Modal from '@material-ui/core/Modal' 

import {useLocation} from 'react-router-dom'
import {connect} from 'react-redux'
import {getAllBookings} from '../../../../../containers/serviceProvider/actions'
import {showAlert} from '../../../../../containers/app/actions'
import axios from 'axios'

import BtnLoader from '../../../../utils/BtnLoader/BtnLoader'

const FulfilComp = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [loading,setLoading] = React.useState(false);
    const [code,setCode] = React.useState("");
    const [error,setError] = React.useState(false)

    const onSubmit = () => {
        if(code == "" && code.toString().length != 6) {
            setError("Enter valid 6 digit code");
        } else {
            setError(false)
            setLoading(true);
            axios({
                method: "put",
                url: `/dashboard/service-provider/fulfilBooking?serviceProvider=${query.get("userId")}`,
                data: {
                    otc: code,
                    orderId: props.booking._id  
                }
            }).then(res => {
                setLoading(false);
                props.showAlert('Booking Fulfilled Successfully')
                props.getAllBookings();
                props.onClose();
            }).catch(err => {
                if(err && err.response) 
                    props.showAlert(err.response.data.error)
                else 
                    props.showAlert("Something went wrong Try Again")

                setLoading(false);
            })
        }
    }

    return (
        <Modal className={styles.modal} open={props.open} onClose={props.onClose}>
            <div className={styles.container}>
                <h1>Please Enter 6 digit One Time Code</h1>
                <TextField 
                    className={styles.textField}
                    variant="outlined"
                    fullWidth
                    type="number"
                    value={code}
                    onChange={e => setCode(e.target.value)}

                    error={error}
                    helperText={error} 
                /> 

                <div className="btnCenter">
                    {loading 
                        ? <BtnLoader className="btnGreen" />
                        : <button className="btnGreen" onClick={onSubmit}>FULFIL</button>
                    }   
                </div>
            </div>
        </Modal>
    )
}

export default connect(null, {getAllBookings, showAlert})(FulfilComp);