import { CircularProgress } from '@material-ui/core'
import React from 'react'

const BtnLoader = (props) => {
    return (
        <button className={props.className} style={{opacity: 0.6,display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
            <CircularProgress size={16} color="white" />
            {!props.onlyLoader &&
                <React.Fragment>
                    &nbsp;
                    Loading...
                </React.Fragment>}
        </button>
    )
}
export default BtnLoader;