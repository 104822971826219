import React from "react";
import styles from "./Notification.module.css";
import TextField from "@material-ui/core/TextField";
import DataTable from "react-data-table-component";
import AppLoader from "../../utils/AppLoader/AppLoader";
import { connect } from "react-redux";
import { getAllNotifications } from "../../../containers/endUser/actions";
import { withRouter } from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";

const Notifications = (props) => {
    const [notifications, setNotifications] = React.useState(props.notifications);
    const [searchVal, setSearchVal] = React.useState("");

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    React.useEffect(() => {
        if (!props.notifications) {
            props.getAllNotifications(rowsPerPage, page + 1, searchVal);
        }
        setNotifications(props.notifications);
    }, [props.notifications]);

    const handleSearchChange = (e) => {
        setNotifications([]);
        setPage(0);
        props.getAllNotifications(rowsPerPage, 1, e.target.value);
        setSearchVal(e.target.value);
    };

    const handleChange = (event, newPage) => {
        setNotifications(false);
        props.getAllNotifications(rowsPerPage, newPage + 1);
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setNotifications(false);
        props.getAllNotifications(event.target.value, page + 1);
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    let isLoading = !notifications;
    let showData = !isLoading;
    let rowData = [];

    notifications &&
        notifications.forEach((notify, index) => {
            rowData.push({
                sl_no: index + 1,
                firstName: notify?.meta?.userId?.userProfile?.firstName ?? notify.username,
                lastName: notify?.meta?.userId?.userProfile?.lastName,
                email: notify?.meta?.userId?.email,
                notification: notify?.notification,
                date: moment(notify?.date).format("YYYY-MM-DD"),
                time: moment(notify?.date).format("LT"),
            });
        });

    return (
        <div className={styles.container}>
            <div className={styles.topHeader}>
                <h3>Notification Log</h3>
                <p>Manage customer information</p>
            </div>
            <div className={styles.header}>
                <div className={styles.leftHeader}>&nbsp;</div>

                <div className={styles.rightHeader}>
                    <TextField
                        placeholder="Search Here"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        className={styles.search}
                        value={searchVal}
                        onChange={(e) => handleSearchChange(e)}
                    />
                </div>
            </div>

            {isLoading && <AppLoader />}

            {showData && (
                <DataTable
                    noHeader={true}
                    fixedHeader={true}
                    fixedHeaderScrollHeight={"calc(100vh - 320px)"}
                    columns={[
                        { name: "Sl No", selector: "sl_no", sortable: true },
                        { name: "First Name", selector: "firstName", sortable: true },
                        { name: "Last Name", selector: "lastName", sortable: true },

                        { name: "Email", selector: "email", sortable: true },
                        { name: "Notification", selector: "notification", sortable: true },
                        { name: "Date", selector: "date", sortable: true },
                        { name: "Time", selector: "time", sortable: true },
                    ]}
                    data={rowData}
                />
            )}
            <div className={styles.flexEnd}>
                <TablePagination
                    component="div"
                    count={props.count}
                    page={page}
                    onChangePage={handleChange}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    notifications: state.endUser.notifications,
    count: state.endUser.notificationsCount,
});
export default withRouter(connect(mapStateToProps, { getAllNotifications })(Notifications));
