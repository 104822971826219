import React from "react";
import { Container, Grid, makeStyles, Modal } from "@material-ui/core";

import { TimerRounded, CheckCircleOutline } from "@material-ui/icons";

import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import styles from "./Amenities.module.css";
import mainIcon from "../../../../assets/svg/21done-icon-secondary.svg";
import woodImg from "../../../../assets/img/wood_bg.jpeg";
import { specImgYellow } from "../../../../config/specificationImageYellow";

const useStyles = makeStyles((theme) => ({
    itemCenter: {
        display: "flex",
        justifyContent: "center",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function AmenitiesModal({ open, service, onClose, ...props }) {
    const classes = useStyles();
    const iconsYellow = specImgYellow();

    // TWO KEY ARE SAME. SO ONE KEY NAME IS BEING CHANGED
    const difficultyLevel = service?.specifications?.difficultyLevel.map((item) => {
        if (item === "NOT_APPLICABLE") {
            return "NOT_APPLICABLE_LEVEL";
        } else return item;
    });

    const accessibility = service?.specifications?.accessibility ? service?.specifications?.accessibility : [];
    const size = service?.specifications?.size ? service?.specifications?.size : [];
    const age = service?.specifications?.age ? service?.specifications?.age : [];
    const gender = service?.specifications?.gender && service?.specifications?.gender;

    const specificationArray = [...accessibility, ...size, ...age, ...difficultyLevel, gender];

    return (
        <Modal open={open} onClose={onClose}>
            <div className={styles.modalContainer}>
                <div className={styles.content}>
                    <div className={styles.modalHeader}>
                        <img src={mainIcon} alt="21 Done" />
                        <IconButton style={{ paddingRight: 0 }} onClick={() => onClose(false)} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>

                    <div style={{ padding: "0px 20px 32px 20px" }}>
                        <div
                            style={{
                                borderRadius: 13,
                                backgroundImage: `url(${woodImg})`,
                                backgroundSize: "cover",
                            }}
                        >
                            <h1
                                style={{
                                    textAlign: "center",
                                    width: "100%",
                                    color: "#e6e1d2",
                                    padding: "15px 0px",
                                }}
                            >
                                All amenities
                            </h1>
                            <Container maxWidth="md">
                                <Grid container spacing={3} justifyContent="center" alignItems="center">
                                    <Grid item xs={12} md={6} className={classes.itemCenter}>
                                        <div className={styles.tagGroup}>
                                            <div className={styles.iconContainer}>
                                                <TimerRounded />
                                            </div>
                                            <span className={styles.tag}>
                                                <React.Fragment>
                                                    {service.serviceDuration &&
                                                        service.serviceDuration.days != 0 &&
                                                        service.serviceDuration.days + "Tage"}
                                                    &nbsp;
                                                    {service.serviceDuration &&
                                                        service.serviceDuration.hours != 0 &&
                                                        service.serviceDuration.hours + "Stunde"}
                                                    &nbsp;
                                                    {service.serviceDuration &&
                                                        service.serviceDuration.minutes != 0 &&
                                                        service.serviceDuration.minutes + "Minuten"}
                                                </React.Fragment>
                                            </span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6} className={classes.itemCenter}>
                                        <div className={styles.tagGroup}>
                                            <div className={styles.iconContainer}>
                                                <CheckCircleOutline />
                                            </div>
                                            <span className={styles.tag}>{"21Done Verifiziert"}</span>
                                        </div>
                                    </Grid>

                                    {specificationArray.length > 0 &&
                                        specificationArray.map(
                                            (item, index) =>
                                                iconsYellow[item] && (
                                                    <Grid
                                                        key={index}
                                                        item
                                                        xs={12}
                                                        md={6}
                                                        className={classes.itemCenter}
                                                    >
                                                        <div className={styles.tagGroup}>
                                                            <div className={styles.iconContainer}>
                                                                <img src={iconsYellow[item]?.icon} alt="de" />
                                                            </div>
                                                            <span className={styles.tag}>
                                                                {iconsYellow[item]?.text}
                                                            </span>
                                                        </div>
                                                    </Grid>
                                                ),
                                        )}
                                </Grid>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
