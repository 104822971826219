import React from 'react'
import styles from './DiscountLimit.module.css'


import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
 

const DiscountLimit = (props) => { 
    const [err,setErr] = React.useState({
        discountCountLimit: false,
        discountCustomerCountLimit: false
    })
    const formData = props.formData;
    const error = props.error;

    const handleChange = (e) => {
        setErr({...err,[e.target.name]: false})
        if(/^\d+$/.test(e.target.value) || e.target.value == "") {
            props.setFormData({...formData,[e.target.name]: e.target.value});
        } else {
            setErr({...err,[e.target.name]: "You can only type in numbers"})
        }
    }


    return (
        <div className={styles.compContent}>
            <h3>Customer Type: </h3>

            <div className={styles.formGroup}>
                <div className={styles.selectors}>
                    <div className={styles.radio}>
                        <Checkbox
                            checked={formData.isThereCountLimit}
                            onChange={e => props.setFormData({...formData,isThereCountLimit: !formData.isThereCountLimit})}
                        />
                        <p>Limit how often this discount can be used in total</p>
                    </div> 
                </div>  

                {formData.isThereCountLimit &&
                    <TextField
                        label="Discount Count Limit"
                        variant="outlined" 
                        value={formData.discountCountLimit}
                        onChange={handleChange}
                        error={error.discountCountLimit || err.discountCountLimit}
                        helperText={error.discountCountLimit || err.discountCountLimit}
                        className={styles.textField}
                        name="discountCountLimit"
                    />}
            </div>

            <div className={styles.formGroup}>
                <div className={styles.selectors}>
                    <div className={styles.radio}>
                        <Checkbox
                            checked={formData.isThereCustomerCountLimit}
                            onChange={e => props.setFormData({...formData,isThereCustomerCountLimit: !formData.isThereCustomerCountLimit})}
                        />
                        <p>Specify that each customer may only use this discount once</p>
                    </div> 
                </div>  

                {formData.isThereCustomerCountLimit &&
                    <TextField
                        label="Discount Customer Count Limit"
                        variant="outlined" 
                        value={formData.discountCustomerCountLimit}
                        onChange={handleChange}
                        error={error.discountCustomerCountLimit || err.discountCustomerCountLimit}
                        helperText={error.discountCustomerCountLimit || err.discountCustomerCountLimit}
                        className={styles.textField}
                        name="discountCustomerCountLimit"
                    />}
            </div>
        </div>
    )
}
 
export default DiscountLimit;