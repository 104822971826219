import React from 'react'
import styles from './Login.module.css' 
import axios from 'axios' 
import {connect} from 'react-redux'
import {showAlert,setAuth} from '../../../containers/app/actions'
import {getLifeCoachNavbar} from "../../../containers/General/actions"
import TextField from '@material-ui/core/TextField'
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import PassTextField from '../../utils/PassTextField/PassTextField'

const Login = (props) => {
    const [formData,setFormData] = React.useState({
        email: '',
        password: "",
    });
    const [formError,setFormError] = React.useState({
        email: false,
        password: false,
    });
    const [loading,setLoading] = React.useState(false);

    React.useEffect(() => {
        if(props.auth) {
            props.history.push("/user/home")
        }
    },[props.auth]);
    
    const validate = () => {
        let value = true;
        let err = {email: false,password: false}
        let isEmail = /^([A-Za-z0-9_\-\.+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        setFormError({...err}); 
        if(formData.password.length < 8) {
            value = false;
            err.password = "Password should be atleast 8 charactors"
        }
        if(!isEmail.test(formData.email)) {
            err.email = 'Enter valid email'
            value = false;
        }
        setFormError({...err});
        return value;
    }

    const onSubmit = () => {
        if(validate()) {
            setLoading(true);

            axios({
                method: "post",
                url: "/dashboard/auth/login",
                data: {
                    ...formData, 
                }
            }).then(res => { 
                if(res.data.user.userType == "ADMIN" || res.data.user.userType == "SALES" || res.data.user.userType == "LIFECOACH") {
                    if(res.data.user.userType == "LIFECOACH"){
                        props.getLifeCoachNavbar();
                    }
                    props.setAuth({...res.data.user,token: res.data.token})
                    localStorage.setItem("token",res.data.token)
                    props.showAlert("Logged In Successfully")
                    
                } else {
                    props.showAlert("401: You don't have enough access")
                }
                setLoading(false);
            }).catch(err => {
                if(err && err.response) 
                    props.showAlert(err.response.data.error)
                else 
                    props.showAlert("Something went wrong Try Again")

                setLoading(false);
            });
        }
    }
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.header}> 
                    <h1 className={styles.head}>21 Done Admin</h1>
                </div>

                <div className={styles.loginContainer}>
                    <div className={styles.loginContent}>
                        <h1 className={styles.loginHead}>Sign In</h1>

                        <TextField
                            variant="standard"
                            fullWidth
                            label="Email"
                            className={styles.textField}
                            value={formData.email}
                            onChange={(e) => setFormData({...formData,email: e.target.value})}
                            error={formError.email}
                            helperText={formError.email}
                        /> 

                        <PassTextField
                            variant="standard"
                            fullWidth
                            label="Password"
                            className={styles.textField}
                            value={formData.password}
                            onChange={(e) => setFormData({...formData,password: e.target.value})}
                            error={formError.password}
                            helperText={formError.password}
                        />

                        {loading
                            ?
                        <button className={styles.signIn}>
                            Loading...
                            <ArrowForwardRoundedIcon />
                        </button>
                            :
                        <button className={styles.signIn} onClick={onSubmit}>
                            Sign In
                            <ArrowForwardRoundedIcon />
                        </button>}
                    </div>
                </div>

                <div className={styles.loginFooter}>&nbsp;</div>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    auth: state.app.auth
});
export default connect(mapStateToProps,{showAlert,setAuth,getLifeCoachNavbar})(Login);