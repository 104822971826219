import React from "react";
import axios from "axios";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import rrulePlugin from "@fullcalendar/rrule";
import EventPopup from "./EventPopup/EventPopup";
import moment from "moment";
import timeDiffCalc from "../../../hooks/timeDiffCalc";
import { showAlert } from "../../../containers/app/actions";
import { withRouter, useLocation } from "react-router-dom";
import styles from "./ServiceCalender.module.css";
import { Button, ButtonGroup, FormControl, MenuItem, Select } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { connect } from "react-redux";
import isDesktop from "../../../hooks/isDesktop";
import sortDate from "../../../hooks/sortDate";

/**
 * *! genetrates ID for the RRULE Object
 */
function getID() {
    return Math.random().toString(16).slice(2);
}

/**
 * *! prevents from creating overlaping events
 * */
function checkOverlapingEvents(formData, eventObject, currentEventInfo, filter = false) {
    let flag = true;

    const filteredDate = formData.dates.filter(
        (item) =>
            !moment(moment(currentEventInfo?.event?.start).format("YYYY-MM-DDTHH:mm")).isSame(
                moment(`${item.startDate}T${item.startTime}`),
            ) &&
            !moment(moment(currentEventInfo?.event?.end).format("YYYY-MM-DDTHH:mm")).isSame(
                moment(`${item.endDate}T${item.endTime}`),
            ),
    );

    const dateArray = filter ? filteredDate : formData.dates;

    /**
     * *! first part of the condition check if the entered dates fall in between other created event
     * *! second part of the condition check if there is any event that fall in between the etered dates
     * */
    dateArray.map((item) => {
        if (
            moment(eventObject.rrule.dtstart).isBetween(
                `${item.startDate}T${item.startTime}`,
                `${item.endDate}T${item.endTime}`,
                undefined,
                "()",
            ) ||
            moment(eventObject.slotEndDate).isBetween(
                `${item.startDate}T${item.startTime}`,
                `${item.endDate}T${item.endTime}`,
                undefined,
                "()",
            ) ||
            moment(`${item.startDate}T${item.startTime}`).isBetween(
                eventObject.rrule.dtstart,
                eventObject.slotEndDate,
                undefined,
                "()",
            ) ||
            moment(`${item.endDate}T${item.endTime}`).isBetween(
                eventObject.rrule.dtstart,
                eventObject.slotEndDate,
                undefined,
                "()",
            )
        ) {
            flag = false;
        }
    });

    return flag;
}

function ServiceCalender({ formData, setFormData, showAlert, ...props }) {
    const desktop = isDesktop();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const calendarRef = React.useRef(null);
    const [eventObject, setEventObject] = React.useState({
        id: getID(),
        title: "",
        slotEndDate: "",
        // meetingLink: "",
        duration: { days: "", hours: "", minutes: "" },
        rrule: {
            freq: "daily",
            dtstart: "",
            count: 1,
            until: "",
        },
        exdate: [],
    });

    const [error, setError] = React.useState({
        slotEndDate: false,
        rrule: {
            dtstart: false,
        },
    });

    const [today, setToday] = React.useState(false);
    const [calenderView, setCalenderView] = React.useState("dayGridMonth");
    const [eventPopup, setEventPopup] = React.useState(false);
    const [update, setUpdate] = React.useState(false);
    const [eventInfo, setEventInfo] = React.useState(null);
    const [duplicate, setDuplicate] = React.useState(false);
    const [startValidation, setStartValidation] = React.useState(false);

    const customDay = (day) => {
        return (
            /**
             * *! custom rendering of the dates
             */
            <>
        
                {day.dayNumberText ? (
                    <div className={day.isToday && styles.day}>
                        <p>{day.dayNumberText}</p>
                    </div>
                ) : null}
            </>
        );
    };

    // const customEventDisplay = (eventData) => {
    //     return (
    //         /**
    //          * *! custom rendering of the vent details
    //          */
    //         <div className={styles.event}>
    //             <span>{eventData.timeText}</span> <span>{eventData.event.title}</span>
    //         </div>
    //     );
    // };

    /**
     **! check if there any booking present forn the particular date
     */

    async function checkBookings() {
        return new Promise(async (resolve, reject) => {
            /**
             * *! returns false if it is a new event(eventId will not be present in the URl) or if user is trying to crate a duplicate event
             */
            if (query.get("type") == "DUPLICATE" || !query.get("eventId")) {
                return resolve(false);
            } else {
                axios({
                    method: "post",
                    url: "/dashboard/service-provider/checkSingleEventBookingAvailable",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    data: {
                        eventId: formData?._id,
                        serviceProvider: formData?.serviceProvider,
                        timeSlot: {
                            startDate: moment.utc(eventInfo?.event._instance.range.start).format("YYYY-MM-DD"),
                            startTime: moment.utc(eventInfo?.event._instance.range.start).format("HH:mm"),
                            endDate: moment.utc(eventInfo?.event._instance.range.end).format("YYYY-MM-DD"),
                            endTime: moment.utc(eventInfo?.event._instance.range.end).format("HH:mm"),
                        },
                    },
                })
                    .then((res) => {
                        res.data?.message && showAlert(res.data?.message);
                        if (res.data.bookingPresent === true) {
                            return resolve(true);
                        } else return resolve(false);
                    })
                    .catch((err) => {
                        if (err && err.response && err.response.data) showAlert(err.response.data.error);
                        else props.showAlert("Something went wrong Try Again");
                        return resolve(true);
                    });
            }
        });
    }

    const validate = () => {
        let validData = true;
        setStartValidation(true);

        const err = {
            slotEndDate: false,

            rrule: {
                dtstart: false,
            },
        };

        Object.keys(err).forEach((key) => {
            if (eventObject[key] === "" && key !== "meetingLink") {
                err[key] = `Field cannot be empty`;
                validData = false;
            }
        });
        Object.keys(err.rrule).forEach((key) => {
            if (eventObject.rrule[key] === "" && key !== "until") {
                err.rrule[key] = `Field cannot be empty`;
                validData = false;
            }
        });

        if (moment(eventObject.slotEndDate).isBefore(eventObject.rrule.dtstart)) {
            validData = false;
            err.slotEndDate = "serviceProviderFullCalender.End Date cannot be before Start Date";
        }

        if (moment(eventObject.rrule.dtstart).isBefore(moment())) {
            validData = false;
            err.rrule.dtstart = "serviceProviderFullCalender.Please select future date and time";
        }

        if (moment(eventObject.slotEndDate).isBefore(moment())) {
            validData = false;
            err.slotEndDate = "serviceProviderFullCalender.Please select future date and time";
        }

        if ((eventObject.rrule.count === "" || eventObject.rrule.count < 1) && eventObject.rrule.until === "") {
            err.rrule.until = `Field cannot be empty`;
            validData = false;
        }

        if (
            eventObject.rrule.until !== "" &&
            moment(eventObject?.rrule?.until).isBefore(moment(eventObject.rrule.dtstart))
        ) {
            validData = false;
            err.rrule.until = "serviceProviderFullCalender.Please select date and time greater than start date";
        }
        setError({ ...err });
        return validData;
    };

    const handleDateClick = (date) => {
        /**
         * *! on click of date the modal will only open if the dates are not past
         */
        if (
            moment().format("YYYY-MM-DD") === moment(date.date).format("YYYY-MM-DD") ||
            moment(date.date).isAfter(moment())
        ) {
            // This allows today and future date
            setEventPopup(true);
            setEventObject((val) => ({
                ...val,
                slotEndDate: moment(date.date).add(30, "minutes").format("YYYY-MM-DDTHH:mm"),
                rrule: {
                    ...val.rrule,
                    dtstart: moment(date.date).format("YYYY-MM-DDTHH:mm"),
                },
            }));
        } else {
            // Else part is for past dates
            setEventPopup(false);
        }
    };

    const handleEventClick = (info) => {
        setEventInfo(info);
        setUpdate(true);
        const eventSrc =
            formData.rruleData &&
            formData.rruleData.length > 0 &&
            formData.rruleData.find((item) => item.id === info.event.id);
        /**
         * *! on click on any event it will populate the form from the `info` object
         */
        if (eventSrc.rrule.count && eventSrc.rrule.count == 1) {
            setEventObject((val) => ({
                ...val,
                id: info.event.id,
                slotEndDate: moment(info.event.end).format("YYYY-MM-DDTHH:mm"),

                rrule: {
                    ...val.rrule,
                    dtstart: moment(info.event.start).format("YYYY-MM-DDTHH:mm"),
                },
            }));
        } else {
            setEventObject((val) => ({
                ...val,
                slotEndDate: moment(info.event.end).format("YYYY-MM-DDTHH:mm"),

                rrule: {
                    ...val.rrule,
                    dtstart: moment(info.event.start).format("YYYY-MM-DDTHH:mm"),
                },
            }));
        }
        setEventPopup(true);
    };

    const deleteEvent = () => {
        const eventID = eventInfo.event.id;
        const eventSrc =
            formData.rruleData &&
            formData.rruleData.length > 0 &&
            formData.rruleData.find((item) => item.id === eventID);
        const modifiedEvents =
            formData.rruleData &&
            formData.rruleData.length > 0 &&
            formData.rruleData.filter((item) => item.id !== eventID);
        if (eventSrc.rrule.count && eventSrc.rrule.count == 1) {
            /**
             * *! if the event is not recurring it will remove the particular rrule object
             */
            eventInfo.event.remove();
            setFormData((val) => ({
                ...val,
                rruleData: modifiedEvents,
            }));
        } else {
            /**
             * *! if the event is recurring it will add the particular date in the `exdate` array of the rrule object
             */
            const newEvent = {
                ...eventSrc,
                exdate: [...eventSrc.exdate, moment(eventInfo.event.start).format("YYYY-MM-DDTHH:mm")],
            };
            setFormData((val) => ({
                ...val,
                rruleData: [...modifiedEvents, newEvent],
            }));
        }
        setEventInfo(null);
    };

    const addEvents = () => {
        if (validate()) {
            if (checkOverlapingEvents(formData, eventObject)) {
                // error && setError(false);
                setFormData((val) => ({
                    ...val,
                    rruleData: [...val.rruleData, eventObject],
                }));

                return true;
            } else {
                setDuplicate(true);
                return false;
            }
        }
    };

    const updateEvent = () => {
        /**
         * *! first it checks for overlapping then it calls the removeEvent function then it updates accordingly
         */
        if (validate()) {
            if (checkOverlapingEvents(formData, eventObject, eventInfo, true)) {
                deleteEvent();
                setFormData((val) => ({
                    ...val,
                    rruleData: [...val.rruleData, eventObject],
                }));
                return true;
            } else {
                setDuplicate(true);
                return false;
            }
        } else return false;
    };

    // const updateEventMeetingLink = () => {
    //     /**
    //      * *! if the event is recurring it will update the meeting link for all the event
    //      */
    //     const eventID = eventInfo.event.id;
    //     const eventSrc = formData.rruleData.find((item) => item.id === eventID);
    //     const modifiedEvents = formData.rruleData.filter((item) => item.id !== eventID);

    //     setFormData((val) => ({
    //         ...val,
    //         rruleData: [...modifiedEvents, { ...eventSrc, meetingLink: eventObject.meetingLink }],
    //     }));
    // };

    const handleViewChange = (e) => {
        /**
         * *! changes the calender view
         */
        setCalenderView(() => e.target.value);
        const api = calendarRef?.current?.getApi();
        api.changeView(e.target.value);
    };

    React.useEffect(() => {
        /**
         * *! populates the `duration object` in the rrule
         */
        if (eventObject?.rrule?.dtstart != "" && eventObject?.slotEndDate != "") {
            const getDuration = timeDiffCalc(eventObject?.slotEndDate, eventObject?.rrule?.dtstart);

            setEventObject((val) => ({
                ...val,
                title: formData?.eventName,
                duration: {
                    ...val.duration,
                    days: getDuration.days,
                    hours: getDuration.hours,
                    minutes: getDuration.minutes,
                },
            }));
        }
    }, [eventObject?.rrule?.dtstart, eventObject?.slotEndDate]);

    function unique(arr, keyProps) {
        /**
         * *! removes the dublicate object in the dates array
         */
        const kvArray = arr.map((entry) => {
            const key = keyProps.map((k) => entry[k]).join("|");
            return [key, entry];
        });
        const map = new Map(kvArray);
        return Array.from(map.values());
    }

    // function addfrequency(obj) {
    //     if (obj.rrule.freq === "daily") {
    //         return "day";
    //     } else if (obj.rrule.freq === "weekly") {
    //         return "week";
    //     } else if (obj.rrule.freq === "monthly") {
    //         return "month";
    //     } else if (obj.rrule.freq === "yearly") {
    //         return "year";
    //     }
    // }

    // function getUntillDate(obj) {
    //     const date = moment(obj.rrule.dtstart).add(obj.rrule.count, addfrequency(obj));
    //     return date._d;
    // }

    React.useEffect(() => {
        const date = calendarRef.current.getApi();
        /**
         * *! sets the calender title date
         */
        setToday(date.currentDataManager.data.viewTitle);

        const sortedRruleData =
            formData.rruleData &&
            formData.rruleData.length > 0 &&
            formData.rruleData.sort((a, b) => {
                return sortDate(new Date(a.rrule.dtstart, new Date(b.rrule.dtstart)));
            });
        const sortedRruleUntillData =
            formData.rruleData && formData.rruleData.length > 0
                ? formData.rruleData.map((item) => {
                      return item.rrule.until;
                  })
                : [];

        const sortedRruleEndData =
            formData.rruleData && formData.rruleData.length > 0
                ? formData.rruleData.map((item) => {
                      return item.slotEndDate;
                  })
                : [];
        const lastEventDate = [...sortedRruleUntillData, ...sortedRruleEndData]
            .filter((item) => item !== "") // remove empty untill date string
            .sort((a, b) => {
                return sortDate(new Date(a), new Date(b));
            });

        let arr = [];
        let uniqueData = [];
        let getDateArr = [];
        let runLoop = true;
        let currentDate = calendarRef.current.getApi().getDate();

        let loopTillDate = lastEventDate[lastEventDate.length - 1]
            ? lastEventDate[lastEventDate.length - 1]
            : moment().add(2, "year");
        //  sortedRruleUntillData[sortedRruleUntillData.length - 1]?.rrule?.count
        //  getUntillDate(sortedRruleUntillData[sortedRruleUntillData.length - 1])

        /**
         * *! setting the view to the date where it has the first event
         */
        calendarRef.current
            .getApi()
            .gotoDate(sortedRruleData[0]?.rrule?.dtstart ? new Date(sortedRruleData[0].rrule.dtstart) : new Date());

        while (runLoop) {
            if (
                !moment(loopTillDate).isAfter(calendarRef.current.getApi().view.activeEnd) &&
                !moment(loopTillDate).isBetween(
                    calendarRef.current.getApi().view.activeStart,
                    calendarRef.current.getApi().view.activeEnd,
                )
            ) {
                runLoop = false;
            }

            const data = calendarRef.current.getApi().getEvents();
            arr = [...arr, ...data];

            getDateArr = arr.map(({ _instance, id, extendedProps }) => ({
                rruleId: id,
                meetingLink: extendedProps.meetingLink,
                startDate: moment.utc(_instance.range.start).format("YYYY-MM-DD"),
                startTime: moment.utc(_instance.range.start).format("HH:mm"),
                endDate: moment.utc(_instance.range.end).format("YYYY-MM-DD"),
                endTime: moment.utc(_instance.range.end).format("HH:mm"),
            }));

            uniqueData = unique(getDateArr, ["startDate", "startTime", "endDate", "endTime"]);

            setFormData((val) => ({
                ...val,
                dates: uniqueData,
            }));

            calendarRef.current.getApi().next();
        }
        /**
         * *! returns back to the view where the user was present initially
         */
        calendarRef.current.getApi().gotoDate(currentDate);

        /**
         * *! removes the rrule object it does not create any date (event)
         */
        let temp = uniqueData.map((date) => date.rruleId);
        let rruleTemp =
            formData.rruleData &&
            formData.rruleData.length > 0 &&
            formData.rruleData.filter((item) => {
                return temp.includes(item.id);
            });

        if (
            rruleTemp.length != formData?.rruleData?.length &&
            formData?.rruleData?.length > 0 &&
            rruleTemp.length > 0
        ) {
            setFormData((val) => ({
                ...val,
                rruleData: rruleTemp,
            }));
        }
        if (formData.rruleData.length < 1) {
            setFormData((val) => ({
                ...val,
                dates: [],
            }));
        }
    }, [calendarRef, formData.rruleData]);

    React.useEffect(() => {
        if (startValidation) {
            validate();
        }
    }, [eventObject]);

    React.useEffect(() => {
        /**
         * *! updates the calender event name if the main event name is changed
         */ if (formData.rruleData.length) {
            const date = formData.rruleData.map((item) => ({
                ...item,
                title: formData.eventName,
            }));

            setFormData((val) => ({
                ...val,
                rruleData: date,
            }));
        }
    }, [formData.eventName]);

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div>
                    <ButtonGroup variant="contained" size="small">
                        <Button
                            onClick={() => {
                                /**
                                 * *! sets the calender title date
                                 */
                                const api = calendarRef?.current?.getApi();
                                api.prev();
                                setToday(api.currentDataManager.data.viewTitle);
                            }}
                        >
                            <KeyboardArrowLeft color="primary" />
                        </Button>
                        <div className={styles.today}>
                            <p>{today}</p>
                        </div>
                        <Button
                            onClick={() => {
                                /**
                                 * *! sets the calender title date
                                 */
                                const api = calendarRef?.current?.getApi();
                                api.next();
                                setToday(api.currentDataManager.data.viewTitle);
                            }}
                        >
                            <KeyboardArrowRight color="primary" />
                        </Button>
                    </ButtonGroup>
                </div>
                <FormControl size="small" className={styles.headerSelect} color="primary">
                    <Select variant="outlined" value={calenderView} onChange={handleViewChange}>
                        <MenuItem value="dayGridMonth">MONTH</MenuItem>
                        <MenuItem value="timeGridWeek">WEEK</MenuItem>
                        <MenuItem value="timeGridDay">DAY</MenuItem>
                        <MenuItem value="list">LIST</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <EventPopup
                open={eventPopup}
                close={() => {
                    setEventPopup(false);
                    // setEventInfo(null);
                }}
                formData={formData}
                setEventInfo={setEventInfo}
                eventObject={eventObject}
                error={error}
                setError={setError}
                setStartValidation={setStartValidation}
                duplicate={duplicate}
                setDuplicate={setDuplicate}
                update={update}
                setUpdate={setUpdate}
                // updateEventMeetingLink={updateEventMeetingLink}
                setEventObject={setEventObject}
                showButtons={eventInfo !== null ? true : false}
                addEvents={addEvents}
                updateEvent={updateEvent}
                deleteEvent={deleteEvent}
                checkBookings={checkBookings}
            />
            <div className="serviceProviderFullCalender">
                <FullCalendar
                    locale={"en"}
                    ref={calendarRef}
                    height={desktop ? 700 : 400}
                    headerToolbar={false}
                    plugins={[rrulePlugin, listPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    editable={false}
                    selectable={false}
                    nowIndicator={true}
                    selectOverlap={false}
                    eventOverlap={false}
                    dayMaxEventRows={4}
                    dayCellContent={customDay}
                    eventBackgroundColor="rgb(120,134,203)"
                    events={formData.rruleData}
                    // eventContent={customEventDisplay}
                    dateClick={handleDateClick}
                    eventClick={handleEventClick}
                    // eventDrop={({ event, revert }) => {
                    //     if (moment(event.start).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")) {
                    //         revert();
                    //     }
                    // }}
                />
            </div>
        </div>
    );
}

export default connect(null, {
    showAlert,
})(ServiceCalender);
