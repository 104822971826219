import React, { useEffect } from "react";
import styles from "./ViewCoachDetail.module.css";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import ViewSchedule from "../ViewSchedule/ViewSchedule";
import ManageSchedule from "../ManageSchedule/ManageSchedule";
import Bookings from "../Bookings/Bookings";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { getLifeCoach } from "../../../containers/lifeCoaches/actions";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "@material-ui/core";
import { showAlert } from "../../../containers/app/actions";
import axios from "axios";
import { set } from "date-fns";
const ViewCoachDetail = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const [tab, setTab] = React.useState("BOOKINGS");
    const [coach, setCoach] = React.useState();
    const [category, setCategory] = React.useState();
    const [show, setShow] = React.useState(false);
    const [bio, setBio] = React.useState();
    const [error, setError] = React.useState(false);
    const [loadingButton, setLoadingButton] = React.useState(true);
    const [loading, setloading] = React.useState(true);
    const [selectedValue, setSelectedValue] = React.useState('all');
    const [formData, setFormData] = React.useState({
        dates: [],
    });
    React.useEffect(() => {
        props.getLifeCoach(query.get("coachId"));
        setCoach(query.get("coachId"));
    }, []);

    useEffect(() => {
        if (props?.lifeCoach) {
            console.log(
                "----props?.managedCategory--------->>>>>>>>>>",
                props?.lifeCoach?.platform_coaches?.ManagedSubcategory,
            );
        }
    }, [props]);

    const handleChange = (val) => {
        setloading(true);
        setCategory(val.target.value);
        setSelectedValue(val.target.value);
        setShow(val.target.value);
        setFormData({ dates: [] });
        axios({
            method: "get",
            url: "/dashboard/life-coaches-admin/getBio/" + query.get("coachId") + "/" + val.target.value,
        })
            .then((res) => {
                if (res.data && res.data.bio) {
                    setBio(res.data.bio);
                    setloading(false);
                } else {
                    setBio("");
                    setloading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setloading(false);
                props.showAlert("Something went wrong");
            });
    };
    const update = () => {
        setLoadingButton(false);
        var data = {
            JourneyCategory: category,
            bio: bio,
        };
        axios({
            method: "post",
            url: "/dashboard/life-coaches-admin/updateBio/" + query.get("coachId"),
            data,
        })
            .then((res) => {
                setLoadingButton(true);
                props.showAlert("Bio Updated Successfully");
            })
            .catch((err) => {
                setLoadingButton(true);
                props.showAlert("Something went wrong");
            });
    };
    return (
        <div className={styles.container}>
            {!props.lifeCoach && <CircularProgress />}
            {props.lifeCoach && (
                <>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <h2>{"Bookings & Schdule"}</h2>
                            <p>{"Manage Coach Bookings and Schdule"}</p>
                        </div>
                        {/* {!loading && (
                            <div className={styles.formContainer}>
                                <FormControl style={{ width: "100%" }}>
                                    <FormLabel>Bio</FormLabel>

                                    <TextField
                                        size="small"
                                        className={styles.TextField}
                                        variant="outlined"
                                        defaultValue={bio}
                                        onChange={(e) => {
                                            setBio(e.target.value);
                                            if (e.target.value.length > 250) {
                                                setError(true);
                                            } else {
                                                setError(false);
                                            }
                                        }}
                                    />
                                    {error ? (
                                        <p style={{ color: "red" }}>The Bio should be less then 250 characters</p>
                                    ) : (
                                        <></>
                                    )}
                                </FormControl>
                                <Button
                                    className={styles.button}
                                    onClick={() => {
                                        update();
                                    }}
                                    disabled={error}
                                >
                                    {loadingButton ? <>Update</> : <>updating...</>}
                                </Button>
                            </div>
                        )} */}
                        <div>
                            <button
                                className="btnGreen"
                                style={{ marginRight: "30px", marginTop: "20px" }}
                                onClick={() => {
                                    props.history.push(
                                        `/user/life-coaches-admin/view-coach-general?coachId=${query.get("coachId")}`,
                                    );
                                }}
                            >
                                General Settings
                            </button>
                            <FormControl className={styles.textFieldCategory}>
                                <FormLabel>Select subcategory</FormLabel>
                                <Select
                                    className={styles.TextField}
                                    variant="outlined"
                                    onChange={handleChange}
                                    value={selectedValue} // Ensure you have a state variable to track this value
                                    input={<Input />}
                                >
                                    {/* Default ALL option */}
                                    <MenuItem key="all" value="all">
                                        ALL
                                    </MenuItem>

                                    {/* Other options from props */}
                                    {props?.lifeCoach &&
                                        props?.lifeCoach?.platform_coaches?.ManagedSubcategory?.map((category) => (
                                            <MenuItem key={category._id} value={category._id}>
                                                {category?.subCategoryName || category?.serviceSubCatName}
                                            </MenuItem>
                                        ))}
                                </Select>

                            </FormControl>
                        </div>
                    </div>
                    {show && (
                        <div className={styles.content}>
                            <div className={styles.tabs}>
                                <Tabs value={tab} onChange={(e, val) => setTab(val)} indicatorColor="secondary">
                                    <Tab label="Bookings" value="BOOKINGS" />
                                    <Tab label="Manage-Schedule" value="MANAGE-SCHEDULE" />
                                    <Tab label="View-Schedule" value="VIEW-SCHEDULE" />
                                </Tabs>
                            </div>

                            {tab == "BOOKINGS" && <Bookings category={category} coach={coach} />}
                            {tab == "MANAGE-SCHEDULE" && (
                                <ManageSchedule
                                    category={category}
                                    coach={coach}
                                    formData={formData}
                                    setFormData={(val) => setFormData(val)}
                                />
                            )}
                            {tab == "VIEW-SCHEDULE" && <ViewSchedule category={category} coach={coach} />}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    lifeCoach: state.lifeCoachesAdmin.lifecoach,
});
export default withRouter(connect(mapStateToProps, { getLifeCoach, showAlert })(ViewCoachDetail));
