import React from 'react'
import styles from './CartComp.module.css'

import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {getCartItems} from '../../../containers/endUser/actions' 

import Biller from './Biller/Biller' 
import CheckoutItems from './CheckoutItem/CheckoutItems'
import AppLoader from '../../utils/AppLoader/AppLoader'

import {useLocation} from 'react-router-dom'

const CartComp = (props) => { 
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [step,setStep] = React.useState("LOADING"); 

    React.useEffect(() => { 
        props.getCartItems(query.get("userId"));
    },[]) 
 
    
    const isLoading = step == "LOADING";
    const showData = !isLoading;
    return (
        <div className={styles.container}>  
            <React.Fragment>
                <div className={styles.leftContent}> 
                    <CheckoutItems />
                </div>
                <div className={styles.rightContent}>
                    <Biller step={step} setStep={val => setStep(val)} />
                </div>
            </React.Fragment> 
        </div>
    )
}

const mapStateToProps = state => ({ 
    cartItems: state.endUser.cartItems, 
})
export default withRouter(connect(mapStateToProps,{getCartItems})(CartComp));