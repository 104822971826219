import React from 'react'
import styles from './ManageSchedule.module.css'
import axios from 'axios';

import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded';
import SkipNextRoundedIcon from '@material-ui/icons/SkipNextRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'


import { KeyboardDatePicker, TimePicker } from "@material-ui/pickers"
import { showAlert } from '../../../containers/app/actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import moment from 'moment'
import { extendMoment } from 'moment-range';

import defaultMaterialTheme from './dataTheme'
import { ThemeProvider } from "@material-ui/styles";


const momentRange = extendMoment(moment);

const ManageSchedule = (props) => {
    const _id = Math.random();
    const [dates, setDates] = React.useState([{
        _id: _id,
        startDate: null,
        startTime: null,

        endDate: null,
        endTime: null,

        populateType: "",
    }]);

    const [formData, setFormData] = React.useState({
        dates: []
    });

    const [error, setError] = React.useState([{
        _id: _id,
        startTime: false,
        endTime: false,
        startDate: false,
        endDate: false,
        populateType: ""
    }]);

    const [errObj, setObjErr] = React.useState([{
        startTime: false,
        endTime: false,
        startDate: false,
        endDate: false, 
    }]);

    const [loading, setLoading] = React.useState(false);
    const [activePage, setActivePage] = React.useState(1) 
    const [state, setState] = React.useState(true);

    const validate = () => {
        let validData = true;

        let newError = error.map((err, index) => {
            const _id = dates[index]._id;
            err = {
                _id: _id,
                startTime: false,
                endTime: false,
                startDate: false,
                endDate: false,
                populateType: false
            };
            Object.keys(err).forEach(key => {
                if (dates[index][key] == "" || dates[index][key] == null) {
                    err[key] = `Field cannot be empty`
                    validData = false;
                }
            })
            return { ...err };
        })

        setError([...newError]);
        return validData;
    }

    const onSubmit = () => {
        if (validate()) {
            setLoading(true);
            const url = "/dashboard/life-coach-admin/addSchedule"
            const method = "post";

            const dateArr = formData.dates.map(singleDate => {
                const newDate = {
                    ...singleDate
                };
                delete newDate._id;
                return newDate;
            });

            axios({
                method,
                url,
                data: {
                    dates: dateArr
                }
            }).then(res => {
                setLoading(false);
                props.showAlert("Schedule Added Succesfully"); 
            }).catch(err => {
                console.log(err);
                setLoading(false);
                if (err && err.response && err.response.data && err.response.data.error) {
                    props.showAlert(err.response.data.error)
                } else {
                    props.showAlert("Something went wrong ! Try Again")
                }
            })
        }
    }
 

    const populateDates = (dayIndex, _id) => {
        const { startDate, endDate, endTime, startTime, populateType } = dates[dayIndex];

        if (startDate && startTime && endDate && endTime && populateType && validateTime(startDate, endDate, startTime, endTime)) {
            const range = momentRange.range(startDate, moment(startDate).add(3, 'M'));
            const diffDays = moment(endDate).diff(moment(startDate), 'days');

            let arr = Array.from(range.by('day')).map(x => {
                return {
                    _id: _id,
                    startDate: x.format('YYYY-MM-DD'),
                    endDate: moment(x).add(diffDays, 'days').format("YYYY-MM-DD"),
                    startTime: moment(startTime).format("HH:mm"),
                    endTime: moment(endTime).format("HH:mm"),
                    dayIndex: dayIndex,
                }
            });
            let oldArr = formData.dates.filter(val => val._id != _id);

            if (populateType == "DOES_NOT_REPEAT") {
                setFormData({ ...formData, dates: [...oldArr, { _id, startDate, startTime: moment(startTime).format("HH:mm"), endDate, endTime: moment(endTime).format("HH:mm"), dayIndex }] });
            } else if (populateType == "DAILY") {
                setFormData({ ...formData, dates: [...oldArr, ...arr] });
            } else if (populateType == "WEEKLY_ON_MONDAY") {
                let weekData = arr.filter(val => new Date(val.startDate).getDay() == new Date(startDate).getDay());
                setFormData({ ...formData, dates: [...oldArr, ...weekData] })
            } else if (populateType == "MONTHLY_ON_SPECIFIC_DAY") {
                let weekNumber = moment(startDate).week() - moment(moment(startDate)).startOf('month').week() + 1;
                let monData = arr.filter(val => {
                    return moment(val.startDate).week() - moment(moment(val.startDate)).startOf('month').week() + 1 == weekNumber && new Date(startDate).getDay() == new Date(val.startDate).getDay()
                });

                setFormData({ ...formData, dates: [...oldArr, ...monData] })
            } else if (populateType == "ANNUALLY_ON_FIRST") {
                let anData = arr.filter(val => {
                    let month = new Date(startDate).getMonth()
                    return new Date(val.startDate).getDate() == new Date(startDate).getDate() && new Date(val.startDate).getMonth() == month;
                })
                setFormData({ ...formData, dates: [...oldArr, ...anData] })
            } else if (populateType == "EVERYDAY_MONDAY_FRIDAY") {
                let mfData = arr.filter(val => new Date(val.startDate).getDay() != 6 && new Date(val.startDate).getDay() != 0)
                setFormData({ ...formData, dates: [...oldArr, ...mfData] })
            }
        }
    };

    const onAddDateMaker = () => {
        let arr = dates;
        let _id = Math.random();
        arr.push({ _id, startDate: null, startTime: null, endDate: null, endTime: null, populateType: "" })
        setDates([...arr]);
        let err = error;
        err.push({
            _id,
            startTime: false,
            endTime: false,
            startDate: false,
            endDate: false,
            populateType: ""
        })
        setError(err);
    } 

    const onDeletePopulator = (_id) => {
        let arr = formData.dates.filter(val => val._id != _id);
        let arrDates = dates.filter(val => val._id != _id);
        setFormData({ ...formData, dates: [...arr] })
        setDates([...arrDates])

        let newError = error.filter(err => err._id != _id);
        setError([...newError]);
    }

    const validateTime = (startDate,endDate,startTime,endTime) => {
        
        setObjErr({
            startTime: false,
            endTime: false,
            startDate: false,
            endDate: false
        })
        const minTime = moment().format("HH:mm");
        const minDate = moment().format("YYYY-MM-DD")
        if(startDate && startTime) {
            if (startDate == minDate) { 
                if (moment(startTime).format("HH:mm") < minTime) {
                    setObjErr({
                        ...error,
                        startTime: "Error: Start Time should be greator then " + minTime
                    });
                    return false;
                }
            }
        }
        if (startDate && endDate) {
            if (startDate > endDate) { 
                setObjErr({
                    ...error,
                    startDate: "Error: Start Date cannot be greator then endDate"
                });
                return false;
            }
        }
        if(startDate && endDate && startTime && endTime) {
            if (startDate == endDate) {
                if (startTime >= endTime) { 
                    console.log(startTime)
                    console.log(endTime)
                    setObjErr({
                        ...error,
                        endTime: "Error: Start Time cannot be greator then End Time"
                    }); 
                    return false;
                }
            } 
        }
        return true;
    } 
    let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    let helperText = { 1: "st", 2: "nd", 3: "rd", 4: "th", 5: "th" }
    var month = ["January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"];
 
    return (
        <div className={styles.container}>
            <div className={styles.cards}>
            <div className={styles.card}>
                {dates.map((val, dayIndex) => {
                    return (
                        <div className={styles.cardComp} key={dayIndex}>
                            {dayIndex != 0 &&
                                <div className={styles.alignEnd}>
                                    <CloseRoundedIcon onClick={() => onDeletePopulator(val._id)} />
                                </div>
                            }

                            <div className={styles.joinedContent}>
                                <p>Start</p>
                                <KeyboardDatePicker
                                    disablePast
                                    onChange={(date) => {
                                        let arr = dates;
                                        arr[dayIndex] = { ...arr[dayIndex], startDate: moment(date).format("yyyy-MM-DD") }
                                        setDates([...arr]);
                                        validateTime(moment(date).format("yyyy-MM-DD"), val.endDate, val.startTime, val.endTime)
                                    }}
                                    error={errObj.startDate || error[dayIndex].startDate}
                                    helperText={errObj.startDate || error[dayIndex].startDate}
                                    autoOk={true}
                                    defaultValue=""
                                    variant="inline"
                                    className={styles.datePicker}
                                    value={val.startDate}
                                    format="dd/MM/yyyy"
                                />

                                <TimePicker
                                    variant="inline"
                                    error={errObj.startTime || error[dayIndex].startTime}
                                    helperText={errObj.startTime || error[dayIndex].startTime}
                                    defaultValue={null}
                                    ampm={false}
                                    autoOk={true}
                                    value={val.startTime}
                                    onChange={(date) => {
                                        if (validateTime(val.startDate, val.endDate, date, val.endTime)) {
                                            let arr = dates;
                                            arr[dayIndex] = { ...arr[dayIndex], startTime: date }
                                            setDates([...arr]);
                                            populateDates(dayIndex, val._id)
                                        }
                                    }}
                                    className={styles.timePicker}
                                />
                            </div>

                            <div className={styles.joinedContent}>
                                <p>End</p>
                                <KeyboardDatePicker
                                    disablePast
                                    autoOk={true}
                                    minDate={val.startDate}
                                    onChange={(date) => {
                                        let arr = dates;
                                        arr[dayIndex] = { ...arr[dayIndex], endDate: moment(date).format("yyyy-MM-DD") }
                                        setDates([...arr]);
                                        validateTime(val.startDate, moment(date).format("yyyy-MM-DD"), val.startTime, val.endTime)
                                    }}
                                    error={errObj.endDate || error[dayIndex].endDate}
                                    helperText={errObj.endDate || error[dayIndex].endDate}
                                    variant="inline"
                                    defaultValue=""
                                    value={val.endDate}
                                    format="dd/MM/yyyy"
                                    className={styles.datePicker}
                                />

                                <TimePicker
                                    variant="inline"
                                    error={errObj.endTime || error[dayIndex].endTime }
                                    helperText={errObj.endTime || error[dayIndex].endTime }
                                    defaultValue=""
                                    ampm={false}
                                    autoOk={true}
                                    value={val.endTime}
                                    onChange={(date) => {
                                        if(validateTime(val.startDate,val.endDate,val.startTime,date)) {
                                            let arr = dates;
                                            arr[dayIndex] = { ...arr[dayIndex], endTime: date }
                                            setDates([...arr]);
                                            populateDates(dayIndex, val._id)
                                        }
                                    }}
                                    className={styles.timePicker}
                                />
                            </div>

                            <React.Fragment>
                                <div className={styles.formGroup}>
                                    <p>Repeats</p>
                                    <Select
                                        className={styles.date}
                                        value={val.populateType}
                                        onChange={e => {
                                            let arr = dates;
                                            arr[dayIndex] = { ...arr[dayIndex], populateType: e.target.value }
                                            setDates([...arr]);
                                            populateDates(dayIndex, val._id)
                                        }}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null,
                                            className: styles.menuItems
                                        }}
                                        error={error[dayIndex].populateType}
                                        helperText={error[dayIndex].populateType}
                                    >
                                        <MenuItem value="DOES_NOT_REPEAT">Doesn't Repeat</MenuItem>
                                        <MenuItem value="DAILY">Daily</MenuItem>
                                        <MenuItem value="WEEKLY_ON_MONDAY">Weekly on {days[new Date(val.startDate).getDay()]}</MenuItem>
                                        <MenuItem value="MONTHLY_ON_SPECIFIC_DAY">
                                            Monthly on the &nbsp;
                                                        {Math.ceil(new Date(val.startDate).getDate() / 7 + 1)}
                                            {helperText[Math.ceil(new Date(val.startDate).getDate() / 7 + 1)]} &nbsp;
                                                        {days[new Date(val.startDate).getDay()]}</MenuItem>

                                        <MenuItem value="ANNUALLY_ON_FIRST">Annually on {month[new Date(val.startDate).getMonth()]} {new Date(val.startDate).getDate()}</MenuItem>
                                        <MenuItem value="EVERYDAY_MONDAY_FRIDAY">Every Day(Monday to Friday)</MenuItem>
                                    </Select>
                                </div>
                            </React.Fragment>

                        </div>
                    )
                })}

                <div className="btnCenter">
                    <button className="btnGreen" onClick={onAddDateMaker}>Add More</button>
                </div>

            </div>

            <div className={styles.card}>
                {formData.dates.length > 0 &&
                    <React.Fragment>
                        <h3>Booking Schedule</h3>

                        {formData.dates.map((date, index) => {
                            if (index >= (activePage - 1) * 8 && index < activePage * 8) {
                                return (
                                    <div className={styles.dateItem}>
                                        <span>{new Date(date.startDate).toString().substr(0, 15)} &nbsp; {date.startTime}-{date.endTime}</span>
                                        <HighlightOffRoundedIcon
                                            onClick={() => {
                                                let arr = formData.dates;
                                                if (arr.length > 1) {
                                                    arr = arr.filter((val, i) => i != index)
                                                    setFormData({ ...formData, dates: [...arr] })
                                                }
                                            }}
                                        />
                                    </div>
                                )
                            }
                        })}

                        {formData.dates.length > 8 &&
                            <React.Fragment>
                                <div className={styles.pagination}>
                                    <SkipPreviousRoundedIcon
                                        onClick={() => {
                                            activePage > 1 && setActivePage(activePage - 1);
                                        }}
                                    />

                                    {Array(Math.ceil(formData.dates.length / 8)).fill("").map((val, index) => {
                                        let currentValue = index + 1;
                                        return (
                                            <span
                                                onClick={() => setActivePage(currentValue)}
                                                className={activePage == (currentValue) && styles.activeSpan}>{currentValue}</span>
                                        )
                                    })}
                                    <SkipNextRoundedIcon
                                        onClick={() => {
                                            activePage < Math.ceil(formData.dates.length / 8) && setActivePage(activePage + 1);
                                        }}
                                    />
                                </div>
                            </React.Fragment>}
                    </React.Fragment>}
            </div>
        </div>
            <div className={styles.addScheduleButton}>
                {loading
                    ?
                    <Button startIcon={<CircularProgress color="inherit" size={20} />}>Loading ...</Button>
                        :
                    <Button  onClick={onSubmit}>Add Schedule</Button>}
            </div>
        </div>
    )
}

export default withRouter(connect(null, { showAlert })(ManageSchedule))