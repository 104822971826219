import React from "react";
import styles from "./Action.module.css";

import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";

import SettingsApplicationsRoundedIcon from "@material-ui/icons/SettingsApplicationsRounded";

import { connect } from "react-redux";
import { showAlert } from "../../../../containers/app/actions";
import { withRouter } from "react-router-dom";
import { disableAccount } from "../../../../containers/endUser/actions";
import Modal from "../Modal/Modal";
import axios from "axios";

const Action = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openPage, setOpenPage] = React.useState({
        open: false,
        tab: "",
    });
    let token = localStorage.getItem("token");
    const sendPasswordResetLink = (email, firstName, lang) => {
        axios({
            method: "get",
            url: `/dashboard/user/sendResetPasswordLink?email=${email}&firstName=${firstName}&language=${lang}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                props.showAlert("Password reset email is sent to user");
            })
            .catch((err) => {
                props.showAlert("Something Went Wrong");
            });
    };

    const verifyUser = (email) => {
        axios({
            method: "get",
            url: `/dashboard/user/updateVerification?email=${email}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                props.showAlert("User is successfully verified");
            })
            .catch((err) => {
                props.showAlert("Something Went Wrong");
            });
    };

    const open = Boolean(anchorEl);
    return (
        <React.Fragment key={props.userId}>
            <Modal
                open={openPage.open}
                onClose={() => setOpenPage({ ...openPage, open: false })}
                tab={openPage.tab}
                user={props.userId}
            />
            <IconButton
                onClick={(e) => {
                    open ? setAnchorEl(null) : setAnchorEl(e.currentTarget);
                }}
            >
                <SettingsApplicationsRoundedIcon />
                <Popover
                    id="mouse-over-popover"
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    onClose={() => setAnchorEl(null)}
                    disableRestoreFocus
                >
                    <div className={styles.popContent}>
                        <p onClick={() => props.history.push(`/user/end-users/SETTINGS?userId=${props.userId._id}`)}>
                            Profile Settings
                        </p>
                        <p onClick={() => setOpenPage({ ...openPage, open: true, tab: false })}>
                            Deactivate Subscription
                        </p>
                        <p onClick={() => setOpenPage({ ...openPage, open: true, tab: "Grant Entitlment" })}>
                            Grant Entitlement
                        </p>
                        <p
                            onClick={() =>
                                sendPasswordResetLink(
                                    props.userId?.email,
                                    props.userId?.userProfile?.firstName ?? props.userId?.username,
                                    props.userId?.userProfile?.language,
                                )
                            }
                        >
                            Reset password link
                        </p>

                        <p onClick={() => setOpenPage({ ...openPage, open: true, tab: "Change password" })}>
                            Change password
                        </p>
                        <p onClick={() => verifyUser(props.userId?.email)}>Verify user</p>
                        <p onClick={() => props.disableAccount(!props.userId.blocked, props.userId._id)}>
                            {props.userId.blocked ? "Enable Account" : "Disable Account"}
                        </p>
                    </div>
                </Popover>
            </IconButton>
        </React.Fragment>
    );
};

export default withRouter(connect(null, { showAlert, disableAccount })(Action));
