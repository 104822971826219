import React from "react"; 
import { Pricing } from "./Pricing/Pricing"; 
import Gallery from "./Gallery/Gallery"; 
import styles from "./ServiceSpecs.module.css";
import { ManageOrder } from "./ManageOrder/ManageOrder";
import { ShareOutlined } from "@material-ui/icons";
import { Button } from '@material-ui/core';
import {  Router } from 'react-router-dom';


export function ServiceSpecs(props) { 

    const [dates, setDates] = React.useState({});
    const [date, setDate] = React.useState("");
    const [quantity, setQuantity] = React.useState(1);
    const [open, setOpen] = React.useState(false);
    // const router = Router();

    const [selectedBookingtype, setBookingType] = React.useState(false);

    React.useEffect(() => {
        if (props.service && props.service.scheduleType != "REGULAR" && !props.preview) {
            let obj = {};
            props.service.dates &&
                props.service.dates.forEach((val) => {
                    if (obj[val.startDate]) {
                        obj[val.startDate].push(val);
                    } else {
                        obj[val.startDate] = [val];
                    }
                });
            setDates({ ...obj });
        }
    }, [props.service]);

    const service = props.service;
    const serviceProvider = props.serviceProvider;

    return (
        <> 
            <div className={styles.flexHeaderContainer}>
                <div className={styles.galleryContainer}>
                    <Gallery service={service} />
                </div>
                <div className={styles.info}>
                    <div className={styles.flexContainer}>
                        <h1 style={{ fontSize: "24px" }}>
                            {service.eventName}{" "}
                            {service.locationType === "ONLINE" ? (
                                <div style={{ fontSize: "10px" }} className={styles.onlineLabel}>
                                    ONLINE
                                </div>
                            ) : null}
                        </h1>
                        <div className={styles.tags}>
                            {service.tags.map((val) => {
                                return <span className={styles.tag}>{val}</span>;
                            })}
                        </div>
                    </div>
                    <div className={styles.flexContainer}>
                        <div></div>
                        <div className={styles.flexContainer} style={{ marginBottom: 0 }}>
                            <div>
                                <Button
                                    variant="tetx"
                                    size="small"
                                    startIcon={<ShareOutlined />}
                                    style={{ color: "#e6e1d2" }}
                                    onClick={() => setOpen(true)}
                                    className={styles.shareButton}
                                >
                                    SHARE
                                </Button>
                            </div>
                        </div>
                         
                    </div>
                    <div className={`${styles.flexContainer} ${styles.borderBottom}`}>
                        <pre className={styles.infoText}>{service.description}</pre>
                    </div>
                    <div className={`${styles.flexContainer} ${styles.borderBottom}`}>
                        <h3 className={styles.infoText} style={{ fontSize: "18px" }}>
                            {serviceProvider.storeName}
                        </h3>
                        <span className={styles.tag}>{"21Done Verifiziert"}</span>
                    </div>
                    <React.Fragment>
                        <Pricing quantity={quantity} service={service} preview={props.preview} />
                        <ManageOrder
                            preview={props.preview}
                            service={service}
                            onBooking={setBookingType}
                            quantity={quantity}
                            setQuantity={setQuantity}
                        />
                    </React.Fragment>
                </div>
            </div>
        </>
    );
}
