import * as actionTypes from './actionTypes'
import axios from 'axios'
 
export const getEmailList = () => dispatch => {
    dispatch({
        type: actionTypes.SET_EMAIL_LIST,
        payload: false
    })

    axios({
        method: "get",
        url: "/dashboard/mail/getEmailList"
    }).then(res => {
        dispatch({
            type: actionTypes.SET_EMAIL_LIST,
            payload: res.data.emailList
        })
    }).catch(err => {
        dispatch({
            type: actionTypes.SET_EMAIL_LIST,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}

export const getChatList = (emailId) => dispatch => {
    dispatch({
        type: actionTypes.SET_CHAT_LIST,
        payload: []
    })


    axios({
        method: "get",
        url: "/dashboard/mail/getChatList?email=" + emailId, 
    }).then(res => {
        dispatch({
            type: actionTypes.SET_CHAT_LIST,
            payload: res.data.chatList
        })
    }).catch(err => {
        dispatch({
            type: actionTypes.SET_CHAT_LIST,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}