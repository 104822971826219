import React from "react";
import styles from "./ViewSingleEvent.module.css";

import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import AddRoundedIcon from "@material-ui/icons/AddRounded";

import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import { getAllSingleEvents } from "../../../../containers/serviceProvider/actions";
import { makeSubString } from "../../../../hooks/makeSubString";
import Action from "./Action/Action";
import Header from "../../Header/Header";

const ViewSingleEvent = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [events, setEvents] = React.useState(false);

    React.useEffect(() => {
        props.getAllSingleEvents(query.get("userId"));
    }, []);

    React.useEffect(() => {
        setEvents(props.singleEvents);
    }, [props.singleEvents]);

    const isLoading = !events;
    const showData = !isLoading;
    return (
        <div className={styles.container}>
            <Header type="SERVICE" />
            <div className={styles.content}>
                <div className={styles.header}>
                    <div className={styles.leftHeader}>
                        <h1>Your Services</h1>
                        <p>All Services Offered</p>
                    </div>
                    <div className={styles.rightHeader}>
                        <Button
                            color="primary"
                            varinat="contained"
                            startIcon={<AddRoundedIcon />}
                            onClick={() =>
                                props.history.push(
                                    `/user/service-provider/ADD-STORE-SERVICE?eventId=${Math.random()}&userId=${query.get(
                                        "userId",
                                    )}`,
                                )
                            }
                        >
                            Add A Service
                        </Button>
                    </div>
                </div>

                {isLoading && (
                    <div className={styles.loader}>
                        <CircularProgress />
                    </div>
                )}

                {showData && (
                    <div className={styles.showData}>
                        {events.length > 0 ? (
                            <React.Fragment>
                                {events.map((event, index) => {
                                    return (
                                        <Paper variant="outlined" className={styles.paper} key={index}>
                                            <div>
                                                <Checkbox color="secondary" />
                                            </div>
                                            <div className={styles.eventConf}>
                                                <h1>{event.eventName}</h1>
                                              
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                event.description &&
                                                                makeSubString(event.description, 60),
                                                        }}
                                                    ></div>
                                              
                                            </div>
                                            <div className={styles.eventInfo}>
                                                <p>
                                                    <span>Price Per Person</span>
                                                    <span>€{event.pricePerPerson}</span>
                                                </p>
                                                <p>
                                                   
                                                    <span>Status</span>
                                                    <span>{event.active ? "Active" : "Disabled"}</span>
                                                </p>
                                            </div>
                                            <div className={styles.actions}>
                                                <Action eventId={event} userId={query.get("userId")} />
                                            </div>
                                        </Paper>
                                    );
                                })}
                            </React.Fragment>
                        ) : (
                            <div className={styles.notFound}>
                                <p>No Services Found</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    singleEvents: state.serviceProvider.singleEvents,
});
export default withRouter(connect(mapStateToProps, { getAllSingleEvents })(ViewSingleEvent));
