import React from "react";
import styles from "./CancelComp.module.css";

import Modal from "@material-ui/core/Modal";

import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import { connect } from "react-redux";
import { getAllBookings } from "../../../../containers/niv/actions";
import { showAlert } from "../../../../containers/app/actions";
import axios from "axios";

import BtnLoader from "../../../utils/BtnLoader/BtnLoader";

const CancelComp = (props) => {
    const [loading, setLoading] = React.useState(false);

    const onSubmit = () => {
        if (props.booking) {
            setLoading(true);
            axios({
                method: "put",
                url: "/dashboard/life-coaches-admin/cancelBooking",
                data: {
                    bookings: props.booking,
                },
            })
                .then((res) => {
                    setLoading(false);
                    props.showAlert("Booking Cancelled Successfully");
                    props.getBookings();
                    props.onClose();
                })
                .catch((err) => {
                    if (err && err.response) props.showAlert(err.response.data.error);
                    else props.showAlert("Something went wrong Try Again");

                    setLoading(false);
                });
        }
    };

    return (
        <Modal className={styles.modal} open={props.open} onClose={props.onClose}>
            <React.Fragment>
                <div className={styles.container}>
                    <CloseRoundedIcon className={styles.close} onClick={props.onClose} />
                    <h1>"Are you sure you want to proceed for cancellation?"</h1>

                    <div className="btnCenter">
                        {loading ? (
                            <BtnLoader className="btnGreen" />
                        ) : (
                            <button className="btnGreen" onClick={onSubmit}>
                                CANCEL
                            </button>
                        )}
                    </div>
                </div>
            </React.Fragment>
        </Modal>
    );
};

export default connect(null, { getAllBookings, showAlert })(CancelComp);
