import * as actionTypes from './actionTypes'

const initialState = { 
  emailList: false,  
  chatList: false
} 
const reducer =  (state = initialState, action={}) => {
    switch (action.type) { 
        case actionTypes.SET_EMAIL_LIST:
            return {...state,emailList: action.payload}
        case actionTypes.SET_CHAT_LIST:
            return {...state,chatList: action.payload} 
        default:
            return state
    }
}

export default reducer;