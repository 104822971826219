import * as actionTypes from './actionTypes'
import axios from 'axios'

export const getLifeCoach = () => dispatch => {
        dispatch({
        type: actionTypes.GET_DATA,
        payload: false
    })
    axios({
        method: "get",
        url: "/dashboard/life-coaches/getData"
    }).then(res => {
        let fetchedDates = res.data;
        if(!fetchedDates) fetchedDates=[];
        dispatch({
            type: actionTypes.GET_DATA,
            payload: fetchedDates
        })
    }).catch(err => {
        dispatch({
            type: actionTypes.GET_DATA,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}
export const getLifeCoachNavbar = () => dispatch => {
        dispatch({
        type: actionTypes.GET_DATA_NAVBAR,
        payload: false
    })
    axios({
        method: "get",
        url: "/dashboard/life-coaches/getDataNavbar"
    }).then(res => {

        let fetchedDates = res.data;
        if(!fetchedDates) fetchedDates=[];
        dispatch({
            type: actionTypes.GET_DATA_NAVBAR,
            payload: fetchedDates
        })
    }).catch(err => {
        dispatch({
            type: actionTypes.GET_DATA_NAVBAR,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}

export const updateLifeCoach = (data) => dispatch => {
    dispatch({
        type: actionTypes.UPDATE_DATA,
        payload: false
    })
    axios({
        method: "post",
        url: "/dashboard/life-coaches/update",
        data: {
            data
        }
    }).then(res => {
         getLifeCoachNavbar()
        let fetchedDates = res.data;
        if(!fetchedDates) fetchedDates=[];
        dispatch({
            type: actionTypes.UPDATE_DATA,
            payload: fetchedDates
        })
        dispatch({
            type: "SHOW_ALERT",
            payload: "Data Updated Succesfully"
        })
    }).catch(err => {
        dispatch({
            type: actionTypes.UPDATE_DATA,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}