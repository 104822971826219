import React from 'react'
import styles from './ViewUser.module.css';
 
import Button from '@material-ui/core/Button' 
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'

import EditRoundedIcon from '@material-ui/icons/EditRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
 
import AppLoader from '../../utils/AppLoader/AppLoader';
import DataTable from 'react-data-table-component';

import {connect} from 'react-redux'
import {getAllUsers,onUserDelete} from '../../../containers/users/actions'
import {withRouter} from 'react-router-dom'
import ConfirmAlert from '../../utils/ConfirmAlert/ConfirmAlert'



const ViewUser = (props) => { 
    const [users,setUsers] = React.useState(props.users); 
    const [searchVal,setSearchVal] = React.useState("");

    React.useEffect(() => {
        if(!props.users) {
            props.getAllUsers();
        }
        setUsers(props.users);
    },[props.users]);

    let isLoading = !users;
    let showData = !isLoading;
    let rowData = [];

    !isLoading && users.forEach((user,index) => { 
        if(user.name.toLowerCase().includes(searchVal.toLowerCase()) || user.email.toLowerCase().includes(searchVal.toLowerCase())
            || user.userType.toLowerCase().includes(searchVal.toLowerCase())) {

            rowData.push({
                sl_no: index + 1,
                name: user.name,
                email: user.email,  
                userType: user.userType, 
                date: new Date(user.createdAt).toString().substring(0,15),
                actions: 
                <div className="actions">
                    <Tooltip title="Edit User">
                        <IconButton onClick={() => props.history.push("/admin/users/EDIT-USER?userId="+ user._id )}>
                            <EditRoundedIcon />
                        </IconButton>
                    </Tooltip>

                    <ConfirmAlert msg={`Are you sure you want delete ${user.name}`} onClickEvent={() => props.onUserDelete(user._id)}>
                        <Tooltip title="Delete User">
                            <IconButton>
                                <DeleteRoundedIcon />
                            </IconButton>
                        </Tooltip>
                    </ConfirmAlert> 
                </div>
            })
        } 
    }); 
 
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.leftHeader}>
                     &nbsp;
                </div>

                <div className={styles.rightHeader}>
                    <TextField
                        label="Search Here"
                        className={styles.search}
                        value={searchVal}
                        onChange={e => setSearchVal(e.target.value)}
                    />
                    <Button color="primary" variant="contained" endIcon={<AddRoundedIcon />} onClick={() => props.history.push("/admin/users/ADD-USER")}>Add User</Button>
                </div>
            </div>

            {isLoading && <AppLoader />}

            {showData &&
            <DataTable 
                noHeader={true} 
                fixedHeader={true}
                pagination={true}
                fixedHeaderScrollHeight={"calc(100vh - 320px)"}
                columns={[
                    {name: "Sl No",selector: "sl_no",sortable: true},
                    {name: "Name",selector: "name",sortable: true},
                    {name: "Email",selector: "email",sortable: true},
                    {name: "User Type",selector: "userType", sortable: true},
                    {name: "Date of Sign Up",selector: "date",sortable: true},
                    {name: "Action",selector: "actions"}]}
                data={rowData}
            />}

        </div>
    )
}
const mapStateToProps = state => ({
    users: state.users.users
})
export default withRouter(connect(mapStateToProps,{getAllUsers,onUserDelete})(ViewUser));