import React from 'react'
import styles from './Requirements.module.css'

import Radio from '@material-ui/core/Radio'
import TextField from '@material-ui/core/TextField'


const Requirements = (props) => { 
    const [err,setErr] = React.useState({
        minimumRequirementValue: false, 
    });

    const formData = props.formData;
    const error = props.error;

    const handleChange = (e) => {
        setErr({...err,[e.target.name]: false})
        if(/^\d+$/.test(e.target.value) || e.target.value == "") {
            props.setFormData({...formData,[e.target.name]: e.target.value});
        } else {
            setErr({...err,[e.target.name]: "You can only type in numbers"})
        }
    }

    return (
        <div className={styles.compContent}>
            <h3>Minimum Requirements: </h3>

            <div className={styles.formGroup}>
                <div className={styles.selectors}>
                    <div className={styles.radio}>
                        <p>No Requirements</p>
                        <Radio
                            checked={formData.minimumRequirement == "NO-REQUIREMENT"}
                            onChange={e => props.setFormData({...formData,minimumRequirement: "NO-REQUIREMENT"})}
                        />
                    </div>

                    <div className={styles.radio}>
                        <p>Minimum Purchase Amount</p>
                        <Radio
                            checked={formData.minimumRequirement == "MINIMUM-PURCHASE-AMOUNT"}
                            onChange={e => props.setFormData({...formData,minimumRequirement: "MINIMUM-PURCHASE-AMOUNT"})}
                        />
                    </div>

                    {/* <div className={styles.radio}>
                        <p>Minimum Number</p>
                        <Radio
                            checked={formData.minimumRequirement == "MINIMUM-NUMBER"}
                            onChange={e => props.setFormData({...formData,minimumRequirement: "MINIMUM-NUMBER",})}
                        />
                    </div> */}
                </div> 

                 

                {formData.minimumRequirement != "NO-REQUIREMENT" &&
                    <TextField
                        label="Minimum requirement Value"
                        variant="outlined" 
                        value={formData.minimumRequirementValue}
                        onChange={handleChange}
                        error={error.minimumRequirementValue || err.minimumRequirementValue}
                        name="minimumRequirementValue"
                        helperText={error.minimumRequirementValue || err.minimumRequirementValue}
                        className={styles.textField}
                    />}  
            </div>
        </div>
    )
} 
export default Requirements;