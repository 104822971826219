import React from "react";

import { connect } from "react-redux";
import { getAllReferrals } from "../../../../containers/endUser/actions";
import AppLoader from "../../../utils/AppLoader/AppLoader";
import DataTable from "react-data-table-component";

import { useLocation } from "react-router-dom";

const ReferralData = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [referrals, setReferrals] = React.useState(false);

    React.useEffect(() => {
        props.getAllReferrals();
    }, []);

    React.useEffect(() => {
        setReferrals(props.referrals);
    }, [props.referrals]);
    const isLoading = !referrals;
    const showData = !isLoading;
    let rowData = [];

    !isLoading &&
        referrals.forEach((ref, index) => {
            rowData.push({
                sl_no: index + 1,
                name: ref.name,
                startDate: ref.startDate,
                endDate: ref.endDate,
                Referrer: `${ref.referrerAmount}${ref.valueType === "PERCENTAGE" ? "%" : "€"}`,
                Referree: `${ref.referreeAmount}${ref.valueType === "PERCENTAGE" ? "%" : "€"}`,
            });
        });
    return (
        <div className="container">
            {isLoading && <AppLoader />}
            {showData && (
                <React.Fragment>
                    <DataTable
                        noHeader={true}
                        pagination={true}
                        fixedHeaderScrollHeight={"calc(100vh - 320px)"}
                        columns={[
                            { name: "Sl No", selector: "sl_no", sortable: true, minWidth: "70px", maxWidth: "90px" },
                            { name: "Campaign Name", selector: "name", sortable: true, minWidth: "160px" },
                            {
                                name: "Start Date",
                                selector: "startDate",
                                sortable: true,
                                minWidth: "120px",
                                maxWidth: "120px",
                            },
                            {
                                name: "End Date",
                                selector: "endDate",
                                sortable: true,
                                minWidth: "120px",
                                maxWidth: "120px",
                            },
                            { name: "Value for Referrer", selector: "Referrer", sortable: true },
                            { name: "Value for Referree", selector: "Referree", sortable: true },
                        ]}
                        data={rowData}
                    />
                </React.Fragment>
            )}
        </div>
    );
};
const mapStateToProps = (state) => ({
    referrals: state.endUser.referrals,
});

export default connect(mapStateToProps, { getAllReferrals })(ReferralData);
