import React from 'react'
import styles from './ViewSchedule.module.css'

import DataTable from 'react-data-table-component';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import ConfirmAlert from '../../utils/ConfirmAlert/ConfirmAlert'

import { getAllSchedules } from '../../../containers/lifeCoach/actions'
import { showAlert } from '../../../containers/app/actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AppLoader from '../../utils/AppLoader/AppLoader'
import axios from 'axios';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';

const ViewSchedule = (props) => {
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        props.getAllSchedules();
    }, []);

    const handleDelete = (id) => {

        setLoading(id);
        const url = "/dashboard/life-coach-admin/deleteSchedule"
        const method = "delete";

        axios({
            method,
            url,
            data: {
                id
            }
        }).then(res => {
            setLoading(false);
            props.showAlert("Schedule Deleted Succesfully"); 
            props.getAllSchedules();
        }).catch(err => {
            setLoading(false);
            props.showAlert("Something went wrong ! Try Again")
        })

    }

    let isLoading = !props.dates;
    let showData = !isLoading;
    let rowData = [];
    let obj= {};
    props.dates && props.dates.map(val => {
        let key = `${val.startDate}-${val.endDate}-${val.startTime}-${val.endTime}`
        obj[key] = val;
    })
    !isLoading && Object.values(obj).forEach((bookedDate,index) => {  
        rowData.push({
            sl_no: index + 1,
            startDate: bookedDate.startDate,
            startTime: bookedDate.startTime,
            endDate: bookedDate.endDate,
            endTime: bookedDate.endTime,  
            actions: 
            <div className="actions">
                <ConfirmAlert msg="Are you sure want to delete" onClickEvent={()=>handleDelete(bookedDate._id)} >
                    <Tooltip title="Delete">
                        {loading == bookedDate._id
                            ?
                            <IconButton>
                                <CircularProgress size={16} />
                            </IconButton>
                            :
                            <IconButton>
                                <DeleteRoundedIcon />
                            </IconButton>}
                    </Tooltip>
                </ConfirmAlert > 
            </div>
        }) 
    }); 

    return (
        <div className={styles.container}>
            {isLoading && <AppLoader />}

            {showData &&
                <DataTable 
                    noHeader={true} 
                    fixedHeader={true}
                    pagination={true}
                    fixedHeaderScrollHeight={"calc(100vh - 260px)"}
                    columns={[
                        {name: "Sl No",selector: "sl_no",sortable: true},
                        {name: "Start Date",selector: "startDate",sortable: true},
                        {name: "Start Time",selector: "startTime",sortable: true},
                        {name: "End Date",selector: "endDate", sortable: true}, 
                        {name: "End Time", selector: "endTime", sortable: true},
                        {name: "Action",selector: "actions"}]}
                    data={rowData}
                />}
        </div>   
    )
}
const mapStateToProps = state => ({
    dates: state.lifeCoachAdmin.dates
})

export default withRouter(connect(mapStateToProps, { showAlert, getAllSchedules })(ViewSchedule))