import React from 'react'
import styles from './ServiceManager.module.css'

import {connect} from 'react-redux'
import {withRouter,useLocation} from 'react-router-dom'

import {getAllExperience,onServiceDelete} from '../../../containers/category/actions' 
 
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
 
import ConfirmAlert from '../../utils/ConfirmAlert/ConfirmAlert'
import ConfService from './ConfService/ConfService'


const ServiceManager = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [experiences,setExperiences] = React.useState(false);
    const [activeCat,setActiveCat] = React.useState(false);
    const [activeSubCat,setActiveSubCat] = React.useState(false); 
    const [activeExp,setActiveExp] = React.useState(false);

    React.useEffect(() => {
        if(!props.experienceMap) {
            props.getAllExperience();
        }
        setExperiences(props.experienceMap);
    },[props.experienceMap]);

    React.useEffect(() => {
        if(query.get("categoryId") && query.get("subCategoryId") && query.get("experienceId")) {
            setActiveCat(query.get("categoryId"))
            setActiveSubCat(query.get("subCategoryId"))
            setActiveExp(query.get("experienceId"))
        } else {
            setActiveCat(false)
        }
    },[props.location.search]);

    let isActive = !activeCat;
    let showData = !isActive && experiences;

    return (
        <div className={styles.container}>

            {isActive && 
            <div className={styles.contentCenter}>
                <HourglassEmptyRoundedIcon />
                <p>Select a Sub Category</p>
            </div>}

            {showData && 
                <div className={styles.listItems}>
                    {Object.keys(experiences[activeExp].categories).includes(activeCat) && Object.keys(experiences[activeExp].categories[activeCat].subCategory).includes(activeSubCat) && 
                    
                    Object.keys(experiences[activeExp].categories[activeCat].subCategory[activeSubCat].services).length > 0
                        ?
                    <React.Fragment>
                        {Object.keys(experiences[activeExp].categories[activeCat].subCategory[activeSubCat].services).map((key,index) => {
                            let category = experiences[activeExp].categories;
                            return (
                                <div className={styles.listItem} key={index} > 
                                    <p>{category[activeCat].subCategory[activeSubCat].services[key].serviceName}</p>

                                    <div className="actions">
                                        <div className={styles.hoverItems}>
                                            <ConfService service={category[activeCat].subCategory[activeSubCat].services[key]} experienceId={activeExp} type="EDIT" />

                                            <ConfirmAlert msg="Are you sure you wanna delete this service" onClickEvent={() => {
                                                props.onServiceDelete(key)
                                            }}>
                                                <Tooltip title="Delete Category">
                                                    <IconButton>
                                                        <DeleteRoundedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </ConfirmAlert>
                                        </div> 
                                    </div>
                                </div>
                            )
                        })}
                    </React.Fragment>
                        :
                    <div className={styles.contentCenter}>
                        <HourglassEmptyRoundedIcon />
                        <p>No Activity Found</p>
                    </div>}

                    <br/>
                    <ConfService categoryId={activeCat} subCategoryId={activeSubCat} experienceId={activeExp} type="ADD" />
                </div>}
        </div>
    )
}

const mapStateToProps = state => ({
    experienceMap: state.category.experienceMap
})
export default withRouter(connect(mapStateToProps, {getAllExperience,onServiceDelete})(ServiceManager));