import React from "react";
import { getAllImages, addImage, showAlert } from "../../containers/app/actions";
import { connect } from "react-redux";
import S3 from "react-aws-s3";
import { s3Config } from "../../config/config";
import { Buffer } from "buffer";
import CropImage from "./CropImage/CropImage";
import axios from "axios";
import Cropper from "./CropImage/Cropper";
// import { CropRotate } from "@material-ui/icons";
window.Buffer = Buffer;

const MediaHandler = (props) => {
    const [progress, setProgress] = React.useState(false);
    const [imgUrl, setImgUrl] = React.useState(false);

    const [img, setImage] = React.useState(false);
    const [nameRequired, setNameRequired] = React.useState(false);
    const [name, setName] = React.useState("");
    const [error, setError] = React.useState(false);

    let inputFile = React.useRef(null);

    // React.useEffect(() => {
    //     if (props.open) {
    //         inputFile.current && inputFile.current.click();
    //     }
    // }, [props.open]);

    React.useLayoutEffect(() => {
        if (props.open && inputFile?.current) {
            inputFile.current && inputFile.current.click();
        }
    }, [props.open, inputFile]);

    // handle image
    async function uploadImage(image) {
        if (props.imageType && props.aspectRatio) {
            try {
                const { validate } = await validateImage(image, props.aspectRatio);
                if (validate) {
                    uploadFile(image);
                }
            } catch (error) {
                props.showAlert(error.error);
            }
        } else {
            uploadFile(image);
        }
    }

    // upload image and video
    function uploadFile(file) {
        if (props.nameRequired) {
            setImage(file);
            setNameRequired(true);
        } else {
            uploadDocument(file);
        }
    }

    // onchange function
    const handleFile = async (e) => {
        if (e.target.files[0]) {
            let file = e.target.files[0];

            e.target.value = "";
            setImage(file);
            if (props.allowedSize && file.size / 1024 > props.allowedSize) {
                return props.showAlert("File Size Error: Maximum 1MB is allowed");
            }
            if (props?.allowedList?.includes("video")) {
                uploadVideo(file);
            } else {
                uploadImage(file);
            }
        }
    };

    // image validation
    function validateImage(image, aspectRatio) {
        return new Promise(async (resolve, reject) => {
            var img = document.createElement("img");

            img.onload = function () {
                const { width, height } = img;
                const aspect = `${width}x${height}`;

                if (Math.round(image.size) / 1024 > 2048) {
                    return reject({
                        validate: false,
                        error: "Image Upload Error: Maximum size only 2Mb",
                    });
                } else if (aspect !== aspectRatio) {
                    return reject({
                        validate: false,
                        error: `Image Upload Error: Allowerd aspect ratio ${aspectRatio}`,
                    });
                }
                return resolve({ validate: true });
            };
            if (image) {
                var reader = new FileReader();
                reader.onloadend = function (ended) {
                    img.src = ended.target.result;
                };
                reader.readAsDataURL(image);
            }
        });
    }

    function validateVideo(video) {
        const url = window.URL || window.webkitURL;
        return new Promise(async (resolve, reject) => {
            const vid = document.createElement("video");
            vid.preload = "metadata";

            vid.onloadedmetadata = function () {
                window.URL.revokeObjectURL(vid.src);
                const { videoWidth, videoHeight, duration } = vid;
                const aspectRatio = `${videoWidth}x${videoHeight}`;
                if (Math.round(video.size) / 1024 > 48000) {
                    return reject({
                        validate: false,
                        error: "Video Upload Error: Maximum size is 48MB",
                    });
                }
                return resolve({ validate: true });
            };
            if (video) {
                vid.src = URL.createObjectURL(video);
            }
        });
    }
    async function uploadVideo(video) {
        try {
            const { validate } = await validateVideo(video);
            if (validate) {
                uploadFile(video);
            }
        } catch (error) {
            props.showAlert(error.error);
        }
    }
    const uploadDocument = (img) => {
        if (img && img.type.includes("image")) {
            setImage(img);
            if (Math.round(img.size) / 1024 > 2048) {
                let error = "Image Upload Error: Maximum size only 2MB";
                props.showAlert(error);
            } else {
                setImgUrl(URL.createObjectURL(img));
            }
        } else handleFileUpload(img);
    };

    const handleFileUpload = (file, isImage, AltText) => {
        if (file) {
            setProgress(true);
            let folderName = props.folderName ? props.folderName : "";
            let docName = `${folderName}${name ? "***" + name + "***" : ""}${(Math.random() + 1)
                .toString(36)
                .substring(2)
                .toUpperCase()}${isImage ? ".jpeg" : ""}`;

            const ReactS3Client = new S3(s3Config);
            if (props.isPrivate) {
                uploadFileToPrivateBucket(docName, file);
            } else {
                ReactS3Client.uploadFile(file, `${props.auth._id}/${docName}`)
                    .then((data) => {
                        props.onSelectImage(data.location, AltText);
                        setProgress(false);
                        setImgUrl(false);
                        setName("");
                    })
                    .catch((err) => {
                        console.log(err);
                        setProgress(false);
                        setImgUrl(false);
                        // props.showAlert("Something went wrong Try Again");
                    });
            }
        }
    };

    const uploadFileToPrivateBucket = (docName, file) => {
        setProgress(true);
        var formData = new FormData();
        formData.append("file", file);
        formData.append("name", docName);

        axios
            .post("/user/utils/uploadFileToPrivateBucket", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => {
                props.onSelectImage(res.data.key);
                setProgress(false);
                setImgUrl(false);
                setName("");
            })
            .catch((err) => {
                setProgress(false);
                if (err && err.response) {
                    props.showAlert(err.response.data.error);
                } else {
                    props.showAlert("Something went wrong Try Again");
                }
            });
    };
    return (
        <React.Fragment>
            {imgUrl && (
                <Cropper>
                    {({ cropperRef }) => (
                        <CropImage
                            open={imgUrl}
                            onClose={() => setImgUrl(false)}
                            imgUrl={imgUrl}
                            handleFileUpload={handleFileUpload}
                            showLoading={progress}
                            image={img}
                            cropperRef={cropperRef}
                            cropRatio={props.cropRatio}
                            cropBoxResizable={props.cropBoxResizable}
                        />
                    )}
                </Cropper>
            )}
            {progress && (
                <Cropper>
                    {({ cropperRef }) => (
                        <CropImage
                            open={progress}
                            showLoading={progress}
                            onClose={() => setProgress(false)}
                            cropperRef={cropperRef}
                        />
                    )}
                </Cropper>
            )}
            {nameRequired && (
                <Cropper>
                    {({ cropperRef }) => (
                        <CropImage
                            open={true}
                            nameRequired={true}
                            value={name}
                            error={error}
                            onChange={(value) => setName(value)}
                            onSubmit={() => {
                                setError(false);
                                if (name == "") {
                                    setError("Field cannot be empty");
                                } else {
                                    setNameRequired(false);
                                    uploadDocument(img);
                                }
                            }}
                            onClose={() => setNameRequired(false)}
                            cropperRef={cropperRef}
                        />
                    )}
                </Cropper>
            )}

            {props.open && (
                <input
                    type={"file"}
                    onChange={handleFile}
                    multiple
                    ref={inputFile}
                    style={{ display: "none" }}
                    accept={props.allowedList ? props.allowedList : "*"}
                />
            )}
        </React.Fragment>
    );
};
const mapStateToProps = (state) => ({
    auth: state.app.auth,
});

export default connect(mapStateToProps, { getAllImages, addImage, showAlert })(MediaHandler);
