import React from 'react'
import styles from './AddCustomerGroup.module.css'

import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress'

import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

import {connect} from 'react-redux'
import {getAllCustomerGroup, showAlert} from '../../../../../containers/app/actions'
import axios from 'axios'


const AddCustomerGroup = (props) => {
    const [formData,setFormData] = React.useState({
        groupName: "",
        users: []
    }); 
    const [err,setErr] = React.useState({
        groupName: false,
        users: false
    }); 
    const [open,setOpen] = React.useState(false);

    const [loading,setLoading] = React.useState(false);

    const validate = () => {
        const err = {groupName: false,users: false};
        let validData = true;
        setErr({...err});
        if(formData.groupName == "") {
            err.groupName = "Field connot be empty"
            validData = false;
        }
        if(formData.users.length == 0) {
            err.users = "Select atleast 1 user"
            validData = false;
        }

        setErr({...err});
        return validData;
    }
 
    const onSubmit = () => {
        if(validate()) {
            setLoading(true);

            axios({
                method: "post",
                url: "/dashboard/customer-group/addCustomerGroup",
                data: {
                    ...formData
                }
            }).then(res => {
                setLoading(false);
                props.showAlert("Customer Group Added Succesfully");
                props.getAllCustomerGroup() 
                setOpen(false);
                setFormData({users: [],groupName: ""})
            }).catch(err => {
                setLoading(false);
                if(err && err.response && err.response.data && err.response.data.error) {
                    props.showAlert(err.response.data.error)
                } else {
                    props.showAlert("Something went wrong ! Try Again")
                }
            })
        }
    }
    return (
        <React.Fragment>
            <Button onClick={() => setOpen(true)} variant="contained" color="secondary" endIcon={<AddCircleOutlineRoundedIcon />} style={{marginLeft: 15}}>Add Group</Button>

            <Modal open={open} onClose={() => setOpen(false)}>
                <div className={styles.container}>
                    <div className={styles.content}>
                        <IconButton className={styles.closeIcon} onClick={() => setOpen(false)}>
                            <CancelRoundedIcon />
                        </IconButton>   

                        <h1>Add Customer Group</h1>

                        <TextField
                            variant="outlined"
                            label="Group Name"
                            value={formData.groupName}
                            onChange={e => setFormData({...formData,groupName: e.target.value})}
                            className={styles.textField}
                            error={err.groupName}
                            helperText={err.groupName}
                        />
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            options={props.basicUserList ? props.basicUserList : []}
                            getOptionLabel={(option) => option.email}
                            className={styles.textField}
                            value={formData.users}
                            onChange={(e,val) => setFormData({...formData,users: val})}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Select Customer List"
                                    placeholder="Select Customer List"
                                    error={err.users}
                                    helperText={err.users}
                                />
                            )}
                        />

                        {loading
                            ?
                        <Button color="secondary" variant="contained" startIcon={<CircularProgress color="inherit" size={20} />}>Loading ...</Button>
                            :
                        <Button color="secondary" variant="contained" startIcon={<AddCircleOutlineRoundedIcon />} onClick={onSubmit}>Add Customer Group</Button>}
                    </div>
                </div>
            </Modal>

        </React.Fragment>
    )
}

export default connect(null, {getAllCustomerGroup, showAlert})(AddCustomerGroup);