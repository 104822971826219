import React from 'react'
import styles from './ConfDiscountCode.module.css'

import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button' 
import CircularProgress from '@material-ui/core/CircularProgress'

import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';

import Applicable from './Applicable/Applicable'
import DiscountType from './DiscountType/DiscountType'
import Requirements from './Requirements/Requirements'
import CustomerGroup from './CustomerGroup/CustomerGroup'
import DiscountLimit from './DiscountLimit/DiscountLimit'
import Duration from './Duration/Duration'

import {withRouter,useLocation} from 'react-router-dom'
import axios from 'axios'
import {connect} from 'react-redux'
import {showAlert} from '../../../containers/app/actions'
import {getAllDiscountCodes} from '../../../containers/discountCode/actions'


const ConfDiscountCode = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [formData,setFormData] = React.useState({
        discountName: "", 
        discountType: "PERCENTAGE",
        discountTypeValue: "",
        selectedCountry: [],
        excludeShippingTraffic: false,
        excludeShippingTrafficValue: "",
        applicableFor: "ALL-PRODUCTS",
        applicaleProductList: [],
        applicableCategoryList: [],
        minimumRequirement: "NO-REQUIREMENT",
        minimumRequirementValue: "",
        customerType: "ALL-CUSTOMERS",
        customerGroup: [],
        customerList:[],
        isThereCountLimit: false,
        isThereCustomerCountLimit: false,
        discountCountLimit: "",
        discountCustomerCountLimit: "",
        startDate: new Date().toISOString().slice(0,10),
        startTime: new Date().getHours() + ":" + new Date().getMinutes(),
        endDate: "",
        endTime: ""
    });

    const [error,setError] = React.useState({
        discountName: false,
        discountType: false,
        discountTypeValue: false,
        selectedCountry: false,
        excludeShippingTraffic: false,
        excludeShippingTrafficValue: false,
        applicableFor: false,
        applicaleProductList: false,
        applicableCategoryList: false,
        minimumRequirement: false,
        minimumRequirementValue: false,
        customerType: false,
        customerGroup: false,
        customerList: false,
        isThereCountLimit: false,
        isThereCustomerCountLimit: false,
        discountCountLimit: false,
        discountCustomerCountLimit: false,
        startDate: false,
        startTime: false,
        endDate: false,
        endTime: false 
    });
    const [loading,setLoading] = React.useState(false);

    React.useEffect(() => {
        if(props.type == "EDIT") {
            if(query.get("codeId")) {
                if(props.codes) {
                    props.codes.forEach(code => {
                        if(code._id == query.get("codeId")) { 
                            setFormData({...formData,...code,discountCodeId: code._id,startDate: code.startDate.substr(0,10),endDate: code.endDate.substr(0,10)})
                        }
                    })
                } else {
                    props.getAllDiscountCodes();
                }
            } else {
                props.showAlert("Invalid Request")
                props.history.push("/user/discount-codes/VIEW-DISCOUNT-CODES")
            }
        }
    },[props.codes])

    const generateString = () => {
        setFormData({...formData,discountName: Math.random().toString(20).substr(2, 10).toUpperCase()})
    }
    
    const validate = () => {
        const err = {discountName: false, discountType: false, discountTypeValue: false, selectedCountry: false, excludeShippingTraffic: false, excludeShippingTrafficValue: false, applicableFor: false, applicaleProductList: false, applicableCategoryList: false, minimumRequirement: false, minimumRequirementValue: false, customerType: false, customerGroup: false, customerList: false, discountCountLimit: false, discountCustomerCountLimit: false, startDate: false, startTime: false, endDate: false, endTime: false };
        let validData = true;
        setError({...err});
        if(formData.discountName.length < 3) {
            err.discountName = 'Discount Code should be 3 or more than 3 characters';
            validData = false;
        }
        if(formData.discountType == "PERCENTAGE" || formData.discountType == "FIXED-VALUE") {
            if(formData.discountTypeValue == "") {
                err.discountTypeValue = 'Field cannot be empty';
                validData = false;
            }
        }
        if(formData.discountType == "FREE-SHIPPING" && formData.discountTypeValue == "SELECTED-COUNTRIES") {
            if(formData.selectedCountry.length == 0) {
                err.selectedCountry = 'Field cannot be empty';
                validData = false;
            }
        }
        if(formData.excludeShippingTraffic && formData.excludeShippingTrafficValue == "") {
            err.excludeShippingTrafficValue = 'Field cannot be empty';
            validData = false;
        }
        if(formData.applicableFor == "SPECIFIC-PRODUCTS" && formData.applicaleProductList.length == 0) {
            err.applicaleProductList = 'Field cannot be empty';
            validData = false;
        }
        if(formData.applicableFor == "SPECIFIC-CATEGORY" && formData.applicableCategoryList.length == 0) {
            err.applicableCategoryList = 'Field cannot be empty';
            validData = false;
        }
        if(formData.minimumRequirement != "NO-REQUIREMENT" && formData.minimumRequirementValue == "") {
            err.minimumRequirementValue = 'Field cannot be empty';
            validData = false;
        }
        if(formData.customerType == "CUSTOMER-GROUP" && formData.customerGroup.length == 0) {
            err.customerGroup = 'Field cannot be empty';
            validData = false;
        }
        if(formData.customerType == "CUSTOMERS" && formData.customerList.length == 0) {
            err.customerList = 'Field cannot be empty';
            validData = false;
        }
        if(formData.isThereCountLimit && formData.discountCountLimit == "") {
            err.discountCountLimit = 'Field cannot be empty';
            validData = false;
        }
        if(formData.isThereCustomerCountLimit && formData.discountCustomerCountLimit == "") {
            err.discountCustomerCountLimit = 'Field cannot be empty';
            validData = false;
        } 
        if(formData.startTime == "") {
            err.startTime = 'Field cannot be empty';
            validData = false;
        } 
        if(formData.startDate == "") {
            err.startDate = 'Field cannot be empty';
            validData = false;
        } 
        if(formData.endTime == "") {
            err.endTime = 'Field cannot be empty';
            validData = false;
        } 
        if(formData.endDate == "") {
            err.endDate = 'Field cannot be empty';
            validData = false;
        } 
 
        setError({...err});
        return validData;
    }
    const onSubmit = () => {
        if(validate()) {
            setLoading(true);
            const url = props.type == "ADD" ? "/dashboard/discount-code/addDiscountCode" : "/dashboard/discount-code/editDiscountCode";
            const method = props.type == "ADD" ? "post" : "put";

            axios({
                method: method,
                url: url,
                data: {
                    ...formData
                }
            }).then(res => {
                setLoading(false);
                props.showAlert(`Discount Code ${props.type == "ADD" ? "Added" : "Updated"} Succesfully`);
                props.getAllDiscountCodes()
                props.history.push("/user/discount-codes/VIEW-DISCOUNT-CODES")
            }).catch(err => {
                setLoading(false);
                if(err && err.response && err.response.data && err.response.data.error) {
                    props.showAlert(err.response.data.error)
                } else {
                    props.showAlert("Something went wrong ! Try Again")
                }
            })
        }
    }
    return (
        <div className={styles.container}>
            <Paper className={styles.content} variant="outlined">
                <div className={styles.compContent}>
                    <h3>Enter Discount Name</h3>

                    <div className={styles.textFieldContent}>
                        <TextField
                            label="Discount Name"
                            variant="outlined" 
                            value={formData.discountName}
                            onChange={e => e.target.value.length <= 10 && setFormData({...formData,discountName: e.target.value.toUpperCase().replace(/[^a-zA-Z0-9]/,'')})}
                            error={error.discountName}
                            helperText={error.discountName}
                            className={styles.textField}
                        />

                        <Button color="secondary" variant="contained" onClick={generateString}>Generate One</Button>
                    </div>
                </div>

                {/* DISCOUNT TYPE */}
                <DiscountType formData={formData} setFormData={setFormData} error={error}/>

                {/* PRODUCT OR CATEGORY APPLICABLE */}
                <Applicable formData={formData} setFormData={setFormData} error={error} />

                {/* MINIMUM REQUIREMENTS */}
                <Requirements formData={formData} setFormData={setFormData} error={error} />

                {/* CUSTOMER GROUP */}
                <CustomerGroup formData={formData} setFormData={setFormData} error={error} />

                {/* DISCOUNT LIMIT */}
                <DiscountLimit formData={formData} setFormData={setFormData} error={error} />

                {/* DISCOUNT DURATION */}
                <Duration formData={formData} setFormData={setFormData} error={error} />

                <div className={styles.rowButton}>
                    {loading
                        ?
                    <Button color="primary" variant="contained" startIcon={<CircularProgress color="inherit" size={20} />}>Loading ...</Button>
                        :
                    <Button color="primary" variant="contained" startIcon={<UpdateRoundedIcon />} onClick={onSubmit}>Save &amp; Update</Button>}

                    <Button color="primary" variant="contained" onClick={() => props.history.push("/user/discount-codes/VIEW-DISCOUNT-CODES")}>Cancel</Button>
                </div>

            </Paper>
        </div>
    )
}

const mapStateToProps = state => ({
    codes: state.discountCode.codes
})

export default withRouter(connect(mapStateToProps,{showAlert,getAllDiscountCodes})(ConfDiscountCode));