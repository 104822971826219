import React from "react";
import styles from "./Modal.module.css";
import Modal from "@material-ui/core/Modal";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { makeStyles, Select, MenuItem } from "@material-ui/core";
import axios from "axios";
import { getAllEndUsers, getPromoCodes } from "../../../../containers/endUser/actions";
import { connect } from "react-redux";

import { showAlert } from "../../../../containers/app/actions";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: 10,
    },
    password: {
        width: "300px",
        marginBottom: "95px",
        marginTop: "15px",
        "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
            height: "44px",
        },
    },
}));

const ModalPage = ({ open, onClose, tab, user, ...props }) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({
        prefix: "",
        numChars: false,
        numCodes: false,
        entitlementType: "Quarterly",
        isAutoRenwal: false,
        companyName: "",
        StartDate: moment().format("YYYY-MM-DD"),
        EndDate: moment().format("YYYY-MM-DD"),
        emailEndsWith: "",
        redemptionLimit: "",
    });

    const [formError, setFormError] = React.useState({
        prefix: false,
        numChars: false,
        numCodes: false,
        entitlementType: false,
        isAutoRenwal: false,
        companyName: false,
        StartDate: false,
        EndDate: false,
    });
    const token = localStorage.getItem("token");
    const validate = () => {
        let value = true;
        let err = {
            code: false,
            prefix: false,
            numChars: false,
            numCodes: false,
            companyName: false,
            StartDate: false,
            EndDate: false,
        };

        setFormError({ ...err });
        if (formData.code == "") {
            value = false;
            err.code = "Please enter code";
        }

        if (formData.prefix === "") {
            value = false;
            err.prefix = "Please enter prefix";
        }
        if (!formData.numChars) {
            value = false;
            err.numChars = "Enter valid No of Characters";
        }
        if (!formData.numCodes) {
            value = false;
            err.numCodes = "Enter valid No of Code";
        }
        if (!formData.companyName) {
            value = false;
            err.companyName = "Enter valid companyName";
        }
        if (!formData.StartDate) {
            value = false;
            err.StartDate = "Enter valid Start Date";
        }
        if (!formData.EndDate) {
            value = false;
            err.EndDate = "Enter valid End Date";
        }

        setFormError({ ...err });
        console.log(value, err);
        return value;
    };

    const onSubmit = () => {
        if (validate()) {
            console.log({ ...formData });
            setLoading(true);
            axios({
                method: "post",
                url: "/dashboard/promoCode/createPromoCode",
                data: {
                    ...formData,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    if (res.data) {
                        setLoading(false);
                        setFormData({ ...formData, prefix: "", numChars: false, numCodes: false });
                        props.showAlert("PROMO CODE CREATED SUCCESSFULLY");
                        onClose();
                        props.getPromoCodes(10, 1, "", "", "");
                    }
                })
                .catch((err) => {
                    if (err && err.response) {
                        setLoading(false);
                        props.showAlert("Something Went Wrong");
                    }
                });
        }
    };

    return (
        <Modal open={open ? true : false} onClose={onClose} className={styles.modal}>
            <div className={styles.container}>
                <div className={styles.closeButton}>
                    <IconButton onClick={onClose}>
                        <CloseRoundedIcon />
                    </IconButton>
                </div>

                <div className={styles.content}>
                    <div className={styles.infoHeader}>
                        <h5>Add New Promo Code</h5>
                    </div>

                    <div className={styles.flex}>
                        <TextField
                            variant="outlined"
                            placeholder="Prefix (e.g. 21DEMPLU)"
                            className={styles.halfField}
                            value={formData.prefix}
                            onChange={(e) => setFormData({ ...formData, prefix: e.target.value.toUpperCase() })}
                            error={formError.prefix}
                            helperText={formError.prefix}
                        />
                        <TextField
                            type="Number"
                            variant="outlined"
                            placeholder="No. of characters"
                            className={styles.halfField}
                            value={formData.numChars}
                            onChange={(e) => setFormData({ ...formData, numChars: e.target.value })}
                            error={formError.numChars}
                            helperText={formError.numChars}
                        />
                        <TextField
                            type="Number"
                            variant="outlined"
                            placeholder="No. of codes"
                            className={styles.halfField}
                            value={formData.numCodes}
                            onChange={(e) => setFormData({ ...formData, numCodes: e.target.value })}
                            error={formError.numCodes}
                            helperText={formError.numCodes}
                        />
                    </div>

                    <div className={styles.flex}>
                        <Select
                            className={styles.halfField}
                            placeholder="Entitlement type"
                            variant="outlined"
                            value={formData.entitlementType}
                            onChange={(e) => setFormData({ ...formData, entitlementType: e.target.value })}
                            MenuProps={{
                                classes: {
                                    paper: classes.paper,
                                },
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                        >
                            <MenuItem
                                value="Quarterly"
                                style={{ display: "flex", justifyContent: "center", color: "#2D4141" }}
                            >
                                Quarterly
                            </MenuItem>
                            <MenuItem
                                style={{ display: "flex", justifyContent: "center", color: "#2D4141" }}
                                value="Annual"
                            >
                                Annualy
                            </MenuItem>
                        </Select>
                        <Select
                            className={styles.halfField}
                            placeholder="Auto Renewal"
                            variant="outlined"
                            value={formData.isAutoRenwal}
                            onChange={(e) => setFormData({ ...formData, isAutoRenwal: e.target.value })}
                            MenuProps={{
                                classes: {
                                    paper: classes.paper,
                                },
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                        >
                            <MenuItem
                                value={true}
                                style={{ display: "flex", justifyContent: "center", color: "#2D4141" }}
                            >
                                Auto-renewing
                            </MenuItem>
                            <MenuItem
                                style={{ display: "flex", justifyContent: "center", color: "#2D4141" }}
                                value={false}
                            >
                                Non-renewing
                            </MenuItem>
                        </Select>
                        <TextField
                            variant="outlined"
                            placeholder="Company Name"
                            className={styles.halfField}
                            value={formData.companyName}
                            onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
                            error={formError.companyName}
                            helperText={formError.companyName}
                        />
                    </div>

                    <div className={`${styles.flex} ${styles.justify}`}>
                        <TextField
                            type="Date"
                            variant="outlined"
                            className={styles.halfField}
                            value={formData.StartDate}
                            onChange={(e) => setFormData({ ...formData, StartDate: e.target.value })}
                            error={formError.StartDate}
                            helperText={formError.StartDate}
                        />
                        <TextField
                            type="Date"
                            variant="outlined"
                            className={styles.halfField}
                            value={formData.EndDate}
                            onChange={(e) => setFormData({ ...formData, EndDate: e.target.value })}
                            error={formError.EndDate}
                            helperText={formError.EndDate}
                        />
                        <div className={styles.halfField} />
                    </div>

                    <div className={styles.additional}>
                        <h4>Additional conditions (optional)</h4>
                        <TextField
                            placeholder="Email ends with (format:@xyz.com)"
                            variant="outlined"
                            value={formData.emailEndsWith}
                            onChange={(e) => setFormData({ ...formData, emailEndsWith: e.target.value })}
                        />
                        <TextField
                            placeholder="Redemption limit (1, if left empty)"
                            variant="outlined"
                            value={formData.redemptionLimit}
                            onChange={(e) => setFormData({ ...formData, redemptionLimit: e.target.value })}
                        />
                    </div>

                    <div className={styles.Button}>
                        <button
                            disabled={loading}
                            onClick={() => {
                                onSubmit();
                            }}
                        >
                            {loading ? "...Adding" : "Add"}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default connect(null, {
    getAllEndUsers,
    showAlert,
    getPromoCodes,
})(ModalPage);
