import React from 'react'; 

import CircularProgress from '@material-ui/core/CircularProgress'; 
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';

import axios from 'axios'
import {connect} from 'react-redux'
import {getAllRequestedData} from '../../../containers/serviceProviderRequest/actions'
import {showAlert} from '../../../containers/app/actions'
import {withRouter} from 'react-router-dom'

import ConfirmAlert from './../../utils/ConfirmAlert/ConfirmAlert'
import Reject from './RejectServiceProvider/Reject'


const Action = (props) => {
    const request = props.request; 

    const [loading,setLoading] = React.useState(false);
    const [rejectModal,setRejectModal] = React.useState(false);

    const approveRequest = (requestId) => {
        setLoading("APPROVED");
        axios({
            method: "post",
            url: "/dashboard/service-provider/approveServiceProvider", 
            data: {
                requestId
            }
        }).then(res => {
            setLoading(false);
            props.showAlert("Service Provider Approved");
            props.getAllRequestedData();
        }).catch(err => {
            setLoading(false);
            if(err && err.response && err.response.data && err.response.data.error) {
                props.showAlert(err.response.data.error)
            } else {
                props.showAlert("Something went wrong ! Try Again")
            }
        })
    }
    const resendEmail = (requestId) => {
        setLoading("RESEND");
        axios({
            method: "post",
            url: "/dashboard/service-provider/resendApproveEmailToServiceProvider",
            data: {
                requestId
            }
        }).then(res => {
            setLoading(false);
            props.showAlert("Email sent Successfully");
        }).catch(err => {
            setLoading(false);
            if (err && err.response && err.response.data && err.response.data.error) {
                props.showAlert(err.response.data.error)
            } else {
                props.showAlert("Something went wrong ! Try Again")
            }
        })
    }
 
    return (
        <div key={request._id} className={"actions"}>  
            <Reject open={rejectModal} onClose={() => setRejectModal(false)} requestId={request._id} />

            <ConfirmAlert msg="Are you sure you want resend email" onClickEvent={() => resendEmail(request._id)} disabled={request.status != "APPROVED"}>
                <Tooltip title="Resend Email">
                    <IconButton disabled={request.status != "APPROVED"}>
                        {loading == "RESEND"
                            ?
                        <CircularProgress size={16} />
                            :
                        <RefreshRoundedIcon />}
                    </IconButton>
                </Tooltip>
            </ConfirmAlert> 

            <ConfirmAlert msg="Are you sure you want approve this provider" onClickEvent={() => approveRequest(request._id)} disabled={request.status != "PENDING"}>
                <Tooltip title="Approve">
                    <IconButton disabled={request.status != "PENDING"}>
                        {loading == "APPROVED"
                            ?
                            <CircularProgress size={16} />
                            :
                            <CheckRoundedIcon />}
                    </IconButton>
                </Tooltip>
            </ConfirmAlert>

            <Tooltip title="Reject Service Provider">
                <IconButton onClick={() => setRejectModal(true)} disabled={request.status != "PENDING"}> 
                    <CloseRoundedIcon />
                </IconButton>
            </Tooltip>

            <Tooltip title="Contact Service Provider">
                <IconButton onClick={() => props.history.push("/user/email-system?email=" + props.request.email)}> 
                    <SendRoundedIcon />
                </IconButton>
            </Tooltip>
        </div>
    )
}

export default withRouter(connect(null,{getAllRequestedData,showAlert})(Action));
 