import React from "react"; 

import styles from "../ServiceSpecs.module.css";
// import Image from "next/image";
// import blurImage from "../../../../../assets/img/blur-image.jpeg";
import { connect } from "react-redux"; 

const Gallery = ({ service, catHashMap }) => {
    
    const images = service.images || [];
    const [current, setCurrent] = React.useState(0);
    const [viewMore, setViewMore] = React.useState(false);
     

    return (
        <React.Fragment>
             
            <div className={styles.gallery}>
                <div className={styles.mainImage}>
                    <img
                        // blurDataURL={blurImage}
                        // sizes="100%"
                        // layout="fill"
                        // objectFit="cover"
                        // quality={100}
                        src={images[current]}
                        alt=""
                        className={styles.mainImg}
                    />
                </div>
                <div className={styles.imagesContainer}>
                    {images.slice(0, 3).map((image, i) => (
                        <div className={styles.subImage}>
                            <img
                                // sizes="100%"
                                // layout="fill"
                                // objectFit="cover"
                                // quality={100}
                                style={{ cursor: "pointer" }}
                                src={image}
                                alt=""
                                onClick={() => setCurrent(i)}
                            />
                        </div>
                    ))}
                    {viewMore
                        ? images.slice(3).map((image, i) => {
                            i += 3;
                            return (
                                <div className={styles.subImage}>
                                    <img
                                        // sizes="100%"
                                        // layout="fill"
                                        // objectFit="cover"
                                        // quality={100}
                                        alt=""
                                        style={{ cursor: "pointer" }}
                                        src={image}
                                        onClick={() => setCurrent(i)}
                                    />
                                </div>
                            );
                        })
                        : null}
                </div>
                {images.length > 3 ? (
                    <button
                        className={styles.button}
                        style={{
                            textTransform: "uppercase",
                            marginLeft: "auto",
                            letterSpacing: "1.8px",
                        }}
                        onClick={() => setViewMore(!viewMore)}
                    >
                        {viewMore ? <>Weniger anzeigen</> : <>Alle ansehen</>}
                    </button>
                ) : null}
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    catHashMap: state.app.catHashMap,
});

export default connect(mapStateToProps, {})(Gallery);
