export function getWeek() {
  return [
    { full: "Sunday", half: "SU" },
    { full: "Monday", half: "MO" },
    { full: "Tuesday", half: "TU" },
    { full: "Wednesday", half: "WE" },
    { full: "Thursday", half: "TH" },
    { full: "Friday", half: "FR" },
    { full: "Saturday", half: "SA" },
  ];
}
