import * as actionTypes from './actionTypes'

const initialState = {
    dates: false,
    bookings:false
}
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.SET_ADMIN_SCHEDULE_DATES:
            return { ...state, dates: action.payload }
        case actionTypes.SET_ALL_BOOKING_DATA:
            return {...state,bookings: action.payload}
        default:
            return state
    }
}

export default reducer;