import React from 'react';
import styles from './ServiceProviderRequest.module.css';

import BreadCrump from '../../components/utils/BreadCrump/BreadCrump'; 
import TopBar from '../../components/utils/TopBar/TopBar';
import ServiceProviderRequestedComp from '../../components/ServiceProviderRequested/ServiceProviderRequested'


const ServiceProviderRequest = () => { 
    return (
        <div className={styles.container}>
            <TopBar head="Service Provider Request" />
            <BreadCrump 
                navItems={[{name:"Pending Access Requests",path: "/admin/service-provider-request/VIEW-REQUESTS"}]}
            />

            <ServiceProviderRequestedComp />
        </div>
    )
}

export default ServiceProviderRequest;