import {createMuiTheme} from '@material-ui/core/styles'

export const theme = createMuiTheme({
  overrides: { 
    MuiButton: { 
      root : { 
        textTransform: 'capitalize',
        fontWeight: "400", 
        padding: '8px 18px', 
        borderRadius: '4px'
      },
      text: {  
        textTransform: 'capitalize',
      },
    },
  },
  palette: {
    primary: {
      main: "rgba(0,0,0,0.9)",
    },
    secondary: {
      main: "#495E47",
    },
  },
}); 