import React from "react";

export default function IsDesktop(params) {
    const [isDesktop, setIsDesktop] = React.useState(true);
    React.useEffect(() => {
        if (window.innerWidth > 600) {
            setIsDesktop(true);
        } else {
            setIsDesktop(false);
        }
    }, []);
    return isDesktop;
}
