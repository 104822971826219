import React from 'react'
import styles from './ChangePassword.module.css' 

import Dialog from '@material-ui/core/Dialog' 
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

import CloseRoundedIcon from '@material-ui/icons/CloseRounded'; 

import {connect} from 'react-redux';
import axios from 'axios'
import {showAlert} from '../../../../../containers/app/actions'
import PassTextField from '../../../../utils/PassTextField/PassTextField'
 
import {useLocation} from 'react-router-dom'

const ChangePassword = (props) => { 
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [formData,setFormData] = React.useState({
        oldPassword: "",
        newPassword: ""
    })

    const [error,setError] = React.useState({
        oldPassword: false,
        newPassword: false
    }); 

    const [loading,setLoading] = React.useState(false);
 
    const validatePassword = () => {
        const err = {oldPassword: false,newPassword: false};
        let validData = true;
        setError({...err});
        Object.keys(err).forEach(key => {
            if(formData[key].length < 8) {
                err[key] = `${key == "oldPassword" ? ('Old Password') : ('New Password')} ${('should be mimimum 8 charactors')}`
                validData = false;
            } 
        })

        setError({...err});
        return validData;
    }
    const onSubmitPassword = () => {
        if(validatePassword()) {
            setLoading("PASSWORD");

            axios({
                method: "post",
                url: `/user/user/changePassword?userId=${query.get("userId")}`,
                data: {
                    ...formData
                }
            }).then(res => {
                setLoading(false) 
                props.showAlert(('Password Updated successfully'))
                setFormData({oldPassword: "",newPassword: ""})
            }).catch(err => {
                setLoading(false);
                if(err && err.response && err.response.data && err.response.data.error) {
                    props.showAlert(err.response.data.error)
                } else {
                    props.showAlert(('Something went wrong Try Again'))
                }
            })
        }
    } 
    return (
        <Dialog open={props.open} onClose={() => props.onClose()}>
            <div className={styles.container}>
                <CloseRoundedIcon  onClick={() => props.onClose()} className={styles.close} />

                <div className={styles.content}>
                    <h1>{('Change Password')}</h1> 

                    <div className={styles.formContent}> 
                        <React.Fragment>
                            <PassTextField
                                className={styles.textField}
                                label={('Enter Old Password')}
                                value={formData.oldPassword}
                                error={error.oldPassword}
                                helperText={error.oldPassword}
                                onChange={(e) => setFormData({...formData,oldPassword: e.target.value})}
                                fullWidth
                            />
                            <PassTextField
                                className={styles.textField}
                                label={('Enter New Password')}
                                value={formData.newPassword}
                                error={error.newPassword}
                                helperText={error.newPassword}
                                onChange={(e) => setFormData({...formData,newPassword: e.target.value})}
                                fullWidth
                            />
                            
                            {loading == "PASSWORD"
                                ?
                            <Button color="primary" variant="contained" startIcon={<CircularProgress color="inherit" size={20} />}>{('Loading')}</Button>
                                :
                            <Button color="primary" variant="contained" onClick={onSubmitPassword}>{('Change Password')}</Button>}
                        </React.Fragment>
                    </div> 
                </div>
            </div>
        </Dialog>
    )
}
const mapStateToProps = state => ({
    auth: state.app.auth
})
export default connect(mapStateToProps,{showAlert})(ChangePassword);