import React from 'react' 
import styles from './FileUploader.module.css'
import Tooltip from '@material-ui/core/Tooltip'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios'

const FileUploader = (props) => { 

    const [loading, setLoading] = React.useState(false);

    const getPrivateFileFromS3 = () => {
        setLoading(true);

        axios({
            method: "get",
            url: `/dashboard/user/viewFileFromPrivateBucket?key=${props.value}`,
        })
            .then((res) => {
                setLoading(false);
                window.open(res.data.url);
            })
            .catch((err) => {
                setLoading(false);
           
            });
    };
    return (
        <div className={styles.row1}>
            <div className={styles.labelDoc}>
                <label htmlFor="">{props.title}</label>
            </div>
            <div className={styles.fileUploader}>
                {props.mode =="READ" && <div className={styles.mapHere}>&nbsp;</div>}
                {!props.noBtn && <button
                    onClick={props.onUpload}
                >
                    {props.value ? "Change" : "Upload"}
                </button>}

                <div className={styles.fileInfo}>
                    <span>{props.name}</span>
                    {props.type
                        ? <span>{props.type}</span>
                        : <span>PDF | IMAGE</span>
                    }
                </div>
                {props.value &&
                    <React.Fragment>

                        <Tooltip title="remove">
                            <CloseRoundedIcon
                                onClick={props.onRemove}
                            />
                        </Tooltip>
                        &nbsp;&nbsp;
                        <Tooltip title="download">
                            {loading ? (
                                <CircularProgress size={16} color="primary" />
                            ) : (
                                <GetAppRoundedIcon
                                    className={styles.icon}
                                    onClick={() => {
                                        props.notPrivateDoc
                                            ? window.open(props.value)
                                            : getPrivateFileFromS3()
                                    }}
                                />
                            )}
                        </Tooltip>
                    </React.Fragment>}
                    &nbsp;&nbsp;&nbsp;
            </div>

            {props.error && <span className={styles.error}>Field cannot be empty</span>}
        </div>
    )
}
export default FileUploader;