import * as actionTypes from "./actionTypes";

const initialState = {
    showAlert: false,
    auth: false,
    images: false,
    basicUserList: false,
    basicServiceList: false,
    customerGroup: false,
    partnerAgreement: false,
    giftCards: false,
};
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.SET_GIFT_CARDS:
            return { ...state, giftCards: action.payload };

        case actionTypes.SET_PARTNER_AGREEMENTS:
            return { ...state, partnerAgreement: action.payload };

        case actionTypes.SET_CUSTOMER_GROUP:
            return { ...state, customerGroup: action.payload };
        case actionTypes.SET_BASIC_SERVICE_LIST:
            return { ...state, basicServiceList: action.payload };
        case actionTypes.SET_BASIC_USER_LIST:
            return { ...state, basicUserList: action.payload };
        case actionTypes.ADD_IMAGE:
            let imgs = state.images;
            if (imgs) imgs.unshift(action.payload);
            else imgs = [action.payload];

            return { ...state, images: [...imgs] };
        case actionTypes.SET_AUTH:
            return { ...state, auth: action.payload };
        case actionTypes.SHOW_ALERT:
            return { ...state, showAlert: action.payload };
        default:
            return state;
    }
};

export default reducer;
