import React from 'react'
import styles from './CancelComp.module.css'

import TextField from '@material-ui/core/TextField'
import Modal from '@material-ui/core/Modal' 

import {useLocation} from 'react-router-dom'
import {connect} from 'react-redux'
import {getAllBookings} from '../../../../../containers/serviceProvider/actions'
import {showAlert} from '../../../../../containers/app/actions'
import axios from 'axios'

import BtnLoader from '../../../../utils/BtnLoader/BtnLoader'


const CancelComp = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [loading,setLoading] = React.useState(false);
    const [reason,setReason] = React.useState("");
    const [error,setError] = React.useState(false)
    

    const onSubmit = () => {
        if(reason == "") {
            setError("Enter valid reason");
        } else {
            if(props.cancelBooking ){
        
            setLoading(true);
            axios({
                method: "put",
                url: `/dashboard/service-provider/cancelBooking?serviceProvider=${query.get("userId")}`,
                data: {
                    cancelReason: reason,
                    orderId: props.cancelBooking._id  
                }
            }).then(res => {
                setLoading(false);
                props.showAlert('Booking Cancelled Successfully')
                props.getAllBookings(query.get("userId"));
                props.onClose();
            }).catch(err => {
                if(err && err.response) 
                    props.showAlert(err.response.data.error)
                else 
                    props.showAlert("Something went wrong Try Again")

                setLoading(false);
            })
        }
        }
    }

    return (
        <Modal className={styles.modal} open={props.open} onClose={props.onClose}>
            <div className={styles.container}>
                <h1>Please include a message for your customers explaining the reason for your cancellation</h1>
                <TextField
                    multiline
                    className={styles.textField}
                    variant="outlined"
                    rows={5}
                    fullWidth

                    value={reason}
                    onChange={e => setReason(e.target.value)}

                    error={error}
                    helperText={error}
                    placeholder="Tips: Be polite and request for their understanding. Let them know what they can expect post-cancellation"
                /> 

                <div className="btnCenter">
                    {loading 
                        ? <BtnLoader className="btnGreen" />
                        : <button className="btnGreen" onClick={onSubmit}>SEND</button>
                    }   
                </div>
            </div>
        </Modal>
    )
}

export default connect(null, {getAllBookings, showAlert})(CancelComp);