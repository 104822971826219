import React from 'react'
import styles from './Checkout.module.css'

// import braintree from 'braintree-web-drop-in';
 
import Modal from '@material-ui/core/Modal'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'

import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import axios from 'axios';
import {connect} from 'react-redux'
import {getCartItems} from '../../../../../containers/endUser/actions' 

import {withRouter, useLocation} from 'react-router-dom'

const Checkout = (props) => { 
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [error,setError] = React.useState(false);
    const [success,setSuccess] = React.useState(false);
    const [loading,setLoading] = React.useState(false);
    
    React.useEffect(() => { 
        setLoading(true);
        let obj = {};
        props.cartItems && props.cartItems.forEach(val => {
            obj[val.serviceId._id] = val.quantity;
        }) 
        axios({
            method: "post",
            url: `/user/order/addOrderWithoutPayment?userId=${query.get("userId")}`,
            data: {
                cartItems: props.cartItems.map(val => val._id),
                discountItems: obj,
                discountCode: props.discountCode,
                billingAddress: props.billingAddress
            }
        }).then(res => {
            setLoading(false)
            setSuccess(true)  
            props.history.push(`/user/end-users/MY-BOOKINGS?userId=${query.get("userId")}`)
        }).catch(err => {
            setLoading(false)
            if(err && err.response) 
                setError(err.response.data.error)
            else 
                setError("Something went wrong Try Again")
        }) 
    },[])

    return (
        <div className={styles.container}>
            <Modal open={props.open} onClose={() => props.setOpen(false)} className={styles.modal}>
                <div className={styles.content}>

                    {loading &&
                        <div className={styles.displayContainer}>
                            <CircularProgress color="secondary" />
                            <p>Please Wait...</p>
                        </div>}

                    
                    {error &&
                        <div className={styles.displayContainer}>
                            <HighlightOffIcon style={{color: 'red',fontSize: '60px'}} />
                            <p>{error}</p>
                        </div>}

                    {success &&
                        <div className={styles.displayContainer}>
                            <CheckCircleOutlineIcon style={{color: 'green',fontSize: '60px'}} />
                            <p>Payment Successfull </p>
                            <p>Order Placed</p>
                        </div>}

                    <IconButton className={styles.close} onClick={() => props.setOpen(false)} disabled={loading}>
                        <CancelRoundedIcon />
                    </IconButton> 
                </div>
            </Modal>

        </div> 
    )
}

export default withRouter(connect(null, {getCartItems})(Checkout));