import React from 'react';
import PlacesAutocomplete,  {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import styles from './searchaddress.module.css' ;
 
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText'; 
import PinDropRoundedIcon from '@material-ui/icons/PinDropRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton'

class SearchAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: '' };
  } 
  handleChange = address => {
    this.props.setFormData("location",address) 
  };
 
  handleSelect = address => { 
    this.props.setFormData("location",address) 
    this.props.fetchZipcode && this.props.fetchZipcode(address) 
    this.props.needLatLng && this.handleGetGeoCode(address)
  };
  handleGetGeoCode = address => { 
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        this.props.setFormData("coordinates", {lat,lng})
      })
      .catch(err => {
        console.log(err);
      });
  };
  
  render() {
    let country = this.props.country ? this.props.country : false;
    const searchOptions = { 
      types: ['address'],
      componentRestrictions: {country: country.code ? country.code : "DE"}
    } 
    return (
      <PlacesAutocomplete
        value={this.props.value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={this.props.className} id={`${styles.div} ${this.props.color == "white" && styles.white}`} >
            <input
              
              {...getInputProps({
                placeholder: this.props.placeholder ? this.props.placeholder : 'Search Place or Area',
                className: `${this.props.error ? styles.searchInputError :styles.searchInput} ${this.props.size == "small" && styles.smallTextField}`,
                disabled: this.props.disabled
              })}
              style={{...this.props.style,backgroundColor: this.props.color ? this.props.color : 'var(--primary-bg-color)'}}
            />
            {this.props.error &&
              <span className={styles.error}>{this.props.helperText}</span>
            }
            <div className={`${styles.autoComplete} ${this.props.color == "white" && styles.whiteAutoComplete}`}>
              {loading && 
                <div className={styles.loader}>
                  <CircularProgress size={30} />
                </div>
              }

              {suggestions.map(suggestion => {  
                return ( 
                  <ListItem button onClick={() => console.log(suggestion)} {...getSuggestionItemProps(suggestion)} className={styles.listItem}>
                    <IconButton>
                      <PinDropRoundedIcon style={{color: "#333"}} />
                    </IconButton>
                    <ListItemText primary={suggestion.formattedSuggestion.mainText} secondary={suggestion.description} />
                  </ListItem>  
                );
              })} 
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default SearchAddress;