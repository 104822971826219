import React from "react";
import styles from "./Survey.module.css";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import AppLoader from "../../utils/AppLoader/AppLoader";
import { connect } from "react-redux";
import { getAllSurvey } from "../../../containers/endUser/actions";
import { withRouter } from "react-router-dom";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TablePagination from "@material-ui/core/TablePagination";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";

const Survey = (props) => {
    const [data, setData] = React.useState(props.survey);
    const [searchVal, setSearchVal] = React.useState("");
    const [dropDown, setDropDown] = React.useState("All");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const tableHead = props.survey && props.survey.length > 0 && Object.keys(props.survey[0]);

    const downloadCsv = () => {
        axios({
            method: "get",
            url: `/dashboard/user/getSurveyCsv?surveyName=${dropDown === "All" ? "" : dropDown}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Survey.csv");
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                console.log(err);
            });
    };
    React.useEffect(() => {
        if (!data) {
            props.getAllSurvey(rowsPerPage, page + 1, "");
            setData(props.survey);
        }
    }, [props.survey]);
    const handleChange = (event, newPage) => {
        props.getAllSurvey(rowsPerPage, newPage + 1, dropDown);
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        props.getAllSurvey(event.target.value, page + 1, dropDown);
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleDropChange = (value) => {
        props.getAllSurvey(rowsPerPage, page + 1, value);
        setPage(page);
    };

    return (
        <div className={styles.container}>
            <div className={styles.topHeader}>
                <h3>Survey</h3>
                <p>View and analyse survey results</p>

                <Select
                    style={{ marginTop: "1rem", width: "150px", borderRadius: "20px" }}
                    variant="outlined"
                    value={dropDown}
                    onChange={(e) => {
                        handleDropChange(e.target.value);
                        setDropDown(e.target.value);
                    }}
                >
                    <MenuItem value="All">All</MenuItem>
                    {props.dropDown && props.dropDown.map((val) => <MenuItem value={val}>{val}</MenuItem>)}
                </Select>
            </div>
            <div className={styles.header}>
                <div className={styles.leftHeader}>&nbsp;</div>

                <div className={styles.rightHeader}>
                    <TextField
                        placeholder="Search Here"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        className={styles.search}
                        value={searchVal}
                        onChange={(e) => setSearchVal(e.target.value)}
                    />
                    <button
                        disabled={!props.survey || (props.survey && props.survey.length < 1)}
                        onClick={() => downloadCsv()}
                    >
                        Export
                    </button>
                </div>
            </div>
            {!props.survey && <AppLoader color="primary" />}
            {props.survey && props.survey.length < 1 && <p>No Data Found</p>}
            <table className={styles.surveyTable}>
                <thead>
                    <tr>{tableHead && tableHead.map((key) => <th key={key}>{key}</th>)}</tr>
                </thead>
                <tbody>
                    {props.survey &&
                        props.survey.map((data, index) => {
                            if (data && data?.email?.toLowerCase().includes(searchVal.toLowerCase())) {
                                return (
                                    <tr key={index}>
                                        {tableHead.map((key) => (
                                            <td key={key}>{data[key]}</td>
                                        ))}
                                    </tr>
                                );
                            }
                        })}
                </tbody>
            </table>
            <div className={styles.flexEnd}>
                <TablePagination
                    component="div"
                    count={props.surveyCount}
                    page={page}
                    onChangePage={handleChange}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    survey: state.endUser.survey,
    surveyCount: state.endUser.surveyCount,
    dropDown: state.endUser.dropDown,
});
export default withRouter(connect(mapStateToProps, { getAllSurvey })(Survey));
