import React from 'react'
import styles from './TwoJourneyMap.module.css'

import {withRouter,useLocation} from 'react-router-dom'


const TwoJourneyMap = (props) => {   
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const journeyData = props.journeyData;
    const category = props.journeyData.category;
    const doGood = props.journeyData.doGood;
    const displayInfo1 =journeyData.subCategoryList.length > 0 ? journeyData.subCategoryList[0] : false;
    const displayInfo2 =journeyData.subCategoryList.length > 1 ? journeyData.subCategoryList[1] : false;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="100%" height="auto" viewBox="0 0 1311.351 959.031">
            <defs>
                <filter id="Path_3" x="1157.351" y="786.149" width="123.358" height="53.779" filterUnits="userSpaceOnUse">
                    <feOffset dy={3} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={3} result="blur" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="Path_241" x="131.999" y={82} width="1040.656" height="750.595" filterUnits="userSpaceOnUse">
                    <feOffset dy={3} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={6} result="blur-2" />
                    <feFlood floodColor="#2d4141" floodOpacity="0.89" />
                    <feComposite operator="in" in2="blur-2" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <clipPath id="clip-path">
                    <path id="Path_314" data-name="Path 314" d="M557.038,562.35V488.161H491.164V562.35" transform="translate(-491.164 -488.161)" fill="#ffd719" />
                </clipPath>
                <clipPath id="clip-path-2">
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_3)">
                        <path id="Path_3-2" data-name="Path 3" d="M0,9.017H105.359V-26.762H0Z" transform="translate(-5.28 20.86)" fill="#feead7" />
                    </g>
                </clipPath>
                <filter id="Path_4" x="1224.773" y="794.651" width="47.782" height="33.443" filterUnits="userSpaceOnUse">
                    <feOffset dy={3} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={3} result="blur-3" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur-3" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="Path_1" x="1203.573" y="792.05" width="36.728" height="39.092" filterUnits="userSpaceOnUse">
                    <feOffset dy={3} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={3} result="blur-4" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur-4" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="Path_2" x="1162.634" y="794.386" width="57.002" height="33.711" filterUnits="userSpaceOnUse">
                    <feOffset dy={3} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={3} result="blur-5" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="blur-5" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="Challenge" x="460.717" y="123.746" width={300} height={61} filterUnits="userSpaceOnUse">
                    <feOffset dx={5} dy={5} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={6} result="blur-6" />
                    <feFlood floodOpacity="0.655" />
                    <feComposite operator="in" in2="blur-6" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="Positive_Affirmations" x="488.717" y="169.748" width={300} height={74} filterUnits="userSpaceOnUse">
                    <feOffset dx={5} dy={5} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={6} result="blur-7" />
                    <feFlood floodColor="#2d4141" floodOpacity="0.847" />
                    <feComposite operator="in" in2="blur-7" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="Challenge-2" x="460.717" y="478.043" width={300} height={61} filterUnits="userSpaceOnUse">
                    <feOffset dx={5} dy={5} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={6} result="blur-8" />
                    <feFlood floodOpacity="0.655" />
                    <feComposite operator="in" in2="blur-8" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="Positive_Affirmations-2" x="488.717" y="524.045" width={300} height={74} filterUnits="userSpaceOnUse">
                    <feOffset dx={5} dy={5} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={6} result="blur-9" />
                    <feFlood floodColor="#2d4141" floodOpacity="0.847" />
                    <feComposite operator="in" in2="blur-9" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="ARTICLE" x="841.717" y="123.746" width={300} height={61} filterUnits="userSpaceOnUse">
                    <feOffset dx={5} dy={5} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={6} result="blur-10" />
                    <feFlood floodOpacity="0.655" />
                    <feComposite operator="in" in2="blur-10" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="What_is_Mindfulness_" x="847.12" y="169.75" width={300} height={74} filterUnits="userSpaceOnUse">
                    <feOffset dx={5} dy={5} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={6} result="blur-11" />
                    <feFlood floodColor="#2d4141" floodOpacity="0.847" />
                    <feComposite operator="in" in2="blur-11" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="ARTICLE-2" x="187.725" y="520.043" width={300} height={61} filterUnits="userSpaceOnUse">
                    <feOffset dx={5} dy={5} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={6} result="blur-12" />
                    <feFlood floodOpacity="0.655" />
                    <feComposite operator="in" in2="blur-12" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="What_is_Mindfulness_2" x="193.127" y="566.047" width={300} height={74} filterUnits="userSpaceOnUse">
                    <feOffset dx={5} dy={5} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={6} result="blur-13" />
                    <feFlood floodColor="#2d4141" floodOpacity="0.847" />
                    <feComposite operator="in" in2="blur-13" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="ACTIVITY" x="1161.351" y="231.375" width={300} height={61} filterUnits="userSpaceOnUse">
                    <feOffset dx={5} dy={5} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={6} result="blur-14" />
                    <feFlood floodOpacity="0.655" />
                    <feComposite operator="in" in2="blur-14" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="Plan_a_Spiritual_Fulfilment_Activity" x="1153.351" y="276.119" width={300} height={74} filterUnits="userSpaceOnUse">
                    <feOffset dx={5} dy={5} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={6} result="blur-15" />
                    <feFlood floodColor="#2d4141" floodOpacity="0.847" />
                    <feComposite operator="in" in2="blur-15" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="ACTIVITY-2" x="183.725" y="711.033" width={300} height={61} filterUnits="userSpaceOnUse">
                    <feOffset dx={5} dy={5} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={6} result="blur-16" />
                    <feFlood floodOpacity="0.655" />
                    <feComposite operator="in" in2="blur-16" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="Plan_a_Spiritual_Fulfilment_Activity-2" x="175.725" y="755.777" width={300} height={74} filterUnits="userSpaceOnUse">
                    <feOffset dx={5} dy={5} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={6} result="blur-17" />
                    <feFlood floodColor="#2d4141" floodOpacity="0.847" />
                    <feComposite operator="in" in2="blur-17" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="QUOTE" x="849.639" y="478.043" width={300} height={61} filterUnits="userSpaceOnUse">
                    <feOffset dx={5} dy={5} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={6} result="blur-18" />
                    <feFlood floodOpacity="0.655" />
                    <feComposite operator="in" in2="blur-18" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="_Wherever_you_are_be_all_there_" x="826.717" y="524.045" width={300} height={74} filterUnits="userSpaceOnUse">
                    <feOffset dx={5} dy={5} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={6} result="blur-19" />
                    <feFlood floodColor="#2d4141" floodOpacity="0.847" />
                    <feComposite operator="in" in2="blur-19" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="QUOTE-2" x="489.639" y="832.34" width={300} height={61} filterUnits="userSpaceOnUse">
                    <feOffset dx={5} dy={5} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={6} result="blur-20" />
                    <feFlood floodOpacity="0.655" />
                    <feComposite operator="in" in2="blur-20" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="_Wherever_you_are_be_all_there_2" x="466.717" y="878.342" width={300} height={74} filterUnits="userSpaceOnUse">
                    <feOffset dx={5} dy={5} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={6} result="blur-21" />
                    <feFlood floodColor="#2d4141" floodOpacity="0.847" />
                    <feComposite operator="in" in2="blur-21" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="DO_GOOD" x="833.717" y="839.029" width={300} height={61} filterUnits="userSpaceOnUse">
                    <feOffset dx={5} dy={5} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={6} result="blur-22" />
                    <feFlood floodOpacity="0.655" />
                    <feComposite operator="in" in2="blur-22" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="_1_Week_Vegan_Challenge" x="843.717" y="885.031" width={300} height={74} filterUnits="userSpaceOnUse">
                    <feOffset dx={5} dy={5} input="SourceAlpha" />
                    <feGaussianBlur stdDeviation={6} result="blur-23" />
                    <feFlood floodColor="#2d4141" floodOpacity="0.847" />
                    <feComposite operator="in" in2="blur-23" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g id="Group_4837" data-name="Group 4837" transform="translate(-7126.283 16509.031)">
                <g transform="matrix(1, 0, 0, 1, 7126.28, -16509.03)" filter="url(#Path_241)">
                    <path id="Path_241-2" data-name="Path 241" d="M-172.216,16H647.351c47.954,0,92.94,21.025,120.716,56.419h0c17.807,22.69,27.375,49.836,27.375,77.672v67.754c0,82.858-74.182,150.028-165.691,150.028H184.206c-91.509,0-165.691,67.17-165.691,150.027v73.253h0c0,73.7,65.956,133.443,147.349,133.443H832.439" transform="translate(322.21 84)" fill="none" stroke="rgba(254,234,215,0.89)" strokeLinejoin="round" strokeWidth={6} strokeDasharray="8.07 8.07" />
                </g>
                <g id="Group_768" data-name="Group 768" transform="translate(6771.282 -17133.031)">
                    <g id="Group_536" data-name="Group 536" transform="translate(414.187 624)">
                    <foreignObject transform="translate(5.908 5)" width="160" height="200">
                        <div className={styles.imgContainer}>
                            <img id="Path_454" data-name="Path 454" transform="translate(5.908 5)" src={category ? category.categoryImage : ""} />
                        </div>
                    </foreignObject>
                    </g>
                </g>
                <path id="Path_2108" data-name="Path 2108" d="M16.708,0A16.708,16.708,0,1,1,0,16.708,16.708,16.708,0,0,1,16.708,0Z" transform="translate(7138.063 -16421.313)" fill="#2d4141" />
                <g id="Group_4774" data-name="Group 4774" transform="translate(6803.282 -17133.031)">
                    <g id="Group_436" data-name="Group 436" transform="translate(333.632 710.428)">
                        <path id="Path_279" data-name="Path 279" d="M18.366,0q-.126,0-.252,0a18.113,18.113,0,0,0-.252,36.224q.126,0,.252,0A18.113,18.113,0,0,0,18.366,0Zm-.252,16.642a9.8,9.8,0,0,0-9.791,9.791q0,.128,0,.256c0,.028,0,.056,0,.084,0,.057,0,.113.007.17,0,.035,0,.07.007.1s.006.1.01.148.007.075.01.113.008.092.013.138.009.077.013.116.01.089.016.133.011.079.017.118.012.086.019.129.013.079.02.119.014.084.022.126.015.079.023.119.016.083.025.124.017.079.026.118.018.082.028.123.019.079.029.118.02.08.03.121.021.078.032.117.022.08.033.12.023.076.034.114.024.08.037.12.024.075.037.113.026.08.04.119l.039.111c.014.04.028.079.043.119l.041.108.047.119.043.105.05.119.02.047A15.172,15.172,0,0,1,18.114,2.937a6.854,6.854,0,1,1,0,13.708Z" transform="translate(0 0)" fill="#ffd719" />
                    </g>
                    <g id="Group_524" data-name="Group 524" transform="translate(323.001 692)" clipPath="url(#clip-path)">
                        <path id="Path_313" data-name="Path 313" d="M526.416,506.566l1.817-5a27.616,27.616,0,0,0-18.89,51.9l1.819-4.995a22.3,22.3,0,0,1,15.254-41.911" transform="translate(-491.163 -491.581)" fill="#ffd719" />
                    </g>
                </g>
                <g id="Path_2088" data-name="Path 2088" transform="translate(7129.353 -17204.129)" fill="#2d4141">
                    <path d="M 587.1010131835938 865.9063110351562 L 491.2186889648438 865.9063110351562 L 494.1929016113281 843.7783813476562 L 590.0751953125 843.7783813476562 L 587.1010131835938 865.9063110351562 Z" stroke="none" />
                    <path d="M 586.6637573242188 865.4063110351562 L 589.5035400390625 844.2783813476562 L 494.6301879882812 844.2783813476562 L 491.7903747558594 865.4063110351562 L 586.6637573242188 865.4063110351562 M 587.538330078125 866.4063110351562 L 490.64697265625 866.4063110351562 L 493.755615234375 843.2783813476562 L 590.6469116210938 843.2783813476562 L 587.538330078125 866.4063110351562 Z" stroke="none" fill="#707070" />
                </g>
                <g id="Group_5" data-name="Group 5" transform="translate(8297.916 -15710.981)">
                    <g id="Group_4" data-name="Group 4" transform="translate(62.14 2.602)">
                        <g transform="matrix(1, 0, 0, 1, -1233.77, -800.65)" filter="url(#Path_4)">
                            <path id="Path_4-2" data-name="Path 4" d="M-4.793,0V15.443H7.164V12.575H-1.242V9h7.17V6.221h-7.17V2.868H6.877V0Zm-7.191,0V9.376L-19.662,0h-2.956V15.443h3.53V6.068l7.7,9.376h2.933V0Z" transform="translate(1256.39 800.65)" fill="#feead7" />
                        </g>
                        <g id="Group_1" data-name="Group 1" transform="translate(-21.2 -2.602)">
                            <g transform="matrix(1, 0, 0, 1, -1212.57, -798.05)" filter="url(#Path_1)">
                                <path id="Path_1-2" data-name="Path 1" d="M-3.111,0-4.145,2.84A7.85,7.85,0,0,1,.547,12.9,7.85,7.85,0,0,1-9.515,17.593l-1.033,2.84a10.872,10.872,0,0,0,13.935-6.5A10.872,10.872,0,0,0-3.111,0m-1.55,4.259.516-1.42A7.86,7.86,0,0,0-14.206,7.532a7.8,7.8,0,0,0,.267,6,7.792,7.792,0,0,0,4.427,4.06L-9,16.174a6.3,6.3,0,0,1-3.574-3.279,6.3,6.3,0,0,1-.214-4.846A6.347,6.347,0,0,1-4.661,4.259" transform="translate(1227.25 798.05)" fill="#feead7" />
                            </g>
                        </g>
                        <g transform="matrix(1, 0, 0, 1, -1233.77, -800.65)" filter="url(#Path_2)">
                            <path id="Path_2-2" data-name="Path 2" d="M-1.541-3.442,2.013-6.8A9.077,9.077,0,0,0,4.064-9.31a5.384,5.384,0,0,0,.529-2.358,4.109,4.109,0,0,0-.719-2.417,4.641,4.641,0,0,0-2.007-1.588,7.482,7.482,0,0,0-3.011-.563,8.324,8.324,0,0,0-3.64.761,6.107,6.107,0,0,0-2.492,2.106l2.6,1.677A3.506,3.506,0,0,1-3.3-12.848a4.233,4.233,0,0,1,1.831-.386,2.923,2.923,0,0,1,1.84.494A1.707,1.707,0,0,1,1-11.317a2.826,2.826,0,0,1-.331,1.29A6.323,6.323,0,0,1-.612-8.471L-6.57-2.846V-.53H5.1V-3.442Zm7.413-12.53v2.865H8.96V-.53h3.573V-15.972ZM16.194-.53h7.017a9.862,9.862,0,0,0,4.445-.96,7.186,7.186,0,0,0,3-2.7,7.6,7.6,0,0,0,1.068-4.059,7.6,7.6,0,0,0-1.068-4.06,7.19,7.19,0,0,0-3-2.7,9.862,9.862,0,0,0-4.445-.96H16.2Zm3.574-2.934v-9.575h3.265a5.189,5.189,0,0,1,3.7,1.29,4.558,4.558,0,0,1,1.379,3.5,4.557,4.557,0,0,1-1.379,3.5,5.189,5.189,0,0,1-3.7,1.29Z" transform="translate(1178.91 816.62)" fill="#feead7" />
                        </g>
                    </g>
                </g>
                <g id="Group_4786" data-name="Group 4786" transform="translate(7517.43 -16954.914)">
                    <g id="Ellipse_56" data-name="Ellipse 56" transform="translate(487.57 521.07)" fill="#2d4141" stroke="#feead7" strokeWidth={2}>
                        <circle cx="24.813" cy="24.813" r="24.813" stroke="none" />
                        <circle cx="24.813" cy="24.813" r="23.813" fill="none" />
                    </g>
                    <path id="Vector" d="M15.81,1.141A2.894,2.894,0,0,0,13.762.29H3.421A2.893,2.893,0,0,0,.536,3.175V26.252a2.881,2.881,0,0,0,2.87,2.885H20.729a2.893,2.893,0,0,0,2.885-2.885V10.141a2.836,2.836,0,0,0-.851-2.034ZM16.4,23.367H7.748a1.442,1.442,0,1,1,0-2.885H16.4a1.442,1.442,0,1,1,0,2.885Zm0-5.769H7.748a1.442,1.442,0,0,1,0-2.885H16.4a1.442,1.442,0,0,1,0,2.885ZM13.517,8.944V2.454l7.933,7.933h-6.49A1.447,1.447,0,0,1,13.517,8.944Z" transform="translate(499.934 530.705)" fill="#ffd719" />
                </g>
                <g id="Group_4831" data-name="Group 4831" transform="translate(6958.711 -16497.059)">
                    <g id="Ellipse_56-2" data-name="Ellipse 56" transform="translate(487.57 521.07)" fill="#2d4141" stroke="#feead7" strokeWidth={2}>
                        <circle cx="24.813" cy="24.813" r="24.813" stroke="none" />
                        <circle cx="24.813" cy="24.813" r="23.813" fill="none" />
                    </g>
                    <path id="Vector-2" data-name="Vector" d="M15.81,1.141A2.894,2.894,0,0,0,13.762.29H3.421A2.893,2.893,0,0,0,.536,3.175V26.252a2.881,2.881,0,0,0,2.87,2.885H20.729a2.893,2.893,0,0,0,2.885-2.885V10.141a2.836,2.836,0,0,0-.851-2.034ZM16.4,23.367H7.748a1.442,1.442,0,1,1,0-2.885H16.4a1.442,1.442,0,1,1,0,2.885Zm0-5.769H7.748a1.442,1.442,0,0,1,0-2.885H16.4a1.442,1.442,0,0,1,0,2.885ZM13.517,8.944V2.454l7.933,7.933h-6.49A1.447,1.447,0,0,1,13.517,8.944Z" transform="translate(499.934 530.705)" fill="#ffd719" />
                </g>
                <g id="Group_4825" data-name="Group 4825" transform="translate(7730.228 -16769.168)">
                    <g id="Ellipse_56-3" data-name="Ellipse 56" transform="translate(1 -1)" fill="#2d4141" stroke="#feead7" strokeWidth={2}>
                        <foreignObject  transform="translate(487.57 521.07)" width="80" height="80">
                            <div className={styles.iconContent}>
                                <img src={category ? category.coverImage : ""} alt=""/>
                            </div>
                        </foreignObject> 
                    </g>
                </g>
                <g id="Group_4833" data-name="Group 4833" transform="translate(6980.898 -16306.07)">
                    <g id="Ellipse_56-4" data-name="Ellipse 56" transform="translate(1 -1)" fill="#2d4141" stroke="#feead7" strokeWidth={2}>
                        <foreignObject   transform="translate(487.57 521.07)" width="80" height="80">
                            <div className={styles.iconContent}>
                                <img src={category ? category.coverImage : ""} alt=""/>
                            </div>
                        </foreignObject>
                    </g>
                </g>
                <g id="Group_4789" data-name="Group 4789" transform="translate(6916.43 -16576.617)">
                    <g id="Ellipse_59" data-name="Ellipse 59" transform="translate(1088.57 497.07)" fill="#2d4141" stroke="#feead7" strokeWidth={2}>
                        <circle cx="24.813" cy="24.813" r="24.813" stroke="none" />
                        <circle cx="24.813" cy="24.813" r="23.813" fill="none" />
                    </g>
                    <g id="Group_4606" data-name="Group 4606" transform="translate(1097.409 510.368)">
                        <path id="Vector-1" d="M7.516,23.711a3.173,3.173,0,0,0,2.845-1.755l3.367-6.734a4.681,4.681,0,0,0,.5-2.11V2.371A2.378,2.378,0,0,0,11.856,0H2.371A2.378,2.378,0,0,0,0,2.371v9.484a2.378,2.378,0,0,0,2.371,2.371H7.113L4.671,19.111A3.174,3.174,0,0,0,7.516,23.711Z" transform="translate(0 0)" fill="#ffd719" />
                        <path id="Vector-1-2" data-name="Vector-1" d="M7.516,23.711a3.173,3.173,0,0,0,2.845-1.755l3.367-6.734a4.681,4.681,0,0,0,.5-2.11V2.371A2.378,2.378,0,0,0,11.856,0H2.371A2.378,2.378,0,0,0,0,2.371v9.484a2.378,2.378,0,0,0,2.371,2.371H7.113L4.671,19.111A3.174,3.174,0,0,0,7.516,23.711Z" transform="translate(17.724 0)" fill="#ffd719" />
                    </g>
                </g>
                <g id="Group_4834" data-name="Group 4834" transform="translate(6556.43 -16222.32)">
                    <g id="Ellipse_59-2" data-name="Ellipse 59" transform="translate(1088.57 497.07)" fill="#2d4141" stroke="#feead7" strokeWidth={2}>
                        <circle cx="24.813" cy="24.813" r="24.813" stroke="none" />
                        <circle cx="24.813" cy="24.813" r="23.813" fill="none" />
                    </g>
                    <g id="Group_4606-2" data-name="Group 4606" transform="translate(1097.409 510.368)">
                        <path id="Vector-1-3" data-name="Vector-1" d="M7.516,23.711a3.173,3.173,0,0,0,2.845-1.755l3.367-6.734a4.681,4.681,0,0,0,.5-2.11V2.371A2.378,2.378,0,0,0,11.856,0H2.371A2.378,2.378,0,0,0,0,2.371v9.484a2.378,2.378,0,0,0,2.371,2.371H7.113L4.671,19.111A3.174,3.174,0,0,0,7.516,23.711Z" transform="translate(0 0)" fill="#ffd719" />
                        <path id="Vector-1-4" data-name="Vector-1" d="M7.516,23.711a3.173,3.173,0,0,0,2.845-1.755l3.367-6.734a4.681,4.681,0,0,0,.5-2.11V2.371A2.378,2.378,0,0,0,11.856,0H2.371A2.378,2.378,0,0,0,0,2.371v9.484a2.378,2.378,0,0,0,2.371,2.371H7.113L4.671,19.111A3.174,3.174,0,0,0,7.516,23.711Z" transform="translate(17.724 0)" fill="#ffd719" />
                    </g>
                </g>
                <g id="Group_4787" data-name="Group 4787" transform="translate(7035.43 -16909.914)">
                    <g id="Ellipse_57" data-name="Ellipse 57" transform="translate(609.57 476.07)" fill="#2d4141" stroke="#feead7" strokeWidth={2}>
                        <circle cx="24.813" cy="24.813" r="24.813" stroke="none" />
                        <circle cx="24.813" cy="24.813" r="23.813" fill="none" />
                    </g>
                    <path id="Group" d="M21.587,7.579,14.821.812A2.8,2.8,0,0,0,12.846,0H2.8A2.8,2.8,0,0,0,.014,2.8L0,25.215a2.8,2.8,0,0,0,2.788,2.8H19.612a2.81,2.81,0,0,0,2.8-2.8V9.568A2.81,2.81,0,0,0,21.587,7.579ZM8.727,21.419l-2.97-2.97a1.4,1.4,0,0,1,1.975-1.975l1.975,1.975,4.959-4.959a1.4,1.4,0,0,1,1.975,1.975L10.7,21.4A1.383,1.383,0,0,1,8.727,21.419ZM14.008,9.806a1.4,1.4,0,0,1-1.4-1.4V2.1l7.7,7.7Z" transform="translate(623.177 486.875)" fill="#ffd719" />
                </g>
                <g id="Group_4829" data-name="Group 4829" transform="translate(7035.43 -16555.617)">
                    <g id="Ellipse_57-2" data-name="Ellipse 57" transform="translate(609.57 476.07)" fill="#2d4141" stroke="#feead7" strokeWidth={2}>
                        <circle cx="24.813" cy="24.813" r="24.813" stroke="none" />
                        <circle cx="24.813" cy="24.813" r="23.813" fill="none" />
                    </g>
                    <path id="Group-2" data-name="Group" d="M21.587,7.579,14.821.812A2.8,2.8,0,0,0,12.846,0H2.8A2.8,2.8,0,0,0,.014,2.8L0,25.215a2.8,2.8,0,0,0,2.788,2.8H19.612a2.81,2.81,0,0,0,2.8-2.8V9.568A2.81,2.81,0,0,0,21.587,7.579ZM8.727,21.419l-2.97-2.97a1.4,1.4,0,0,1,1.975-1.975l1.975,1.975,4.959-4.959a1.4,1.4,0,0,1,1.975,1.975L10.7,21.4A1.383,1.383,0,0,1,8.727,21.419ZM14.008,9.806a1.4,1.4,0,0,1-1.4-1.4V2.1l7.7,7.7Z" transform="translate(623.177 486.875)" fill="#ffd719" />
                </g>
                <g id="Group_4795" data-name="Group 4795" transform="translate(345 167.971)">
                    <g transform="matrix(1, 0, 0, 1, 6781.28, -16677)" filter="url(#Challenge)">
                        <text id="Challenge-3" data-name="Challenge" transform="translate(543.72 156.75)" fill="#feead7" fontSize={21} fontFamily="Montserrat-Bold, Montserrat" fontWeight={700} letterSpacing="0.025em"><tspan x="-69.678" y={0}>CHALLENGE</tspan></text>
                    </g>
                    <g transform="matrix(1, 0, 0, 1, 6670.28, -16697)" filter="url(#Positive_Affirmations)">
                        <foreignObject transform="translate(543.72 196.75)" width="250" height="180">
                            <div className={styles.textContent} >
                                <p>
                                    {displayInfo1 ? (displayInfo1.challenge ? displayInfo1.challenge.title : "Not Available") : "Not Available"}
                                </p>
                            </div>
                        </foreignObject> 
                    </g>
                </g>
                <g id="Group_4828" data-name="Group 4828" transform="translate(345 522.268)"> 
                    <g id="Path_2092" data-name="Path 2092" transform="matrix(1, 0, 0, 1, 6781.28, -17370.3)" fill="#2d4141"><path d="M 587.1010131835938 865.9063110351562 L 491.2186889648438 865.9063110351562 L 494.1929016113281 843.7783813476562 L 590.0751953125 843.7783813476562 L 587.1010131835938 865.9063110351562 Z" stroke="none"></path><path d="M 586.6637573242188 865.4063110351562 L 589.5035400390625 844.2783813476562 L 494.6301879882812 844.2783813476562 L 491.7903747558594 865.4063110351562 L 586.6637573242188 865.4063110351562 M 587.538330078125 866.4063110351562 L 490.64697265625 866.4063110351562 L 493.755615234375 843.2783813476562 L 590.6469116210938 843.2783813476562 L 587.538330078125 866.4063110351562 Z" stroke="none" fill="#707070"></path></g>
                    <g transform="matrix(1, 0, 0, 1, 6781.28, -17031.3)" filter="url(#Challenge-2)">
                        <text id="Challenge-4" data-name="Challenge" transform="translate(543.72 511.04)" fill="#feead7" fontSize={21} fontFamily="Montserrat-Bold, Montserrat" fontWeight={700} letterSpacing="0.025em"><tspan x="-69.678" y={0}>CHALLENGE</tspan></text>
                    </g>
                    <g transform="matrix(1, 0, 0, 1, 6671.28, -17049.3)" filter="url(#Positive_Affirmations-2)">
                        <foreignObject transform="translate(543.72 551.04)" width="250" height="180">
                            <div className={styles.textContent} >
                                <p>
                                    {displayInfo2 ? (displayInfo2.challenge ? displayInfo2.challenge.title : "Not Available") : "Not Available"}
                                </p>
                            </div>
                        </foreignObject> 
                    </g>
                </g>
                <g id="Group_4824" data-name="Group 4824" transform="translate(1329.643 -3078.598)">
                    <g id="Path_2089" data-name="Path 2089" transform="translate(6159.71 -14124.092)" fill="#2d4141">
                        <path d="M 587.1010131835938 865.9063110351562 L 491.2186889648438 865.9063110351562 L 494.1929016113281 843.7783813476562 L 590.0751953125 843.7783813476562 L 587.1010131835938 865.9063110351562 Z" stroke="none" />
                        <path d="M 586.6637573242188 865.4063110351562 L 589.5035400390625 844.2783813476562 L 494.6301879882812 844.2783813476562 L 491.7903747558594 865.4063110351562 L 586.6637573242188 865.4063110351562 M 587.538330078125 866.4063110351562 L 490.64697265625 866.4063110351562 L 493.755615234375 843.2783813476562 L 590.6469116210938 843.2783813476562 L 587.538330078125 866.4063110351562 Z" stroke="none" fill="#707070" />
                    </g>
                    <g id="Group_4796" data-name="Group 4796" transform="translate(2 3390)">
                        <g transform="matrix(1, 0, 0, 1, 5794.64, -16820.43)" filter="url(#ARTICLE)">
                            <text id="ARTICLE-3" data-name="ARTICLE" transform="translate(903.72 156.75)" fill="#feead7" fontSize={21} fontFamily="Montserrat-Bold, Montserrat" fontWeight={700} letterSpacing="0.025em"><tspan x="-48.248" y={0}>ARTICLE</tspan></text>
                        </g>
                        <g transform="matrix(1, 0, 0, 1, 5684.64, -16838.43)" filter="url(#What_is_Mindfulness_)"> 
                            <foreignObject transform="translate(904.12 196.75)" width="250" height="180">
                                <div className={styles.textContent} >
                                    <p>
                                        {displayInfo1 ? (displayInfo1.content ? displayInfo1.content.title : "Not Available") : "Not Available"}
                                    </p>
                                </div>
                            </foreignObject> 
                        </g>
                    </g>
                </g>
                <g id="Group_4830" data-name="Group 4830" transform="translate(675.65 -2682.301)">
                    <g id="Path_2089-2" data-name="Path 2089" transform="translate(6159.71 -14124.092)" fill="#2d4141">
                        <path d="M 587.1010131835938 865.9063110351562 L 491.2186889648438 865.9063110351562 L 494.1929016113281 843.7783813476562 L 590.0751953125 843.7783813476562 L 587.1010131835938 865.9063110351562 Z" stroke="none" />
                        <path d="M 586.6637573242188 865.4063110351562 L 589.5035400390625 844.2783813476562 L 494.6301879882812 844.2783813476562 L 491.7903747558594 865.4063110351562 L 586.6637573242188 865.4063110351562 M 587.538330078125 866.4063110351562 L 490.64697265625 866.4063110351562 L 493.755615234375 843.2783813476562 L 590.6469116210938 843.2783813476562 L 587.538330078125 866.4063110351562 Z" stroke="none" fill="#707070" />
                    </g>
                    <g id="Group_4796-2" data-name="Group 4796" transform="translate(2 3390)">
                        <g transform="matrix(1, 0, 0, 1, 6448.63, -17216.73)" filter="url(#ARTICLE-2)">
                            <text id="ARTICLE-4" data-name="ARTICLE" transform="translate(249.72 553.04)" fill="#feead7" fontSize={21} fontFamily="Montserrat-Bold, Montserrat" fontWeight={700} letterSpacing="0.025em"><tspan x="-48.248" y={0}>ARTICLE</tspan></text>
                        </g>
                        <g transform="matrix(1, 0, 0, 1, 6328.63, -17216.73)" filter="url(#What_is_Mindfulness_2)">
                            <foreignObject transform="translate(250.13 593.05)" width="250" height="180">
                                <div className={styles.textContent}>
                                    <p >
                                        {displayInfo2 ? (displayInfo2.content ? displayInfo2.content.title : "Not Available") : "Not Available"}
                                    </p>
                                </div>
                            </foreignObject> 
                        </g>
                    </g>
                </g>
                <g id="Group_4826" data-name="Group 4826" transform="translate(1888.083 -3189.286)">
                    <g id="Path_2090" data-name="Path 2090" transform="translate(5905.903 -13866.66)" fill="#2d4141">
                        <path d="M 587.1010131835938 865.9063110351562 L 491.2186889648438 865.9063110351562 L 494.1929016113281 843.7783813476562 L 590.0751953125 843.7783813476562 L 587.1010131835938 865.9063110351562 Z" stroke="none" />
                        <path d="M 586.6637573242188 865.4063110351562 L 589.5035400390625 844.2783813476562 L 494.6301879882812 844.2783813476562 L 491.7903747558594 865.4063110351562 L 586.6637573242188 865.4063110351562 M 587.538330078125 866.4063110351562 L 490.64697265625 866.4063110351562 L 493.755615234375 843.2783813476562 L 590.6469116210938 843.2783813476562 L 587.538330078125 866.4063110351562 Z" stroke="none" fill="#707070" />
                    </g>
                    <g id="Group_4797" data-name="Group 4797" transform="translate(-22.813 3351)">
                        <g transform="matrix(1, 0, 0, 1, 5244.01, -16632.75)" filter="url(#ACTIVITY)">
                            <text id="ACTIVITY-3" data-name="ACTIVITY" transform="translate(1227.35 264.38)" fill="#feead7" fontSize={21} fontFamily="Montserrat-Bold, Montserrat" fontWeight={700} letterSpacing="0.025em"><tspan x="-51.86" y={0}>ACTIVITY</tspan></text>
                        </g>
                        <g transform="matrix(1, 0, 0, 1, 5154.01, -16632.75)" filter="url(#Plan_a_Spiritual_Fulfilment_Activity)">
                            <foreignObject transform="translate(1166.35 289.12)" width="250" height="180">
                                <div className={styles.textContent}
                                    onClick={() => {
                                        props.redirect && !displayInfo1.order && category && 
                                        props.history.push(`/user/end-users/SERVICE-LIST?journeyId=${query.get("journeyId")}&userId=${query.get("userId")}&journeyType=${query.get("journeyType")}&categoryId=${category._id}${displayInfo1.subCategory ? "&subCategoryId=" + displayInfo1.subCategory._id : ""}&journeyIndex=${displayInfo1._id}`)
                                    }} 
                                >
                                    <p>
                                        {displayInfo1.order 
                                            ?
                                        <React.Fragment>
                                            {displayInfo1.order.serviceId && displayInfo1.order.serviceId.eventName} 
                                            <br/>
                                            {displayInfo1.order.timeSlot && displayInfo1.order.timeSlot.startDate} 
                                        </React.Fragment>
                                            :
                                        <React.Fragment>
                                            Plan a {displayInfo1.subCategory && displayInfo1.subCategory.subCategoryName} Activity
                                        </React.Fragment>}
                                    </p>
                                </div>
                            </foreignObject> 
                        </g>
                    </g>
                </g>
                <g id="Group_4832" data-name="Group 4832" transform="translate(927.457 -2747.628)">
                    <g id="Path_2090-2" data-name="Path 2090" transform="translate(5905.903 -13866.66)" fill="#2d4141">
                        <path d="M 587.1010131835938 865.9063110351562 L 491.2186889648438 865.9063110351562 L 494.1929016113281 843.7783813476562 L 590.0751953125 843.7783813476562 L 587.1010131835938 865.9063110351562 Z" stroke="none" />
                        <path d="M 586.6637573242188 865.4063110351562 L 589.5035400390625 844.2783813476562 L 494.6301879882812 844.2783813476562 L 491.7903747558594 865.4063110351562 L 586.6637573242188 865.4063110351562 M 587.538330078125 866.4063110351562 L 490.64697265625 866.4063110351562 L 493.755615234375 843.2783813476562 L 590.6469116210938 843.2783813476562 L 587.538330078125 866.4063110351562 Z" stroke="none" fill="#707070" />
                    </g>
                    <g id="Group_4797-2" data-name="Group 4797" transform="translate(-22.813 3351)">
                        <g transform="matrix(1, 0, 0, 1, 6221.64, -17112.4)" filter="url(#ACTIVITY-2)">
                            <text id="ACTIVITY-4" data-name="ACTIVITY" transform="translate(249.72 744.03)" fill="#feead7" fontSize={21} fontFamily="Montserrat-Bold, Montserrat" fontWeight={700} letterSpacing="0.025em"><tspan x="-51.86" y={0}>ACTIVITY</tspan></text>
                        </g>
                        <g transform="matrix(1, 0, 0, 1, 6148.64, -17100.4)" filter="url(#Plan_a_Spiritual_Fulfilment_Activity-2)">
                            <foreignObject transform="translate(188.72 768.78)" width="250" height="180">
                                <div className={styles.textContent}
                                    onClick={() => {
                                        props.redirect && !displayInfo2.order && category && 
                                        props.history.push(`/user/end-users/SERVICE-LIST?journeyId=${query.get("journeyId")}&userId=${query.get("userId")}&journeyType=${query.get("journeyType")}&categoryId=${category._id}${displayInfo2.subCategory ? "&subCategoryId=" + displayInfo2.subCategory._id : ""}&journeyIndex=${displayInfo2._id}`)
                                    }} 
                                >
                                    <p>
                                        {displayInfo2.order 
                                            ?
                                        <React.Fragment>
                                            {displayInfo2.order.serviceId && displayInfo2.order.serviceId.eventName} 
                                            <br/>
                                            {displayInfo2.order.timeSlot && displayInfo2.order.timeSlot.startDate} 
                                        </React.Fragment>
                                            :
                                        <React.Fragment>
                                            Plan a {displayInfo2.subCategory && displayInfo2.subCategory.subCategoryName} Activity
                                        </React.Fragment>}
                                    </p>
                                </div>
                            </foreignObject> 
                        </g>
                    </g>
                </g>
                <g id="Group_4827" data-name="Group 4827" transform="translate(459.389 -100.645)">
                    <g id="Path_2091" data-name="Path 2091" transform="translate(7029.964 -16747.373)" fill="#2d4141">
                        <path d="M 587.1010131835938 865.9063110351562 L 491.2186889648438 865.9063110351562 L 494.1929016113281 843.7783813476562 L 590.0751953125 843.7783813476562 L 587.1010131835938 865.9063110351562 Z" stroke="none" />
                        <path d="M 586.6637573242188 865.4063110351562 L 589.5035400390625 844.2783813476562 L 494.6301879882812 844.2783813476562 L 491.7903747558594 865.4063110351562 L 586.6637573242188 865.4063110351562 M 587.538330078125 866.4063110351562 L 490.64697265625 866.4063110351562 L 493.755615234375 843.2783813476562 L 590.6469116210938 843.2783813476562 L 587.538330078125 866.4063110351562 Z" stroke="none" fill="#707070" />
                    </g>
                    <g id="Group_4799" data-name="Group 4799" transform="translate(-533.389 622.912)">
                        <g transform="matrix(1, 0, 0, 1, 7200.28, -17031.3)" filter="url(#QUOTE)">
                            <text id="QUOTE-3" data-name="QUOTE" transform="translate(903.64 511.04)" fill="#feead7" fontSize={21} fontFamily="Montserrat-Bold, Montserrat" fontWeight={700} letterSpacing="0.025em"><tspan x="-40.478" y={0}>QUOTE</tspan></text>
                        </g>
                        <g transform="matrix(1, 0, 0, 1, 7080.28, -17031.3)" filter="url(#_Wherever_you_are_be_all_there_)"> 
                            <foreignObject  transform="translate(903.72 551.04)" width="250" height="180">
                                <div className={styles.textContent}>
                                    <p>
                                        {displayInfo1 ? (displayInfo1.quote ? displayInfo1.quote.title : "Not Available") : "Not Available"}
                                    </p>
                                </div>
                            </foreignObject> 
                        </g>
                    </g>
                </g>
                <g id="Group_4835" data-name="Group 4835" transform="translate(99.389 253.652)">
                    <g id="Path_2091-2" data-name="Path 2091" transform="translate(7029.964 -16747.373)" fill="#2d4141">
                        <path d="M 587.1010131835938 865.9063110351562 L 491.2186889648438 865.9063110351562 L 494.1929016113281 843.7783813476562 L 590.0751953125 843.7783813476562 L 587.1010131835938 865.9063110351562 Z" stroke="none" />
                        <path d="M 586.6637573242188 865.4063110351562 L 589.5035400390625 844.2783813476562 L 494.6301879882812 844.2783813476562 L 491.7903747558594 865.4063110351562 L 586.6637573242188 865.4063110351562 M 587.538330078125 866.4063110351562 L 490.64697265625 866.4063110351562 L 493.755615234375 843.2783813476562 L 590.6469116210938 843.2783813476562 L 587.538330078125 866.4063110351562 Z" stroke="none" fill="#707070" />
                    </g>
                    <g id="Group_4799-2" data-name="Group 4799" transform="translate(-533.389 622.912)">
                        <g transform="matrix(1, 0, 0, 1, 7560.28, -17385.6)" filter="url(#QUOTE-2)">
                            <text id="QUOTE-4" data-name="QUOTE" transform="translate(543.64 865.34)" fill="#feead7" fontSize={21} fontFamily="Montserrat-Bold, Montserrat" fontWeight={700} letterSpacing="0.025em"><tspan x="-40.478" y={0}>QUOTE</tspan></text>
                        </g>
                        <g transform="matrix(1, 0, 0, 1, 7440.28, -17385.6)" filter="url(#_Wherever_you_are_be_all_there_2)"> 
                            <foreignObject transform="translate(543.72 905.34)" width="250" height="180">
                                <div className={styles.textContent}>
                                    <p>
                                        {displayInfo2 ? (displayInfo2.quote ? displayInfo2.quote.title : "Not Available") : "Not Available"}
                                    </p>
                                </div>
                            </foreignObject> 
                        </g>
                    </g>
                </g>
                <g id="Group_4836" data-name="Group 4836" transform="translate(163.389)">
                    <g id="Path_2092" data-name="Path 2092" transform="translate(7325.964 -16487.844)" fill="#2d4141">
                        <path d="M 587.1010131835938 865.9063110351562 L 491.2186889648438 865.9063110351562 L 494.1929016113281 843.7783813476562 L 590.0751953125 843.7783813476562 L 587.1010131835938 865.9063110351562 Z" stroke="none" />
                        <path d="M 586.6637573242188 865.4063110351562 L 589.5035400390625 844.2783813476562 L 494.6301879882812 844.2783813476562 L 491.7903747558594 865.4063110351562 L 586.6637573242188 865.4063110351562 M 587.538330078125 866.4063110351562 L 490.64697265625 866.4063110351562 L 493.755615234375 843.2783813476562 L 590.6469116210938 843.2783813476562 L 587.538330078125 866.4063110351562 Z" stroke="none" fill="#707070" />
                    </g>
                    <g id="Group_4790" data-name="Group 4790" transform="translate(6490.228 -16222.32)">
                        <g id="Ellipse_60" data-name="Ellipse 60" transform="translate(1351.57 497.07)" fill="#2d4141" stroke="#feead7" strokeWidth={2}>
                            <circle cx="24.813" cy="24.813" r="24.813" stroke="none" />
                            <circle cx="24.813" cy="24.813" r="23.813" fill="none" />
                        </g>
                        <path id="public-24px_1" data-name="public-24px 1" d="M18.55,2.75a15.8,15.8,0,1,0,15.8,15.8A15.806,15.806,0,0,0,18.55,2.75ZM16.97,31.08A12.622,12.622,0,0,1,5.91,18.55a12.828,12.828,0,0,1,.332-2.828L13.81,23.29v1.58a3.169,3.169,0,0,0,3.16,3.16Zm10.9-4.013a3.135,3.135,0,0,0-3-2.2H23.29V20.13a1.585,1.585,0,0,0-1.58-1.58H12.23V15.39h3.16a1.585,1.585,0,0,0,1.58-1.58V10.65h3.16a3.169,3.169,0,0,0,3.16-3.16V6.842a12.612,12.612,0,0,1,4.582,20.224Z" transform="translate(1357.835 503.335)" fill="#ffd719" />
                    </g>
                    <g id="Group_4801" data-name="Group 4801" transform="translate(-532.389 883.254)">
                        <g transform="matrix(1, 0, 0, 1, 7495.28, -17392.29)" filter="url(#DO_GOOD)">
                            <text id="DO_GOOD-2" data-name="DO GOOD" transform="translate(903.72 872.03)" fill="#feead7" fontSize={21} fontFamily="Montserrat-Bold, Montserrat" fontWeight={700} letterSpacing="0.025em"><tspan x="-56.574" y={0}>DO GOOD</tspan></text>
                        </g>
                        <g transform="matrix(1, 0, 0, 1, 7385.28, -17392.29)" filter="url(#_1_Week_Vegan_Challenge)"> 
                            <foreignObject transform="translate(903.72 912.03)" width="250" height="180">
                                <div className={styles.textContent}>
                                    <p >
                                        {doGood ? doGood.title : "Not Available"}
                                    </p>
                                </div>
                            </foreignObject> 
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    )
}
export default withRouter(TwoJourneyMap);