import moment from "moment";

export default function sortDate(dateA, dateB, direction = "asc") {
    const formats = ["DD-MM-YYYY"]; // can be several
    return (
        (moment(dateA, formats).isBefore(moment(dateB, formats))
            ? -1
            : moment(dateA, formats).isAfter(moment(dateB, formats))
            ? 1
            : 0) * (direction === "asc" ? 1 : -1)
    );
}
