import React from "react";
import styles from "./ViewEndUser.module.css";
import TextField from "@material-ui/core/TextField";
import DataTable from "react-data-table-component";
import AppLoader from "../../utils/AppLoader/AppLoader";
import { connect } from "react-redux";
import { getAllEndUsers } from "../../../containers/endUser/actions";
import { withRouter } from "react-router-dom";
import Action from "./Action/Action";
import Modal from "./Modal/Modal";

const ViewEndUser = (props) => {
    const [users, setUsers] = React.useState(props.users);
    const [searchVal, setSearchVal] = React.useState("");
    const [openPage, setOpenPage] = React.useState({
        open: false,
        tab: false,
    });

    React.useEffect(() => {
        if (!props.users) {
           
            props.getAllEndUsers();
        }
        setUsers(props.users);
    }, [props.users]);

    let isLoading = !users;
    let showData = !isLoading;
    let rowData = [];

    !isLoading &&
        users.forEach((user, index) => {
            if (
                // (user&&user.username.toLowerCase().includes(searchVal.toLowerCase())) ||
                (user && user.email.toLowerCase().includes(searchVal.toLowerCase())) ||
                (user &&
                    user.userProfile &&
                    user.userProfile.firstName &&
                    user.userProfile.firstName.toLowerCase().includes(searchVal.toLowerCase())) ||
                (user &&
                    user.userProfile &&
                    user.userProfile.lastName &&
                    user.userProfile.lastName.toLowerCase().includes(searchVal.toLowerCase()))
            ) {
                let displayDate = "";
                if (new Date(user.createdAt).getDate() == new Date().getDate()) {
                    if (new Date().getHours() - new Date(user.createdAt).getHours() == 0) {
                        displayDate =
                            Math.abs(new Date().getMinutes() - new Date(user.createdAt).getMinutes()) + " minutes ago";
                    } else {
                        displayDate =
                            Math.abs(new Date().getHours() - new Date(user.createdAt).getHours()) + " hour ago";
                    }
                } else {
                    displayDate = new Date(user.createdAt).toString().substring(0, 15);
                }

                rowData.push({
                    sl_no: index + 1,

                    email: user.email,
                    sub_type: user?.subscription?.subscriptionType,
                    sub_status: user?.subscription?.status,
                    sub_start: user?.subscription?.transactionDate
                        ? new Date(user?.subscription?.transactionDate).toString().substring(0, 15)
                        : "",
                    sub_end: user?.subscription?.validTill
                        ? new Date(user?.subscription?.validTill).toString().substring(0, 15)
                        : "",
                    verificationType: user.verificationType,
                    joinedOn: displayDate,
                    firstName: user.userProfile ? (
                        user.userProfile.firstName ?? <span className="text-orange">N/A</span>
                    ) : (
                        <span className="text-orange">N/A</span>
                    ),
                    lastName: user.userProfile ? (
                        user.userProfile.lastName ?? <span className="text-orange">N/A</span>
                    ) : (
                        <span className="text-orange">N/A</span>
                    ),

                    actions: (
                        <div className="actions">
                            <Action userId={user} />
                        </div>
                    ),
                });
            }
        });

    return (
        <div className={styles.container}>
            <Modal open={openPage.open} onClose={() => setOpenPage({ ...openPage, open: false })} tab={openPage.tab} />
            <div className={styles.header}>
                <div className={styles.leftHeader}>&nbsp;</div>

                <div className={styles.rightHeader}>
                    <TextField
                        label="Search Here"
                        className={styles.search}
                        value={searchVal}
                        onChange={(e) => setSearchVal(e.target.value)}
                    />
                    <svg
                        onClick={() => setOpenPage({ ...openPage, open: true, tab: "Add new user" })}
                        width="29"
                        height="29"
                        viewBox="0 0 29 29"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M14.6031 21.375C14.901 21.375 15.1474 21.2776 15.3422 21.0828C15.537 20.888 15.6344 20.6417 15.6344 20.3438V15.6688H20.3781C20.6531 15.6688 20.888 15.5714 21.0828 15.3766C21.2776 15.1818 21.375 14.9354 21.375 14.6375C21.375 14.3396 21.2776 14.0932 21.0828 13.8984C20.888 13.7036 20.6417 13.6063 20.3438 13.6063H15.6344V8.62187C15.6344 8.34687 15.537 8.11198 15.3422 7.91719C15.1474 7.7224 14.901 7.625 14.6031 7.625C14.3052 7.625 14.0589 7.7224 13.8641 7.91719C13.6693 8.11198 13.5719 8.35833 13.5719 8.65625V13.6063H8.62187C8.34687 13.6063 8.11198 13.7036 7.91719 13.8984C7.7224 14.0932 7.625 14.3396 7.625 14.6375C7.625 14.9354 7.7224 15.1818 7.91719 15.3766C8.11198 15.5714 8.35833 15.6688 8.65625 15.6688H13.5719V20.3781C13.5719 20.6531 13.6693 20.888 13.8641 21.0828C14.0589 21.2776 14.3052 21.375 14.6031 21.375ZM14.5 28.25C12.5521 28.25 10.7417 27.9005 9.06875 27.2016C7.39583 26.5026 5.94063 25.5344 4.70312 24.2969C3.46563 23.0594 2.4974 21.6042 1.79844 19.9312C1.09948 18.2583 0.75 16.4479 0.75 14.5C0.75 12.575 1.09948 10.776 1.79844 9.10312C2.4974 7.43021 3.46563 5.975 4.70312 4.7375C5.94063 3.5 7.39583 2.52604 9.06875 1.81563C10.7417 1.10521 12.5521 0.75 14.5 0.75C16.425 0.75 18.224 1.10521 19.8969 1.81563C21.5698 2.52604 23.025 3.5 24.2625 4.7375C25.5 5.975 26.474 7.43021 27.1844 9.10312C27.8948 10.776 28.25 12.575 28.25 14.5C28.25 16.4479 27.8948 18.2583 27.1844 19.9312C26.474 21.6042 25.5 23.0594 24.2625 24.2969C23.025 25.5344 21.5698 26.5026 19.8969 27.2016C18.224 27.9005 16.425 28.25 14.5 28.25Z"
                            fill="#2D4141"
                        />
                    </svg>
                </div>
            </div>

            {isLoading && <AppLoader />}

            {showData && (
                <DataTable
                    noHeader={true}
                    fixedHeader={true}
                    pagination={true}
                    fixedHeaderScrollHeight={"calc(100vh - 320px)"}
                    columns={[
                        { name: "Sl No", selector: "sl_no", sortable: true },
                        { name: "First Name", selector: "firstName", sortable: true },
                        { name: "Last Name", selector: "lastName", sortable: true },

                        { name: "Email", selector: "email", sortable: true },
                        { name: "Subscription Type", selector: "sub_type", sortable: true },
                        { name: "Subscription Status", selector: "sub_status", sortable: true },
                        { name: "Start Date", selector: "sub_start", sortable: true },
                        { name: "End Date", selector: "sub_end", sortable: true },
                        { name: "Login Method", selector: "verificationType", sortable: true },
                        { name: "Joined On", selector: "joinedOn", sortable: true },

                        { name: "Action", selector: "actions" },
                    ]}
                    data={rowData}
                />
            )}
        </div>
    );
};
const mapStateToProps = (state) => ({
    users: state.endUser.users,
});
export default withRouter(connect(mapStateToProps, { getAllEndUsers })(ViewEndUser));
