import React from 'react'
import styles from './Service.module.css'

import {getServiceProviderStoreProfile,getAllServices} from '../../../containers/endUser/actions'
import {connect} from 'react-redux'
import {useLocation, withRouter} from 'react-router-dom'

import AppLoader from '../../utils/AppLoader/AppLoader'
import ContentSlider from './ContentSlider/ContentSlider'
import InfoBox from './InfoBox/InfoBox'
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';

const Service  = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [service,setService] = React.useState(false);
    const [serviceProvider,setServiceProvider] = React.useState(false);

    React.useEffect(() => {
        if(query.get("serviceId")) {
            if(!props.services) {
                props.getAllServices();
            } else {
                props.services.forEach(val => {
                    if(val._id == query.get("serviceId")) {
                        setService({...val});
                    }
                })
            }
        } 
    },[props.services, query.get("serviceId")]);

    React.useEffect(() => {
        if(query.get("serviceProvider")) {
            props.getServiceProviderStoreProfile(query.get("serviceProvider"))
        }  
    },[query.get("serviceProvider")]);


    React.useEffect(() => {
        if(!query.get("type")) {
            setServiceProvider(props.serviceProvider)
        }
    },[props.serviceProvider])
 
    const isLoading = !service || !serviceProvider;
    const showData = service && serviceProvider; 
     
    return (
        <div className={styles.container}>
            {showData &&
                <div className={styles.header} onClick={() => {
                    props.history.push(`/user/end-users/SERVICE-LIST?userId=${query.get("userId")}`)
                }}>
                    <KeyboardArrowLeftRoundedIcon size={40} />
                    <h1>{service.eventName}</h1>
                </div>
            } 
            <div className={styles.content}>
                {isLoading &&
                    <AppLoader color="light" />
                } 
                {showData &&
                    <React.Fragment>  
                        <ContentSlider service={service} />
                        <InfoBox service={service} serviceProvider={serviceProvider} />

                    </React.Fragment>}
                    
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    services: state.endUser.services,
    serviceProvider: state.endUser.serviceProviderProfile
})
export default withRouter(connect(mapStateToProps,{getAllServices,getServiceProviderStoreProfile})(Service));