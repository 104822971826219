import { combineReducers } from "redux";
import appReducer from "./app/reducer";
import usersReducer from "./users/reducer";
import serviceProviderRequestReducer from "./serviceProviderRequest/reducer";
import serviceProviderReducer from "./serviceProvider/reducer";
import categoryReducer from "./category/reducer";
import emailReducer from "./mail/reducer";
import discountCodeReducer from "./discountCode/reducer";
import endUserReducer from "./endUser/reducer";
import transactionReducer from "./transactions/reducer";
import lifeCoachReducer from "./niv/reducer";
import createdServiceProviderReducer from "./partner/reducer";
import lifeCoachAdminReducer from "./lifeCoach/reducer";
import lifeCoachGeneralReducer from "./General/reducer";
import lifecoachesAdminReducer from "./lifeCoaches/reducer";

export default combineReducers({
    app: appReducer,
    users: usersReducer,
    serviceProviderRequest: serviceProviderRequestReducer,
    serviceProvider: serviceProviderReducer,
    category: categoryReducer,
    email: emailReducer,
    discountCode: discountCodeReducer,
    endUser: endUserReducer,
    transaction: transactionReducer,
    lifeCoach: lifeCoachReducer,
    createdServiceProvider: createdServiceProviderReducer,
    lifeCoachAdmin: lifeCoachAdminReducer,
    lifeCoachGeneral: lifeCoachGeneralReducer,
    lifeCoachesAdmin: lifecoachesAdminReducer,
});
