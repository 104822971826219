import React from 'react'
import PersonalDetails from './PersonalDetails/PersonalDetails'
import Preferences from './Preferences/Preferences'
import Referral from '../Referral/Referral'
import Security from './Security/Security'
import styles from './Settings.module.css'
import SideBar from './SideBar/SideBar'
import SettingNav from './SettingNav/SettingNav'

import {useLocation,withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {showAlert} from '../../../containers/app/actions'
import {getAllEndUsers} from '../../../containers/endUser/actions'
import AppLoader from '../../utils/AppLoader/AppLoader'

const Settings = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [state,setState] = React.useState("SETTING_NAV")
    const [userData,setUserData] = React.useState(false);

    React.useEffect(() => {
        if(query.get("userId")) {
            if(!props.users) {
                props.getAllEndUsers();
            } else {
                props.users.forEach((user) => {
                    if(user._id == query.get("userId")) {
                        setUserData({...user.userProfile,...user,_id: user._id})
                    }
                })
            }
        } else {
            props.showAlert("Invalid Request")
            props.history.push("/user/end-users/VIEW-END-USERS")
        }
    },[props.users])

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {state == "SETTING_NAV"
                    ?
                <SettingNav setState={val => setState(val)}/>
                    :
                <React.Fragment>
                    <div className={styles.sideBar}>
                        <SideBar state={state} setState={val => setState(val)} />
                    </div>

                    <div className={styles.mainContent}>
                        {!userData && <AppLoader />}

                        {userData && 
                            <React.Fragment>
                                {state == "PERSONAL_DETAILS" && <PersonalDetails userData={userData} />}
                                {state == "PREFERENCES" && <Preferences />}
                                {state == "SECURITY" && <Security />}
                                {state == "REFERRAL" && <Referral />}
                            </React.Fragment>}

                    </div>
                </React.Fragment>}
            </div>
        </div>  
    )
}
const mapStateToProps = state => ({
    users: state.endUser.users
});
export default withRouter(connect(mapStateToProps, {showAlert,getAllEndUsers})(Settings));