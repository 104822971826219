import React from 'react'
import styles from './ConfPartnerAgreement.module.css'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
 
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { connect } from 'react-redux'
import { showAlert } from '../../../containers/app/actions' 
import { withRouter, useLocation } from 'react-router-dom'
import axios from 'axios'
import { Modal, TextField } from '@material-ui/core'
import FileUploader from '../../ServiceProvider/UpdateGeneral/FileUploader/FileUploader'
import MediaHandler from '../../Media/MediaHandler'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import IconButton from '@material-ui/core/IconButton'

const ConfPartnerAgreement = (props) => {
    const [open, setOpen] = React.useState(false);

    const [formData, setFormData] = React.useState({
        agreementLink: '',
        description: ""
    });

    const [error, setError] = React.useState({
        agreementLink: false,
        description: false,
    });

    const [loading, setLoading] = React.useState(false);

    const validate = () => {
        const err = { agreementLink: false, description: false};
        let validData = true;
        setError({ ...err });
        Object.keys(err).forEach(key => {
            if (formData[key] == "") {
                err[key] = `Field cannot be empty`
                validData = false;
            }
        })

        setError({ ...err });
        return validData;
    }

    const onSubmit = () => {
        if (validate()) {
            setLoading(true);
            const url = props.type == "ADD" ? "/dashboard/conf-user/addUser" : "/dashboard/conf-user/editUser"
            const method = props.type == "ADD" ? "post" : "put";
            axios({
                method: "post",
                url: "/dashboard/partner-agreement/addPartnerAgreement",
                data: {
                    ...formData
                }
            }).then(res => {
                setLoading(false); 
                props.getAllPartnerAgreement();
                props.onClose();
            }).catch(err => {
                setLoading(false);
                if (err && err.response && err.response.data && err.response.data.error) {
                    props.showAlert(err.response.data.error)
                } else {
                    props.showAlert("Something went wrong ! Try Again")
                }
            })
        }
    }

    return (
        <Modal open={props.open} onClose={props.onClose} className={styles.modal}>
            <React.Fragment>
                {open &&
                    <MediaHandler
                        open={open}
                        allowedSize={1024}
                        onClose={() => {
                            setOpen(false);
                        }}
                        allowedList={"application/pdf"}
                        onSelectImage={(val) => {
                            setFormData({ ...formData, agreementLink: val });
                            setOpen(false);
                            props.showAlert("File Added Successfully");
                        }}
                    />
                }
                <Paper variant="outlined" className={styles.paper}>
                    <IconButton onClick={props.onClose} className={styles.close}>
                        <HighlightOffRoundedIcon />
                    </IconButton>

                    <h1>Add New Agreement</h1>
                    <br />
                    <FileUploader
                        value={formData.agreementLink}
                        onRemove={() => setFormData({ ...formData, agreementLink: "" })}
                        onUpload={() => {
                            console.log("-----CALLED")
                            setOpen(Math.random());
                        }}
                        notPrivateDoc={true}
                        name={"Partner Agreement Pdf"}
                        type="PDF"
                        error={error.agreementLink}
                        title="Partner Agreement Pdf"
                    />
                    <br />
                    <TextField
                        value={formData.description}
                        onChange={(e) => setFormData({...formData, description: e.target.value})}

                        error={error.description}
                        helperText={error.description}

                        variant="outlined"
                        multiline
                        rows={10}
                        fullWidth 
                    />

                    <br />
                    <div className={styles.row}>
                        {loading
                            ?
                            <Button color="primary" variant="contained" startIcon={<CircularProgress color="inherit" size={20} />}>Loading ...</Button>
                            :
                            <Button color="primary" variant="contained" onClick={onSubmit}>Add Partner Agreement</Button>}
                    </div>
                </Paper>
            </React.Fragment>
        </Modal>
    )
} 
export default withRouter(connect(null, { showAlert })(ConfPartnerAgreement));