import React from 'react';
import styles from './PartnerConf.module.css';
import { useLocation } from 'react-router-dom'

import BreadCrump from '../../components/utils/BreadCrump/BreadCrump';
import TopBar from '../../components/utils/TopBar/TopBar';
import ViewAddedServiceProvider from '../../components/ConfServiceProvider/ViewAddedServiceProvider/ViewAddedServiceProvider';
import AddServiceProvider from '../../components/ConfServiceProvider/AddServiceProviderMain/AddServiceProvider';


const PartnerConf = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [state, setState] = React.useState("VIEW-ADDED-SERVICE-PROVIDERS");

    React.useEffect(() => {
        if (Object.keys(navData).includes(props.match.params.type)) {
            setState(props.match.params.type)
        }
    }, [props.match.params.type]);

    const navData = {
        "VIEW-ADDED-SERVICE-PROVIDERS": {
            name: "View Partner Acquistion",
            path: "/user/add-service-provider/VIEW-ADDED-SERVICE-PROVIDERS"
        }, 
        "ADD-SERVICE-PROVIDER": {
            name: "Add Partner Acquistion",
            path: "/user/add-service-provider/ADD-SERVICE-PROVIDER"
        }, 
        "EDIT-SERVICE-PROVIDER": {
            name: "Edit Partner Acquistion",
            path: "/user/add-service-provider/EDIT-SERVICE-PROVIDER"
        }, 
    }
    return (
        <div className={styles.container}>
            <TopBar head="Partner Acquistion" />
            <BreadCrump
                navItems={[
                    { name: "Partner Acquistions", path: "/user/service-provider/VIEW-ADDED-SERVICE-PROVIDERS" },
                    state == "EDIT-SERVICE-PROVIDER" ? navData["VIEW-ADDED-SERVICE-PROVIDERS"] : false,
                     navData[state]]}
            />

            {state == "VIEW-ADDED-SERVICE-PROVIDERS" && <ViewAddedServiceProvider />}
            {state == "ADD-SERVICE-PROVIDER" && <AddServiceProvider />}
            {state == "EDIT-SERVICE-PROVIDER" && <AddServiceProvider />}
        </div>
    )
}

export default PartnerConf;