import React from 'react';
import styles from './ServiceProvider.module.css';
import {useLocation} from 'react-router-dom'

import BreadCrump from '../../components/utils/BreadCrump/BreadCrump'; 
import TopBar from '../../components/utils/TopBar/TopBar';

import ViewServiceProvder from '../../components/ServiceProvider/ViewServiceProvider/ViewServiceProvder';
import UpdateGeneral from '../../components/ServiceProvider/UpdateGeneral/UpdateGeneral';
import UpdateStoreProfile from '../../components/ServiceProvider/UpdateStoreProfile/UpdateStoreProfile';
import ViewSingleEvent from '../../components/ServiceProvider/SingleEvent/ViewSingleEvent/ViewSingleEvent'
import EditSingleEvent from '../../components/ServiceProvider/SingleEvent/EditSingleEvent/EditSingleEvent';
import Bookings from '../../components/ServiceProvider/Bookings/Bookings';


const ServiceProvider = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [state,setState] = React.useState("VIEW-SERVICE-PROVIDERS");   

    React.useEffect(() => {
        if(Object.keys(navData).includes(props.match.params.type)) {
            setState(props.match.params.type)
        }
    },[props.match.params.type]);

    const navData = {
        "VIEW-SERVICE-PROVIDERS": {
            name: "View Service Provider",
            path: "/user/service-provider/VIEW-SERVICE-PROVIDERS"
        }, 
        "UPDATE-GENERAL": {
            name: "Update General Details",
            path: "/user/service-provider/UPDATE-GENERAL?userId=" + query.get("userId")
        },
        "UPDATE-STORE-PROFILE": {
            name: "Update Store Profile",
            path: "/user/service-provider/UPDATE-STORE-PROFILE?userId=" + query.get("userId")
        },
        "VIEW-STORE-SERVICES": {
            name: "View Store Services",
            path: "/user/service-provider/VIEW-STORE-SERVICES?userId=" + query.get("userId")
        },
        "EDIT-STORE-SERVICE": {
            name: "Edit Store Service",
            path: "/user/service-provider/EDIT-STORE-SERVICE?userId=" + query.get("userId")
        },
        "DUPLICATE-STORE-SERVICE": {
            name: "Duplicate Store Service",
            path: "/user/service-provider/DUPLICATE-STORE-SERVICE?userId=" + query.get("userId")
        },
        "ADD-STORE-SERVICE": {
            name: "Add Store Service",
            path: "/user/service-provider/ADD-STORE-SERVICE?userId=" + query.get("userId")
        },
        "VIEW-BOOKINGS": {
            name: "View Bookings",
            path: "/user/service-provider/VIEW-BOOKINGS?userId=" + query.get("userId")
        },
    }
    return (
        <div className={styles.container}>
            <TopBar head="Service Provider" />
            <BreadCrump 
                navItems={[
                    {name:"Service Providers",path: "/user/service-provider/VIEW-SERVICE-PROVIDERS"},
                    state == "EDIT-STORE-SERVICE" ? navData["VIEW-STORE-SERVICES"] : state == "DUPLICATE-STORE-SERVICE" ? navData["VIEW-STORE-SERVICES"] : false,
                    ,navData[state]]}
            />

            {state == "VIEW-SERVICE-PROVIDERS" && <ViewServiceProvder />}
            {state == "UPDATE-GENERAL" && <UpdateGeneral />} 
            {state == "UPDATE-STORE-PROFILE" && <UpdateStoreProfile />} 
            {state == "VIEW-STORE-SERVICES" && <ViewSingleEvent />} 
            {state == "ADD-STORE-SERVICE" && <EditSingleEvent type="ADD" />} 
            {state == "EDIT-STORE-SERVICE" && <EditSingleEvent type="EDIT" />} 
            {state == "VIEW-BOOKINGS" && <Bookings />} 
        </div>
    )
}

export default ServiceProvider;