import React from 'react'
import styles from './ViewGiftCard.module.css';

import TextField from '@material-ui/core/TextField' 

import AppLoader from '../../utils/AppLoader/AppLoader';
import DataTable from 'react-data-table-component'; 

import { connect } from 'react-redux'
import { showAlert } from '../../../containers/app/actions'
import { getAllGiftCards } from '../../../containers/app/actions'
import { withRouter } from 'react-router-dom'
import TopBar from '../../utils/TopBar/TopBar';
import { dateFormatter } from '../../../hooks/dateFormatter';
 

const ViewGiftCard= (props) => { 
    const [searchVal, setSearchVal] = React.useState("");

    React.useEffect(() => {
        if (!props.giftCards) {
            props.getAllGiftCards();
        } 
    }, [props.giftCards]);
 
 
    let isLoading = !props.giftCards;
    let showData = !isLoading;
    let rowData = [];

    !isLoading && props.giftCards.forEach((card, index) => {
        let senderEmail = card.userId?.email;
        let senderName = card.sendToDetails?.senderName;
        let receiverEmail = card.sendToDetails?.recepientEmail;
        let receiverName = card.sendToDetails?.recepientName;
        if (
            (senderEmail && senderEmail.toLowerCase().includes(searchVal.toLowerCase())) ||
            (senderName && senderName.toLowerCase().includes(searchVal.toLowerCase())) ||
            (receiverEmail && receiverEmail.toLowerCase().includes(searchVal.toLowerCase())) ||
            (receiverName && receiverName.toLowerCase().includes(searchVal.toLowerCase()))
        
        ) {
            rowData.push({
                sl_no: index + 1,
                active: card.active ? "ACTIVE" : "IN_ACTIVE",
                giftCardCode: card.giftCardCode,
                giftCardAmount: card.giftCardAmount,
                giftCardBalance: card.giftCardBalance,
                purchasedOn: dateFormatter(card.createdAt),
                validTill: card.validTill?.substr(0, 10),
                senderEmail,
                senderName,
                receiverEmail,
                receiverName 
            })
        }
    });
    return (
        <div className={styles.container}>
            <TopBar head="Gift Card" />
            <div className={styles.header}>
                <div className={styles.rightHeader}>
                    <TextField
                        label="Search Here"
                        className={styles.search}
                        value={searchVal}
                        onChange={(e) => setSearchVal(e.target.value)}
                    />
                </div>
            </div>

            {isLoading && <AppLoader />}

            {showData &&
                <React.Fragment>
                    <DataTable
                        noHeader={true}
                        fixedHeader={true}
                        pagination={true}
                        fixedHeaderScrollHeight={"calc(100vh - 240px)"}
                        columns={[
                            { name: "Sl No", selector: "sl_no", sortable: true, wrap: true, minWidth: '80px' },
                            { name: "Active", selector: "active", sortable: true, wrap: true, minWidth: '100px' },
                            { name: "Gift Card Code", selector: "giftCardCode", sortable: true, wrap: true, minWidth: '160px' },
                            { name: "Gift Card Amount", selector: "giftCardAmount", sortable: true, wrap: true, minWidth: '100px' },
                            { name: "Gift Card Balance", selector: "giftCardBalance", sortable: true, wrap: true, minWidth: '100px' },
                            { name: "Purchased On", selector: "purchasedOn", sortable: true, wrap: true, minWidth: '220px' },
                            { name: "Valid Till", selector: "validTill", sortable: true, wrap: true, minWidth: '220px' },
                            { name: "Sender Email", selector: "senderEmail", sortable: true, wrap: true, minWidth: '300px' },
                            { name: "Sender Name", selector: "senderName", sortable: true, wrap: true, minWidth: '250px' },
                            { name: "Recepient Email", selector: "receiverEmail", sortable: true, wrap: true, minWidth: '300px' },
                            { name: "Recepient Name", selector: "receiverName", sortable: true, wrap: true, minWidth: '250px' },
                        ]}
                        data={rowData}
                    /> 
                </React.Fragment>}

        </div>
    )
}
const mapStateToProps = state => ({
    giftCards: state.app.giftCards,
})
export default withRouter(connect(mapStateToProps, { getAllGiftCards, showAlert })(ViewGiftCard));