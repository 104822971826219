import React from 'react'

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton'
import Switch from '@material-ui/core/Switch'
import CircularProgress from '@material-ui/core/CircularProgress'

import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';

import ConfirmAlert from '../../../utils/ConfirmAlert/ConfirmAlert'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {showAlert} from '../../../../containers/app/actions'
import axios from 'axios'


const Action = (props) => {
    const [checked,setChecked] = React.useState(props.code.active);
    const [loading,setLoading] = React.useState(false);

    const code = props.code;

    const handleChange = () => {
        setLoading("SWITCH");
        axios({
            method: "post",
            url: "/dashboard/discount-code/publishDiscountCode",
            data: {
                discountCodeId: code._id,
                active: !checked
            }
        }).then(res => {
            setChecked(!checked)
            setLoading(false);
            props.showAlert(`${code.discountName} Updated..`)
        }).catch(err => {
            setLoading(false);
            if(err && err.response && err.response.data && err.response.data.error) {
                props.showAlert(err.response.data.error)
            } else {
                props.showAlert("Something went wrong ! Try Again")
            }
        })
    }
    const handleDelete = () => {
        setLoading("DELETE");
        axios({
            method: "delete",
            url: "/dashboard/discount-code/deleteDiscountCode",
            data: {
                codeId: code._id, 
            }
        }).then(res => { 
            setLoading(false);
            props.getAllDiscountCodes()
            props.showAlert(`${code.discountName} Sucessfully Deleted`)
        }).catch(err => {
            setLoading(false);
            if(err && err.response && err.response.data && err.response.data.error) {
                props.showAlert(err.response.data.error)
            } else {
                props.showAlert("Something went wrong ! Try Again")
            }
        })
    }
    return (
        <div className="actions">
            <Tooltip title="Edit Code">
                <IconButton onClick={() => props.history.push("/user/discount-codes/EDIT-DISCOUNT-CODE?codeId="+ code._id )}>
                    <EditRoundedIcon />
                </IconButton>
            </Tooltip>

            <ConfirmAlert msg={`Are you sure you want to delete ${code.discountName}`} onClickEvent={handleDelete}>
                <Tooltip title="Delete Discount Code">
                    {loading == "DELETE"
                        ?
                    <CircularProgress size={16} />
                        :
                    <IconButton> 
                        <DeleteRoundedIcon />
                    </IconButton>}
                </Tooltip>
            </ConfirmAlert> 

            <ConfirmAlert msg={`Are you sure you want to ${code.active ? "in-active" : "active"} ${code.discountName}`} onClickEvent={handleChange}>
                <Tooltip title="Update Discount Code">
                    {loading == "SWITCH"
                        ?
                    <CircularProgress size={16} />
                        :
                    <Switch
                        checked={checked}
                    />}
                </Tooltip>
            </ConfirmAlert> 
        </div>
    )
}
export default withRouter(connect(null,{showAlert})(Action));