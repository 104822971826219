import React from 'react'; 
 
import TopBar from '../../components/utils/TopBar/TopBar';
import TranslateComp from '../../components/Translate/TranslateComp'   

const Translate = (props) => {  
    return (
        <div>
            <TopBar head={"Translate"} />
            <TranslateComp />
        </div>
    )
}

export default Translate;