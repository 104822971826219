import React from 'react'
import styles from './EditPurposeJourney.module.css'
 
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

import checkBox from '../../../../assets/svg/checkbox.svg'
import logo from '../../../../assets/svg/big-logo-light.svg'
import Button from '@material-ui/core/Button'
import ErrorPage from './ErrorPage/ErrorPage' 

const EditPurposeJourney = (props) => {     

    const [showError,setShowError] = React.useState(false); 

    const handleAddCategory = () => {
        if(props.journeyData.category) {
            setShowError("CATEGORY")
        } else {
            props.setState("START-BEING");
        }
    }
    const handleDeleteCat = () => {
        props.handleEditChanges({...props.journeyData,category: false});
    }
    const handleSelectedList = (subCat) => {
        let arr = props.journeyData.subCategoryList; 

        if(arr.filter(val => val.subCategory._id == subCat._id).length > 0) {
            arr = arr.filter(val => val.subCategory._id != subCat._id)
        } else {
            if(arr.length > 1) {
                setShowError("SUB_CATEGORY");
            } else { 
                arr.push({subCategory: subCat})
            }
        } 
        props.handleEditChanges({...props.journeyData,subCategoryList: [...arr]});
    }
    const handleNext = () => {
        if(props.journeyData.subCategoryList.length == 0) {
            setShowError("EMPTY")
        } else {
            props.updatePurposeJourney();
            props.setState("USER-JOURNEY")
        }
    }

    const category = props.journeyData && props.journeyData.category;
    return ( 
        <React.Fragment>
            <ErrorPage open={showError} onClose={() => setShowError(false)} />

            <div className={styles.container}>
                <h1 className={styles.head}>Edit Purpose Journey</h1> 

                <div className={`${styles.content} customBackgroundBlur`}>
                    <div className={styles.header}>
                        <img src={logo} alt=""/>
                        <h1>What I need</h1>
                    </div>

                    <div className={styles.contentInfo}>
                        <div className={styles.leftContent}>
                            {category &&
                                <React.Fragment>
                                    <div className={styles.col}>
                                        <CancelRoundedIcon onClick={handleDeleteCat}/>
                                        <img className={styles.icon} src={category && category.categoryIcon} alt=""/>
                                        <p>{category && category.categoryName}</p>
                                    </div>

                                    <div className={styles.col}>
                                        {category && category.subCategory.map(subCat => {
                                            let selected = props.journeyData.subCategoryList.filter(val => val.subCategory._id == subCat._id).length > 0;
                                            return (
                                                <div className={styles.info} onClick={() => handleSelectedList(subCat)}>
                                                    <div className={styles.checkBox}>
                                                        <img src={checkBox} alt=""/>
                                                        {selected && <span>&nbsp;</span>}
                                                    </div>
                                                    <p>{subCat.subCategoryName}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </React.Fragment>}
                        </div>
                        <div className={styles.rightContent}>
                            <button className={"btnBorderGreen"}
                                onClick={handleAddCategory}
                            >
                                <AddRoundedIcon />
                                Select Another Category
                            </button>
                        </div>
                    </div>
                    
                    <div className={styles.line}>&nbsp;</div>

                    <div className={styles.btnEnd}>
                        <button className={"btnLight"} onClick={handleNext} >Done</button>
                    </div>
                </div> 
            </div> 
        </React.Fragment>
    )
}  
export default EditPurposeJourney;