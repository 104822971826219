import axios from 'axios'
import * as actionTypes from './actionTypes'

export const getAllServiceProviders = () => dispatch => {
    dispatch({
        type: actionTypes.SET_CREATED_SERVICE_PROVIDERS,
        payload: false
    })

    axios({
        method: "get",
        url: "/dashboard/create-service-provider/getAllServiceProviderCreated"
    }).then(res => {
        dispatch({
            type: actionTypes.SET_CREATED_SERVICE_PROVIDERS,
            payload: res.data.serviceProviders
        })
    }).catch(err => {
        dispatch({
            type: actionTypes.SET_CREATED_SERVICE_PROVIDERS,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}