import * as actionTypes from "./actionTypes";
import axios from "axios";
import { globalGivingKey } from "../../config/config";

export const getAllEndUsers = (type) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_END_USERS,
        payload: false,
    });
    axios({
        method: "get",
        url: `/dashboard/end-users/getAllEndUsers`,
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_END_USERS,
                payload: res.data.users,
            });
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.SET_END_USERS,
                payload: [],
            });

            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};

export const getAllB2BUsers = (type) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_B2B_USERS,
        payload: false,
    });

    axios({
        method: "get",
        url: `/dashboard/end-users/getAllB2BUsers`,
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_B2B_USERS,
                payload: res.data.users,
            });
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.SET_B2B_USERS,
                payload: [],
            });

            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};
export const getAllSubscription = (limit, page, search) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_SUBSCRIPTION,
        payload: false,
    });

    axios({
        method: "get",
        url: `/dashboard/subscriptions/getAllSubscription?limit=${limit}&page=${page}&search=${search}`,
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_SUBSCRIPTION,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.SET_SUBSCRIPTION,
                payload: {
                    data: [],
                    totalData: 0,
                },
            });

            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};

export const deactivateAccount = (userId) => (dispatch) => {
    axios({
        method: "put",
        url: `/dashboard/end-users/deactivateAccount`,
        data: {
            userId,
        },
    })
        .then((res) => {
            dispatch({
                type: "SHOW_ALERT",
                payload: res?.data?.message,
            });
            dispatch(getAllB2BUsers());
        })
        .catch((err) => {
            const errorMessage = err.response?.data?.message || "Something went wrong! Try again";
            dispatch({
                type: "SHOW_ALERT",
                payload: errorMessage,
            });
        });
};

export const disableAccount = (value, userId) => (dispatch) => {
    axios({
        method: "put",
        url: `/dashboard/end-users/disableAccount?userId=${userId}`,
        data: {
            value,
        },
    })
        .then((res) => {
            dispatch(getAllEndUsers());
            window.location.reload()
        })
        .catch((err) => {
            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};

export const getAllReferrals = (userId) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_REFERRALS,
        payload: false,
    });

    axios({
        method: "get",
        url: `/dashboard/newReferalCampaign/getNewCampaign`,
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_REFERRALS,
                payload: res.data.findCampaign,
            });
        })
        .catch((err) => {
            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};

export const getAllMyJourney = (userId) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_MY_JOURNEYS,
        payload: false,
    });
    axios({
        method: "get",
        url: `/user/purpose-journey/getAllMyJourney?userId=${userId}`,
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_MY_JOURNEYS,
                payload: res.data.journeys,
            });
        })
        .catch((err) => {
            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};

export const getAllSentJourney = (userId) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_SENT_JOURNEYS,
        payload: false,
    });
    axios({
        method: "get",
        url: `/user/purpose-journey/getAllSentJourney?userId=${userId}`,
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_SENT_JOURNEYS,
                payload: res.data.journeys,
            });
        })
        .catch((err) => {
            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};

export const updateMyJourney = (journey) => (dispatch) => {
    dispatch({
        type: actionTypes.UPDATE_MY_JOURNEY,
        payload: journey,
    });
};

export const getAllBooking = () => (dispatch) => {
    dispatch({
        type: actionTypes.SET_USER_BOOKINGS,
        payload: false,
    });

    axios({
        method: "get",
        url: `/user/order/getAllBooking`,
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_USER_BOOKINGS,
                payload: res.data.bookings,
            });
            if (res.data.bookings.length > 0) {
                dispatch({
                    type: "SET_BILLING_ADDRESS",
                    payload: res.data.bookings[0].billingAddress,
                });
            }
        })
        .catch((err) => {
            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};

export const getAllBookings = (userId) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_USER_BOOKINGS,
        payload: false,
    });

    axios({
        method: "get",
        url: `/user/order/getAllBookings?userId=${userId}`,
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_USER_BOOKINGS,
                payload: res.data.bookings,
            });
            if (res.data.bookings.length > 0) {
                dispatch({
                    type: "SET_BILLING_ADDRESS",
                    payload: res.data.bookings[0].billingAddress,
                });
            }
        })
        .catch((err) => {
            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};

export const getAllServices = () => (dispatch) => {
    axios({
        method: "get",
        url: "/service-provider/auth/getAllServices",
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_SERVICES,
                payload: res.data.service,
            });
        })
        .catch((err) => {
            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};
export const getServiceProviderStoreProfile = (serviceProviderId) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_SP_SERVICE_STORE_PROFILE,
        payload: false,
    });

    axios({
        method: "get",
        url: "/service-provider/auth/getServiceProviderStoreProfile?serviceProvider=" + serviceProviderId,
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_SP_SERVICE_STORE_PROFILE,
                payload: res.data.serviceProvider,
            });
        })
        .catch((err) => {
            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};

export const getCartItems = (userId) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_CART_ITEMS,
        payload: false,
    });

    axios({
        method: "get",
        url: `/user/cart/getCartItems?userId=${userId}`,
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_CART_ITEMS,
                payload: res.data.cart,
            });
        })
        .catch((err) => {
            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};

export const getBasicCartItems = (userId) => (dispatch) => {
    axios({
        method: "get",
        url: `/user/cart/getBasicCartItems?userId=${userId}`,
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_BASIC_CART_ITEMS,
                payload: res.data.basicCart,
            });
        })
        .catch((err) => {
            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};

export const manageCartQuantity = (cartId, quantity, userId) => (dispatch) => {
    axios({
        method: "put",
        url: `/user/cart/updateServiceQuantity?userId=${userId}`,
        data: {
            cartId,
            quantity,
        },
    })
        .then((res) => {
            dispatch({
                type: actionTypes.MANAGE_CART_QUANTITY,
                payload: {
                    cartId,
                    quantity,
                },
            });
        })
        .catch((err) => {
            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};

export const manageCartDelete = (serviceId) => (dispatch) => {
    // UPDATE REDUX CART ITEMS
    dispatch({
        type: actionTypes.MANAGE_CART_DELETE,
        payload: {
            serviceId,
        },
    });
};

export const setBillingAddress = (data) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_BILLING_ADDRESS,
        payload: data,
    });
};
export const setGlobalGiving = (data) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_GLOBAL_GIVING,
        payload: data,
    });
};

export const getGlobalGivingProjects = (type, nextProjectId) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_GLOBAL_GIVING_PROJECTS,
        payload: false,
    });
    axios({
        method: "get",
        url:
            "https://api.globalgiving.org/api/public/projectservice/all/projects/active?api_key=" +
            globalGivingKey +
            "&nextProjectId=" +
            nextProjectId,
        externalUrl: true,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_GLOBAL_GIVING_PROJECTS,
                payload: res.data.projects.numberFound == 0 ? [] : res.data.projects.project,
            });
        })
        .catch((err) => {
            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });

            dispatch({
                type: actionTypes.SET_GLOBAL_GIVING_PROJECTS,
                payload: [],
            });
        });
};

export const getAllSurvey = (limit, page, action) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_SURVEY,
        payload: false,
    });

    axios({
        method: "get",
        url: `/dashboard/user/getSurvey?surveyName=${
            action && action !== "All" ? action : ""
        }&page=${page}&limit=${limit}`,
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_SURVEY,
                payload: res.data.survey,
            });
            dispatch({
                type: actionTypes.SET_SURVEY_COUNT,
                payload: res.data.count,
            });
            {
                !action &&
                    dispatch({
                        type: actionTypes.SET_SURVEY_DROP_DOWN,
                        payload: res.data.surveyDropDown,
                    });
            }
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.SET_SURVEY,
                payload: [],
            });

            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};

export const getAllNotifications = (limit, page, search) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_NOTIFICATIONS,
        payload: false,
    });

    axios({
        method: "get",
        url: `/dashboard/user/getAllPushNotifications?page=${page}&limit=${limit}&search=${search ?? ""}`,
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_NOTIFICATIONS,
                payload: res.data.notification,
            });
            dispatch({
                type: actionTypes.SET_NOTIFICATIONS_COUNT,
                payload: res.data.totalData,
            });
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.SET_NOTIFICATIONS,
                payload: [],
            });

            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};

export const getKpiData = (start, end) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_GRAPH_DATA,
        payload: false,
    });

    axios({
        method: "get",
        url: `/dashboard/user/getKpiData?startDate=${start ?? ""}&endDate=${end ?? ""}`,
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_GRAPH_DATA,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.SET_GRAPH_DATA,
                payload: [],
            });

            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};

export const getRatings = (limit, page, search, start, end, subCategory) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_RATINGS,
        payload: false,
    });

    axios({
        method: "get",
        url: `/dashboard/user/getRatings?page=${page}&limit=${limit}&search=${search ?? ""}&startDate=${
            start ?? ""
        }&endDate=${end ?? ""}&subCategory=${subCategory ?? ""}`,
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_RATINGS,
                payload: res.data.journey,
            });
            dispatch({
                type: actionTypes.SET_RATINGS_COUNT,
                payload: res.data.totalData,
            });
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.SET_RATINGS,
                payload: [],
            });

            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};

export const getPromoCodes = (limit, page, search, start, end) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_PROMO,
        payload: false,
    });

    axios({
        method: "get",
        url: `/dashboard/promoCode/getPromoCodes?page=${page}&limit=${limit}&search=${search ?? ""}&startDate=${
            start ?? ""
        }&endDate=${end ?? ""}`,
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_PROMO,
                payload: res.data.promo,
            });
            dispatch({
                type: actionTypes.SET_PROMO_COUNT,
                payload: res.data.totalData,
            });
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.SET_PROMO,
                payload: [],
            });

            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};

export const getCompanyIds = (limit, page, search, start, end) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_COMPANY_ID,
        payload: false,
    });

    axios({
        method: "get",
        url: `/dashboard/companyId/getAllCompanyId?page=${page}&limit=${limit}&search=${search ?? ""}&startDate=${
            start ?? ""
        }&endDate=${end ?? ""}`,
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_COMPANY_ID,
                payload: res.data.companyId,
            });
            dispatch({
                type: actionTypes.SET_COMPANY_ID_COUNT,
                payload: res.data.totalData,
            });
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.SET_COMPANY_ID,
                payload: [],
            });

            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};
