import React from "react";
import styles from "./General.module.css";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TopBar from "../../../components/utils/TopBar/TopBar";
import MediaHandler from "../../../components/Media/MediaHandler";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { connect } from "react-redux";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import { showAlert } from "../../../containers/app/actions";
import { getLifeCoachGeneral } from "../../../containers/lifeCoaches/actions";
import { updateLifeCoach } from "../../../containers/lifeCoaches/actions";
import Select from "@material-ui/core/Select";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getAllCategory } from "../../../containers/category/actions";
import { useLocation } from "react-router-dom";
const General = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const [open, setOpen] = React.useState(false);
    const [random, setRandom] = React.useState(false);
    const [allow, setAllow] = React.useState(false);
    const [formData, setFormData] = React.useState({});
    const [error, setError] = React.useState(false);
    React.useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        props.getAllCategory();
        props.getLifeCoachGeneral(query.get("coachId"));
    };
    const handleChange = (event) => {
        setFormData({ ...formData, JourneyCategory: event.target.value });
    };
    const update = () => {
        props.updateLifeCoach(formData, query.get("coachId"));
    };
    return (
        <div className={styles.container}>
            {props.data && props.category ? (
                <>
                    <MediaHandler
                        open={random}
                        onClose={() => {
                            setOpen(false);
                            setRandom(false);
                            setAllow(false);
                        }}
                        onSelectImage={(val) => {
                            if (open) {
                                setFormData({ ...formData, image: val });
                            } else {
                                setFormData({ ...formData, [open]: val });
                            }
                            setOpen(false);
                            setAllow(false);
                            setRandom(false);
                        }}
                        allowedList={allow}
                    />
                    <div className={styles.content}>
                        {/* <TopBar head="General" /> */}
                        <h2>General Settings</h2>
                        <p>Manage Coach Profile</p>
                        <div className={styles.tabs}>
                            <h3 className={styles.h3}>Basic Information</h3>
                            <hr />
                            <div className={styles.row}>
                                <div className={styles.columnimage}>
                                    <div
                                        className={styles.centerAbs}
                                        onClick={() => {
                                            setOpen(true);
                                            setAllow("image/png , image/jpeg");
                                            setRandom(Math.random());
                                        }}
                                    >
                                        {formData.image ? (
                                            <img src={formData.image} className={styles.img} />
                                        ) : (
                                            <>
                                                {props.data.lifeCoach && props.data.lifeCoach.image ? (
                                                    <img src={props.data.lifeCoach.image} className={styles.img} />
                                                ) : (
                                                    <AddAPhotoIcon style={{ padding: 20, width: 150, height: 150 }} />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className={styles.columndata}>
                                    <FormControl className={styles.textField}>
                                        <FormLabel>Name</FormLabel>

                                        <TextField
                                            className={styles.TextField}
                                            variant="outlined"
                                            defaultValue={props.data.name}
                                            onChange={(e) => {
                                                setFormData({ ...formData, name: e.target.value });
                                            }}
                                        />
                                    </FormControl>
                                    {console.log(
                                        "props.data.platform_coaches.ManagedSubcategory----------->>>>>>",
                                        props.data.platform_coaches.ManagedSubcategory,
                                    )}
                                    <FormControl className={styles.textFieldCategory}>
                                        <FormLabel>Journey Category</FormLabel>
                                        <Select
                                            multiple={true}
                                            className={styles.TextField}
                                            variant="outlined"
                                            defaultValue={
                                                props.data.platform_coaches.ManagedSubcategory &&
                                                props.data.platform_coaches.ManagedSubcategory
                                                    ? props.data.platform_coaches.ManagedSubcategory.map(
                                                          (res) => res._id,
                                                      )
                                                    : []
                                            }
                                            onChange={handleChange}
                                            input={<Input />}
                                        >
                                            {props.category &&
                                                props.category.map((name) => {
                                                    return name?.subCategory?.map((res) => (
                                                        <MenuItem key={res?._id} value={res?._id}>
                                                            {res?.subCategoryName || res?.serviceSubCatName}
                                                        </MenuItem>
                                                    ));
                                                })}
                                        </Select>
                                    </FormControl>
                                    <FormControl className={styles.textFieldBio}>
                                        <FormLabel>Bio0</FormLabel>

                                        <TextField
                                            className={styles.TextField}
                                            variant="outlined"
                                            defaultValue={
                                                props.data.platform_coaches && props.data.platform_coaches.shortBio
                                                    ? props.data.platform_coaches.shortBio
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                setFormData({ ...formData, bio: e.target.value });
                                                if (e.target.value.length > 250) {
                                                    setError(true);
                                                } else {
                                                    setError(false);
                                                }
                                            }}
                                            multiline
                                            rows={10}
                                        />
                                        {error ? (
                                            <p style={{ color: "red" }}>The Bio should be less then 250 characters</p>
                                        ) : (
                                            <></>
                                        )}
                                    </FormControl>
                                </div>
                            </div>
                            <div className={styles.row2}>
                                <Button
                                    className={styles.button}
                                    onClick={() => {
                                        update();
                                    }}
                                    disabled={error}
                                >
                                    Update
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <CircularProgress />
            )}
        </div>
    );
};
const mapStateToProps = (state) => ({
    category: state.category.categoryList,
    data: state.lifeCoachesAdmin.lifecoachgeneral,
});

export default connect(mapStateToProps, { showAlert, getLifeCoachGeneral, getAllCategory, updateLifeCoach })(General);
