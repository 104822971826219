import React from "react";
import styles from "./Kpi.module.css";
import TextField from "@material-ui/core/TextField";
import AppLoader from "../../utils/AppLoader/AppLoader";
import { connect } from "react-redux";
import { getKpiData } from "../../../containers/endUser/actions";
import { withRouter } from "react-router-dom";
import { Chart } from "react-google-charts";

const Kpi = (props) => {
    const [start, setStart] = React.useState();
    const [end, setEnd] = React.useState();
    React.useEffect(() => {
        props.getKpiData();
    }, []);

    const handleChange = (startDate, endDate) => {
        if (startDate) {
            setStart(startDate);
        }
        if (endDate) {
            setEnd(endDate);
        }
        props.getKpiData(startDate ?? start, endDate ?? end);
    };
    const data = props.kpi && props.kpi.habitSubCategory;
    const notificationData = props.kpi && props.kpi.habitNotification;
    const habitCompletedData = props.kpi && props.kpi.habitCompleted;
    const suggestVsCreated = [
        ["suggestedHabit", "createdHabit"],
        [props.kpi && props.kpi.suggestedHabit, props.kpi && props.kpi.createdHabit],
    ];

    const options = {
        legend: { position: "none" },
        hAxis: { textPosition: "none" },
    };
    return (
        <div className={styles.container}>
            <div className={styles.topHeader}>
                <div className={styles.leftHeader}>
                    <h3>KPI</h3>
                    <p>Manage customer information</p>
                </div>
                <div className={styles.rightHeader}>
                    <TextField
                        type="date"
                        label="Start Date"
                        variant="outlined"
                        onChange={(e) => {
                            handleChange(e.target.value, null);
                        }}
                        InputLabelProps={{ shrink: true, required: true }}
                        className={styles.textField}
                    />
                    <TextField
                        type="date"
                        label="End Date"
                        variant="outlined"
                        onChange={(e) => {
                            handleChange(null, e.target.value);
                        }}
                        InputLabelProps={{ shrink: true, required: true }}
                        className={styles.textField}
                    />
                </div>
            </div>

            {!props.kpi && <AppLoader />}
            {props.kpi && (
                <div className={styles.chart}>
                    {" "}
                    {data && data.length > 1 && (
                        <Chart chartType="Bar" width="100%" height="400px" data={data} options={options} />
                    )}
                    <div className={styles.flex}>
                        <div className={styles.flexData}>
                            {notificationData && notificationData.length > 1 && (
                                <Chart
                                    chartType="Line"
                                    width="100%"
                                    height="400px"
                                    data={notificationData}
                                    options={options}
                                />
                            )}
                        </div>
                        <div className={styles.flexData}>
                            {habitCompletedData && habitCompletedData.length > 0 && (
                                <Chart
                                    chartType="Line"
                                    width="100%"
                                    height="400px"
                                    data={habitCompletedData}
                                    options={options}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.flex}>
                        <div className={styles.flexData}>
                            <Chart
                                chartType="PieChart"
                                width="100%"
                                height="400px"
                                data={[
                                    ["CreatedHabit", "Suggestedhabit"],
                                    ["createdHabit", props.kpi.createdHabit],
                                    ["suggestedHabit", props.kpi.suggestedHabit],
                                ]}
                                options={{
                                    backgroundColor: "transparent",
                                    pieHole: 0.4,
                                }}
                            />
                        </div>
                        <div className={styles.flexData}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.kpi &&
                                        props.kpi.topUserData.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{data.firstName}</td>
                                                    <td>{data.lastName}</td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>

                            <p>Top active Users</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
const mapStateToProps = (state) => ({
    kpi: state.endUser.kpi,
});
export default withRouter(connect(mapStateToProps, { getKpiData })(Kpi));
