import React from 'react'
import styles from './Bookings.module.css'

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import QuestionFromUser from './ModalComp/ModalComp';
import MeetingInfo from './MeetingInfo/MeetingInfo';

import {connect} from 'react-redux'
import { showAlert } from '../../../containers/app/actions';
import {getAllBookings} from '../../../containers/lifeCoach/actions'
import AppLoader from '../../utils/AppLoader/AppLoader';


const Bookings = (props) => {
    const [questionModal,setQuestionModal] = React.useState(false);
    const [meetingModal,setMeetingModal] = React.useState(false)

    React.useEffect(() => {
        props.getAllBookings();
    },[])

    let obj = {};
    props.bookings && props.bookings.forEach(val => {
        let time = val.timings;
        if(obj[time.startDate + time.startTime + time.endTime]) {
            obj[time.startDate + time.startTime + time.endTime].push(val)
        } else {
            obj[time.startDate + time.startTime + time.endTime] = [val];
        }
    })
    return (
        <React.Fragment>
            <QuestionFromUser
                open={questionModal}
                onClose={() => setQuestionModal(false)}
            />
            <MeetingInfo
                open={meetingModal}
                showAlert={props.showAlert}
                onClose={() => setMeetingModal(false)}
            />
            <div className={styles.container}>
                <div className={styles.content}>

                    {!props.bookings && <AppLoader />}
                    {props.bookings && Object.values(obj).map(booking => {
                        let bookData = booking[0];
                        return (
                            <Accordion className={styles.accordion}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className={styles.head}>
                                        <div className={styles.item}>
                                            <span>Date</span>
                                            <p>{bookData.timings.startDate}</p>
                                        </div>
                                        <div className={styles.item}>
                                            <span>Time Slot</span>
                                            <p>{bookData.timings.startTime} - {bookData.timings.endTime}</p>
                                        </div>
                                        <div className={styles.item}>
                                            <span>Service</span>
                                            <p>Live With Coach</p>
                                        </div>
                                        <div className={styles.item}>
                                            <span>No of Bookings</span>
                                            <p>{booking.length}</p>
                                        </div>
                                        <div className={styles.item}>
                                            <button className={"btnGreen"}
                                                onClick={() => setMeetingModal(booking)}
                                            >Meeting Link</button>
                                            <button className={"btnRed"}>Cancel</button>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails className={styles.bookSubDataDiv}>
                                    <div className={styles.bookingInfo}>
                                        {booking.map(bookData => {
                                            console.log(bookData)
                                            let name = bookData.userId && bookData.userId.userProfile ? (bookData.userId.userProfile.firstName ? bookData.userId?.userProfile.firstName : bookData.userId.username) : "User"
                                            return (
                                                <div className={styles.bookSubData}>
                                                    <div className={styles.item}>
                                                        <span>Date</span>
                                                        <p>{bookData.timings.startDate}</p>
                                                    </div>
                                                    <div className={styles.item}>
                                                        <span>Time Slot</span>
                                                        <p>{bookData.timings.startTime} - {bookData.timings.endTime}</p>
                                                    </div>
                                                    <div className={styles.item}>
                                                        <span>Customer</span>
                                                        <p>{name}</p>
                                                    </div>
                                                    <div className={styles.item}>
                                                        <button className={"btnGreen"}
                                                            onClick={() => setQuestionModal(bookData)}
                                                        >View Questions</button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </div>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = state => ({
    bookings: state.lifeCoachAdmin.bookings
})
export default connect(mapStateToProps, { getAllBookings, showAlert})(Bookings);