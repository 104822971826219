import * as actionTypes from './actionTypes'

const initialState = {
    lifecoach: false,
    lifecoachnavbar:false

}
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.GET_DATA:
            return { ...state, lifecoach: action.payload }
            break;
        case actionTypes.GET_DATA_NAVBAR:
            return {...state,lifecoachnavbar:action.payload}
            break;
        case actionTypes.UPDATE_DATA:
            return {...state,lifecoach:action.payload}
        default:
            return state
        

    }
}

export default reducer;