import React from 'react'
import styles from './PersonalDetails.module.css'

import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import Country from '../../../utils/Country/Country'
import SearchAddress from '../../../utils/SearchAddress/SearchAddress'
import MediaHandler from '../../../Media/MediaHandler'

import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';

import {getAllEndUsers} from '../../../../containers/endUser/actions'
import {showAlert,setAuth} from '../../../../containers/app/actions'

import {connect} from 'react-redux' 
import moment from 'moment' 
import axios from 'axios' 
import BtnLoader from '../../../utils/BtnLoader/BtnLoader'

import {useLocation} from 'react-router-dom'

const PersonalDetails = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    
    const [formData,setFormData] = React.useState({
        profilePicture: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        dob: "",
        gender: "",
        country: "",
        address: "",
        username: ""
    })
    const [formError,setFormError] = React.useState({
        profilePicture: false,
        firstName: false,
        lastName: false,
        email: false,
        phoneNumber: false,
        dob: false,
        gender: false,
        country: false,
        address: false, 
    });
    const [open,setOpen] = React.useState(false);
    const [loading,setLoading] = React.useState(false);
    const [available,setAvailable] = React.useState(false);

    React.useEffect(() => {
        if(props.userData) {
            setFormData({...formData,...props.userData})
        }
    },[props.userData])

    const onSubmit = () => {
        setLoading("PROFILE");
        axios({
            method: "post",
            url: `/user/user/updateUserProfile?userId=${query.get("userId")}`,
            data: {
                ...formData
            }
        }).then(res => {
            setLoading(false); 
            props.showAlert("User Profile Updated"); 
            props.getAllEndUsers()
        }).catch(err => {
            if(err && err.response) 
                    props.showAlert(err.response.data.error)
                else 
                    props.showAlert(('Something went wrong Try Again'))

                setLoading(false);
        })
    }

    const handleUsernameChange = (e) => {
        let value = e.target.value.trim().replace(/[^a-zA-Z0-9]/,'');
        setFormData({...formData,username: value})
        if(value.length < 5) {
            setFormError({...formError,username: "Username must be at least 5 characters"})
        } else {
            setAvailable("LOADING");
            axios({
                method: "get",
                url: `/user/auth/checkUsernameAvailable?username=${value}&userId=${query.get("userId")}`,
            }).then(res => {
                if(res.data.available) {
                    setAvailable("YES")
                    setFormError({...formError,username: false})
                } else {
                    setAvailable(false)
                    setFormError({...formError,username: "Username not available"})
                }
            }).catch(err => {
                setAvailable(false)
                setFormError({...formError,username: "Username not available"})
            })
        }
    }
    const handleUserNameUpdate = (e) => {
        setLoading("USERNAME");
        axios({
            method: "post",
            url: `/user/user/updateUserName?userId=${query.get("userId")}`,
            data: {
                username: formData.username
            }
        }).then(res => {
            setLoading(false); 
            props.showAlert("Username Updated")
        }).catch(err => {
            if(err && err.response) 
                    props.showAlert(err.response.data.error)
                else 
                    props.showAlert(('Something went wrong Try Again'))

                setLoading(false);
        })
    }


    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <MediaHandler open={open} onClose={() => setOpen(false)} onSelectImage={(val) => {
                    setFormData({...formData,profilePicture: val})
                    setOpen(false)
                }}/>


                <h1>Personal Details</h1>
                <p>Update your personal information</p>

                <div className={styles.profileContainer}>
                    <div className={styles.rowContent}>
                        <Tooltip title="Click to Change Picture">
                            <div className={styles.imgContainer} onClick={() => setOpen(true)}>
                                <React.Fragment>
                                    {formData.profilePicture 
                                    ?
                                    <img src={formData.profilePicture} />
                                    :
                                    <ImageRoundedIcon style={{fontSize: 30,color: '#333'}}/>}
                                </React.Fragment>
                            </div>

                        </Tooltip>

                        <button className={styles.choose} onClick={() => setOpen(true)}>Choose</button>
                    </div>
  
                    {/* <div className={styles.rowButton}>
                        <FormControl className={styles.textField}>
                            <FormLabel className={styles.label}>Username</FormLabel>
                            <TextField
                                variant="outlined"
                                fullWidth 
                                value={formData.username}
                                onChange={handleUsernameChange}
                                error={formError.username}
                                helperText={formError.username}
                                className={styles.textField}

                                InputProps={{
                                    endAdornment: 
                                    <InputAdornment position="start">
                                        {available == "LOADING" && <CircularProgress size={18} />}
                                        {available == "YES" && <CheckCircleRoundedIcon style={{color: "green"}} />}
                                    </InputAdornment>,
                                }}
                            />
                        </FormControl>

                        {loading == "USERNAME"
                            ?
                        <BtnLoader className="btnGreen" />
                            :
                        <button className="btnGreen" onClick={handleUserNameUpdate}>Update</button>}
                    </div> */}
                </div>

                
                
                <div className={styles.row}>
                    <FormControl className={styles.textField}>
                        <FormLabel className={styles.label}>First Name</FormLabel>
                        <TextField 
                            variant="outlined" 
                            value={formData.firstName}
                            onChange={e => setFormData({...formData,firstName: e.target.value})}
                            error={formError.firstName}
                            helperText={formError.firstName}
                        /> 
                    </FormControl>

                    <FormControl className={styles.textField}>
                        <FormLabel className={styles.label}>Last Name</FormLabel>
                        <TextField 
                            variant="outlined" 
                            value={formData.lastName}
                            onChange={e => setFormData({...formData,lastName: e.target.value})}
                            error={formError.lastName}
                            helperText={formError.lastName}
                        /> 
                    </FormControl>
                </div>


                <div className={styles.row}>
                    
                    <FormControl className={styles.textField}>
                        <FormLabel className={styles.label}>Email Address</FormLabel>
                        <TextField 
                            variant="outlined" 
                            value={formData.email}
                            onChange={e => setFormData({...formData,email: e.target.value})}
                            error={formError.email}
                            helperText={formError.email}
                            disabled
                        /> 
                    </FormControl>

                    <FormControl className={styles.textField}>
                        <FormLabel className={styles.label}>Phone Number</FormLabel>
                        <TextField 
                            variant="outlined" 
                            value={formData.phoneNumber}
                            onChange={(e) => {
                                e.target.value.length <= 11 && setFormData({...formData,phoneNumber: e.target.value.replace(/\D/,'')})
                            }} 
                            error={formError.phoneNumber}
                            helperText={formError.phoneNumber}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">+ {formData.country && formData.country.phone}</InputAdornment>,
                            }}
                        /> 
                    </FormControl>
                </div>

                <div className={styles.row}>
                    <FormControl className={styles.textField}>
                        <FormLabel className={styles.label}>Date Of Birth</FormLabel>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker 
                                variant="outlined" 
                                value={formData.dob}
                                autoOk={true}
                                onChange={date => setFormData({...formData,dob:  moment(date).format("yyyy-MM-DD")})}
                                error={formError.dob}
                                format="yyyy-MM-dd"
                                inputVariant="outlined"
                                maxDate={new Date().toISOString().slice(0,10)}
                                helperText={formError.dob}
                                InputLabelProps={{ shrink: true, required: true }}
                            /> 
                        </MuiPickersUtilsProvider>
                    </FormControl> 

                    <FormControl className={styles.textField} variant="outlined">
                        <FormLabel className={styles.label}>Gender</FormLabel>
                        
                        <Select onChange={e => setFormData({...formData,gender: e.target.value})} >
                            <MenuItem value="MALE">Male</MenuItem>
                            <MenuItem value="FEMALE">Female</MenuItem>
                            <MenuItem value="OTHER">Other</MenuItem>
                        </Select>
                    </FormControl>
                </div>



                <div className={styles.row}>
                    <FormControl className={styles.textField}>
                        <FormLabel className={styles.label}>Country</FormLabel>
                        <Country
                            variant="outlined" 
                            label={false}
                            value={formData.country}
                            onChange={val => setFormData({...formData,country: val})}
                            error={formError.country}
                            helperText={formError.country}
                        />
                    </FormControl>

                    <FormControl className={styles.textField}>
                        <FormLabel className={styles.label}>Search Address</FormLabel>
                        <SearchAddress
                            setFormData={(key,val) => setFormData({...formData,address: val.trimStart().replace(/[^a-zA-Z0-9]/,'')})}
                            value={formData.address} 
                            error={formError.address}
                            helperText={formError.address} 
                            country={formData.country}
                            style={{backgroundColor: 'var(--primary-bg-color'}}
                            placeholder="Search Address"
                        />
                    </FormControl>
                </div>

                {loading == "PROFILE"
                    ?
                <BtnLoader className="btnGreen" />
                    :
                <button className="btnGreen" onClick={onSubmit}>SAVE</button>}

            </div>
        </div>
    )
} 
export default connect(null, {showAlert,setAuth,getAllEndUsers})(PersonalDetails);