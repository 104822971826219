import React from 'react'
import styles from './MailComp.module.css'

import MailSystem from './MailSystem/MailSystem'

import Paper from '@material-ui/core/Paper'

import {connect} from 'react-redux'
import {getEmailList} from '../../containers/mail/actions'
import {useLocation,withRouter} from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'

const ChatComp = (props) => {     
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    
    const [emailList,setEmailList] = React.useState(false); 
    const [searchVal,setSearchVal] = React.useState("");
    const [activeValue,setActiveValue] = React.useState("")

    React.useEffect(() => {
        props.getEmailList();
    },[]);

    React.useEffect(() => { 
        if(props.emailList) { 
            setEmailList([...new Map(props.emailList.map(item => [item['email'], item])).values()])
        } else {
            setEmailList(false)
        }
    },[props.emailList]);

    let isLoading = !emailList;
    let showData = !isLoading;
 
    return (
        <Paper className={styles.container} variant="outlined"> 
            <div className={styles.leftContent}>
                <h1>Email List</h1>

                {(props.auth.userType == "ADMIN" || props.auth.userType == "SALES") &&
                    <div className={styles.searchBar}>
                        <input type="text" placeholder="Search Here.." value={searchVal} onChange={e => setSearchVal(e.target.value)} />
                    </div>}

                {isLoading &&
                    <div className={styles.loader}>
                        <CircularProgress size={18} />
                    </div>
                }
                {showData &&
                <React.Fragment>
                    {query.get("email") &&
                        <div  
                            className={styles.activeListItem}
                        >{query.get("email")}
                        </div>}

                    {emailList.map((val,index) => {
                        if(props.auth.userType == "ADMIN" || props.auth.userType == "SALES") {
                            if (val.email.toLowerCase().includes(searchVal.toLowerCase()) && val.email != query.get("email")) {
                                return (
                                    <div
                                        key={index} 
                                        onClick={() => {
                                            setActiveValue(val)
                                            props.history.push("/user/email-system?email=" + val.email)
                                        }}
                                        className={query.get("email") == val.email ? styles.activeListItem : styles.listItem}
                                    >{val.email}
                                    </div>
                                )
                            } else return;
                        }
                    })}
                </React.Fragment>}
            </div>
            <div className={styles.rightContent}> 
                {showData && <MailSystem  activeValue={activeValue} />}
            </div> 
        </Paper>
    )
}

const mapStateToProps = state => ({
    emailList: state.email.emailList,
    auth: state.app.auth
})
export default withRouter(connect(mapStateToProps,{getEmailList})(ChatComp));