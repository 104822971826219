import { usePriceFormatter } from "../../../../hooks/usePriceFormatter";
import styles from "../ServiceSpecs.module.css"; 


export function Pricing({ quantity, service }) {
  const price = service?.pricePerPerson || 1 * quantity || 1;

  return (
    <div className={`${styles.flexContainer} ${styles.borderBottom}`}>
      <div className={`${styles.flexContainer} ${styles.priceContainer}`}>
        {usePriceFormatter(price)}
      </div> 
    </div>
  );
}
