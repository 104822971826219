import React from 'react'; 
 
import TopBar from '../../components/utils/TopBar/TopBar';
import MailComp from '../../components/Mail/MailComp'   

const Mail = (props) => {  
    return (
        <div>
            <TopBar head={"Email System"} />
             
            <MailComp />
        </div>
    )
}

export default Mail;