import React from 'react'
import styles from './ViewPartnerAgreement.module.css';
 
import Button from '@material-ui/core/Button' 
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton'

import AddRoundedIcon from '@material-ui/icons/AddRounded';

import AppLoader from '../../utils/AppLoader/AppLoader';
import DataTable from 'react-data-table-component';

import {connect} from 'react-redux'
import { getAllPartnerAgreement} from '../../../containers/app/actions'
import {withRouter} from 'react-router-dom' 
import ConfPartnerAgreement from '../ConfPartnerAgreement/ConfPartnerAgreement';
import TopBar from '../../utils/TopBar/TopBar';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';

const ViewPartnerAgreement = (props) => { 
    const [partnerAgreement, setPartnerAgreement] = React.useState(props.partnerAgreement);
    const [open,setOpen] = React.useState(false);
    
    React.useEffect(() => {
        if (!props.partnerAgreement) {
            props.getAllPartnerAgreement();
        }
        setPartnerAgreement(props.partnerAgreement);
    }, [props.partnerAgreement]);

    let isLoading = !partnerAgreement;
    let showData = !isLoading;
    let rowData = [];

    !isLoading && partnerAgreement.forEach((data,index) => {
        rowData.push({
            sl_no: 
            <React.Fragment>
                {index + 1}
                {index == 0 && <span className={styles.active}> Active</span>}
            </React.Fragment>,
            description: data.description,
            version: data.version,
            key: data.key,
            date: new Date(data.createdAt).toString().substring(0,15),
            actions: 
            <div className="actions">
                <Tooltip title="View Agreement">
                    <IconButton onClick={() => window.open(data.agreementLink)}>
                        <VisibilityRoundedIcon />
                    </IconButton>
                </Tooltip> 
            </div>
        })
    }); 
 
    return (
        <React.Fragment>
            {open && 
            <ConfPartnerAgreement 
                open={open} 
                onClose={() => setOpen(false)}
                getAllPartnerAgreement={props.getAllPartnerAgreement}
            />}

            <div className={styles.container}>
                <TopBar head="Partner Agreement" />

                <div className={styles.header}>
                    <div className={styles.leftHeader}>
                        &nbsp;
                    </div>

                    <div className={styles.rightHeader}>
                        <Button color="primary" variant="contained" endIcon={<AddRoundedIcon />} onClick={() => setOpen(true)}>Add New Partner Agreement</Button>
                    </div>
                </div>

                {isLoading && <AppLoader />}

                {showData &&
                <DataTable 
                    noHeader={true} 
                    fixedHeader={true}
                    pagination={true}
                    fixedHeaderScrollHeight={"calc(100vh - 320px)"}
                    columns={[
                        {name: "Sl No",selector: "sl_no",sortable: true},
                        {name: "Description",selector: "description",sortable: true, maxWidth: '500px'},
                        {name: "Version", selector: "version", sortable: true },
                        {name: "Key Name",selector: "key",sortable: true}, 
                        {name: "Created Date",selector: "date",sortable: true},
                        {name: "Action",selector: "actions"}]}
                    data={rowData}
                />}

            </div>
        </React.Fragment>
    )
}
const mapStateToProps = state => ({
    partnerAgreement: state.app.partnerAgreement
})
export default withRouter(connect(mapStateToProps, { getAllPartnerAgreement})(ViewPartnerAgreement));