import React from "react";
import styles from "./ContactCustomer.module.css";

import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";

import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import { connect } from "react-redux";
import { showAlert } from "../../../../containers/app/actions";
import axios from "axios";

import BtnLoader from "../../../utils/BtnLoader/BtnLoader";

const ContactCustomer = (props) => {
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [error, setError] = React.useState(false);

    const onSubmit = () => {
        if (message == "") {
            setError("Enter valid message");
        } else {
            setLoading(true);
            axios({
                method: "put",
                url: "/dashboard/life-coaches/contactCustomer",
                data: {
                    message: message,
                    user: props.customer,
                },
            })
                .then((res) => {
                    setLoading(false);
                    props.showAlert("Mail Sent Successfully");
                    props.onClose();
                })
                .catch((err) => {
                    if (err && err.response) props.showAlert(err.response.data.error);
                    else props.showAlert("Something went wrong Try Again");

                    setLoading(false);
                });
        }
    };

    return (
        <Modal className={styles.modal} open={props.open} onClose={props.onClose}>
            <React.Fragment>
                <div className={styles.container}>
                    <CloseRoundedIcon className={styles.close} onClick={props.onClose} />
                    <h1>"Please include a message for your customers"</h1>
                    <TextField
                        multiline
                        className={styles.textField}
                        variant="outlined"
                        rows={5}
                        fullWidth
                        onChange={(e) => setMessage(e.target.value.replace(/\n/g, "<br>"))}
                        error={error}
                        helperText={error}
                        placeholder={"Tips: Be polite and request for their understanding."}
                    />

                    <div className="btnCenter">
                        {loading ? (
                            <BtnLoader className="btnGreen" />
                        ) : (
                            <button className="btnGreen" onClick={onSubmit}>
                                SEND
                            </button>
                        )}
                    </div>
                </div>
            </React.Fragment>
        </Modal>
    );
};

export default connect(null, { showAlert })(ContactCustomer);
