import React from "react";
import styles from "./Niv.module.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import TopBar from "../../components/utils/TopBar/TopBar";
import ManageSchedule from "../../components/Niv/ManageSchedule/ManageSchedule";
import Bookings from "../../components/Niv/Bookings/Bookings";
import ViewSchedule from "../../components/Niv/ViewSchedule/ViewSchedule";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Button } from "@material-ui/core";
import { showAlert } from "../app/actions";
import { connect } from "react-redux";
import axios from "axios";
const Niv = (props) => {
    const [tab, setTab] = React.useState("BOOKINGS");
    const [search, setSearch] = React.useState("");
    const [category, setCategory] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const [bio, setBio] = React.useState({});
    const [error, setError] = React.useState(false);
    const [loadingButton, setLoadingButton] = React.useState(true);
    const [formData, setFormData] = React.useState({
        dates: [],
    });

    React.useEffect(() => {
        setLoading(true);
        const url = "/dashboard/category/getCategory/" + props.match.params.type;
        const method = "get";
        const url2 = "/dashboard/life-coaches/getBio/" + props.match.params.type;
        axios({
            method,
            url,
        })
            .then((res) => {
                setCategory(res.data);
                axios({
                    method: "get",
                    url: "/dashboard/life-coaches/getBio/" + props.match.params.type,
                })
                    .then((res) => {
                        if (res.data && res.data.bio) {
                            setBio(res.data.bio);
                            setLoading(false);
                        } else {
                            setBio("");
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        setLoading(false);
                        console.log(err);
                    });
            })
            .catch((err) => {
                setLoading(false);
            });
    }, [props.match.params.type]);

    const update = () => {
        setLoadingButton(false);
        var data = {
            JourneyCategory: props.match.params.type,
            bio: bio,
        };
        axios({
            method: "post",
            url: "/dashboard/life-coaches/updateBio",
            data,
        })
            .then((res) => {
                setLoadingButton(true);
                props.showAlert("Bio Updated Successfully");
            })
            .catch((err) => {
                setLoadingButton(true);
                props.showAlert("Something went wrong");
            });
    };
    return (
        <div className={styles.container}>
            {loading ? (
                <CircularProgress />
            ) : (
                <div className={styles.content}>
                    <TopBar head="21Done" />
                    <div style={{ display: "flex" }}>
                        <div style={{ marginTop: "auto", marginBottom: "auto", width: "40%" }}>
                            <h3>{category.category.categoryName}</h3>
                            <p>Manage Your Live With Coach Session</p>
                        </div>
                        <div className={styles.formContainer}>
                            <FormControl style={{ width: "40%" }}>
                                <FormLabel>Bio</FormLabel>

                                <TextField
                                    size="small"
                                    className={styles.TextField}
                                    variant="outlined"
                                    defaultValue={bio}
                                    onChange={(e) => {
                                        setBio(e.target.value);
                                        if (e.target.value.length > 250) {
                                            setError(true);
                                        } else {
                                            setError(false);
                                        }
                                    }}
                                />
                                {error ? (
                                    <p style={{ color: "red" }}>The Bio should be less then 250 characters</p>
                                ) : (
                                    <></>
                                )}
                            </FormControl>

                            <Button
                                className={styles.button}
                                onClick={() => {
                                    update();
                                }}
                                disabled={error}
                            >
                                {loadingButton ? <>Update</> : <>updating...</>}
                            </Button>
                        </div>
                    </div>
                    <div className={styles.tabs}>
                        <Tabs value={tab} onChange={(e, val) => setTab(val)} indicatorColor="secondary">
                            <Tab label="Bookings" value="BOOKINGS" />
                            <Tab label="Add Slots" value="MANAGE_SCHEDULE" />
                            <Tab label="Manage Slots" value="VIEW-SCHEDULE" />
                        </Tabs>
                    </div>
                    {tab == "BOOKINGS" && <Bookings search={search} match={props.match} />}
                    {tab == "MANAGE_SCHEDULE" && (
                        <ManageSchedule formData={formData} setFormData={(val) => setFormData(val)} />
                    )}
                    {tab == "VIEW-SCHEDULE" && <ViewSchedule />}
                </div>
            )}
        </div>
    );
};

export default connect(null, { showAlert })(Niv);
