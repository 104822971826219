import React from 'react'
import styles from './CheckoutItems.module.css'

import TextField from '@material-ui/core/TextField'

import CheckoutItem from './CheckoutItem/CheckoutItem'
import AppLoader from '../../../utils/AppLoader/AppLoader'
import GlobalGiving from './GlobalGiving/GlobalGiving'
import ModalPage from '../../../utils/ModalPage/ModalPage' 
import BillingAddress from './BillingAddress/BillingAddress'

import AddRoundedIcon from '@material-ui/icons/AddRounded'; 
 
import {setBillingAddress,setGlobalGiving,getGlobalGivingProjects} from '../../../../containers/endUser/actions'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'


const CheckoutItems = (props) => {
    const [globalGiving,setGlobalGiving] = React.useState(false)
    const [billAdrModal,setBillAdrModal] = React.useState(false);
    const [address,setAddress] = React.useState();
    const [cartItems,setCartItems] = React.useState(props.cartItems)

    React.useEffect(() => { 
        if(props.globalGiving) {
            setGlobalGiving(props.globalGiving) 
        } else {
            props.getGlobalGivingProjects("NEXT",1);
        }
    },[props.globalGiving]);

    React.useEffect(() => { 
        setCartItems(props.cartItems);
    },[props.cartItems]);

    return (
        <React.Fragment>
            <ModalPage open={billAdrModal} onClose={() => setBillAdrModal(false)}>
                <h1 className={styles.head}>Billing Address</h1>
                <TextField
                    onChange={e => setAddress(e.target.value)}
                    value={address}
                    variant="outlined"
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    className={styles.textField}  
                />
                <div className={styles.btnContainer}>
                    <button className={"btnGreen"} onClick={() => {
                        setBillAdrModal(false)
                        props.setBillingAddress(address);
                    }}>Done</button>
                </div>
            </ModalPage>

            <div className={styles.container}>
                <h1 className={styles.head}>Order Confirmation</h1>

                <div className={styles.serviceContent}>
                    <h1>Experience</h1>
                    {cartItems && cartItems.map(cart => {
                        if(cart.serviceId) {
                            return (
                                <CheckoutItem cart={cart} key={cart.quantity + cart._id} />
                            )
                        }
                    })}
                </div>

                <div className={styles.serviceContent}>
                    <h1>Select Charitable Project</h1>
                    <span>No additional payment required. 3% of booking amount goes to selected project</span>

                    <div className={styles.globalGivingContent}>
                        {!globalGiving && <AppLoader />}

                        {globalGiving && 
                            <React.Fragment>
                                {props.selectedGlobalGiving &&
                                    <GlobalGiving item={props.selectedGlobalGiving} onClick={() => props.setGlobalGiving(false)} active={true}/>
                                }   
                                
                                {globalGiving.map((val,index) => {
                                    if(index < 2 && props.selectedGlobalGiving != val) {
                                        return (
                                            <GlobalGiving item={val} onClick={() => props.setGlobalGiving(val)}/>
                                        )
                                    } else return;
                                })}
                                <div className={styles.anotherProject} onClick={() => {
                                    props.history.push("/21-tage-plan?type=WHAT-THE-WORLD-NEEDS&checkout=true")
                                }}>
                                    <AddRoundedIcon />
                                    <p>Select Another Project</p>
                                </div>
                            </React.Fragment>}
                    </div> 
                </div>

                <div className={styles.serviceContent}>
                    <h1 className={styles.underlineHead}>
                        Billing Address    
                    </h1>

                    <BillingAddress />
                </div>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = state => ({
    cartItems: state.endUser.cartItems,
    billingAddress: state.endUser.billingAddress,
    globalGiving: state.endUser.globalGivingProjects,
    selectedGlobalGiving: state.endUser.globalGiving
})
export default withRouter(connect(mapStateToProps,{setBillingAddress,setGlobalGiving,getGlobalGivingProjects})(CheckoutItems));