import React from 'react'
import styles from './ViewEndUser.module.css';
import TextField from '@material-ui/core/TextField'
import DataTable from 'react-data-table-component';
import AppLoader from '../../utils/AppLoader/AppLoader';
import {connect} from 'react-redux'
import {getAllLifeCoahes} from '../../../containers/lifeCoaches/actions'
import {withRouter} from 'react-router-dom'


const Viewlifecoaches = (props) => { 
    const [users,setUsers] = React.useState(props.lifecoaches); 
    const [searchVal,setSearchVal] = React.useState("");

    React.useEffect(() => {
        if(!props.lifecoaches) {
            props.getAllLifeCoahes();
        }
        setUsers(props.lifecoaches);
    },[props.lifecoaches]);

    let isLoading = !users;
    let showData = !isLoading;
    let rowData = [];

    !isLoading && users.forEach((user,index) => { 
        if(
            (user.name.toLowerCase().includes(searchVal.toLowerCase())) || 
            (user.email.toLowerCase().includes(searchVal.toLowerCase())) 
        ) {

        
            rowData.push({                 
                sl_no: index + 1,
                name: user.name,
                email: user.email,  
                actions: 
                    <div >
                      <button className="btnGreen" style={{marginLeft:"30px"}} onClick= {()=>{ props.history.push(`/user/life-coaches-admin/view-coach-details?coachId=${user._id}`)}} >VIEW</button> 
                    </div>
            })
        } 
    });
 
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.leftHeader}> 
                &nbsp;
                </div>

                <div className={styles.rightHeader}>
                    <TextField
                        label="Search Here"
                        className={styles.search}
                        value={searchVal}
                        onChange={e => setSearchVal(e.target.value)}
                    /> 
                </div>
            </div>

            {isLoading && <AppLoader />}

            {showData &&
                <DataTable 
                    noHeader={true}
                    fixedHeader={true}
                    pagination={true}
                    fixedHeaderScrollHeight={"calc(100vh - 320px)"}
                    columns={[
                        {name: "Sl No",selector: "sl_no",sortable: true},
                        {name: "Name",selector: "name",sortable: true}, 
                        {name: "Email",selector: "email",sortable: true},
                        {name: "Schdule & Appointments",selector: "actions"}
                    ]}
                    data={rowData}
                />}

        </div>
    )
}
const mapStateToProps = state => ({
    lifecoaches: state.lifeCoachesAdmin.lifecoaches
})
export default withRouter(connect(mapStateToProps,{getAllLifeCoahes})(Viewlifecoaches));