import React from 'react'
import styles from './CheckoutItem.module.css'
  
import CircularProgress from '@material-ui/core/CircularProgress'
 
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
 
 
import axios from 'axios'
import {showAlert} from '../../../../../containers/app/actions'
import {connect} from 'react-redux'
import {getCartItems,manageCartQuantity,manageCartDelete} from '../../../../../containers/endUser/actions'
import {useLocation} from 'react-router-dom'

const CheckoutItem = (props) => { 
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [loading,setLoading] = React.useState(false)

    React.useEffect(() => {
        setLoading(false);
    },[props.cart]);

    const deleteCartItem = () => { 
        setLoading("DELETE")
        axios({
            method: "delete",
            url: `/user/cart/deleteCartItem?userId=${query.get("userId")}`,
            data: {
                cartId: props.cart._id, 
            }
        }).then(res => {
            setLoading(false)
            props.getCartItems(query.get("userId")); 
        }).catch(err => { 
            setLoading(false)
            if(err && err.response) 
                props.showAlert(err.response.data.error)
            else 
                props.showAlert("Something went wrong Try Again")
        })
    } 
    const service = props.cart.serviceId;
    const serviceImg = service.images.length > 0 ? service.images[0] : "";
    const quantity = props.cart.quantity;
    return (
        <div className={styles.container}> 
            <div className={styles.imgContent}>
                <img src={serviceImg} />
            </div>
            <div className={styles.infoContent}>
                <h2>{service.eventName}</h2>
                <p>{new Date(props.cart.date).toString().substr(0,15)} {props.cart.timeSlot.startTime}: {props.cart.timeSlot.endTime}</p>
                <h4>{service.pricePerPerson} €</h4>
            </div>
            <div className={styles.actionContent}>
                <div className={styles.topContent}>  
                    {loading == "DELETE" 
                        ?   
                    <CircularProgress size={16} />
                        :
                    <DeleteRoundedIcon style={{fontSize: 20,color: 'var(--primary-color)'}} onClick={deleteCartItem} />}

                </div>
                <div className={styles.bottomContent}> 
                    {!props.cart.journeyId &&
                        <div className={styles.qtyController}>
                            {loading == "DEC" 
                                ?
                            <CircularProgress size={16} />
                                :
                            <div className={styles.border}>
                                <RemoveRoundedIcon onClick={() => {
                                    if(quantity > 1)  {
                                        setLoading("DEC")
                                        props.manageCartQuantity(props.cart._id,quantity - 1,query.get("userId"))
                                    }
                                }} />
                            </div>}

                            <input value={quantity} onChange={(e) => e.target.value > 0 && props.manageCartQuantity(props.cart._id,e.target.value.replace(/\D/,''),query.get("userId"))} type="number" />

                            {loading == "INC" 
                                ?
                            <CircularProgress size={16} />
                                :
                            <div className={styles.border}>
                                <AddRoundedIcon 
                                    onClick={() => {
                                        setLoading("INC")
                                        props.manageCartQuantity(props.cart._id,quantity + 1,query.get("userId"))
                                    }}
                                />
                            </div>} 
                        </div>}
                </div>
            </div>
        </div>
    )   
} 
export default connect(null,{showAlert,getCartItems,manageCartQuantity, manageCartDelete})(CheckoutItem);