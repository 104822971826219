import * as actionTypes from "./actionTypes";
import axios from "axios";

export const getAllExperience = () => (dispatch) => {
    dispatch({
        type: actionTypes.SET_EXPERIENCE,
        payload: false,
    });

    axios({
        method: "get",
        url: "/dashboard/experience/getAllExperience",
    })
        .then((res) => {
            let experiences = {};
            res.data.experiences.forEach((experience) => {
                experiences[experience._id] = { ...experience, categories: {} };
                experience.categories.forEach((cat) => {
                    experiences[experience._id].categories[cat._id] = { ...cat, subCategory: {} };
                    cat.subCategory.forEach((subCat) => {
                        experiences[experience._id].categories[cat._id].subCategory[subCat._id] = {
                            ...subCat,
                            services: {},
                        };
                        subCat.services.forEach((service) => {
                            experiences[experience._id].categories[cat._id].subCategory[subCat._id].services[
                                service._id
                            ] = service;
                        });
                    });
                });
            });
            dispatch({
                type: actionTypes.SET_EXPERIENCE_MAP,
                payload: experiences,
            });
            dispatch({
                type: actionTypes.SET_EXPERIENCE,
                payload: res.data.experiences,
            });
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.SET_EXPERIENCE,
                payload: [],
            });

            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};

export const getAllCategory = () => (dispatch) => {
    dispatch({
        type: actionTypes.SET_CATEGORY_LIST,
        payload: false,
    });

    axios({
        method: "get",
        url: "/dashboard/category/getAllCategory",
    })
        .then((res) => {
            let category = {};

            dispatch({
                type: actionTypes.SET_CATEGORY_LIST,
                payload: res.data.category,
            });
            res.data.category.forEach((cat) => {
                category[cat._id] = { ...cat, subCategory: {} };
                cat.subCategory.forEach((subCat) => {
                    category[cat._id].subCategory[subCat._id] = { ...subCat, services: {} };
                    subCat.services.forEach((service) => {
                        category[cat._id].subCategory[subCat._id].services[service._id] = service;
                    });
                });
            });
            dispatch({
                type: actionTypes.SET_CATEGORY_MAP,
                payload: category,
            });
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.SET_CATEGORY_LIST,
                payload: [],
            });

            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};
export const onExperienceDelete = (experienceId) => (dispatch) => {
    axios({
        method: "delete",
        url: "/dashboard/experience/deleteExperience",
        data: {
            experienceId,
        },
    })
        .then((res) => {
            dispatch(getAllExperience());
            dispatch({
                type: "SHOW_ALERT",
                payload: "Experience Deleted Successfully",
            });
        })
        .catch((err) => {
            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};

export const onCategoryDelete = (categoryId) => (dispatch) => {
    axios({
        method: "delete",
        url: "/dashboard/category/deleteCategory",
        data: {
            categoryId,
        },
    })
        .then((res) => {
            dispatch(getAllExperience());
            dispatch({
                type: "SHOW_ALERT",
                payload: "Category Deleted Successfully",
            });
        })
        .catch((err) => {
            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};
export const onSubCategoryDelete = (subCategoryId) => (dispatch) => {
    axios({
        method: "delete",
        url: "/dashboard/sub-category/deleteSubCategory",
        data: {
            subCategoryId,
        },
    })
        .then((res) => {
            dispatch(getAllExperience());
            dispatch({
                type: "SHOW_ALERT",
                payload: "Sub Category Deleted Successfully",
            });
        })
        .catch((err) => {
            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};

export const onServiceDelete = (serviceId) => (dispatch) => {
    axios({
        method: "delete",
        url: "/dashboard/service/deleteService",
        data: {
            serviceId,
        },
    })
        .then((res) => {
            dispatch(getAllExperience());
            dispatch({
                type: "SHOW_ALERT",
                payload: "Service Deleted Successfully",
            });
        })
        .catch((err) => {
            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again",
            });
        });
};

export const getSubcategory = () => (dispatch) => {
    dispatch({
        type: "SET_SUBCATEGORY",
        payload: false,
    });
    axios({
        method: "get",
        url: "/dashboard/sub-category/getAllSubCategory",
    })
        .then((res) => {
            dispatch({
                type: "SET_SUBCATEGORY",
                payload: res.data.subcategory,
            });
        })
        .catch((err) => {
            dispatch({
                type: "SET_SUBCATEGORY",
                payload: [],
            });
        });
};

export const getCategories = () => (dispatch) => {
    dispatch({
        type: actionTypes.SET_CATEGORIES,
        payload: false,
    });

    axios({
        method: "get",
        url: "/service-provider/auth/getAllCategory",
    })
        .then((res) => {
            dispatch({
                type: actionTypes.SET_CATEGORIES,
                payload: res.data.category,
            });
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.SET_CATEGORIES,
                payload: [],
            });
        });
};
