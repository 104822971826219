import React from 'react'
import styles from './ServiceBox.module.css'
 
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';

import {withRouter,useLocation} from 'react-router-dom'

const ServiceBox = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    let service = props.service;
    let img = service.images.length > 0 ? service.images[0] : ""; 
    return (
        <div 
            style={{backgroundImage: `url(${img})`}}
            className={props.type == "primary" ? styles.primaryContainer : styles.container} 
            onClick={() => props.history.push(`/user/end-users/SERVICE-PAGE?serviceId=${service._id}&serviceProvider=${service.serviceProvider}&userId=${query.get("userId")}${query.get("journeyId") ? "&journeyId=" + query.get("journeyId") : ""}${query.get("journeyType") ? "&journeyType=" + query.get("journeyType") : ""}${query.get("journeyIndex") ? "&journeyIndex=" + query.get("journeyIndex") : ""}`)}
        > 
            <div className={styles.infoContent}>
                <div className={styles.headContent}>
                    <h1>{service.eventName}</h1>
                    <span>  
                        {service.serviceProviderProfile ? service.serviceProviderProfile.storeName : ""}
                    </span>
                </div>
                <h4>{service.pricePerPerson} €</h4>
            </div>
        </div>
    )
}

export default withRouter(ServiceBox);