import React, { useEffect } from "react";
import styles from "./Rating.module.css";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import AppLoader from "../../utils/AppLoader/AppLoader";
import { connect } from "react-redux";
import { getRatings } from "../../../containers/endUser/actions";
import DataTable from "react-data-table-component";
import { withRouter } from "react-router-dom";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TablePagination from "@material-ui/core/TablePagination";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { getSubcategory } from "../../../containers/category/actions";

const Rating = (props) => {
    const [searchVal, setSearchVal] = React.useState("");
    const [dropDown, setDropDown] = React.useState("All");
    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    // const tableHead = props.survey && props.survey.length > 0 && Object.keys(props.survey[0]);

    const downloadCsv = () => {
        axios({
            method: "get",
            url: `/dashboard/user/getRatings?page=${page+1}&limit=${rowsPerPage}&search=${searchVal ?? ""}&startDate=${
                startDate ?? ""
            }&endDate=${endDate ?? ""}&subCategory=${dropDown === "All" ? "" : dropDown}&csv=true`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));

                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Rating.csv");
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                console.log(err);
            });
    };
    React.useEffect(() => {
        props.getSubcategory();
        props.getRatings(rowsPerPage, page + 1, searchVal, "", "", "");
    }, []);
    const handleChange = (event, newPage) => {
        props.getRatings(rowsPerPage, newPage + 1, searchVal, startDate, endDate, dropDown === "All" ? "" : dropDown);
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        props.getRatings(
            event.target.value,
            page + 1,
            searchVal,
            startDate,
            endDate,
            dropDown === "All" ? "" : dropDown,
        );
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleSearchChange = (e) => {
        setPage(0);
        props.getRatings(rowsPerPage, 1, e.target.value, startDate, endDate, dropDown === "All" ? "" : dropDown);
    };
    const handleDropChange = (value) => {
        props.getRatings(rowsPerPage, page + 1, searchVal, startDate, endDate, value === "All" ? "" : value);
        setPage(page);
    };
    const handleDateFilter = (start, end) => {
        props.getRatings(
            rowsPerPage,
            page + 1,
            searchVal,
            start ?? startDate,
            end ?? endDate,
            dropDown === "All" ? "" : dropDown,
        );
        setPage(page);
    };
    // console.log(count);

    let rowData = [];
    let isLoading = !props.ratings;
    let showData = !isLoading;

    !isLoading &&
        props.ratings.forEach((item, index) => {
            rowData.push({
                sl_no: index + 1,
                ...item,
            });
        });
console.log(rowData)
    return (
        <div className={styles.container}>
            <div className={styles.topHeader}>
                <div>
                    {" "}
                    <h3>Rating</h3>
                    <p>View and analyse step ratings of 21-day-plans</p>
                    <Select
                        style={{ marginTop: "1rem", width: "150px", borderRadius: "20px" }}
                        variant="outlined"
                        value={dropDown}
                        onChange={(e) => {
                            handleDropChange(e.target.value);
                            setDropDown(e.target.value);
                        }}
                    >
                        <MenuItem value="All">All</MenuItem>
                        {props.subCategory &&
                            props.subCategory.map((val) => <MenuItem value={val._id}>{val.subCategoryName}</MenuItem>)}
                    </Select>
                </div>
                <div className={styles.dateFilter}>
                    <TextField
                        type="date"
                        label="Start Date"
                        variant="outlined"
                        onChange={(e) => {
                            handleDateFilter(e.target.value, null);
                            setStartDate(e.target.value);
                        }}
                        InputLabelProps={{ shrink: true, required: true }}
                        className={styles.textField}
                    />
                    <TextField
                        type="date"
                        label="End Date"
                        variant="outlined"
                        onChange={(e) => {
                            handleDateFilter(null, e.target.value);
                            setEndDate(e.target.value);
                        }}
                        InputLabelProps={{ shrink: true, required: true }}
                        className={styles.textField}
                    />
                </div>
            </div>
            <div className={styles.header}>
                <div className={styles.leftHeader}>&nbsp;</div>

                <div className={styles.rightHeader}>
                    <TextField
                        placeholder="Search Here"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        className={styles.search}
                        value={searchVal}
                        onChange={(e) => {
                            setSearchVal(e.target.value);
                            handleSearchChange(e);
                        }}
                    />
                    <button
                        onClick={() => {
                            downloadCsv();
                        }}
                    >
                        Download
                    </button>
                </div>
            </div>
            {isLoading && <AppLoader />}

            {showData && (
                <React.Fragment>
                    {showData && (
                        <DataTable
                            noHeader={true}
                            fixedHeader={true}
                            pagination={false}
                            fixedHeaderScrollHeight={"calc(100vh - 320px)"}
                            columns={[
                                {
                                    name: "Email",
                                    selector: "email",
                                    sortable: true,
                                    minWidth: "250px",
                                
                                },
                                { name: "Subcategory", selector: "subCategoryName", sortable: true, minWidth: "200px" },
                                { name: "Created Date", selector: "date", sortable: true, minWidth: "200px" },
                                { name: "Step1", selector: "Step1", sortable: true },
                                { name: "Step2", selector: "Step2", sortable: true },
                                { name: "Step3", selector: "Step3", sortable: true },
                                { name: "Step4", selector: "Step4", sortable: true },
                                { name: "Step5", selector: "Step5", sortable: true },
                                { name: "Step6", selector: "Step6", sortable: true },
                                { name: "Step7", selector: "Step7", sortable: true },
                                { name: "Step8", selector: "Step8", sortable: true },
                                { name: "Step9", selector: "Step9", sortable: true },
                                { name: "Step10", selector: "Step10", sortable: true },
                                { name: "Step11", selector: "Step11", sortable: true },
                                { name: "Step12", selector: "Step12", sortable: true },
                                { name: "Step13", selector: "Step13", sortable: true },
                                { name: "Step14", selector: "Step14", sortable: true },
                                { name: "Step15", selector: "Step15", sortable: true },
                                { name: "Step16", selector: "Step16", sortable: true },
                                { name: "Step17", selector: "Step17", sortable: true },
                                { name: "Step18", selector: "Step18", sortable: true },
                                { name: "Step19", selector: "Step19", sortable: true },
                                { name: "Step20", selector: "Step20", sortable: true },
                                { name: "Step21", selector: "Step21", sortable: true },
                            ]}
                            data={rowData}
                        />
                    )}
                    <div className={styles.flexEnd}>
                        <TablePagination
                            component="div"
                            count={props.ratingsCount}
                            page={page}
                            onChangePage={handleChange}
                            rowsPerPage={rowsPerPage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};
const mapStateToProps = (state) => ({
    ratings: state.endUser.ratings,
    ratingsCount: state.endUser.ratingsCount,
    subCategory: state.category.subCategory,
});
export default withRouter(connect(mapStateToProps, { getRatings, getSubcategory })(Rating));
