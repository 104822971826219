import React from "react";
import styles from "./Fulfilment.module.css";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";
import SearchAddress from "../../../../utils/SearchAddress/SearchAddress";

const useStyles = makeStyles((theme) => ({
    radio: {
        "&$checked": {
            color: "#5B5959",
        },
    },
    infoicon: {
        fontSize: "15px",
        paddingTop: 1,
        border: 0,
    },
    select: {
        borderRadius: 5,
        border: "solid 1px #F8F8F8",
        position: "relative",
        transform: 0,
        transition: 0,
    },
    textfield: {
        borderRadius: 5,
        minWidth: "350px",
    },
}));

const Fulfilment = (props) => {
    const formData = props.formData;
    const classes = useStyles();
    const error = props.error;
    const [newError, setNewError] = React.useState(error.locationType);
    const [showTextField, setShowTextField] = React.useState(false);
    const msg = {
        NOT_IN_STORE: "Out-of-Store Service Fulfilment Location",
        ONLINE: "Online Meeting Link",
    };
    return (
        <div className={styles.contentContainer}>
            <h2>Service Fulfilment</h2>
            <h3>Location</h3>
            <div className={styles.row}>
                <FormControl className={styles.halfField} error={error.locationType || newError}>
                    <FormLabel>* Service Fulfilment Location</FormLabel>
                    <Select
                        variant="outlined"
                        value={formData.locationType}
                        onChange={(e) => {
                            if (e.target.value == "OUT_OF_STORE") {
                                setNewError(false);
                                if (props.storeProfile) {
                                    props.setFormData({
                                        ...formData,
                                        locationType: e.target.value,
                                        location: props.storeProfile.street,
                                    });
                                } else {
                                    setNewError("Please Complete your Store General Details");
                                }
                            } else {
                                props.setFormData({
                                    ...formData,
                                    locationType: e.target.value,
                                });
                            }
                        }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            className: styles.menuItems,
                        }}
                    >
                        <MenuItem value="NOT_IN_STORE">Not in Store</MenuItem>
                        <MenuItem value="ONLINE">Online</MenuItem>
                        <MenuItem value="Others">Others</MenuItem>
                    </Select>
                    <FormHelperText>
                        {newError} {error.locationType}
                    </FormHelperText>
                </FormControl>

                <FormControl className={styles.halfField} error={error.locationType} helperText={error.locationType}>
                    <>
                        <div className={styles.labelContainer}>
                            <FormLabel id="location-service">
                                {formData.locationType
                                    ? msg[formData.locationType]
                                    : "Out-of-Store Service Fulfilment Location"}
                            </FormLabel>
                            {formData.locationType == "ONLINE" ? (
                                <FormControlLabel
                                    id="standardMetingLink-service"
                                    control={
                                        <Checkbox
                                            size="small"
                                            color="primary"
                                            checked={formData.isStandardLink}
                                            onChange={(e) => {
                                                if (e.target.checked === false) {
                                                    props.setFormData({
                                                        ...formData,
                                                        isStandardLink: e.target.checked,
                                                        standardMetingLink: "",
                                                        standardMetingLinkInfo: "",
                                                    });
                                                } else {
                                                    props.setFormData((val) => ({
                                                        ...val,
                                                        isStandardLink: e.target.checked,
                                                    }));
                                                }
                                            }}
                                        />
                                    }
                                    label={"I have a standard link for all of my sessions"}
                                />
                            ) : null}
                        </div>
                        {formData.locationType == "NOT_IN_STORE" ? (
                            <SearchAddress
                                className={styles.fullField}
                                value={formData.location}
                                color="white"
                                setFormData={(key, val) => props.setFormData({ ...formData, [key]: val })}
                                placeholder={"Service Fulfilment Location"}
                                needLatLng={true}
                                disabled={formData.locationType == "IN_STORE"}
                                error={error.location}
                                helperText={error.location}
                            />
                        ) : (
                            <div>
                                {formData.locationType == "ONLINE" ? (
                                    <>
                                        {!formData.isStandardLink ? (
                                            <p>
                                                {
                                                    "If you have a unique meeting link for each session, you will be able to send your customers the respective link when you receive a booking"
                                                }
                                            </p>
                                        ) : (
                                            <>
                                                <TextField
                                                    fullWidth
                                                    className={classes.textfield}
                                                    variant="outlined"
                                                    rows={1}
                                                    value={formData.standardMetingLink}
                                                    onChange={(e) =>
                                                        props.setFormData({
                                                            ...formData,
                                                            standardMetingLink: e.target.value,
                                                        })
                                                    }
                                                    error={error.standardMetingLink}
                                                    helperText={error.standardMetingLink}
                                                />
                                                {!showTextField ? (
                                                    <p
                                                        className={styles.meetingLinkAdditionalInfo}
                                                        onClick={() => setShowTextField((val) => !val)}
                                                    >
                                                        Insert additional details about meeting
                                                    </p>
                                                ) : (
                                                    <p
                                                        className={styles.meetingLinkAdditionalInfo2}
                                                        onClick={() => setShowTextField((val) => !val)}
                                                    >
                                                      Additional Details About Meeting Link
                                                    </p>
                                                )}
                                                {showTextField && (
                                                    <TextField
                                                        fullWidth
                                                        className={classes.textfield}
                                                        variant="outlined"
                                                        rows={1}
                                                        value={formData.standardMetingLinkInfo}
                                                        onChange={(e) =>
                                                            props.setFormData({
                                                                ...formData,
                                                                standardMetingLinkInfo: e.target.value,
                                                            })
                                                        }
                                                    />
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <TextField
                                        fullWidth
                                        className={classes.textfield}
                                        inputProps={{ maxLength: 50 }}
                                        variant="outlined"
                                        rows={1}
                                        value={formData.others}
                                        error={error.others}
                                        helperText={error.others}
                                        onChange={(e) => props.setFormData({ ...formData, others: e.target.value })}
                                    />
                                )}
                            </div>
                        )}
                    </>
                </FormControl>
            </div>
            <h3>Type</h3>
            {error.bookingType && <span className={styles.error}>{error.bookingType}</span>}
            <div className={styles.rowFlexStart}>
                <div className={styles.item}>
                    <p>Voucher Booking</p>
                    <Checkbox
                        checked={formData.bookingType.includes("VOUCHER")}
                        onChange={(e) => {
                            let arr = formData.bookingType;
                            if (formData.bookingType.includes("VOUCHER")) arr.splice(arr.indexOf("VOUCHER"), 1);
                            else arr.push("VOUCHER");

                            props.setFormData({ ...formData, bookingType: arr });
                        }}
                    />
                </div>

                <div className={styles.item}>
                    <p>Appointment Booking</p>
                    <Checkbox
                        checked={formData.bookingType.includes("APPOINTMENT")}
                        onChange={(e) => {
                            let arr = formData.bookingType;
                            if (formData.bookingType.includes("APPOINTMENT")) arr.splice(arr.indexOf("APPOINTMENT"), 1);
                            else arr.push("APPOINTMENT");

                            props.setFormData({ ...formData, bookingType: arr });
                        }}
                    />
                </div>
            </div>

            <div className={styles.rowFlexStart}>
                <div className={`${styles.item}`}>
                    <p>One To One Booking</p>
                    <Checkbox
                        color="primary"
                        checked={formData.oneToOneBooking}
                        onChange={(e) => {
                            let val = !formData.oneToOneBooking ? { minQuota: 0, maxQuota: 1 } : {};
                            props.setFormData({
                                ...formData,
                                oneToOneBooking: !formData.oneToOneBooking,
                                ...val,
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
export default Fulfilment;
