import React from 'react'
import styles from './Preferences.module.css'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Switch from '@material-ui/core/Switch'

 
import {connect} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {setAuth} from '../../../../containers/app/actions'
import axios from 'axios'


const Preferences = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [lang,setLang] = React.useState(localStorage.getItem('i18nextLng'));
    const [currency,setCurrency] = React.useState(localStorage.currencyType ? localStorage.getItem('currencyType') : 'USD')
    const [checked,setChecked] = React.useState(props.auth.sendEmail);

    const handleChange = () => {
        const oldValue = checked;
        setChecked(!oldValue)
        axios({
            method: "put",
            url: `/user/user/updateEmailPreference?userId=${query.get("userId")}`,
            data: {
                sendEmail: !oldValue
            }
        }).then(res => { 
            props.setAuth({...props.auth,sendEmail: !oldValue});
        }).catch(err => {
            setChecked(oldValue)
        })
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h1>Preferences</h1>
                <p>Change your language currency and manage notifications</p>

                <div className={styles.row}>
                    <FormControl className={styles.textField}  variant="outlined">
                        <FormLabel className={styles.label}>Currency</FormLabel>
                        <Select 
                            value={currency}
                            onChange={e => {
                                localStorage.setItem("currencyType",e.target.value)
                                setCurrency(e.target.value)
                            }}
                        >
                            <MenuItem value="USD">USD</MenuItem>
                            <MenuItem value="EUR">EUR</MenuItem>
                            <MenuItem value="DKK">DKK</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl className={styles.textField} variant="outlined">
                        <FormLabel className={styles.label}>Choose Language</FormLabel>
                        <Select
                            value={lang} 
                            onChange={e => {
                                setLang(e.target.value)
                            }}
                        >
                            <MenuItem value="en">English</MenuItem>
                            <MenuItem value="de">German</MenuItem>
                        </Select>
                    </FormControl>
                </div>


                <div className={styles.notify}>
                    <div>
                        <p>Email notifications</p>
                        <p>I would like to subscribe 21done newletters which <br /> includes marketing and promotional information</p>
                    </div>

                    <Switch 
                        color="primary"
                        checked={checked}
                        onChange={handleChange}
                    />
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    auth: state.app.auth
})

export default connect(mapStateToProps,{setAuth})(Preferences);