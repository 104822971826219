import React from "react";
import styles from "./Subscription.module.css";

import TextField from "@material-ui/core/TextField";

import DataTable from "react-data-table-component";
import AppLoader from "../../utils/AppLoader/AppLoader";

import { connect } from "react-redux";
import { getAllSubscription } from "../../../containers/endUser/actions";
import { withRouter } from "react-router-dom";
import { TablePagination } from "@material-ui/core";
import moment from "moment";

const Subscription = (props) => {
    const [searchVal, setSearchVal] = React.useState("");

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    React.useEffect(() => {
        if (!props.subscription) {
            props.getAllSubscription(rowsPerPage, page + 1, searchVal);
        }
    }, []);

    const handleChangePage = (event, newPage) => {
        props.getAllSubscription(rowsPerPage, newPage + 1, searchVal);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.getAllSubscription(event.target.value, page + 1, searchVal);
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchChange = (e) => {
        setPage(0);
        props.getAllSubscription(rowsPerPage, 1, e.target.value);
        setSearchVal(e.target.value);
    };

    let isLoading = !props.subscription;
    let showData = !isLoading;
    let rowData = [];

    !isLoading &&
        props.subscription.forEach((item, index) => {
            rowData.push({
                sl_no: index + 1,
                name: item.userProfile ? (
                    item.userProfile.firstName ? (
                        `${item.userProfile.firstName} ${item.userProfile.lastName}`
                    ) : item.verificationType == "EMAIL" ? (
                        item.userId.username
                    ) : (
                        <span className="text-orange">NaN</span>
                    )
                ) : (
                    <span className="text-orange">NaN</span>
                ),
                email: item.userId.email,
                subscription: item.subscriptionType.toUpperCase(),
                startDate: moment(item.transactionDate).format("YYYY-MM-DD"),
                endDate: moment(item.validTill).format("YYYY-MM-DD"),
                status: item.status,
            });
        });

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.leftHeader}>&nbsp;</div>

                <div className={styles.rightHeader}>
                    <TextField
                        label="Search Here"
                        className={styles.search}
                        value={searchVal}
                        onChange={handleSearchChange}
                    />
                </div>
            </div>

            {isLoading && <AppLoader />}

            {showData && (
                <React.Fragment>
                    {showData && (
                        <DataTable
                            noHeader={true}
                            fixedHeader={true}
                            pagination={false}
                            fixedHeaderScrollHeight={"calc(100vh - 320px)"}
                            columns={[
                                { name: "Sl No", selector: "sl_no", sortable: true },
                                { name: "Customer Name", selector: "name", sortable: true },
                                {
                                    name: "Customer Email",
                                    selector: "email",
                                    sortable: true,
                                    minWidth: "250px",
                                    cell: (row) => (
                                        <div className={styles.tooltip}>
                                            {row.email && row.email.length > 24
                                                ? row.email.substr(0, 24) + "..."
                                                : row.email}
                                            <span className={styles.tooltiptext}>{row.email}</span>
                                        </div>
                                    ),
                                },
                                { name: "Subscription", selector: "subscription", sortable: true },
                                { name: "Start Date", selector: "startDate", sortable: true },
                                { name: "End Date", selector: "endDate", sortable: true },
                                { name: "Status", selector: "status", sortable: true },
                            ]}
                            data={rowData}
                        />
                    )}
                    <div className={styles.flexEnd}>
                        <TablePagination
                            component="div"
                            count={props.count}
                            page={page}
                            onChangePage={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};
const mapStateToProps = (state) => ({
    subscription: state.endUser.userSubscription.subscription,
    count: state.endUser.userSubscription.count,
});
export default withRouter(connect(mapStateToProps, { getAllSubscription })(Subscription));
