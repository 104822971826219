import React from "react";
import styles from "./VoucherComp.module.css";

import ClaimComp from "./Claim/Claim";


const VoucherComp = (props) => {
  

  const voucher = props.voucher;
  const [claimModal, setClaimModal] = React.useState(false);
  const [voucherId,setVoucherID] = React.useState("");
  
  const name =
    voucher.userId && voucher.userId.verificationType == "EMAIL"
      ? voucher.userId.username
      : voucher.userId &&
        voucher.userId.userProfile &&
        voucher.userId.userProfile.firstName;
  return (
    <React.Fragment>
      <ClaimComp
        open={claimModal}
        onClose={() => setClaimModal(false)}
        voucher={voucherId}
      />

      <div className={styles.container}>
        <div className={styles.tableContent}>
          
          <p>{name}</p>
          <p>{voucher?.serviceId?.eventName}</p>
          <p>Personnel</p>
          <p>{voucher.createdAt}</p>
          <div className={styles.btnContainer}>
            {voucher.claimed ? (
              <button className={"btnBorderDisabled"} disabled>
                CLAIMED
              </button>
            ) : (
              <React.Fragment>
                <button
                  className={"btnGreen"}
                  onClick={() => {setClaimModal(true); setVoucherID(voucher._id) }}
                >
                  Claim
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VoucherComp;
