import React from "react";
import styles from "./AboutShop.module.css";

 

const AboutShop = (props) => { 
    const serviceProvider = props.serviceProvider;
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.leftSide}>
                    <div className={styles.list}>
                        <h1>Über den Anbieter</h1>
                    </div>
                    <div className={styles.list}>
                        <h4>{serviceProvider.storeName}</h4>

                        <div className={styles.tag}>21done Verifiziert</div>
                    </div>
                    <div className={styles.list}>
                        <pre>{serviceProvider.description}</pre>
                    </div>
                </div>
                <div className={styles.rightSide}>
                    <div className={styles.mainImg}>
                        {serviceProvider.introductionVideo
                        ?
                        <video controls>
                            <source src={serviceProvider.introductionVideo} />
                        </video>
                        :
                        serviceProvider.storeImage &&
                        <img src={serviceProvider.storeImage} alt="" />}
                    </div>
                    <div className={styles.sideImg}>
                        {serviceProvider.introductionVideo && (
                            <img 
                                src={serviceProvider.storeImage}
                                alt=""
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AboutShop;
