import React from 'react'
import styles from './GlobalGiving.module.css'

import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

const GlobalGiving = (props) => {
    const item = props.item;
    return (
        <div className={styles.container} onClick={props.onClick}>
            {props.active && <CheckRoundedIcon className={styles.icon} />}
            <img src={item.imageLink} alt=""/>
            <p>{item.title}</p>
        </div>
    )
}
export default GlobalGiving;