import React from 'react'
import styles from './ModalComp.module.css'

import Modal from '@material-ui/core/Modal'
import IconButton from '@material-ui/core/IconButton';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const ModalComp = (props) => {
    return (
        <Modal open={props.open} onClose={props.onClose} className={styles.container}>
            <div className={styles.content}>
                <IconButton className={styles.close} onClick={props.onClose}>
                    <CloseRoundedIcon />
                </IconButton>

                <h1>Questions for Users</h1>


                <p>{props.open.questions}</p>

                <div className="btnCenter">
                    <button className={"btnGreen"}
                        onClick={props.onClose}
                    >Close</button>
                </div>
            </div> 
        </Modal>
    )
}
export default ModalComp;