import * as actionTypes from "./actionTypes";

const initialState = {
    category: false,
    categoryList: false,
    experiences: false,
    experienceMap: false,
    subCategory: false,
    categories: false,
};
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.SET_EXPERIENCE_MAP:
            return { ...state, experienceMap: action.payload };
        case actionTypes.SET_CATEGORIES:
            return { ...state, categories: action.payload };
        case actionTypes.SET_EXPERIENCE:
            return { ...state, experiences: action.payload };
        case actionTypes.SET_CATEGORY_MAP:
            return { ...state, category: action.payload };
        case actionTypes.SET_CATEGORY_LIST:
            return { ...state, categoryList: action.payload };
        case actionTypes.SET_SUBCATEGORY:
            return { ...state, subCategory: action.payload };
        default:
            return state;
    }
};

export default reducer;
