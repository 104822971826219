import React from 'react'
import styles from './ConfExperience.module.css'

import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField' 
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button' 
import Switch from '@material-ui/core/Switch' 
import CircularProgress from '@material-ui/core/CircularProgress'
import Modal from '@material-ui/core/Modal'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
  
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
 
import {getAllExperience} from '../../../../containers/category/actions' 
import {showAlert} from '../../../../containers/app/actions'
import axios from 'axios' 
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

const ConfExperience = (props) => { 
    const [formData,setFormData] = React.useState({
        experienceName: "",
        description: "",
        active: true,
        experienceId: ""
    });

    const [error,setError] = React.useState({
        experienceName: false,
        description: false
    });

    const [loading,setLoading] = React.useState(false);
    const [open,setOpen] = React.useState(false)

    React.useEffect(() => {
        props.type == "EDIT" && props.experience && setFormData({...formData,...props.experience,experienceId: props.experience._id})
    },[props.experience])

    const validate = () => {
        const err = {experienceName: false,description: false};
        let validData = true;
        setError({...err});
        Object.keys(err).forEach(key => {
            if(formData[key] == "") {
                err[key] = `${key} field cannot be empty`
                validData = false;
            } 
        }) 
        setError({...err});
        return validData;
    }

    const onSubmit = () => {
        if(validate()) {
            setLoading(true);
            const method = props.type == "ADD" ? "post" : "put";
            const url = props.type == "ADD" ? "/dashboard/experience/addExperience" : "/dashboard/experience/editExperience"
            axios({
                method,
                url,
                data: {
                    ...formData
                }
            }).then(res => {
                setLoading(false);
                props.showAlert(`Experience ${props.type == "ADD" ? "Added" : "Update"} Succesfully`);
                props.getAllExperience()
                setOpen(false)
            }).catch(err => {
                setLoading(false);
                if(err && err.response && err.response.data && err.response.data.error) {
                    props.showAlert(err.response.data.error)
                } else {
                    console.log(err);   
                    props.showAlert("Something went wrong ! Try Again")
                }
            })
        }
    }
    const name = props.type == "ADD" ? "Add Experience" : "Edit Experience"
    return (
        <React.Fragment>
            {props.type == "ADD"
                ?
            <Button  onClick={() => setOpen(true)}>{name}</Button>
                :
            <Tooltip title="Edit Experience">
                <IconButton onClick={() => setOpen(true)}>
                    <EditRoundedIcon />
                </IconButton>
            </Tooltip>}

            <Modal className={styles.modal} open={open} onClose={() => setOpen(false)}>
                <div className={styles.container}>

                    <IconButton className={styles.close} onClick={() => setOpen(false)}>
                        <CloseRoundedIcon />
                    </IconButton>

                    <Paper variant="outlined" className={styles.paper}>
                        <h1>{name}</h1>

                        <div className={styles.row}>
                            <TextField 
                                label="Experience Name"
                                className={styles.catName}
                                value={formData.experienceName}
                                onChange={e => setFormData({...formData,experienceName: e.target.value.trimStart()})}
                                error={error.experienceName}
                                helperText={error.experienceName}
                            />
                            <TextField 
                                label="Experience Description"
                                className={styles.catName}
                                value={formData.description}
                                onChange={e => setFormData({...formData,description: e.target.value.trimStart()})}
                                error={error.description}
                                helperText={error.description}
                            />
        
                            <FormControlLabel
                                className={styles.switch}
                                control={<Switch checked={formData.active} onChange={(e) => setFormData({...formData,active: !formData.active})}  color="primary" />}
                                label="Experience Active"
                            /> 
                        </div>

                        <div className={styles.row}>
                            {loading
                                ?
                            <Button color="primary" variant="contained" startIcon={<CircularProgress color="inherit" size={20} />}>Loading ...</Button>
                                :
                            <Button color="primary" variant="contained"  onClick={onSubmit}>{name}</Button>}
                        </div>
                    </Paper>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default withRouter(connect(null,{getAllExperience,showAlert})(ConfExperience));