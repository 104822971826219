import React from "react";
import styles from "./Modal.module.css";
import AppLoader from "../../utils/AppLoader/AppLoader";
import Modal from "@material-ui/core/Modal";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@material-ui/core/IconButton";
// import useTranslation from "next-translate/useTranslation";

const transactionModal = ({ open, onClose, ibanValidation }) => {
   
    let ibanValidated = [...new Set(ibanValidation && ibanValidation)];
    let isLoading = !ibanValidated;
    
    return (
        <Modal open={open ? true : false} onClose={onClose} className={styles.modal}>
            {!isLoading && (
                <div className={styles.container}>
                    {ibanValidated &&
                        ibanValidated.map((val) => {
                            return <li>{val}</li>;
                        })}
                </div>
            )}
        </Modal>
    );
};
export default transactionModal;
