import React from "react";
import styles from "./ChooseCategory.module.css";

import { connect } from "react-redux";
import { getAllExperience, getCategories } from "../../../../containers/category/actions";

import NavigateNextRoundedIcon from "@material-ui/icons/NavigateNextRounded";

import Modal from "@material-ui/core/Modal";
import AppLoader from "../../../utils/AppLoader/AppLoader";

function JourneyTopic(props) {
    const [formData, setFormData] = React.useState({
        categoryId: "",
        subCategoryId: "",
    });
    const [error, setError] = React.useState({
        categoryId: false,
        subCategoryId: false,
    });

    React.useEffect(() => {
        setFormData((val) => ({
            ...val,
            journeyTopics: [...props.data.journeyTopics],
        }));
    }, []);

    React.useEffect(() => {
        if (!props.categories) {
            props.getCategories();
        }
    }, [props.categories]);

    const validate = () => {
        const err = { subCategoryId: false, categoryId: false };
        let validData = true;

        setError({ ...err });
        Object.keys(err).forEach((key) => {
            if (formData[key] == "") {
                err[key] = `Field cannot be empty`;
                validData = false;
            }
        });

        setError({ ...err });
        return validData;
    };

    const onSubmit = () => {
        if (validate()) {
            props.onAdd(formData);
            props.onClose();
        }
    };

    return (
        <Modal open={true} onClose={props.onClose} className={styles.modal}>
            <div className={styles.container}>
                <div className={styles.categoryController}>
                    <div className={styles.selectContent}>
                        <h1>Category</h1>
                        {error.categoryId && <span className={styles.error}>Select a Category</span>}
                        {!props.categories && <AppLoader />}
                        {props.categories &&
                            props.categories.map((val) => {
                                const isActive = val == formData.categoryId;
                                return (
                                    <div
                                        key={val._id}
                                        className={isActive ? styles.activeRowContent : styles.rowContent}
                                        onClick={() => {
                                            setFormData({
                                                ...formData,
                                                categoryId: val,
                                                subCategoryId: "",
                                                serviceType: "",
                                            });
                                        }}
                                    >
                                        <p>{val.categoryName}</p>
                                        {isActive && <NavigateNextRoundedIcon />}
                                    </div>
                                );
                            })}
                    </div>

                    <div className={styles.selectContent}>
                        <h1>Journey Topic</h1>
                        {error.subCategoryId && <span className={styles.error}>Select a Journey Topic</span>}
                        {formData.categoryId &&
                            formData.categoryId.subCategories.map((val) => {
                                if (val.active) {
                                    const isActive = val == formData.subCategoryId;
                                    return (
                                        <div
                                            key={val._id}
                                            className={isActive ? styles.activeRowContent : styles.rowContent}
                                            onClick={() =>
                                                setFormData({ ...formData, subCategoryId: val, serviceType: "" })
                                            }
                                        >
                                            <p>{val.subCategoryName}</p>
                                        </div>
                                    );
                                }
                            })}
                    </div>
                </div>

                <div className={styles.dEnd}>
                    <button className="btnGreen" onClick={onSubmit}>
                        Add Journey Topics
                    </button>
                </div>
            </div>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    experience: state.category.experiences,
    categories: state.category.categories,
});
export default connect(mapStateToProps, { getAllExperience, getCategories })(JourneyTopic);
