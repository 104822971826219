import React from "react";
import styles from "./Referral.module.css";
import { RadioGroup, TextField, FormControlLabel, Radio, FormHelperText } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import ReferralData from "./ReferralData/ReferralData";
import axios from "axios";
import { showAlert } from "../../../containers/app/actions";
import moment from "moment";
import { getAllReferrals } from "../../../containers/endUser/actions";
import BtnLoader from "../../utils/BtnLoader/BtnLoader";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const Referral = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const [startValidation, setStartValidation] = React.useState(false);
    let minDate = new Date().toISOString().slice(0, 10);
    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({
        name: "",
        valueType: "",
        referrerAmount: "",
        referreeAmount: "",
        startDate: "",
        endDate: "",
    });
    const [error, setError] = React.useState({
        name: false,
        valueType: false,
        referrerAmount: false,
        referreeAmount: false,
        startDate: false,
        endDate: false,
    });

    const validate = () => {
        setStartValidation(startValidation + 1);

        const err = {
            name: false,
            valueType: false,
            referrerAmount: false,
            referreeAmount: false,
            startDate: false,
            endDate: false,
        };

        let validData = true;
        Object.keys(err).forEach((key) => {
            if (formData[key] == "") {
                err[key] = `Field cannot be empty`;
                validData = false;
            }
        });
        if (formData.startDate === null) {
            err["startDate"] = `Field cannot be empty`;
            validData = false;
        }
        if (formData.endDate === null) {
            err["endDate"] = `Field cannot be empty`;
            validData = false;
        }
        setError({ ...err });
        return validData;
    };

    const saveNewCampaign = () => {
        setLoading(true);

        axios({
            method: "post",
            url: "/dashboard/newReferalCampaign/addNewCampaign",
            data: {
                ...formData,
            },
        })
            .then((res) => {
                if (res) {
                    setLoading(false);
                    props.showAlert("New Referral Campaign Added Successfully");
                    props.getAllReferrals();
                    setFormData({
                        ...formData,
                        name: "",
                        valueType: "",
                        referrerAmount: "",
                        referreeAmount: "",
                        startDate: null,
                        endDate: null,
                    });
                    setError({
                        ...error,
                        name: false,
                        valueType: false,
                        referrerAmount: false,
                        referreeAmount: false,
                        startDate: false,
                        endDate: false,
                    });
                }
            })
            .catch((err) => {
                if (err && err.response && err.response.data) {
                    setLoading(false);
                    validate();
                    props.showAlert("Mandatory Field Required");
                }
            });
    };

    return (
        <div className={styles.container}>
            <h3>Referral System</h3>
            <div className={styles.content}>
                <div className={styles.flexBoxFirst}>
                    <FormControl className={styles.fullWidth}>
                        <FormLabel className={styles.formLabel}>* Campaign Name</FormLabel>
                        <TextField
                            variant="outlined"
                            size="small"
                            value={formData.name}
                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                            error={error.name}
                            helperText={error.name}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel className={styles.formLabel}>* Set Referral Value</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            className={styles.radioGroup}
                        >
                            <FormControlLabel
                                className={styles.radio}
                                value="fixed"
                                control={
                                    <Radio
                                        size="sm"
                                        checked={formData.valueType == "FIXED-VALUE"}
                                        onChange={(e) => {
                                            setFormData({
                                                ...formData,
                                                valueType: "FIXED-VALUE",
                                            });
                                        }}
                                    />
                                }
                                label="Fixed Value"
                            />
                            <FormControlLabel
                                className={styles.radio}
                                value="percentage"
                                control={
                                    <Radio
                                        checked={formData.valueType == "PERCENTAGE"}
                                        onChange={(e) => {
                                            setFormData({
                                                ...formData,
                                                valueType: "PERCENTAGE",
                                            });
                                        }}
                                    />
                                }
                                label="Percentage"
                            />
                        </RadioGroup>
                        <FormHelperText className={styles.error}>{error.valueType}</FormHelperText>
                    </FormControl>
                    <FormControl className={styles.fullWidth}>
                        <FormLabel className={styles.referre}>Referrer, i.e person who referred</FormLabel>
                        <TextField
                            variant="outlined"
                            size="small"
                            type="number"
                            className={styles.textField}
                            value={formData.referrerAmount}
                            onChange={(e) => setFormData({ ...formData, referrerAmount: e.target.value })}
                            error={error.referrerAmount}
                            helperText={error.referrerAmount}
                        ></TextField>
                    </FormControl>
                    <FormControl className={styles.fullWidth}>
                        <FormLabel className={styles.referre}>Referree i.e person who referred to</FormLabel>
                        <TextField
                            variant="outlined"
                            size="small"
                            type="number"
                            className={styles.textField}
                            value={formData.referreeAmount}
                            onChange={(e) => setFormData({ ...formData, referreeAmount: e.target.value })}
                            error={error.referreeAmount}
                            helperText={error.referreeAmount}
                        ></TextField>
                    </FormControl>
                    <div className={styles.flexComp}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                label="Start Date"
                                variant="inline"
                                size="small"
                                autoOk={true}
                                InputLabelProps={{ shrink: true, required: true }}
                                InputProps={{ inputProps: { min: minDate } }}
                                value={formData.startDate}
                                onChange={(date) =>
                                    setFormData({ ...formData, startDate: moment(date).format("yyyy-MM-DD") })
                                }
                                error={error.startDate}
                                helperText={error.startDate}
                                format="yyyy-MM-dd"
                                inputVariant="outlined"
                                className={styles.dateField}
                                minDate={minDate}
                            />
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                label="End Date"
                                variant="inline"
                                size="small"
                                autoOk={true}
                                InputLabelProps={{ shrink: true, required: true }}
                                InputProps={{ inputProps: { min: minDate } }}
                                value={formData.endDate}
                                onChange={(date) =>
                                    setFormData({ ...formData, endDate: moment(date).format("yyyy-MM-DD") })
                                }
                                error={error.endDate}
                                helperText={error.endDate}
                                format="yyyy-MM-dd"
                                inputVariant="outlined"
                                className={styles.dateField}
                                minDate={minDate}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div>
                        {loading ? (
                            <BtnLoader className={styles.saveButtom} />
                        ) : (
                            <button
                                className={styles.saveButtom}
                                onClick={() => {
                                    saveNewCampaign();
                                }}
                            >
                                Add
                            </button>
                        )}
                    </div>
                </div>
                <div className={styles.flexBoxSecond}>
                    <ReferralData tableFooter={styles.tableFooter} />
                </div>
            </div>
        </div>
    );
};

export default connect(null, { showAlert, getAllReferrals })(Referral);
