import React from "react";
import styles from "./BookingComp.module.css";
import Badge from "@material-ui/core/Badge";
import CancelComp from "./Cancel/CancelComp";
import FulfilComp from "./FulFil/Fulfil";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import moment from "moment";

const BookingComp = (props) => {
    const booking = props.booking;
    const [cancelModal, setCancelModal] = React.useState(false);
    const [fulfilModal, setFulfilModal] = React.useState(false);
    const [cancelBooking, setCancelBooking] = React.useState("");

    // const name = booking.userId && booking.userId.verificationType == "EMAIL" ? booking.userId.username : (booking.userId && booking.userId.userProfile && booking.userId.userProfile.firstName + " " + booking.userId.userProfile.lastName)
    return (
        <React.Fragment>
            <CancelComp
                open={cancelModal}
                onClose={() => setCancelModal(false)}
                booking={booking}
                cancelBooking={cancelBooking}
            />
            <FulfilComp open={fulfilModal} onClose={() => setFulfilModal(false)} booking={booking} />

            {props.data.map((bookingData) => {
                let booking = bookingData[0];
                console.log(booking);
                return (
                    <Accordion className={styles.accordian}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className={styles.headContent}>
                                <div className={styles.item}>
                                    <span>Date</span>
                                    <p>{moment.utc(booking.timeSlot.startDate).format("YYYY-MM-DD")}</p>
                                </div>
                                <div className={styles.item}>
                                    <span>Time Slot</span>
                                    <p>
                                        {booking.timeSlot.startTime} - {booking.timeSlot.endTime}
                                    </p>
                                </div>
                                <div className={styles.item}>
                                    <span>Service</span>
                                    <p>{booking.serviceId?.eventName}</p>
                                </div>
                                <div className={styles.item}>
                                    <span>No of Bookings</span>
                                    <p>{bookingData.length}</p>
                                </div>

                                {/* {booking.serviceId.locationType == "ONLINE" &&
									<div className={styles.action}>
										<button className="btnRed" onClick={() => setOnlineModal(booking)}>Send Link</button>
									</div>} */}
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={styles.accordianData}>
                                {bookingData.map((booking) => {
                                    const name =
                                        booking.userId && booking.userId.userProfile.firstName
                                            ? booking.userId.userProfile.firstName
                                            : booking?.userId?.username;
                                    return (
                                        <div className={styles.headContent}>
                                            <div className={styles.item}>
                                                <span>Date</span>
                                                <p>{moment.utc(booking.timeSlot.startDate).format("YYYY-MM-DD")}</p>
                                            </div>
                                            <div className={styles.item}>
                                                <span>Time Slot</span>
                                                <p>
                                                    {booking.timeSlot.startTime} - {booking.timeSlot.endTime}
                                                </p>
                                            </div>
                                            <div className={styles.item}>
                                                <span>Customer</span>
                                                <p>{name}</p>
                                            </div>
                                            <div className={styles.item}>
                                                <span>Service</span>
                                                <p>{booking.serviceId?.eventName}</p>
                                            </div>
                                            <div className={styles.item}>
                                                <span>Booking No</span>
                                                <p>{booking.uniqueId}</p>
                                            </div>

                                            <div className={styles.action}>
                                                <div className={styles.btnContainer}>
                                                    {booking.status != "AWAITING FULFILMENT" ? (
                                                        <button className={"btnBorderGreen"} disabled>
                                                            {booking.status}
                                                        </button>
                                                    ) : (
                                                        <React.Fragment>
                                                            <button
                                                                className={"btnGreen"}
                                                                onClick={() => setFulfilModal(booking)}
                                                            >
                                                                Fulfil
                                                            </button>
                                                            <button
                                                                className={"btnRed"}
                                                                onClick={() => {
                                                                    setCancelModal(booking);
                                                                    setCancelBooking(booking);
                                                                }}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </React.Fragment>
    );
};

export default BookingComp;
