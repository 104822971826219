import deImg from "../assets/img/de.png";
import enImg from "../assets/img/en.png";

// import englishIcon from "../assets/svg/english.svg";
// import germanyIcon from "../assets/svg/germany.svg";

import maleIcon from "../assets/svg/male-yellow.svg";
import femaleIcon from "../assets/svg/female-yellow.svg";
import notappliIcon from "../assets/svg/not_applicable-yellow.svg";

import childrenIcon from "../assets/svg/children-yellow.svg";
import plusIcon from "../assets/svg/18-yellow.svg";
import seniourIcon from "../assets/svg/seniour-yellow.svg";
import allAgesIcon from "../assets/svg/allAges-yellow.svg";

import singleIcon from "../assets/svg/single-yellow.svg";
import pairIcon from "../assets/svg/pair-yellow.svg";
import groupIcon from "../assets/svg/group-yellow.svg";

import beginnerIcon from "../assets/svg/beginner-yellow.svg";
import intermediateIcon from "../assets/svg/intermediate-yellow.svg";
import advancedIcon from "../assets/svg/advanced-yellow.svg";
import notAppLevelIcon from "../assets/svg/not_app-yellow.svg";

import publicIcon from "../assets/svg/public-yellow.svg";
import freeIcon from "../assets/svg/free-yellow.svg";
import paidIcon from "../assets/svg/paid-yellow.svg";
import wheelchairIcon from "../assets/svg/wheelchair-yellow.svg";
import familyIcon from "../assets/svg/family-yellow.svg";
import pregnantIcon from "../assets/svg/pregnant-yellow.svg";
import petIcon from "../assets/svg/pet-yellow.svg";

export function specImgYellow() { 

    return {
        DEUTSCH: { icon: deImg, text: "German" },
        ENGLISH: {
            icon: enImg,
            text: "Englisch",
        },
        FEMALE: {
            icon: maleIcon,
            text: "Weiblich",
        },
        MALE: {
            icon: femaleIcon,
            text: "Männlich",
        },
        CHILDREN: {
            icon: childrenIcon,
            text: "Kinder",
        },
        "18_PLUS": {
            icon: plusIcon,
            text: "18+",
        },
        SENIORS: {
            icon: seniourIcon,
            text: "Senioren",
        },
        SINGLE: {
            icon: singleIcon,
            text: "Einzel",
        },
        PAIR: {
            icon: pairIcon,
            text: "Paar",
        },
        GROUP: {
            icon: groupIcon,
            text: "Gruppe",
        },
        BEGINNER: {
            icon: beginnerIcon,
            text: "Anfänger:in",
        },
        INTERMEDIATE: {
            icon: intermediateIcon,
            text: "Dazwischenliegend",
        },
        ADVANCED: {
            icon: advancedIcon,
            text: "Fortgeschritte",
        },
        NOT_APPLICABLE_LEVEL: {
            icon: notAppLevelIcon,
            text: "Alle Schwierigkeitslevel",
        },
        PUBLIC_TRANSPORT: {
            icon: publicIcon,
            text: "Mit öffentlichen Verkehrsmitteln erreichbar",
        },
        FREE_PARKING: {
            icon: freeIcon,
            text: "Kostenlose Parkplätze vorhanden",
        },
        PAID_PARKING: {
            icon: paidIcon,
            text: "Kostenpflichtige Parkplätze vorhanden",
        },
        WHEELCHAIR_FRIENDLY: {
            icon: wheelchairIcon,
            text: "Rollstuhlfreundlich",
        },
        FAMILY_FRIENDLY: {
            icon: familyIcon,
            text: "Familienfreundlich",
        },
        PREGNANCY_FRIENDLY: {
            icon: pregnantIcon,
            text: "Schwangerschaftsfreundlich",
        },
        PET_FRIENDLY: {
            icon: petIcon,
            text: "Haustierfreundlich",
        },
        SUITABLE_FOR_ALL_AGES: {
            icon: allAgesIcon,
            text: "Alle Altersgruppen",
        },
        NOT_APPLICABLE: {
            icon: notappliIcon,
            text: "Alle Geschlechter",
        },
    };
}
