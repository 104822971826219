import React from 'react'
import styles from './Experience.module.css'

import {connect} from 'react-redux'
import {withRouter,useLocation} from 'react-router-dom'

import {getAllExperience,onExperienceDelete} from '../../../containers/category/actions' 

import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
 
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
 
import ConfirmAlert from '../../utils/ConfirmAlert/ConfirmAlert'
import ConfExperience from './ConfExperience/ConfExperience'

const Experience = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [experiences,setExperiences] = React.useState(false);

    React.useEffect(() => {
        if(!props.experienceMap) {
            props.getAllExperience();
        }
        setExperiences(props.experienceMap);
    },[props.experienceMap]);

    let isLoading = !experiences;
    let showData = !isLoading;

    return (
        <div className={styles.container}>

            {isLoading &&
            <div className={styles.loader}>
                <CircularProgress />
            </div>}

            {showData &&
            <div className={styles.listItems}>
                {Object.keys(experiences).length > 0
                    ?
                <React.Fragment>
                    {Object.keys(experiences).map((key,index) => {
                        return (
                            <div 
                                key={index} 
                                className={query.get("experienceId") && query.get("experienceId") == key ? styles.activeListItem : styles.listItem} 
                                onClick={() => props.history.push("/admin/category?experienceId=" + key)}
                            >
                                <p>{experiences[key].experienceName}</p>

                                <div className="actions">
                                    <div className={styles.hoverItems}>
                                        <ConfExperience experience={experiences[key]} type="EDIT" />

                                        <ConfirmAlert msg="Are you sure you wanna delete this experience" onClickEvent={() => {
                                            props.onExperienceDelete(key)
                                        }}>
                                            <Tooltip title="Delete Experience">
                                                <IconButton>
                                                    <DeleteRoundedIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </ConfirmAlert>
                                    </div> 
                                </div>
                            </div>
                        )
                    })}
                </React.Fragment>
                    :
                <div className={styles.contentCenter}>
                    <HourglassEmptyRoundedIcon />
                    <p>No experience Found</p>
                </div> }

                <br/>

                <ConfExperience type="ADD" />
            </div>}
        </div>
    )
}

const mapStateToProps = state => ({
    experienceMap: state.category.experienceMap
})
export default withRouter(connect(mapStateToProps, {getAllExperience,onExperienceDelete})(Experience));