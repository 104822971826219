import React from 'react'
import styles from './ConfService.module.css'

import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField' 
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button' 
import Switch from '@material-ui/core/Switch' 
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Modal from '@material-ui/core/Modal' 
import Tooltip from '@material-ui/core/Tooltip'
 
import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded'; 
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

import {connect} from 'react-redux'
import {getAllExperience} from '../../../../containers/category/actions' 
import {showAlert} from '../../../../containers/app/actions'
import axios from 'axios'
import {withRouter} from 'react-router-dom' 


const ConfService = (props) => { 
    const [formData,setFormData] = React.useState({
        serviceName: "",
        description: "",
        active: true,
        serviceId: ""
    });

    const [error,setError] = React.useState({
        serviceName: false,
        description: false
    });

    const [loading,setLoading] = React.useState(false);
    const [open,setOpen] = React.useState(false)

    React.useEffect(() => {
        props.type == "EDIT" && props.service && setFormData({...formData,...props.service,serviceId: props.service._id})
    },[props.service])

    const validate = () => {
        const err = {serviceName: false,description: false};
        let validData = true;
        setError({...err});
        Object.keys(err).forEach(key => {
            if(formData[key] == "") {
                err[key] = `${key} field cannot be empty`
                validData = false;
            } 
        }) 
        setError({...err});
        return validData;
    }

    const onSubmit = () => {
        if(validate()) {
            setLoading(true);
            const method = props.type == "ADD" ? "post" : "put";
            const url = props.type == "ADD" ? "/dashboard/service/addService" : "/dashboard/service/editService"

            axios({
                method,
                url,
                data: {
                    ...formData,
                    categoryId: props.categoryId,
                    subCategoryId: props.subCategoryId,
                    experienceId: props.experienceId
                }
            }).then(res => {
                setLoading(false);
                props.showAlert(`Service ${props.type == "ADD" ? "Added" : "Updated"} Succesfully`);
                props.getAllExperience()
                setOpen(false)
                setFormData({...formData,serviceName: "", description: ""})
            }).catch(err => {
                setLoading(false);
                if(err && err.response && err.response.data && err.response.data.error) {
                    props.showAlert(err.response.data.error)
                } else {  
                    props.showAlert("Something went wrong ! Try Again")
                }
            })
        }
    }
    const name = props.type == "ADD" ? "Add Activity" : "Edit Activity";
    return (
        <React.Fragment>
            {props.type == "ADD"
                ?
            <Button startIcon={<AddRoundedIcon />} onClick={() => setOpen(true)}>{name}</Button>
                :

            <Tooltip title={name}>
                <IconButton onClick={() => setOpen(true)}>
                    <EditRoundedIcon />
                </IconButton>
            </Tooltip>}

            <Modal className={styles.modal} open={open} onClose={() => setOpen(false)}>
                <div className={styles.container}>

                    <IconButton className={styles.close} onClick={() => setOpen(false)}>
                        <CloseRoundedIcon />
                    </IconButton>

                    <Paper variant="outlined" className={styles.paper}>
                        <h1>{name}</h1>

                        <div className={styles.row}>
                            <TextField 
                                label="Activity Name"
                                className={styles.catName}
                                value={formData.serviceName}
                                onChange={e => setFormData({...formData,serviceName: e.target.value.trimStart()})}
                                error={error.serviceName}
                                helperText={error.serviceName}
                            />
                            <TextField 
                                label="Activity Description"
                                className={styles.catName}
                                value={formData.description}
                                onChange={e => setFormData({...formData,description: e.target.value.trimStart()})}
                                error={error.description}
                                helperText={error.description}
                            />
        
                            <FormControlLabel
                                className={styles.switch}
                                control={<Switch checked={formData.active} onChange={(e) => setFormData({...formData,active: !formData.active})}  color="primary" />}
                                label="Activity Active"
                            /> 
                        </div>

                        <div className={styles.row}>
                            {loading
                                ?
                            <Button color="primary" variant="contained" startIcon={<CircularProgress color="inherit" size={20} />}>Loading ...</Button>
                                :
                            <Button color="primary" variant="contained" startIcon={<UpdateRoundedIcon />} onClick={onSubmit}>{name}</Button>}
                        </div>
                    </Paper>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default withRouter(connect(null,{getAllExperience,showAlert})(ConfService));