import React from 'react'
import styles from './BillingAddress.module.css'

import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import InputAdornment from '@material-ui/core/InputAdornment'

import {connect} from 'react-redux'
import {setBillingAddress} from '../../../../../containers/endUser/actions'

import Country from '../../../../utils/Country/Country'
import SearchAddress from '../../../../utils/SearchAddress/SearchAddress'

const BillingAddress = (props) => {
    const formData = props.billingAddress;
    return ( 
        <div className={styles.container}>
            <div className={styles.row}>
                <FormControl className={styles.fullWidth}>
                    <FormLabel>Street</FormLabel>

                    <SearchAddress
                        variant="outlined"
                        placeholder="Street"
                        size="small"
                        value={formData.street}
                        setFormData={(e, val) => props.setBillingAddress({...formData,street: val})}
                    />   
                </FormControl>

                <FormControl className={styles.halfWidth}>
                    <FormLabel>Building</FormLabel>

                    <TextField 
                        variant="outlined"
                        placeholder="Building"
                        size="small"
                        value={formData.building}
                        onChange={(e) => props.setBillingAddress({...formData,building: e.target.value})}
                    />
                </FormControl> 
            </div>

            <div className={styles.row}>
                <FormControl className={styles.fullWidth}>
                    <FormLabel>City</FormLabel>

                    <TextField 
                        variant="outlined"
                        placeholder="City"
                        size="small"
                        value={formData.city}
                        onChange={(e) => props.setBillingAddress({...formData,city: e.target.value})}
                    />
                </FormControl>

                <FormControl className={styles.halfWidth}>
                    <FormLabel>Zipcode</FormLabel>

                    <TextField 
                        variant="outlined"
                        placeholder="Zipcode"
                        size="small"
                        value={formData.zipcode}
                        onChange={(e) => props.setBillingAddress({...formData,zipcode: e.target.value})}
                    />
                </FormControl> 
            </div>

            <div className={styles.row}>
                <FormControl className={styles.fullWidth}>
                    <FormLabel>Country</FormLabel>
                    <Country
                        className={styles.textField}
                        value={formData.country}
                        onChange={(val) => props.setBillingAddress({...formData,country: val})}
                        variant="outlined" 
                        size="small"
                    />
                </FormControl>

                <FormControl className={styles.fullWidth}>
                    <FormLabel>Phone Number</FormLabel>
                    <TextField
                        variant="outlined"
                        className={styles.textField} 
                        value={formData.phone} 
    
                        onChange={(e) => {
                            e.target.value.length <= 15 && props.setBillingAddress({...formData,phone: e.target.value.replace(/\D/,'')})
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">+ {formData.country && formData.country.phone}</InputAdornment>,
                        }}
                        size="small"
                    />  
                </FormControl>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    billingAddress: state.endUser.billingAddress
})
export default connect(mapStateToProps, {setBillingAddress})(BillingAddress);