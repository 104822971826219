import React from 'react';
import styles from './Users.module.css';

import BreadCrump from '../../components/utils/BreadCrump/BreadCrump'; 
import TopBar from '../../components/utils/TopBar/TopBar';

import ConfUser from '../../components/Users/ConfUser/ConfUser';
import ViewUser from '../../components/Users/ViewUser/ViewUser';


const Users = (props) => {
    const [state,setState] = React.useState("VIEW-USERS");   

    React.useEffect(() => {
        if(Object.keys(navData).includes(props.match.params.type)) {
            setState(props.match.params.type)
        }
    },[props.match.params.type]);

    const navData = {
        "VIEW-USERS": {
            name: "View Users",
            path: "/admin/users/VIEW-USERS"
        },
        "ADD-USER": {
            name: "Add Users",
            path: "/admin/users/ADD-USER"
        },
        "EDIT-USER": {
            name: "Edit Users",
            path: "/admin/users/EDIT-USER"
        },
    }
    return (
        <div className={styles.container}>
            <TopBar head="Users" />
            <BreadCrump 
                navItems={[{name:"User List",path: "/admin/users/VIEW-USERS"},navData[state]]}
            />

            {state == "VIEW-USERS" && <ViewUser />}
            {state == "ADD-USER" && <ConfUser type="ADD" />}
            {state == "EDIT-USER" && <ConfUser type="EDIT" />}
        </div>
    )
}

export default Users;