import React from "react";
import styles from "./navbar.module.css";
import { NavLink } from "react-router-dom";
import InsertDriveFileRoundedIcon from "@material-ui/icons/InsertDriveFileRounded";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import { List, Collapse, Button, ListItemText, makeStyles } from "@material-ui/core";

import { ExpandLess, ExpandMore, Subscriptions } from "@material-ui/icons";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import StorefrontRoundedIcon from "@material-ui/icons/StorefrontRounded";
import AddBoxRoundedIcon from "@material-ui/icons/AddBoxRounded";
import StoreIcon from "@material-ui/icons/Store";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import NotificationsIcon from "@material-ui/icons/Notifications";
import CategoryRoundedIcon from "@material-ui/icons/CategoryRounded";
import ContactMailRoundedIcon from "@material-ui/icons/ContactMailRounded";
import WebRoundedIcon from "@material-ui/icons/WebRounded";
import PeopleAltRoundedIcon from "@material-ui/icons/PeopleAltRounded";
import AccountBalanceWalletRoundedIcon from "@material-ui/icons/AccountBalanceWalletRounded";
import { getLifeCoachNavbar, getLifeCoach } from "../../containers/General/actions";
import { connect } from "react-redux";
import AppLoader from "../utils/AppLoader/AppLoader";

const useStyles = makeStyles((theme) => ({
    listB2b: {
        width: "65%",
    },
    button: {
        paddingLeft: "10px",
        paddingRight: "90px",
    },
    listitemicon: {
        minWidth: "35px",
    },
    listItem: {
        color: "#2D4141",
        fontSize: "14px",
    },
}));

const NavBar = (props) => {
    const [open, setOpen] = React.useState(false);
    const [openList, setOpenList] = React.useState(false);
    const [openB2b, setOpenB2b] = React.useState(false);

    const classes = useStyles();
    const handleClick = () => {
        setOpenList(!openList);
    };
    const handleB2bClick = () => {
        setOpenB2b(!openB2b);
    };
    React.useEffect(() => {
        if (props.auth.userType == "LIFECOACH" && !props.lifeCoach) {
            props.getLifeCoachNavbar();
        }
    }, [props.lifeCoach]);
    const handleUrl = (id) => {
        return "/user/life-coach/" + id;
    };

    const navElements = (
        <React.Fragment>
            {props.auth.userType != "LIFECOACH" && (
                <React.Fragment>
                    <NavLink className={styles.navItem} to="/user/home" activeClassName={styles.activeNavItem}>
                        <HomeRoundedIcon />
                        <p>Home</p>
                    </NavLink>

                    <NavLink
                        className={styles.navItem}
                        to="/user/service-provider/VIEW-SERVICE-PROVIDERS"
                        activeClassName={styles.activeNavItem}
                    >
                        <StoreIcon />
                        <p>Service Provider</p>
                    </NavLink>

                    <List>
                        <Button onClick={handleClick} className={classes.button}>
                            <ListItemText primary="Customers" className={classes.listItem} />
                            {openList ? <ExpandLess /> : <ExpandMore />}
                        </Button>
                        <Collapse in={openList} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <NavLink
                                    className={styles.navItem}
                                    to="/user/end-users/MY-BOOKINGS"
                                    activeClassName={styles.activeNavItem}
                                >
                                    <PeopleAltRoundedIcon />
                                    <p>Bookings</p>
                                </NavLink>
                            </List>
                            <List component="div" disablePadding>
                                <NavLink
                                    className={styles.navItem}
                                    to="/user/end-users/VIEW-END-USERS"
                                    activeClassName={styles.activeNavItem}
                                >
                                    <PeopleAltRoundedIcon />
                                    <p>Customer</p>
                                </NavLink>
                            </List>
                            <List component="div" disablePadding>
                                <NavLink
                                    className={styles.navItem}
                                    to="/user/end-users/Survey"
                                    activeClassName={styles.activeNavItem}
                                >
                                    <PeopleAltRoundedIcon />
                                    <p>Survey</p>
                                </NavLink>
                            </List>
                            <List component="div" disablePadding>
                                <NavLink
                                    className={styles.navItem}
                                    to="/user/end-users/Ratings"
                                    activeClassName={styles.activeNavItem}
                                >
                                    <PeopleAltRoundedIcon />
                                    <p>Ratings</p>
                                </NavLink>
                            </List>
                            {/* <List component="div" disablePadding>
                                <NavLink
                                    className={styles.navItem}
                                    to="/user/end-users/PromoCode"
                                    activeClassName={styles.activeNavItem}
                                >
                                    <PeopleAltRoundedIcon />
                                    <p>Promo Codes</p>
                                </NavLink>
                            </List> */}
                            <List component="div" disablePadding>
                                <NavLink
                                    className={styles.navItem}
                                    to="/user/end-users/Notification"
                                    activeClassName={styles.activeNavItem}
                                >
                                    <NotificationsIcon />
                                    <p>Notification</p>
                                </NavLink>
                            </List>
                            <List component="div" disablePadding>
                                <NavLink
                                    className={styles.navItem}
                                    to="/user/end-users/VIEW-USERS-SUBSCRIPTION"
                                    activeClassName={styles.activeNavItem}
                                >
                                    <Subscriptions />
                                    <p>Subscription</p>
                                </NavLink>
                            </List>

                            <List component="div" disablePadding>
                                <NavLink
                                    className={styles.navItem}
                                    to="/user/end-users/Kpi"
                                    activeClassName={styles.activeNavItem}
                                >
                                    <Subscriptions />
                                    <p>KPI Dashboard</p>
                                </NavLink>
                            </List>
                        </Collapse>
                    </List>

                    <List className={classes.listB2b}>
                        <Button onClick={handleB2bClick} className={classes.b2bButton}>
                            <ListItemText primary="B2b" className={classes.listItem} />
                            {openB2b ? <ExpandLess /> : <ExpandMore />}
                        </Button>
                        <Collapse in={openB2b} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <NavLink
                                    className={styles.navItem}
                                    to="/user/end-users/PromoCode"
                                    activeClassName={styles.activeNavItem}
                                >
                                    <PeopleAltRoundedIcon />
                                    <p>PromoCodes</p>
                                </NavLink>
                            </List>
                            <List component="div" disablePadding>
                                <NavLink
                                    className={styles.navItem}
                                    to="/user/end-users/CompanyId"
                                    activeClassName={styles.activeNavItem}
                                >
                                    <PeopleAltRoundedIcon />
                                    <p>CompanyId</p>
                                </NavLink>
                            </List>
                            <List component="div" disablePadding>
                                <NavLink
                                    className={styles.navItem}
                                    to="/user/end-users/ViewB2BUser"
                                    activeClassName={styles.activeNavItem}
                                >
                                    <PeopleAltRoundedIcon />
                                    <p>B2B Users</p>
                                </NavLink>
                            </List>
                        </Collapse>
                    </List>
                    <NavLink
                        className={styles.navItem}
                        to="/user/add-service-provider/VIEW-ADDED-SERVICE-PROVIDERS"
                        activeClassName={styles.activeNavItem}
                    >
                        <PeopleAltRoundedIcon />
                        <p>Partner Acquistion</p>
                    </NavLink>

                    <NavLink
                        className={styles.navItem}
                        to="/user/service-provider-request/VIEW-REQUESTS"
                        activeClassName={styles.activeNavItem}
                    >
                        <StorefrontRoundedIcon />
                        <p>Service Provider Request</p>
                    </NavLink>

                    <NavLink
                        className={styles.navItem}
                        to="/user/life-coaches-admin/view-coaches"
                        activeClassName={styles.activeNavItem}
                    >
                        <StorefrontRoundedIcon />
                        <p>Life Coaches </p>
                    </NavLink>

                    <NavLink className={styles.navItem} to="/user/email-system" activeClassName={styles.activeNavItem}>
                        <ContactMailRoundedIcon />
                        <p>E-Mail</p>
                    </NavLink>

                    <NavLink
                        className={styles.navItem}
                        to="/user/discount-codes/VIEW-DISCOUNT-CODES"
                        activeClassName={styles.activeNavItem}
                    >
                        <WebRoundedIcon />
                        <p>Discount Code</p>
                    </NavLink>

                    <NavLink
                        className={styles.navItem}
                        to="/admin/gift-card/VIEW-GIFT-CARD"
                        activeClassName={styles.activeNavItem}
                    >
                        <WebRoundedIcon />
                        <p>Gift Card</p>
                    </NavLink>

                    <NavLink
                        className={styles.navItem}
                        to="/user/transactions/VIEW-TRANSACTIONS"
                        activeClassName={styles.activeNavItem}
                    >
                        <AccountBalanceWalletRoundedIcon />
                        <p>Transactions</p>
                    </NavLink>

                    <NavLink className={styles.navItem} to="/user/translation" activeClassName={styles.activeNavItem}>
                        <StoreIcon />
                        <p>Text Editor</p>
                    </NavLink>
                </React.Fragment>
            )}
            {props.auth && props.auth.userType == "LIFECOACH" && (
                <React.Fragment>
                    {props.data && (
                        <React.Fragment>
                            <NavLink
                                className={styles.navItem}
                                to="/user/General"
                                activeClassName={styles.activeNavItem}
                            >
                                <p>General</p>
                            </NavLink>
                            <React.Fragment>
                                {props.data && props.data.platform_coaches
                                    ? props.data.platform_coaches.ManagedSubcategory.map((value) => {
                                          return (
                                              <NavLink
                                                  className={styles.navItem}
                                                  to={handleUrl(value._id)}
                                                  activeClassName={styles.activeNavItem}
                                              >
                                                  <p>{value?.subCategoryName || value?.serviceSubCatName}</p>
                                              </NavLink>
                                          );
                                      })
                                    : undefined}
                            </React.Fragment>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}

            {props.auth && props.auth.userType == "ADMIN" && (
                <React.Fragment>
                    <NavLink
                        className={styles.navItem}
                        to="/admin/partner-agreement"
                        activeClassName={styles.activeNavItem}
                    >
                        <InsertDriveFileRoundedIcon />
                        <p>Partner Agreement</p>
                    </NavLink>
                    <NavLink
                        className={styles.navItem}
                        to="/admin/users/VIEW-USERS"
                        activeClassName={styles.activeNavItem}
                    >
                        <AddBoxRoundedIcon />
                        <p>Add Users</p>
                    </NavLink>
                    <NavLink className={styles.navItem} to="/admin/category/" activeClassName={styles.activeNavItem}>
                        <CategoryRoundedIcon />
                        <p>Category</p>
                    </NavLink>
                </React.Fragment>
            )}
        </React.Fragment>
    );
    return (
        <div className={styles.container}>
            <IconButton className={styles.menuButton} onClick={() => setOpen(true)}>
                <MenuRoundedIcon />
            </IconButton>
            {props.auth.userType == "LIFECOACH" ? (
                <>
                    <div className={styles.header}>
                        <h1>Live With Coach</h1>
                    </div>
                    {!props.data && <AppLoader />}
                    {props.data && <div className={styles.navItems}>{navElements}</div>}
                </>
            ) : (
                <>
                    <div className={styles.header}>
                        <h1>21 Done</h1>
                    </div>
                    <div className={styles.navItems}>{navElements}</div>
                </>
            )}
            <Drawer anchor={"left"} open={open} onClose={() => setOpen(false)}>
                {props.auth.userType == "LIFECOACH" ? (
                    <div className={styles.header}>
                        <h1>Live With Coach</h1>
                    </div>
                ) : (
                    <div className={styles.header}>
                        <h1>21 Done</h1>
                    </div>
                )}
                <div className={styles.mobileNav} onClick={() => setOpen(false)}>
                    {navElements}
                </div>
            </Drawer>
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.app.auth,
    data: state.lifeCoachGeneral.lifecoachnavbar,
    lifeCoach: state.lifeCoachGeneral.lifecoach,
});
export default connect(mapStateToProps, { getLifeCoachNavbar, getLifeCoach })(NavBar);
