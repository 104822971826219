import React from "react";
import styles from "./CropImage.module.css";

import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";

import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import { TextField, Slider, makeStyles } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";

import AppLoader from "../../utils/AppLoader/AppLoader";

import CropRoundedIcon from "@material-ui/icons/CropRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { ZoomIn, ZoomOut } from "@material-ui/icons";
import InfoIcon from "../../utils/InfoIcon/InfoIcon";

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "80%",
    },
    label: {
        color: "#2D4141",
        display: "flex",
        fontFamily: "Montserrat",
        padding: "5px",
    },
    infoicon: {
        fontSize: 14,
        paddingTop: 1,
    },
    button: {
        marginLeft: "40%",
        background: "#FFD719",
        color: "#2D4141",
    },
}));
const CropImage = (props) => {
    const classes = useStyles();
    const [cropedImage, setCropedImage] = React.useState(false);
    const [blob, setBlob] = React.useState(false);
    const [zoomImage, setZoomImage] = React.useState(0);
    const [alt, setAlt] = React.useState({
        altimage: "",
    });
    const elRef = React.useCallback(async (node) => {
        if (node !== null) {
            const cropper = await new Cropper(node, {
                zoomable: true,
                viewMode: 2,
                scalable: false,
                wheelZoomRatio: 0.1,
                aspectRatio: props.cropRatio ? props.cropRatio : 1 / 1,
                cropBoxResizable: props.cropBoxResizable ? props.cropBoxResizable : false,
                dragMode: "move",
                toggleDragModeOnDblclick: false,
                ready: () => {
                    cropper.zoomTo(0.0, {
                        x: cropper.containerData.width / 2,
                        y: cropper.containerData.height / 2,
                    });
                    // cropper.setCropBoxData({
                    // 	width: 200,
                    // 	height: 200,
                    // });
                },
                autoCropArea: 1,
                crop: () => {
                    const canvas = cropper.getCroppedCanvas();
                    setCropedImage(canvas.toDataURL("image/jpeg", 0.5));
                    canvas.toBlob(
                        function (blob) {
                            setBlob(blob);
                        },
                        "image/jpeg",
                        0.5,
                    );
                },
            });
            props.cropperRef.current = cropper;
        }
    }, []);

    const handleImageUpload = () => {
        props.handleFileUpload(blob, true, alt.altimage);
    };

    const handleZoomIn = () => {
        if (props.cropperRef.current && zoomImage < 2) {
            props.cropperRef.current?.zoom(0.1);
            setZoomImage((val) => Math.round((val + 0.2) * 10) / 10);
        }
    };

    const handleZoomOut = () => {
        if (props.cropperRef.current && zoomImage > 0) {
            props.cropperRef.current?.zoom(-0.1);
            setZoomImage((val) => Math.round((val - 0.2) * 10) / 10);
        }
    };

    return (
        <Modal open={props.open} onClose={props.onClose}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <IconButton className={styles.close} onClick={props.onClose}>
                        <CancelRoundedIcon />
                    </IconButton>
                    {props.nameRequired && (
                        <React.Fragment>
                            <div className={styles.row}>
                                <FormControl className={styles.textField}>
                                    {/* <FormLabel>Document Name</FormLabel> */}
                                    <TextField
                                        variant="outlined"
                                        label={"Document name"}
                                        value={props.value}
                                        error={props.error}
                                        helperText={props.error}
                                        onChange={(e) => props.onChange(e.target.value)}
                                    />
                                </FormControl>

                                <button className="btnGreen" onClick={props.onSubmit}>
                                    {"Upload"}
                                </button>
                            </div>
                        </React.Fragment>
                    )}

                    {!props.nameRequired && (
                        <React.Fragment>
                            {props.showLoading ? (
                                <AppLoader />
                            ) : (
                                <div className={styles.contentContainer}>
                                    <div className={styles.leftContent}>
                                        <img ref={elRef} src={props.imgUrl} alt="" />
                                        <div className={styles.zoomSlider}>
                                            <IconButton
                                                id="ZoomOutBtn"
                                                style={{ marginRight: 10 }}
                                                onClick={handleZoomOut}
                                            >
                                                <ZoomOut />
                                            </IconButton>
                                            <Slider
                                                aria-label="Zoom Image"
                                                valueLabelDisplay="auto"
                                                step={0.2}
                                                marks
                                                min={0}
                                                max={2}
                                                value={zoomImage}
                                            />
                                            <IconButton
                                                id="ZoomInBtn"
                                                style={{ marginLeft: 10 }}
                                                onClick={handleZoomIn}
                                            >
                                                <ZoomIn />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <div className={styles.rightContent}>
                                        <img src={cropedImage} alt="" />
                                        <FormControl className={classes.formControl}>
                                            <FormLabel className={classes.label}>
                                                AltText
                                                <InfoIcon
                                                    className={classes.infoicon}
                                                    message={"Alttext"}
                                                    width="100px"
                                                    height="50px"
                                                />
                                            </FormLabel>

                                            <TextField
                                                style={{ width: "100%" }}
                                                variant="outlined"
                                                rows={1}
                                                InputProps={{
                                                    className: styles.input,
                                                }}
                                                value={alt.setAlt}
                                                onChange={(e) => setAlt({ ...alt, altimage: e.target.value })}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            )}
                            <div>
                                {!props.showLoading && (
                                    <Button
                                        className={classes.button}
                                        startIcon={<CropRoundedIcon />}
                                        variant="contained"
                                        onClick={handleImageUpload}
                                    >
                                        {"Save Compressed Image"}
                                    </Button>
                                )}
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default CropImage;
