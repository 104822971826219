import styles from "../ServiceSpecs.module.css";

// import SendInquiry from "../../InfoBox/Voucher/SendInquiry/SendInquiry";

export function ManageOrder({
  quantity,
  setQuantity,
  onBooking,
  service,
  journeyId,
  preview,
}) {
  return (
    <div>
      <div className={`${styles.flexContainer}`}>
        {!service.oneToOneBooking && !journeyId ? (
          <Counter quantity={quantity} setQuantity={setQuantity} />
        ) : (
          <div>&nbsp;</div>
        )}

        <div className={styles.actionButtons}>
          {service.bookingType.includes("VOUCHER") && (
            <button
              onClick={() => !preview && onBooking("VOUCHER")}
              className={styles.buyVoucherButton}
            >
              GUTSCHIEN KAUFEN
            </button>
          )}
          {service.bookingType.includes("APPOINTMENT") && (
            <button
              onClick={() => !preview && onBooking("APPOINTMENT")}
              className={styles.bookNowButton}
            >
              Termin Buchung
            </button>
          )}
        </div>
      </div>
      <div className={`${styles.flexContainer}`}>
          <div>&nbsp;</div>
      <div className={styles.actionButtons}>
            <button
              className={styles.bookNowButton}
            >
              Anbieter kontaktieren
            </button>
        </div>
        </div>
      {/* <SendInquiry preview={preview} /> */}
    </div>
  );
}

export function Counter({ quantity, setQuantity }) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <button
        onClick={() => quantity > 1 && setQuantity(quantity - 1)}
        className={`${styles.tag} ${styles.counterButton}`}
      >
        -
      </button>
      <span style={{ width: "18px" }} />
      <span style={{ color: "var(--primary-bg-color)" }}>{quantity}</span>
      <span style={{ width: "18px" }} />
      <button
        onClick={() => setQuantity(quantity + 1)}
        className={`${styles.tag} ${styles.counterButton}`}
      >
        +
      </button>
    </div>
  );
}