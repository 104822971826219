import React from 'react'
import styles from './Header.module.css'

import {withRouter} from 'react-router-dom'
import { useLocation } from 'react-router-dom'


const Header = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div 
                    onClick={() => props.history.push(`/user/service-provider/UPDATE-GENERAL?userId=${query.get("userId")}`)}
                    className={`${styles.card} ${props.type == "GENERAL" && styles.activeCard}`}>
                    <h1>General</h1>
                </div>
                <div 
                    onClick={() => props.history.push(`/user/service-provider/UPDATE-STORE-PROFILE?userId=${query.get("userId")}`)}
                    className={`${styles.card} ${props.type == "PROFILE" && styles.activeCard}`}>
                    <h1>Store Profile</h1>
                </div>
                <div 
                    onClick={() => props.history.push(`/user/service-provider/VIEW-STORE-SERVICES?userId=${query.get("userId")}`)}
                    className={`${styles.card} ${props.type == "SERVICE" && styles.activeCard}`}>
                    <h1>Services</h1>
                </div>
                <div 
                    onClick={() => props.history.push(`/user/service-provider/VIEW-BOOKINGS?userId=${query.get("userId")}`)}
                    className={`${styles.card} ${props.type == "BOOKING" && styles.activeCard}`}>
                    <h1>Bookings</h1>
                </div>
            </div>
        </div>
    )
}
export default withRouter(Header);