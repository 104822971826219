import * as actionTypes from './actionTypes'
import axios from 'axios'
 
export const getAllServiceProviders = () => dispatch => {
    dispatch({
        type: actionTypes.SET_SERVICE_PROVIDERS,
        payload: false
    })

    axios({
        method: "get",
        url: "/dashboard/service-provider/getServiceProviders"
    }).then(res => {
        dispatch({
            type: actionTypes.SET_SERVICE_PROVIDERS,
            payload: res.data.users
        })
    }).catch(err => {
        dispatch({
            type: actionTypes.SET_SERVICE_PROVIDERS,
            payload: []
        })
        
        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
} 
export const getSPGeneralDetails = (userId) => dispatch => {
    dispatch({
        type: actionTypes.SET_SP_GENERAL_DETAILS,
        payload: false
    })

    axios({
        method: "get",
        url: "/dashboard/service-provider/getSPGeneralDetails?userId=" + userId
    }).then(res => {
        dispatch({
            type: actionTypes.SET_SP_GENERAL_DETAILS,
            payload: res.data.data
        })
    }).catch(err => {
        dispatch({
            type: actionTypes.SET_SP_GENERAL_DETAILS,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
} 
export const getSPStoreProfile = (userId) => dispatch => {
    dispatch({
        type: actionTypes.SET_SP_STORE_PROFILE,
        payload: false
    })

    axios({
        method: "get",
        url: "/dashboard/service-provider/getSPStoreProfile?userId=" + userId
    }).then(res => {
        dispatch({
            type: actionTypes.SET_SP_STORE_PROFILE,
            payload: res.data.data
        })
    }).catch(err => {
        console.log(err)
        dispatch({
            type: actionTypes.SET_SP_STORE_PROFILE,
            payload: []
        })


        if(err.response && err.response.data) {
            dispatch({
                type: "SHOW_ALERT",
                payload: err.response.data.error
            })
        } else {
            dispatch({
                type: "SHOW_ALERT",
                payload: "Something went Wrong! Try Again"
            })
        } 
    })
} 

export const getAllSingleEvents = (userId) => dispatch => {
    dispatch({
        type: actionTypes.SET_SP_SINGLE_EVENTS,
        payload: false
    })

    axios({
        method: "get",
        url: "/dashboard/service-provider/getAllSingleEvents?userId=" + userId
    }).then(res => {
        dispatch({
            type: actionTypes.SET_SP_SINGLE_EVENTS,
            payload: res.data.events
        })
    }).catch(err => {
        console.log(err)
        dispatch({
            type: actionTypes.SET_SP_SINGLE_EVENTS,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
} 
export const updateEventStatus = (data) => dispatch => { 
    axios({
        method: "put",
        url: "/dashboard/service-provider/updateEventStatus?userId=" + data.userId,
        data: {
            ...data
        }
    }).then(res => { 
        dispatch(getAllSingleEvents(data.userId))
        dispatch({
            type: "SHOW_ALERT",
            payload: res.data.message
        })
    }).catch(err => { 
        dispatch({
            type: actionTypes.SET_SP_SINGLE_EVENTS,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
} 

export const getAllBookings = (serviceProvider) => dispatch => {
    dispatch({
        type: actionTypes.SET_ALL_BOOKINGS,
        payload: false
    })

    axios({
        method: "get",
        url: `/dashboard/service-provider/getAllBookings?serviceProvider=${serviceProvider}`
    }).then(res => {
        dispatch({
            type: actionTypes.SET_ALL_BOOKINGS,
            payload: res.data.bookings
        })
    }).catch(err => {
        dispatch({
            type: actionTypes.SET_ALL_BOOKINGS,
            payload: [] 
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}
export const getAllVouchers = (serviceProvider) => (dispatch) => {
    
	dispatch({
		type: actionTypes.SET_ALL_VOUCHERS,
		payload: false,
	});

	axios({
		method: "get",
		url: `/dashboard/service-provider/getAllVoucher?serviceProvider=${serviceProvider}`
	}).then((res) => {
			dispatch({
				type: actionTypes.SET_ALL_VOUCHERS,
				payload: res.data.voucher,
			});
		})
		.catch((err) => {
			dispatch({
				type: actionTypes.SET_ALL_VOUCHERS,
				payload: [],
			}); 
		});
};