import * as actionTypes from './actionTypes'

const initialState = { 
  codes: false,
  codeUsed: false
} 
const reducer =  (state = initialState, action={}) => {
    switch (action.type) { 
        case actionTypes.SET_DISCOUNT_CODE_USED:
            return {...state,codeUsed: action.payload}
        case actionTypes.SET_DISCOUNT_CODES:
            return {...state,codes: action.payload} 
        default:
            return state
    }
}

export default reducer;