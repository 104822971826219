import React from 'react'
import styles from './ViewSchedule.module.css'

import DataTable from 'react-data-table-component';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import ConfirmAlert from '../../utils/ConfirmAlert/ConfirmAlert'

import { getAllSchedules } from '../../../containers/lifeCoaches/actions'
import { showAlert } from '../../../containers/app/actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AppLoader from '../../utils/AppLoader/AppLoader'
import axios from 'axios';


const ViewSchedule = (props) => {
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        props.getAllSchedules(props.coach,props.category);
    }, [props.coach,props.category]);

    const handleDelete = (id) => {

        setLoading(true);
        const url = "/dashboard/life-coaches-admin/deleteSchedule/"+props.coach+"/"+props.category
        const method = "delete";

        axios({
            method,
            url,
            data: {
                id
            }
        }).then(res => {
            setLoading(false);
            props.showAlert("Deleted Succesfully");
            props.history.push(`/user/life-coaches-admin/view-coach-details?coachId=${props.coach}`);
            props.getAllSchedules(props.coach,props.category);
        }).catch(err => {
            setLoading(false);
            props.showAlert("Something went wrong ! Try Again")
        })

    }

    let isLoading = !props.scheduledData;
    let showData = !isLoading;
    let rowData = [];
    !isLoading && props.scheduledData.dates&&props.scheduledData.dates.forEach((bookedDate,index) => {  
        rowData.push({
            sl_no: index + 1,
            startDate: bookedDate.startDate.toString().substr(0,16),
            startTime: bookedDate.startTime,  
            endTime: bookedDate.endTime,  
            actions: 
            <div className="actions">
                <ConfirmAlert msg="Are you sure want to delete" onClickEvent={()=>handleDelete(bookedDate._id)} >
                    <Tooltip title="Delete">
                        {loading
                            ?
                            <CircularProgress size={16} />
                            :
                            <IconButton>
                                <CloseRoundedIcon />
                            </IconButton>}
                    </Tooltip>
                </ConfirmAlert > 
            </div>
        }) 
    }); 

    return (
        <div className={styles.container}>
            {isLoading && <AppLoader />}

            {showData &&
                <DataTable 
                    noHeader={true} 
                    fixedHeader={true}
                    pagination={true}
                    fixedHeaderScrollHeight={"calc(100vh - 320px)"}
                    columns={[
                        {name: "Sl No",selector: "sl_no",sortable: true},
                        {name: "Start Date",selector: "startDate",sortable: true},
                        {name: "Start Time",selector: "startTime",sortable: true},
                        {name: "End Time",selector: "endTime", sortable: true}, 
                        {name: "Action",selector: "actions"}]}
                    data={rowData}
                />}
        </div>   
    )
}
const mapStateToProps = state => ({
    scheduledData:  state.lifeCoachesAdmin.dates
})

export default withRouter(connect(mapStateToProps, { showAlert, getAllSchedules })(ViewSchedule))