import React from 'react';
import styles from './Transactions.module.css';

import BreadCrump from '../../components/utils/BreadCrump/BreadCrump'; 
import TopBar from '../../components/utils/TopBar/TopBar';

import ViewTransaction from '../../components/Transaction/ViewTransactions/ViewTransactions';


const Transactions = (props) => {
    const [state,setState] = React.useState("VIEW-TRANSACTIONS");   

    React.useEffect(() => {
        if(Object.keys(navData).includes(props.match.params.type)) {
            setState(props.match.params.type)
        }
    },[props.match.params.type]);

    const navData = {
        "VIEW-TRANSACTIONS": {
            name: "View Transactions",
            path: "/user/transactions/VIEW-TRANSACTIONS"
        }, 
    }
    return (
        <div className={styles.container}>
            <TopBar head="Transactions" />
            <BreadCrump 
                navItems={[{name:"Transaction List",path: "/user/transactions/VIEW-TRANSACTIONS"},navData[state]]}
            />

            {state == "VIEW-TRANSACTIONS" && <ViewTransaction />} 
        </div>
    )
}

export default Transactions;