import * as actionTypes from './actionTypes'

const initialState = { 
  users: false, 
  general: false,
  storeProfile: false,
  singleEvents: false,

  bookings: false
} 
const reducer =  (state = initialState, action={}) => {
    switch (action.type) { 
        case actionTypes.SET_ALL_BOOKINGS:
            return {...state,bookings: action.payload}
        case actionTypes.SET_ALL_VOUCHERS:
            return { ...state, vouchers: action.payload };
        case actionTypes.SET_SP_SINGLE_EVENTS:
            return {...state,singleEvents: action.payload}
        case actionTypes.SET_SP_STORE_PROFILE:
            return {...state,storeProfile: action.payload}
        case actionTypes.SET_SP_GENERAL_DETAILS:
            return {...state,general: action.payload}
        case actionTypes.SET_SERVICE_PROVIDERS:
            return {...state,users: action.payload}
        default:
            return state
    }
}

export default reducer;