import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import styles from "./CompanyId.module.css";
import TextField from "@material-ui/core/TextField";
import AppLoader from "../../utils/AppLoader/AppLoader";
import { connect } from "react-redux";
import { getCompanyIds } from "../../../containers/endUser/actions";
import DataTable from "react-data-table-component";
import { withRouter } from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import Modal from "./Modal/Modal";
import ConfirmAlert from "../../utils/ConfirmAlert/ConfirmAlert";
import { IconButton, Tooltip } from "@material-ui/core";
import { showAlert } from "../../../containers/app/actions";

import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import { FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";
import EditIcon from "@material-ui/icons/Edit";
import AddPersonIcon from "@material-ui/icons/PersonAdd";

const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
    ({ theme }) => ({
        width: 44,
        height: 26,
        padding: 0,
        "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
                transform: "translateX(16px)",
                color: "#fff",
                "& + .MuiSwitch-track": {
                    backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
                    opacity: 1,
                    border: 0,
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                    opacity: 0.5,
                },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
                color: "#33cf4d",
                border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
                color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
            },
        },
        "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 22,
            height: 22,
        },
        "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
            opacity: 1,
            transition: theme.transitions.create(["background-color"], {
                duration: 500,
            }),
        },
    }),
);

function ActivateDeactivateButton({ isActiveId }) {
    return (
        <FormGroup>
            <FormControlLabel control={<IOSSwitch checked={isActiveId} sx={{ marginLeft: "1.5rem" }} />} />
        </FormGroup>
    );
}

const Promo = (props) => {
    const [searchVal, setSearchVal] = useState("");
    const [dropDown, setDropDown] = useState("All");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const token = localStorage.getItem("token");
    const [id, setId] = useState();
    const [deleteAccount, setDeleteAccount] = useState(false);

    const [isActiveId, setIsActiveId] = useState(true);
    const [contactDetails, setContactDetails] = useState({
        firstName: "",
        emailAddress: "",
    });
    const [activateDeactivateModal, setActivateDeactivateModal] = useState(false);
    const [addContactDetailsModal, setAddContactDetailsModal] = useState(false);

    useEffect(() => {
        props.getCompanyIds(rowsPerPage, page + 1, searchVal, "", "", "");
    }, []);

    const handleChange = (event, newPage) => {
        props.getCompanyIds(
            rowsPerPage,
            newPage + 1,
            searchVal,
            startDate,
            endDate,
            dropDown === "All" ? "" : dropDown,
        );
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        props.getCompanyIds(
            event.target.value,
            page + 1,
            searchVal,
            startDate,
            endDate,
            dropDown === "All" ? "" : dropDown,
        );
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleSearchChange = (e) => {
        setPage(0);
        props.getCompanyIds(rowsPerPage, 1, e.target.value, startDate, endDate);
    };

    let rowData = [];
    let isLoading = !props.companyId;
    let showData = !isLoading;
    !isLoading &&
        props.companyId.forEach((item, index) => {
            rowData.push({
                sl_no: index + 1,
                ...item,
                StartDate: moment(item.startDate).format("DD/MM/YYYY"),
                EndDate: moment(item.endDate).format("DD/MM/YYYY"),
                firstName: item?.contactDetails?.firstName ? item?.contactDetails.firstName : "-",
                emailAddress: item?.contactDetails?.emailAddress ? item.contactDetails.emailAddress : "-",
                action: (
                    <>
                        <IconButton
                            onClick={() => {
                                setDeleteAccount(true);
                                setOpen(true);
                                setId(item._id);
                            }}
                        >
                            <svg
                                width="17"
                                height="19"
                                viewBox="0 0 17 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.79671 18.875C2.38005 18.875 2.01546 18.7187 1.70296 18.4062C1.39046 18.0937 1.23421 17.7292 1.23421 17.3125V2.46875H0.947754C0.722059 2.46875 0.535428 2.39497 0.387858 2.2474C0.240289 2.09983 0.166504 1.91319 0.166504 1.6875C0.166504 1.46181 0.240289 1.27517 0.387858 1.1276C0.535428 0.980035 0.722059 0.90625 0.947754 0.90625H5.06234C5.06234 0.680556 5.13612 0.493924 5.28369 0.346354C5.43126 0.198785 5.61789 0.125 5.84359 0.125H11.1561C11.3818 0.125 11.5684 0.198785 11.716 0.346354C11.8636 0.493924 11.9373 0.680556 11.9373 0.90625H16.0519C16.2776 0.90625 16.4642 0.980035 16.6118 1.1276C16.7594 1.27517 16.8332 1.46181 16.8332 1.6875C16.8332 1.91319 16.7594 2.09983 16.6118 2.2474C16.4642 2.39497 16.2776 2.46875 16.0519 2.46875H15.7655V17.3125C15.7655 17.7292 15.6092 18.0937 15.2967 18.4062C14.9842 18.7187 14.6196 18.875 14.203 18.875H2.79671ZM5.55713 14.2917C5.55713 14.5174 5.63091 14.704 5.77848 14.8516C5.92605 14.9991 6.11268 15.0729 6.33838 15.0729C6.56407 15.0729 6.7507 14.9991 6.89827 14.8516C7.04584 14.704 7.11963 14.5174 7.11963 14.2917V5.46354C7.11963 5.23785 7.04584 5.05122 6.89827 4.90365C6.7507 4.75608 6.56407 4.68229 6.33838 4.68229C6.11268 4.68229 5.92605 4.75608 5.77848 4.90365C5.63091 5.05122 5.55713 5.23785 5.55713 5.46354V14.2917ZM9.88004 14.2917C9.88004 14.5174 9.95383 14.704 10.1014 14.8516C10.249 14.9991 10.4356 15.0729 10.6613 15.0729C10.887 15.0729 11.0736 14.9991 11.2212 14.8516C11.3688 14.704 11.4425 14.5174 11.4425 14.2917V5.46354C11.4425 5.23785 11.3688 5.05122 11.2212 4.90365C11.0736 4.75608 10.887 4.68229 10.6613 4.68229C10.4356 4.68229 10.249 4.75608 10.1014 4.90365C9.95383 5.05122 9.88004 5.23785 9.88004 5.46354V14.2917Z"
                                    fill="#2D4141"
                                />
                            </svg>
                        </IconButton>
                    </>
                ),
                addContactDetails: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {item?.contactDetails?.firstName || item?.emailAddress?.emailAddress ? (
                            <Tooltip title={"Edit Contact Details"}>
                                <IconButton
                                    onClick={() => {
                                        setId(item._id);
                                        setOpen(true);
                                        setContactDetails({
                                            firstName: item?.contactDetails?.firstName,
                                            emailAddress: item?.contactDetails?.emailAddress,
                                        });
                                        setAddContactDetailsModal(true);
                                    }}
                                >
                                    <EditIcon style={{ color: "black", marginLeft: "10px" }} />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title={"Add Contact Details"}>
                                <IconButton
                                    onClick={() => {
                                        setId(item._id);
                                        setOpen(true);
                                        setAddContactDetailsModal(true);
                                        setContactDetails({
                                            firstName: "",
                                            emailAddress: "",
                                        });
                                    }}
                                >
                                    <AddPersonIcon style={{ color: "black" }} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                ),
                showLeaderboard: (
                    <>
                        <Tooltip title={!item.showLeaderboard ? "Activate" : "Deactivate"}>
                            <IconButton onClick={() => leaderboardHandler(item._id, item.showLeaderboard)}>
                                <ActivateDeactivateButton
                                    isActiveId={item.showLeaderboard}
                                    setIsActiveId={setIsActiveId}
                                />
                            </IconButton>
                        </Tooltip>
                    </>
                ),
                isAICoachEnabled: (
                    <>
                        <Tooltip title={!item.isAICoachEnabled ? "Activate" : "Deactivate"}>
                            <IconButton onClick={() => aiCoachHandler(item._id, item.isAICoachEnabled)}>
                                <ActivateDeactivateButton
                                    isActiveId={item.isAICoachEnabled}
                                    setIsActiveId={setIsActiveId}
                                />
                            </IconButton>
                        </Tooltip>
                    </>
                ),
                activateDeactivate: (
                    <>
                        <Tooltip title={item.isDeactivated ? "Activate" : "Deactivate"}>
                            <IconButton
                                onClick={() => {
                                    setId(item._id);
                                    setOpen(true);
                                    setIsActiveId(item?.isDeactivated);
                                    setActivateDeactivateModal(true);
                                }}
                            >
                                <ActivateDeactivateButton
                                    isActiveId={!item.isDeactivated}
                                    setIsActiveId={setIsActiveId}
                                />
                            </IconButton>
                        </Tooltip>
                    </>
                ),
            });
        });

    const leaderboardHandler = (companyId, showLeaderboard) => {
        axios({
            method: "put",
            url: "/dashboard/companyId/activateDeactivateShowLeaderboard",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                companyId,
            },
        })
            .then((res) => {
                props.showAlert(showLeaderboard ? "Leaderboard hidden successfully" : "Leaderboard is being shown");
                props.getCompanyIds(10, page + 1, "", "", "");
            })
            .catch((err) => {
                if (err && err.response) {
                    props.showAlert("Something Went Wrong");
                }
            });
    };

    const aiCoachHandler = (companyId, isAICoachEnabled) => {
        axios({
            method: "put",
            url: "/dashboard/companyId/activateDeactivateAICoach",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                companyId,
            },
        })
            .then((res) => {
                props.showAlert(!isAICoachEnabled ? "AI coach enabled successfully" : "AI coach disabled successfully");
                props.getCompanyIds(10, page + 1, "", "", "");
            })
            .catch((err) => {
                if (err && err.response) {
                    props.showAlert("Something Went Wrong");
                }
            });
    };

    return (
        <div className={styles.container}>
            <Modal
                open={open}
                id={id}
                onClose={() => {
                    setOpen(false);
                    setDeleteAccount(false);
                    setActivateDeactivateModal(false);
                    setAddContactDetailsModal(false);
                }}
                isActiveId={isActiveId}
                deleteAccount={deleteAccount}
                setDeleteAccount={setDeleteAccount}
                activateDeactivateModal={activateDeactivateModal}
                addContactDetailsModal={addContactDetailsModal}
                setActivateDeactivateModal={setActivateDeactivateModal}
                contactDetails={contactDetails}
                setContactDetails={setContactDetails}
                setAddContactDetailsModal={setAddContactDetailsModal}
            />
            <div className={styles.topHeader}>
                <div>
                    <h3>CompanyId</h3>
                    <p>Create and view status of promo codes for companies</p>
                </div>
            </div>
            <div className={styles.header}>
                <div className={styles.leftHeader}>&nbsp;</div>

                <div className={styles.rightHeader}>
                    <svg
                        onClick={() => setOpen(true)}
                        width="29"
                        height="29"
                        viewBox="0 0 29 29"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M14.6031 21.375C14.901 21.375 15.1474 21.2776 15.3422 21.0828C15.537 20.888 15.6344 20.6417 15.6344 20.3438V15.6688H20.3781C20.6531 15.6688 20.888 15.5714 21.0828 15.3766C21.2776 15.1818 21.375 14.9354 21.375 14.6375C21.375 14.3396 21.2776 14.0932 21.0828 13.8984C20.888 13.7036 20.6417 13.6063 20.3438 13.6063H15.6344V8.62187C15.6344 8.34687 15.537 8.11198 15.3422 7.91719C15.1474 7.7224 14.901 7.625 14.6031 7.625C14.3052 7.625 14.0589 7.7224 13.8641 7.91719C13.6693 8.11198 13.5719 8.35833 13.5719 8.65625V13.6063H8.62187C8.34687 13.6063 8.11198 13.7036 7.91719 13.8984C7.7224 14.0932 7.625 14.3396 7.625 14.6375C7.625 14.9354 7.7224 15.1818 7.91719 15.3766C8.11198 15.5714 8.35833 15.6688 8.65625 15.6688H13.5719V20.3781C13.5719 20.6531 13.6693 20.888 13.8641 21.0828C14.0589 21.2776 14.3052 21.375 14.6031 21.375ZM14.5 28.25C12.5521 28.25 10.7417 27.9005 9.06875 27.2016C7.39583 26.5026 5.94063 25.5344 4.70312 24.2969C3.46563 23.0594 2.4974 21.6042 1.79844 19.9312C1.09948 18.2583 0.75 16.4479 0.75 14.5C0.75 12.575 1.09948 10.776 1.79844 9.10312C2.4974 7.43021 3.46563 5.975 4.70312 4.7375C5.94063 3.5 7.39583 2.52604 9.06875 1.81563C10.7417 1.10521 12.5521 0.75 14.5 0.75C16.425 0.75 18.224 1.10521 19.8969 1.81563C21.5698 2.52604 23.025 3.5 24.2625 4.7375C25.5 5.975 26.474 7.43021 27.1844 9.10312C27.8948 10.776 28.25 12.575 28.25 14.5C28.25 16.4479 27.8948 18.2583 27.1844 19.9312C26.474 21.6042 25.5 23.0594 24.2625 24.2969C23.025 25.5344 21.5698 26.5026 19.8969 27.2016C18.224 27.9005 16.425 28.25 14.5 28.25Z"
                            fill="#2D4141"
                        />
                    </svg>
                    <TextField
                        placeholder="Search Here"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        className={styles.search}
                        value={searchVal}
                        onChange={(e) => {
                            setSearchVal(e.target.value);
                            handleSearchChange(e);
                        }}
                    />
                </div>
            </div>
            {isLoading && <AppLoader />}

            {showData && (
                <React.Fragment>
                    {showData && (
                        <DataTable
                            noHeader={true}
                            fixedHeader={true}
                            pagination={false}
                            fixedHeaderScrollHeight={"calc(100vh - 320px)"}
                            columns={[
                                {
                                    name: "Company Id",
                                    selector: "companyId",
                                    sortable: true,
                                    minWidth: "150px",
                                },
                                {
                                    name: "Company Name",
                                    selector: "companyName",
                                    sortable: true,
                                    minWidth: "150px",
                                },

                                { name: "StartDate", selector: "StartDate", sortable: true, minWidth: "150px" },
                                { name: "EndDate", selector: "EndDate", sortable: true, minWidth: "150px" },

                                {
                                    name: "First Name",
                                    selector: "firstName",
                                    sortable: true,
                                    minWidth: "180px",
                                    center: true,
                                },
                                {
                                    name: "Email Address",
                                    selector: "emailAddress",
                                    sortable: true,
                                    minWidth: "180px",
                                    center: true,
                                },
                                {
                                    name: "Email Restriction",
                                    selector: "emailEndsWith",
                                    sortable: true,
                                    minWidth: "160px",
                                },
                                {
                                    name: "Registeration Limit",
                                    selector: "registerationLimit",
                                    sortable: true,
                                    minWidth: "180px",
                                    center: true,
                                },
                                {
                                    name: "Subscription Type",
                                    selector: (row) => (row.subscriptionType ? row.subscriptionType?.name : "3-months"),
                                    sortable: true,
                                    minWidth: "160px",
                                    center: true,
                                },
                                {
                                    name: "Status",
                                    selector: (row) => (row.isDeactivated ? "Inactive" : "Active"),
                                    sortable: true,
                                    minWidth: "120px",
                                },
                                {
                                    name: "Contact Details",
                                    selector: "addContactDetails",
                                    sortable: true,
                                    minWidth: "150px",
                                    center: true,
                                },
                                {
                                    name: "Manage Leaderboard",
                                    selector: "showLeaderboard",
                                    sortable: true,
                                    minWidth: "180px",
                                },
                                {
                                    name: "Manage AI Coach",
                                    selector: "isAICoachEnabled",
                                    sortable: true,
                                    minWidth: "180px",
                                },
                                {
                                    name: "Manage Status",
                                    selector: "activateDeactivate",
                                    sortable: true,
                                    minWidth: "180px",
                                },
                                { name: "Action", selector: "action", sortable: true },
                            ]}
                            data={rowData}
                        />
                    )}
                    <div className={styles.flexEnd}>
                        <TablePagination
                            component="div"
                            count={props?.companyIdCount || 0}
                            page={page}
                            onChangePage={handleChange}
                            rowsPerPage={rowsPerPage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};
const mapStateToProps = (state) => ({
    companyId: state.endUser.companyId,
    companyIdCount: state.endUser.companyIdCount,
});

export default withRouter(connect(mapStateToProps, { getCompanyIds, showAlert })(Promo));
