import React from 'react'
import styles from './CustomerGroup.module.css'

import Autocomplete from '@material-ui/lab/Autocomplete';
import Radio from '@material-ui/core/Radio'
import TextField from '@material-ui/core/TextField'
 
import {getBasicEndUserList,getAllCustomerGroup} from '../../../../containers/app/actions'
import {connect} from 'react-redux';
import AddCustomerGroup from './AddCustomerGroup/AddCustomerGroup';
 

const Applicable = (props) => {
    React.useEffect(() => {
        if(!props.basicUserList) {
            props.getBasicEndUserList();
        }
    },[props.basicUserList]);

    React.useEffect(() => {
        if(!props.customerGroup) {
            props.getAllCustomerGroup();
        }
    },[props.customerGroup]);
     

    const formData = props.formData;
    const error = props.error;
    return (
        <div className={styles.compContent}>
            <h3>Customer Type: </h3>

            <div className={styles.formGroup}>
                <div className={styles.selectors}>
                    <div className={styles.radio}>
                        <p>All Customers</p>
                        <Radio
                            checked={formData.customerType == "ALL-CUSTOMERS"}
                            onChange={e => props.setFormData({...formData,customerType: "ALL-CUSTOMERS"})}
                        />
                    </div>

                    <div className={styles.radio}>
                        <p>Specific Customer Groups</p>
                        <Radio
                            checked={formData.customerType == "CUSTOMER-GROUP"}
                            onChange={e => props.setFormData({...formData,customerType: "CUSTOMER-GROUP"})}
                        />
                    </div>

                    <div className={styles.radio}>
                        <p>Specific Customers</p>
                        <Radio
                            checked={formData.customerType == "CUSTOMERS"}
                            onChange={e => props.setFormData({...formData,customerType: "CUSTOMERS",})}
                        />
                    </div>
                </div> 

                {formData.customerType == "CUSTOMER-GROUP" &&
                    <React.Fragment>
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            options={props.customerGroup ? props.customerGroup : []}
                            getOptionLabel={(option) => option.groupName}
                            className={styles.textField}
                            value={formData.customerGroup}
                            onChange={(e,val) => props.setFormData({...formData,customerGroup: val})}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Select Customer Group"
                                    placeholder="Select Customer Group"
                                    error={error.customerGroup}
                                    helperText={error.customerGroup}
                                />
                            )}
                        />

                        <AddCustomerGroup basicUserList={props.basicUserList}/>
                    </React.Fragment>}

                {formData.customerType == "CUSTOMERS" &&
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        options={props.basicUserList ? props.basicUserList : []}
                        getOptionLabel={(option) => option.email}
                        className={styles.textField}
                        value={formData.customerList}
                        onChange={(e,val) => props.setFormData({...formData,customerList: val})}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Select Customer List"
                                placeholder="Select Customer List"
                                error={error.customerList}
                                helperText={error.customerList}
                            />
                        )}
                    />}  
            </div>
        </div>
    )
}

const mapStateToProps = state => ({ 
    basicUserList: state.app.basicUserList,
    customerGroup: state.app.customerGroup  
})
export default connect(mapStateToProps,{getBasicEndUserList, getAllCustomerGroup})(Applicable);