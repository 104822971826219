import React from 'react';
import styles from './LifeCoach.module.css';
import {useLocation} from 'react-router-dom'
import TopBar from '../../components/utils/TopBar/TopBar'; 
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs'; 
import TextField from '@material-ui/core/TextField'
import ViewSchedule from '../../components/LifeCoach/ViewSchedule/ViewSchedule';
import ManageSchedule from '../../components/LifeCoach/ManageSchedule/ManageSchedule';
import Bookings from '../../components/LifeCoach/Bookings/Bookings';
import BreadCrump from '../../components/utils/BreadCrump/BreadCrump'; 
import Viewlifecoaches from "../../components/lifecoaches/viewlifecaochese/Viewlifecoaches"
import ViewCoachDetail from "../../components/lifecoaches/ViewCoachDetail/ViewCoachDetail"
import General from "../../components/lifecoaches/General/General"
import { withRouter } from 'react-router-dom';
const LifeCoach = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const [state,setState] = React.useState("view-coaches");
    React.useEffect(() => { 
        console.log("*********")
        console.log("*********")
        console.log("*********")
        console.log("*********")
        console.log("*********")
        console.log("*********")
        console.log(props)
        setState(props.match.params.type)
    }, [props.match.params.type]);
    const navData = {
        "view-coaches": {
            name: "View Coaches",
            path: "/user/life-coaches-admin/view-coaches"
        },
        "view-coach-details": {
            name: "View Coach details",
            path: `/user/life-coaches-admin/view-coach-details?coachId=${query.get("coachId")}`
        }, 
        "view-coach-general": {
            name: "View Coach details",
            path: `/user/life-coaches-admin/view-coach-general?coachId=${query.get("coachId")}`
        }, 
    }
    return (
        <div className={styles.container}>
            <TopBar head="Platform Coach" /> 
            <BreadCrump 
                navItems={[
                    {name:"Coach",path: "/user/life-coaches-admin/view-coaches"},
                    state == "view-coaches" ? navData["Coach"] : false,
                    state == "view-coach-details" ? navData["Coach"] : false,
                    state == "view-coach-general" ? navData["Coach"] : false,
                    

                    navData[state]
                ]}
            />
            {console.log(state)}
            {state == "view-coaches" && <Viewlifecoaches />} 
            {state == "view-coach-details" && <ViewCoachDetail />} 
            {state == "view-coach-general" && <General   />} 

        </div>
    )
}

export default LifeCoach;
// export default withRouter (LifeCoach);