import React from 'react'
import styles from './SetAccount.module.css'

import axios from 'axios' 
import {connect} from 'react-redux'

import {showAlert,setAuth} from '../../../containers/app/actions'
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import CircularProgress from '@material-ui/core/CircularProgress'

import PassTextField from '../../utils/PassTextField/PassTextField'
import {useLocation} from 'react-router-dom'


const SetAccount = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [formData,setFormData] = React.useState({
        name: '',
        email: '',
        password: "",
        cpassword: ""
    });
    const [formError,setFormError] = React.useState({
        password: false,
        cpassword: false
    });
    const [loading,setLoading] = React.useState(false);
    const [loaded,setLoaded] = React.useState(false);

    React.useEffect(() => {
        if(props.auth) {
            props.history.push("/user/home")
        }
    },[props.auth]);

    React.useEffect(() => {
        if(query.get("token")) {
            getUserDetails();
        } else {
            props.history.push("/login")
        }
    },[])
    
    const validate = () => {
        let value = true;
        let err = {password: false,cpassword: false}
        setFormError({...err}); 
        if(formData.password.length < 8) {
            value = false;
            err.password = "Password should atleast 8 charactors"
        } else {
            if(formData.cpassword != formData.password) {
                value = false;
                err.password = "Password are not equal"
            }
        }
        if(formData.cpassword.length < 8) {
            value = false;
            err.cpassword = "Confirm Password should atleast 8 charactors"
        }

        if(formData.password == "") {
            value = false;
            err.password = "Enter valid Password"
        } 
        if(formData.cpassword == "") {
            value = false;
            err.cpassword = "Enter valid Confirm Password"
        }

        setFormError({...err});
        return value;
    }

    const getUserDetails = () => {
        axios({
            method: "get",
            url: "/dashboard/user/getUserDetails",
            headers: {
                'Authorization': query.get("token")
            }
        }).then(res => {
            setFormData({...formData,...res.data.user,password: ""})
            setLoaded(true);  
        })
        .catch(err => {
            if(err && err.response) 
                props.showAlert(err.response.data.error)
            else  
                props.showAlert("Something went wrong Try Again")
            
            if(err && err.response.status == 401) {
                props.history.push("/login")
            }
            setLoaded(true);
        })
    }

    const onSubmit = () => {
        if(validate()) {
            setLoading(true);

            axios({
                method: "post",
                url: "/dashboard/user/updateUserDetailsWithPassword",
                data: {
                    ...formData, 
                },
                headers: {
                    'Authorization': query.get("token")
                }
            }).then(res => { 
                if(res.data.user.userType == "ADMIN" || res.data.user.userType == "SALES" || res.data.user.userType == "LIFECOACH") {
                    props.setAuth({...res.data.user,token: res.data.token})
                    localStorage.setItem("token",res.data.token)
                    props.showAlert("Logged In Successfully")
                } else {
                    props.showAlert("401: You don't have enough access")
                }
                setLoading(false);
            }).catch(err => {
                if(err && err.response) 
                    props.showAlert(err.response.data.error)
                else 
                    props.showAlert("Something went wrong Try Again")

                setLoading(false);
            });
        }
    }
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.header}> 
                    <h1 className={styles.head}>21 Done Admin</h1>
                </div>

                <div className={styles.loginContainer}>
                    {!loaded
                        ?
                    <div className={styles.loader}>
                        <CircularProgress />
                    </div>
                        :
                    <div className={styles.loginContent}>
                        <h1 className={styles.loginHead}>Complete your profile</h1>
                        
                        <h3>Hey {formData.name}</h3>
                        <p>Please set your password for account {formData.email}</p>
                        {/*                     
                        <TextField
                            variant="standard"
                            fullWidth
                            label="Name"
                            className={styles.textField}
                            value={formData.name}
                            onChange={(e) => setFormData({...formData,name: e.target.value})}
                            error={formError.name}
                            helperText={formError.name}
                        /> 

                        <TextField
                            variant="standard"
                            fullWidth
                            label="Email"
                            className={styles.textField}
                            value={formData.email}
                            onChange={(e) => setFormData({...formData,email: e.target.value})}
                            error={formError.email}
                            helperText={formError.email}
                        />  */}

                        <PassTextField
                            variant="standard"
                            fullWidth
                            label="Password"
                            className={styles.textField}
                            value={formData.password}
                            onChange={(e) => setFormData({...formData,password: e.target.value})}
                            error={formError.password}
                            helperText={formError.password}
                        />

                        <PassTextField
                            variant="standard"
                            fullWidth
                            label="Confirm Password"
                            className={styles.textField}
                            value={formData.cpassword}
                            onChange={(e) => setFormData({...formData,cpassword: e.target.value})}
                            error={formError.cpassword}
                            helperText={formError.cpassword}
                        />

                        {loading
                            ?
                        <button className={styles.signIn}>
                            Loading...
                            <ArrowForwardRoundedIcon />
                        </button>
                            :
                        <button className={styles.signIn} onClick={onSubmit}>
                            Complete Profile
                            <ArrowForwardRoundedIcon />
                        </button>}
                    </div>}
                </div>

                <div className={styles.loginFooter}>&nbsp;</div>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    auth: state.app.auth
});
export default connect(mapStateToProps,{showAlert,setAuth})(SetAccount);