import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import countries from '../../../config/countries.json'

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

const useStyles = makeStyles({
  option: {
    backgroundColor: 'var(--primary-bg-color)',
    color: 'var(--primary-color)',
    fontSize: 14,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
    '&:hover': {
      backgroundColor: 'var(--primary-bg-color)',
    }
  },
  optionWhite: {
    backgroundColor: 'white',
    color: 'var(--primary-color)',
    fontSize: 14,
    '& > span': {
      marginRight: 10,
      fontSize: 14,
    },
    '&:hover': {
      backgroundColor: 'white',
    }
  },
});

const Country = (props) => {
  const classes = useStyles();

  return (
    <Autocomplete
      id="country-select-demo"
      className={props.className}
      options={countries}
      disabled={props.disabled}
      classes={{
        option: props.color ? classes.optionWhite : classes.option,
      }} 
      value={props.value}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        getContentAnchorEl: null, 
      }}
      onChange={(e,val) => props.onChange(val)}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(option) => (
        <React.Fragment>
          <span>{countryToFlag(option.code)}</span>
          {option.label} ({option.code}) +{option.phone}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label && "Choose a country"}
          variant={props.variant}
          size={props.size}
          placeholder={props.placeholder}
          inputProps={{
            ...params.inputProps,
            autoComplete: "off", 
          }}
          error={props.error}
          helperText={props.error}
        />
      )}
    />
  );
}
export default Country;
