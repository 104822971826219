import React from "react";
import styles from "./EditSingleEvent.module.css";

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ChipInput from "material-ui-chip-input";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { InputLabel, FormHelperText, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import PanoramaRoundedIcon from "@material-ui/icons/PanoramaRounded";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import MediaHandler from "../../../Media/MediaHandler";
import JourneyTopic from "../ChooseCategory/JourneyTopic";
import ChooseCategory from "../ChooseCategory/ChooseCategory";
import BtnLoader from "../../../utils/BtnLoader/BtnLoader";

import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import { showAlert } from "../../../../containers/app/actions";
import {
    getAllSingleEvents,
    getSPGeneralDetails,
    getSPStoreProfile,
} from "../../../../containers/serviceProvider/actions";
import axios from "axios";

import Specifications from "./Specifications/Specifications";
import Fulfilment from "./Fulfilment/Fulfilment";
import ServiceCalender from "../../ServiceCalender/ServiceCalender";
import getPreviewUrl from "../../../../hooks/getPreviewUrl";

const useStyles = makeStyles((theme) => ({
    accordian: {
        boxShadow: "none",
    },
    headersWidth: {
        width: "100%",
    },
    included: {
        color: "#2D4141",
        display: "flex",
        paddingRight: "80px",
        alignItems: "center",
        fontSize: "14px",
    },
    Requirements: {
        color: "#2D4141",
        display: "flex",
        paddingRight: "99px",
        alignItems: "center",
        fontSize: "14px",
    },
    good: {
        color: "#2D4141",
        paddingRight: "98px",
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
    },
    terms: {
        color: "#2D4141",
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
    },
    infoicon: {
        fontSize: 14,
        paddingTop: 1,
    },
    formLabel: {
        display: "flex",
    },
}));

const EditSingleEvent = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const classes = useStyles();
    const [formData, setFormData] = React.useState({
        eventName: "",
        description: "",
        isStandardLink: false,
        headers: {
            "What Is Included": {
                active: false,
                value: "",
                message: "include",
            },
            Requirements: {
                active: false,
                value: "",
                message: "requirment",
            },
            "Good to Know": {
                active: false,
                value: "",
                message: "goodtoKnow",
            },
            "Service Terms and Conditions": {
                active: false,
                value: "",
                message: "terms",
            },
        },
        images: [],
        tags: [],
        coverimage: "",
        altimage: [],
        coverAlttext: "",
        sortNumber: "",
        rruleData: [],
        pricePerPerson: "",
        catList: [],
        specifications: {
            language: [],
            gender: "",
            age: [],
            size: [],
            difficultyLevel: [],
            accessibility: [],
        },
        locationType: "",
        coordinates: { lat: "", lng: "" },
        location: "",
        bookingType: ["APPOINTMENT"],
        serviceDuration: {
            days: 0,
            minutes: 0,
            hours: 0,
        },
        minQuota: "",
        standardMetingLink: "",
        standardMetingLinkInfo: "",
        maxQuota: 0,
        metadescription: "",
        additionalRequired: 0,
        NoofSession: 0,
        journeyTopics: [],
        confirmedBooking: 0,
        others: "",
        remainingAvailability: 0,
        priceWithoutVat: "",
        vat: 0,
        oneToOneBooking: null,
        CapacityLimitation: null,
        scheduleType: "MULTIPLE_DATES",
        dates: [""],
        daySlot: {},
        triggers: [],
        weekSlots: {
            Mon: {
                active: true,
                timeSlots: [{ start: "09:00", end: "10:30" }],
            },
            Tue: {
                active: true,
                timeSlots: [{ start: "09:00", end: "10:30" }],
            },
            Wed: {
                active: true,
                timeSlots: [{ start: "09:00", end: "10:30" }],
            },
            Thu: {
                active: true,
                timeSlots: [{ start: "09:00", end: "10:30" }],
            },
            Fri: {
                active: true,
                timeSlots: [{ start: "09:00", end: "10:30" }],
            },
            Sat: {
                active: true,
                timeSlots: [{ start: "09:00", end: "10:30" }],
            },
            Sun: {
                active: true,
                timeSlots: [{ start: "09:00", end: "10:30" }],
            },
        },
    });

    const [error, setError] = React.useState({
        eventName: false,
        rruleData: false,
        description: false,
        headers: {
            "What Is Included": false,
            Requirements: false,
            "Good to Know": false,
        },
        images: false,
        tags: false,
        language: false,
        size: false,
        coverimage: false,
        journeyTopics: false,
        standardMetingLink: false,
        NoofSession: false,
        pricePerPerson: false,
        catList: false,
        locationType: false,
        location: false,
        scheduleType: false,
        triggers: false,
        others: false,
        minQuota: false,
        maxQuota: false,
        additionalRequired: false,
        confirmedBooking: false,
        remainingAvailability: false,

        vat: false,
    });

    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [opencover, setOpenCover] = React.useState(false);
    const [categoryModal, setCategoryModal] = React.useState(false);
    const [journeyTopicModal, setJourneyTopicModal] = React.useState(false);
    const [startValidation, setStartValidation] = React.useState(false);
    const [serviceId, setServiceId] = React.useState(true);
    const [preview, setPreview] = React.useState(false);
    const [errormsg, setErrorMsg] = React.useState(false);

    const editorRef = React.useRef();
    const [editorLoaded, setEditorLoaded] = React.useState(false);
    const { CKEditor, ClassicEditor } = editorRef.current || {};

    React.useEffect(() => {
        editorRef.current = {
            // CKEditor: require('@ckeditor/ckeditor5-react'), // depricated in v3
            CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
            ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
        };
        setEditorLoaded(true);
    }, []);

    React.useEffect(() => {
        if (startValidation) {
            validate();
        }
    }, [formData]);

    React.useEffect(() => {
        if (query.get("eventId")) {
            if (props.singleEvents) {
                props.singleEvents.forEach((event) => {
                    if (event._id == query.get("eventId")) {
                        if (props.type == "EDIT") {
                            setFormData({
                                ...formData,
                                ...event,
                                eventId: event._id,
                                // weekSlots: event.scheduleType == "REGULAR" ? event.weekSlots : temp,
                            });
                        } else {
                            setFormData({
                                ...formData,
                                ...event,
                                // weekSlots: event.scheduleType == "REGULAR" ? event.weekSlots : temp,
                            });
                        }
                    }
                });
            } else {
                props.getAllSingleEvents(query.get("userId"));
            }
        } else {
            props.showAlert("Invalid request");
            props.history.push("/service-provider/VIEW-STORE-SERVICES?userId=" + query.get("userId"));
        }
    }, [props.singleEvents]);

    React.useEffect(() => {
        props.getSPGeneralDetails(query.get("userId"));
        props.getSPStoreProfile(query.get("userId"));
    }, []);

    const validate = (showAlert) => {
        setStartValidation(true);
        const err = {
            eventName: false,
            description: false,
            headers: {
                "What Is Included": false,
                Requirements: false,
                "Good to Know": false,
            },
            images: false,
            serviceDuration: false,
            tags: false,
            language: false,
            size: false,
            standardMetingLink: false,
            journeyTopics: false,
            NoofSession: false,
            pricePerPerson: false,
            catList: false,
            others: false,
            locationType: false,
            location: false,

            bookingType: false,
            minQuota: false,
            coverimage: false,
            maxQuota: false,
            dates: false,
        };

        let validData = true;
        let notValidate1 = ["tags", "images", "dates", "catList", "journeyTopics", "bookingType"];
        let notValidate2 = ["serviceDuration", "headers", "minQuota", "rruleData", "standardMetingLink"];
        let notValidate3 = ["language", "size"];

        setError({ ...err, vat: false });
        Object.keys(err).forEach((key) => {
            if (
                formData[key] == "" &&
                !notValidate1.includes(key) &&
                !notValidate2.includes(key) &&
                !notValidate3.includes(key) &&
                key != "location" &&
                key != "maxQuota" &&
                key !== "minQuota" &&
                key != "others" &&
                key != "oneToOneBooking"
            ) {
                err[key] = `Field cannot be empty`;
                validData = false;
            }
        });

        notValidate1.forEach((key) => {
            if (formData[key] == "" && key != "dates") {
                err[key] = `Field cannot be empty`;
                validData = false;
            }
        });

        if (formData.NoofSession == 0) {
            err["NoofSession"] = `Field cannot be empty`;
            validData = false;
        }

        if (formData.coverimage == "") {
            err["coverimage"] = `Field cannot be empty`;
            validData = false;
        }
        if (formData.locationType == "Others" && formData.others === "") {
            err["others"] = `Field cannot be empty`;
            validData = false;
        }
        var meetingLinkRegex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        if (
            formData.locationType === "ONLINE" &&
            formData.isStandardLink === true &&
            !meetingLinkRegex.test(formData.standardMetingLink)
        ) {
            err["standardMetingLink"] = "Enter valid link";
            validData = false;
        }
        if (
            formData.serviceDuration.days == 0 &&
            formData.serviceDuration.minutes == 0 &&
            formData.serviceDuration.hours == 0
        ) {
            err["serviceDuration"] = `Please Enter Valid Service Duration`;
            validData = false;
        }
        if (formData.locationType != "ONLINE" && formData.locationType != "Others" && formData.location == "") {
            err["location"] = `Enter Valid Location`;
            validData = false;
        }
        if (
            formData.bookingType.includes("APPOINTMENT") &&
            parseInt(formData.minQuota) >= parseInt(formData.maxQuota)
        ) {
            err["maxQuota"] = `Max Quota should be greater then Min Quota`;
            validData = false;
        }
        if (formData.bookingType.includes("APPOINTMENT") && formData.scheduleType == "") {
            err["scheduleType"] = `Field cannot be empty`;
            validData = false;
        }
        if (props.general.smallBusiness == true && formData.vat != 0) {
            err["vat"] = `Field cannot be empty`;
            validData = false;
        }
        if (props.general.smallBusiness == false && formData.vat != 7 && formData.vat != 19) {
            err["vat"] = `Field cannot be empty`;
            validData = false;
        }
        notValidate3.forEach((key) => {
            if (formData.specifications[key].length == 0) {
                err[key] = `Field cannot be empty`;
                validData = false;
            }
        });
        if (
            formData.bookingType.includes("APPOINTMENT") &&
            formData.scheduleType != "REGULAR" &&
            formData.dates.length == 0
        ) {
            err["dates"] = `Field cannot be empty`;
            validData = false;
        }
        if (formData.bookingType.includes("APPOINTMENT") && formData.scheduleType == "") {
            err["scheduleType"] = `Field cannot be empty`;
            validData = false;
        }

        if (showAlert && !validData) {
            props.showAlert("Mandatory Field Required");
        }
        console.log(err);
        setError({ ...err });
        return validData;
    };

    const onSubmit = (updateStatus) => {
        if (validate()) {
            setLoading(updateStatus);
            console.log("COMING");
            axios({
                method: "put",
                url: "/dashboard/service-provider/updateSingleEvent",
                data: {
                    ...formData,
                    active: updateStatus === "SAVE" ? false : true,
                    serviceProvider: query.get("userId"),
                    userId: query.get("userId"),
                    updateStatus: updateStatus,
                },
            })
                .then((res) => {
                    setLoading(false);
                    if (updateStatus === "SAVE") {
                        setServiceId(false);
                        setFormData({
                            ...formData,

                            eventId: res.data.Event._id,
                        });
                        props.showAlert("Event Saved Succesfully");
                    } else {
                        props.showAlert(`Event ${props.type == "ADD" ? "Added" : "Updated"} Succesfully`);
                        props.getAllSingleEvents(query.get("userId"));
                        props.history.push("/user/service-provider/VIEW-STORE-SERVICES?userId=" + query.get("userId"));
                    }
                })
                .catch((err) => {
                    console.log(err);
                    if (err && err.response) props.showAlert(err.response.data.error);
                    else props.showAlert("Something went wrong Try Again");

                    setLoading(false);
                });
        }
    };

    const onPreview = () => {
        if (validate()) {
            setPreview(true);
            let backUrl = "&preview=PREVIEW&source=adminService&displayFooter=NO";
            const link = getPreviewUrl(formData.eventName, formData.eventId, backUrl);
            window.open(link, "_blank");
        } else {
            props.showAlert("Mandatory Field Required");
        }
    };

    let displaySaveButton = props.type == "ADD" || formData.updateStatus == "SAVE" || props.type == "EDIT";
    console.log(formData);
    return (
        <React.Fragment>
            <MediaHandler
                open={open}
                onClose={() => setOpen(false)}
                onSelectImage={(img, alttxt) => {
                    let imagearr = formData.images;
                    imagearr.push(img);
                    let alttextarr = formData.altimage;
                    alttextarr.push(alttxt);
                    setFormData({ ...formData, images: imagearr, altimage: alttextarr });
                    setOpen(false);
                }}
                allowedList={"image/png, image/jpeg"}
                folderName="service-images/"
            />

            <MediaHandler
                open={opencover}
                onClose={() => setOpenCover(false)}
                onSelectImage={(img, alttxt) => {
                    setFormData({ ...formData, coverimage: img, coverAlttext: alttxt });
                    setOpenCover(false);
                }}
                allowedList={"image/png, image/jpeg"}
                folderName="service-images/"
            />

            {categoryModal && (
                <ChooseCategory
                    onAdd={(val) => {
                        let arr = formData?.catList || [];
                        arr = arr
                            ?.filter((oldVal) => oldVal?.categoryId?._id)
                            .filter((oldVal) => {
                                return oldVal?.categoryId?._id != val.categoryId._id;
                            });
                        arr.push(val);
                        setFormData({ ...formData, catList: [...arr] });
                    }}
                    open={categoryModal}
                    onClose={() => setCategoryModal(false)}
                />
            )}

            {journeyTopicModal && (
                <JourneyTopic
                    data={formData}
                    onAdd={(val) => {
                        let arr = formData.journeyTopics;
                        arr = arr.filter((oldVal) => {
                            return oldVal.subCategoryId != val.subCategoryId;
                        });
                        delete val["journeyTopics"];
                        arr.push(val);
                        setFormData({ ...formData, journeyTopics: [...arr] });
                    }}
                    setData={setFormData}
                    open={journeyTopicModal}
                    onClose={() => setJourneyTopicModal(false)}
                />
            )}

            <div className={styles.container}>
                <div className={styles.content}>
                    <h1>{props.type == "ADD" ? "Add" : "Update"} a Service</h1>
                    <p className={styles.para}>Please input all relevant information regarding this service</p>
                    <Accordion className={classes.accordian}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <h2 className={styles.summaryText}>Basic Information</h2>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={styles.contentContainer}>
                                <div className={styles.row}>
                                    <FormControl className={styles.halfField}>
                                        <FormLabel>Service Name</FormLabel>

                                        <TextField
                                            variant="outlined"
                                            value={formData.eventName}
                                            onChange={(e) => setFormData({ ...formData, eventName: e.target.value })}
                                            error={error.eventName}
                                            helperText={error.eventName}
                                        />
                                    </FormControl>

                                    <div className={styles.dFlexComp}>
                                        <div className={styles.catContent}>
                                            <label className={styles.labelFlex}>Select Category </label>

                                            <div className={styles.catBox}>
                                                {formData.catList.map((val) => {
                                                    if (val.categoryId) {
                                                        return (
                                                            <span className={styles.tag}>
                                                                {val.categoryId.categoryName}

                                                                <CancelRoundedIcon
                                                                    color="white"
                                                                    onClick={() => {
                                                                        let arr = formData.catList;
                                                                        arr = arr.filter((cat) => val != cat);
                                                                        setFormData({
                                                                            ...formData,
                                                                            catList: [...arr],
                                                                        });
                                                                    }}
                                                                />
                                                            </span>
                                                        );
                                                    }
                                                })}
                                            </div>
                                            {error.catList && <span className={styles.error}>{error.catList}</span>}
                                        </div>

                                        <IconButton onClick={() => setCategoryModal(true)}>
                                            <AddCircleOutlineRoundedIcon />
                                        </IconButton>
                                    </div>
                                </div>

                                <div className={styles.row}>
                                    <div className={styles.col}>
                                        <FormControl className={styles.fullWidth}>
                                            <FormLabel>* General Description</FormLabel>

                                            {editorLoaded && (
                                                <div className={styles.styleCK}>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        config={{
                                                            placeholder: `${"Please describe"}`,
                                                            toolbar: [
                                                                "Heading",
                                                                "|",
                                                                "Italic",
                                                                "NumberedList",
                                                                "BulletedList",
                                                                "|",
                                                                "Undo",
                                                                "Redo",
                                                            ],
                                                        }}
                                                        data={formData.description}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            setFormData((val) => ({ ...val, description: data }));
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            {error.description && (
                                                <FormHelperText className={styles.error}>
                                                    {error.description}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </div>{" "}
                                    <div className={styles.imgMainContainer}>
                                        <FormLabel id="coverimage-service">*Cover Service Image</FormLabel>
                                        <div className={styles.imgContainer}>
                                            <div
                                                className={styles.centerbbs}
                                                onClick={() => {
                                                    if (formData.coverimage == "") {
                                                        setOpenCover(Math.random());
                                                    }
                                                }}
                                            >
                                                <PanoramaRoundedIcon />
                                                <span>
                                                    *Add Cover image.
                                                    <br />
                                                    Maximum image size of 2MB <br />
                                                </span>
                                            </div>

                                            {formData.coverimage && (
                                                <div className={styles.imgContent}>
                                                    <div className={styles.imgComp}>
                                                        <span
                                                            onClick={() => {
                                                                setFormData({ ...formData, coverimage: "" });
                                                            }}
                                                        >
                                                            x
                                                        </span>

                                                        <img
                                                            src={formData.coverimage}
                                                            className={styles.img}
                                                            width={100}
                                                            height={100}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {error.coverimage && (
                                                <span className={styles.error}>{error.coverimage}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.row}>
                                    <div className={styles.col}>
                                        <FormControl className={styles.fullWidth}>
                                            <FormLabel className={classes.formLabel}>Meta Description </FormLabel>

                                            <TextField
                                                variant="outlined"
                                                multiline
                                                rows={3}
                                                inputProps={{ maxLength: 160 }}
                                                value={formData.metadescription}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        metadescription: e.target.value,
                                                    })
                                                }
                                                error={error.metadescription}
                                                helperText={error.metadescription}
                                            />
                                        </FormControl>
                                        {Object.keys(formData.headers).map((key) => {
                                            let active = formData.headers[key].active;

                                            return (
                                                <div className={styles.headerFormContent}>
                                                    <FormControl className={classes.headersWidth}>
                                                        <div className={styles.headersFlex}>
                                                            <FormLabel
                                                                className={` ${
                                                                    key == "What Is Included" && classes.included
                                                                } ${key == "Requirements" && classes.Requirements} ${
                                                                    key == "Good to Know" && classes.good
                                                                } ${
                                                                    key == "Service Terms and Conditions" &&
                                                                    classes.terms
                                                                } `}
                                                            >
                                                                {key}{" "}
                                                            </FormLabel>
                                                            <Switch
                                                                checked={active}
                                                                onChange={() => {
                                                                    let newHeaders = formData.headers;
                                                                    newHeaders[key].active = !active;
                                                                    setFormData({
                                                                        ...formData,
                                                                        headers: newHeaders,
                                                                    });
                                                                }}
                                                            />{" "}
                                                        </div>
                                                        {active && (
                                                            <TextField
                                                                variant="outlined"
                                                                fullWidth
                                                                multiline
                                                                rows={2}
                                                                disabled={!active}
                                                                value={formData.headers[key].value}
                                                                onChange={(e) => {
                                                                    let newHeaders = formData.headers;
                                                                    newHeaders[key].value = e.target.value;
                                                                    setFormData({
                                                                        ...formData,
                                                                        headers: newHeaders,
                                                                    });
                                                                }}
                                                            />
                                                        )}
                                                    </FormControl>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className={styles.imgMainContainer}>
                                        <FormLabel id="serviceimage-service">*Service Image</FormLabel>
                                        <div className={styles.imgContainer}>
                                            <div
                                                className={styles.centerAbs}
                                                onClick={() => formData.images.length < 5 && setOpen(Math.random())}
                                            >
                                                <PanoramaRoundedIcon />
                                                <span>
                                                    * Add up to 5 images.
                                                    <br />
                                                    Minimum image ratio recommended is 500 * 500 Pixel <br />
                                                    Maximum image size of 2MB <br />
                                                </span>
                                            </div>

                                            <div className={styles.imgContent}>
                                                {formData.images.map((img, index) => {
                                                    return (
                                                        <div className={styles.imgComp}>
                                                            <span
                                                                onClick={() => {
                                                                    let arr = formData.images;
                                                                    arr = arr.filter((url, i) => index != i);

                                                                    let altarr = formData.altimage;
                                                                    altarr = altarr.filter((url, i) => index != i);
                                                                    setFormData({
                                                                        ...formData,
                                                                        images: [...arr],
                                                                        altimage: [...altarr],
                                                                    });
                                                                }}
                                                            >
                                                                x
                                                            </span>

                                                            <img src={img} className={styles.img} />
                                                        </div>
                                                    );
                                                })}
                                            </div>

                                            {error.images && <span className={styles.error}>{error.images}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.row}>
                                    <FormControl className={styles.halfField}>
                                        <FormLabel className={classes.formLabel}>* No of Session </FormLabel>

                                        <TextField
                                            variant="outlined"
                                            value={formData.NoofSession}
                                            onChange={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    NoofSession: e.target.value,
                                                })
                                            }
                                            error={error.NoofSession}
                                            helperText={error.NoofSession}
                                        />
                                    </FormControl>

                                    <div className={styles.duration}>
                                        <label htmlFor="">* Service Duration</label>
                                        <div className={styles.inputs}>
                                            <TextField
                                                variant="outlined"
                                                label="Days"
                                                type="number"
                                                className={styles.smallInput}
                                                value={formData.serviceDuration.days}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        serviceDuration: {
                                                            ...formData.serviceDuration,
                                                            days: e.target.value,
                                                        },
                                                    })
                                                }
                                            />
                                            <TextField
                                                variant="outlined"
                                                label="Hours"
                                                type="number"
                                                className={styles.smallInput}
                                                value={formData.serviceDuration.hours}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        serviceDuration: {
                                                            ...formData.serviceDuration,
                                                            hours: e.target.value,
                                                        },
                                                    })
                                                }
                                            />
                                            <TextField
                                                variant="outlined"
                                                label="Minutes"
                                                type="number"
                                                className={styles.smallInput}
                                                value={formData.serviceDuration.minutes}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        serviceDuration: {
                                                            ...formData.serviceDuration,
                                                            minutes: e.target.value,
                                                        },
                                                    })
                                                }
                                            />
                                        </div>
                                        {error.serviceDuration && (
                                            <span className={styles.error}>{error.serviceDuration}</span>
                                        )}
                                    </div>
                                </div>

                                <div className={styles.row}>
                                    <div className={styles.duration}>
                                        <label htmlFor="">* Service Price</label>
                                        <div className={styles.inputs}>
                                            <TextField
                                                variant="outlined"
                                                label="Price (incl.VAT)"
                                                type="number"
                                                className={styles.smallInput}
                                                value={formData.pricePerPerson}
                                                error={error.pricePerPerson}
                                                onChange={(e) => {
                                                    if (e.target.value >= 0) {
                                                        let val = e.target.value;
                                                        setFormData({
                                                            ...formData,
                                                            priceWithoutVat: (
                                                                (100 / (100 + formData.vat)) *
                                                                e.target.value
                                                            ).toFixed(2),
                                                            pricePerPerson: val,
                                                        });
                                                    } else {
                                                        setFormData({
                                                            ...formData,
                                                            priceWithoutVat: "",
                                                        });
                                                    }
                                                }}
                                            />

                                            <FormControl
                                                variant="outlined"
                                                className={styles.smallInput}
                                                error={error.vat}
                                            >
                                                <InputLabel>VAT %</InputLabel>
                                                <Select
                                                    variant="outlined"
                                                    label="VAT %"
                                                    value={parseInt(formData.vat)}
                                                    onChange={(e) => {
                                                        let val = parseInt(e.target.value);
                                                        setFormData({
                                                            ...formData,
                                                            vat: val,
                                                            priceWithoutVat:
                                                                (100 / (100 + e.target.value)) *
                                                                formData.pricePerPerson,
                                                        });
                                                    }}
                                                >
                                                    {props.general && props.general.smallBusiness && (
                                                        <MenuItem value={0}>0%</MenuItem>
                                                    )}
                                                    {props.general && !props.general.smallBusiness && (
                                                        <MenuItem value={7}>7%</MenuItem>
                                                    )}
                                                    {props.general && !props.general.smallBusiness && (
                                                        <MenuItem value={19}>19%</MenuItem>
                                                    )}
                                                </Select>
                                                {error.vat && <FormHelperText>{error.vat}</FormHelperText>}
                                            </FormControl>
                                            <TextField
                                                variant="outlined"
                                                label="Price (excl.VAT)"
                                                className={styles.smallInput}
                                                value={formData.priceWithoutVat}
                                            />
                                        </div>
                                        {error.pricePerPerson && (
                                            <span className={styles.error}>{error.pricePerPerson}</span>
                                        )}
                                    </div>
                                    <FormControl className={styles.halfField}>
                                        <FormLabel className={classes.formLabel}>* Keywords/Tags </FormLabel>
                                        <ChipInput
                                            value={formData.tags}
                                            onAdd={(chip) =>
                                                setFormData({
                                                    ...formData,
                                                    tags: [...formData.tags, chip],
                                                })
                                            }
                                            onDelete={(chip, index) => {
                                                let arr = formData.tags;
                                                arr = arr.filter((val, i) => index != i);
                                                setFormData({ ...formData, tags: [...arr] });
                                            }}
                                            variant="outlined"
                                            error={error.tags}
                                            helperText={error.tags || "Please press enter after each tag"}
                                        />
                                    </FormControl>
                                </div>

                                <div className={styles.row}>
                                    <div className={styles.dFlexComp}>
                                        <div className={styles.catContent}>
                                            <label>* Select Journey Topic</label>

                                            <div className={styles.catBox}>
                                                {formData.journeyTopics.map((val) => {
                                                    if (val.subCategoryId) {
                                                        return (
                                                            <span className={styles.tag}>
                                                                {val.subCategoryId.subCategoryName}

                                                                <CancelRoundedIcon
                                                                    color="white"
                                                                    onClick={() => {
                                                                        let arr = formData.journeyTopics;
                                                                        arr = arr.filter((cat) => val != cat);
                                                                        setFormData({
                                                                            ...formData,
                                                                            journeyTopics: [...arr],
                                                                        });
                                                                    }}
                                                                />
                                                            </span>
                                                        );
                                                    }
                                                })}
                                            </div>
                                            {error.journeyTopics && (
                                                <span className={styles.error}>{error.journeyTopics}</span>
                                            )}
                                        </div>

                                        <IconButton onClick={() => setJourneyTopicModal(true)}>
                                            <AddCircleOutlineRoundedIcon />
                                        </IconButton>
                                    </div>
                                    <FormControl className={styles.halfField}>
                                        <FormLabel className={classes.formLabel}>Sorting Number </FormLabel>
                                        <TextField
                                            variant="outlined"
                                            type="number"
                                            fullWidth
                                            // className={styles.smallInput}
                                            value={formData.sortNumber}
                                            onChange={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    sortNumber: e.target.value,
                                                })
                                            }
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className={classes.accordian}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <h2 className={styles.summaryText}>Service Specifications</h2>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={styles.contentContainer}>
                                <Specifications
                                    formData={formData}
                                    setFormData={(val) => setFormData(val)}
                                    error={error}
                                />
                            </div>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className={classes.accordian}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <h2 className={styles.summaryText}>Service Fulfilment</h2>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={styles.contentContainer}>
                                <Fulfilment
                                    errormsg={errormsg}
                                    formData={formData}
                                    setFormData={(val) => setFormData(val)}
                                    error={error}
                                    storeProfile={props.general}
                                    storeProfileMain={props.storeProfile}
                                />
                            </div>
                        </AccordionDetails>
                    </Accordion>

                    {formData.bookingType.includes("APPOINTMENT") && (
                        <Accordion className={classes.accordian}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h2 className={classes.formLabel}>Schedule Availability </h2>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={styles.contentContainer}>
                                    {error.dates && <span className={styles.error}>{error.dates}</span>}

                                    <ServiceCalender formData={formData} setFormData={setFormData} />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    )}

                    <div className={styles.contentContainer}>
                        <div className={styles.btnContainer}>
                            <button
                                className={styles.cancelbutton}
                                onClick={() => props.history.push("/user/service-provider/VIEW-SERVICE-PROVIDERS")}
                            >
                                CANCEL
                            </button>

                            {displaySaveButton && (
                                <React.Fragment>
                                    {loading == "SAVE" ? (
                                        <BtnLoader className="btnBorderGreen" />
                                    ) : (
                                        <button
                                            className={styles.savebutton}
                                            onClick={() => {
                                                onSubmit("SAVE");
                                                setErrorMsg(true);
                                            }}
                                        >
                                            SAVE
                                        </button>
                                    )}
                                </React.Fragment>
                            )}

                            {/* {/* {serviceId ? (  */}
                            {serviceId ? (
                                <button
                                    className={styles.previewbutton}
                                    disabled={serviceId}
                                    title="SAVE to take a preview"
                                    onClick={onPreview}
                                >
                                    PREVIEW
                                </button>
                            ) : (
                                <button className={styles.previewbutton} onClick={onPreview}>
                                    PREVIEW
                                </button>
                            )}

                            {loading == "PUBLISH" ? (
                                <BtnLoader className="btnBorderGreen" />
                            ) : (
                                <button
                                    className={styles.publishbutton}
                                    onClick={() => {
                                        onSubmit("PUBLISH");
                                        setErrorMsg(true);
                                    }}
                                >
                                    PUBLISH
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
const mapStateToProps = (state) => ({
    singleEvents: state.serviceProvider.singleEvents,
    general: state.serviceProvider.general,
    storeProfile: state.serviceProvider.storeProfile,
});
export default withRouter(
    connect(mapStateToProps, {
        showAlert,
        getAllSingleEvents,
        getSPGeneralDetails,
        getSPStoreProfile,
    })(EditSingleEvent),
);
