import { RoomRounded as RoomRoundedIcon } from "@material-ui/icons";
import GoogleMapReact from "google-map-react"; 
import React from "react";
import { defaultCenter, mapApiKey } from "../../../../config/config";
import styles from "./LocationSection.module.css";

const Marker = ({ handleClick }) => {
    return (
        <RoomRoundedIcon
            onClick={() => handleClick()}
            style={{
                color: "var(--secondary-color)",
                borderRadius: "50%",
                backgroundColor: "rgba(45, 65, 65, 0.4)",
                padding: "12px",
                fontSize: 40,
                position: "absolute",
                transform: "translate(-50%, -50%)",
            }}
        />
    );
};

export function Location({ service }) { 

    const showInMapClicked = (lat, lng) => {
        window.open("https://maps.google.com?q=" + lat + "," + lng);
    };
    return (
        <div className={styles.locationContainer}>
            {service.locationType != "ONLINE" && (
                <React.Fragment>
                    <div className={styles.locationHeader}>
                        <h2>Standort</h2>
                        <div style={{ textAlign: "right" }}>
                            <h3>{service.serviceProviderProfile?.storeName}</h3>
                            <p>{service.location}</p>
                        </div>
                    </div>
                    <div className={styles.mapContainer}>
                        <div
                            className={styles.locationDetails}
                            onClick={() =>
                                showInMapClicked(
                                    service.coordinates.lat,
                                    service.coordinates.lng
                                )
                            }
                        >
                            <h3>{service.serviceProviderProfile?.storeName}</h3>
                            <p>{service.location}</p>
                        </div>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: mapApiKey }}
                            defaultCenter={{
                                lat: service.coordinates.lat
                                    ? parseFloat(service.coordinates.lat)
                                    : 53.5511,
                                lng: service.coordinates.lng
                                    ? parseFloat(service.coordinates.lng)
                                    : 9.9937,
                            }}
                            defaultZoom={15}
                        >
                            <Marker
                                handleClick={() =>
                                    showInMapClicked(
                                        service.coordinates.lat,
                                        service.coordinates.lng
                                    )
                                }
                                marker={service}
                                lat={service.coordinates.lat}
                                lng={service.coordinates.lng}
                            />
                        </GoogleMapReact>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
}
