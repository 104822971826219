import React from "react";
import styles from "./UpdateGeneral.module.css";

import docIcon from "../../../assets/img/doc.png";

import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";

import UpdateRoundedIcon from "@material-ui/icons/UpdateRounded";

import MediaHandler from "../../Media/MediaHandler";
import { connect } from "react-redux";
import { getAllServiceProviders, getSPGeneralDetails } from "../../../containers/serviceProvider/actions";
import { getAllExperience } from "../../../containers/category/actions";
import { showAlert } from "../../../containers/app/actions";
import axios from "axios";
import { withRouter, useLocation } from "react-router-dom";

import Country from "../../utils/Country/Country";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";

import SearchAddress from "../../utils/SearchAddress/SearchAddress";
import FileUploader from "./FileUploader/FileUploader";
import { getNameFromImage } from "../../../hooks/getNameFromImage";
import Header from "../Header/Header";

const UpdateGeneral = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [formData, setFormData] = React.useState({
        storeName: "",
        companyWebsite: "",
        accountEmail: "",
        companyServiceEmail: "",
        legalBusinessName: "",
        street: "",
        address: "",
        city: "",
        zipcode: "",
        country: "",
        phone: "",
        taxNumber: "",
        companyLicense: "",
        categories: [],
        companyLogo: "",
        smallBusiness: "",
        vatNumber: "",
        identificationDocumentFront: "",
        identificationDocumentBack: "",
        proofOfAddressFront: "",
        // proofOfAddressBack: "",
        commissionPercentage: "",
        iban_Number: "",
        coordinates: {
            lat: "",
            lng: "",
        },
    });
    const [error, setError] = React.useState({
        storeName: false,
        companyWebsite: false,
        accountEmail: false,
        legalBusinessName: false,
        street: false,
        address: false,
        city: false,
        zipcode: false,
        country: false,
        phone: false,
        categories: false,
        companyLogo: false,
        identificationDocumentFront: false,
        identificationDocumentBack: false,
        proofOfAddressFront: false,
        // proofOfAddressBack: false,
        commissionPercentage: false,
        iban_Number: false,
    });

    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [loader, setLoader] = React.useState(true);
    const [modal, setModal] = React.useState(false);
    const [allowedList, setAllowedList] = React.useState(false);

    React.useEffect(() => {
        if (query.get("userId")) {
            props.getSPGeneralDetails(query.get("userId"));
        } else {
            props.showAlert("Data Not Found");
            props.history.push("/admin/users/VIEW-USERs");
        }
    }, []);

    React.useEffect(() => {
        if (props.general) {
            console.log(props.general.smallBusiness);
            setFormData({
                ...formData,
                ...props.general,
                smallBusiness:
                    props.general.smallBusiness != undefined
                        ? props.general.smallBusiness == true
                            ? "YES"
                            : "NO"
                        : "",
            });
            setLoader(false);
        } else {
            setLoader(true);
        }
    }, [props.general]);

    React.useEffect(() => {
        if (!props.experiences) {
            props.getAllExperience();
        }
    }, []);

    const validate = () => {
        const err = {
            categories: false,
            accountEmail: false,
            legalBusinessName: false,
            street: false,
            city: false,
            zipcode: false,
            country: false,
            phone: false,
            identificationDocumentFront: false,
            identificationDocumentBack: false,
            proofOfAddressFront: false,
            // proofOfAddressBack: false,

            commissionPercentage: false,
            iban_Number: false,
            smallBusiness: false,
        };
        let validData = true;
        let isEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        setError({ ...err });
        Object.keys(err).forEach((key) => {
            if (formData[key] == "" && key != "smallBusiness") {
                err[key] = `Field cannot be empty`;
                validData = false;
            }
        });
        if (!isEmail.test(formData.accountEmail)) {
            err["accountEmail"] = "Enter valid email";
            validData = false;
        }
        if (formData.companyServiceEmail != "" && !isEmail.test(formData.companyServiceEmail)) {
            err["companyServiceEmail"] = "Enter valid email";
            validData = false;
        }
        if (formData.phone == "") {
            err.phone = `Enter valid Phone Number`;
            validData = false;
        }
        if (formData.smallBusiness == "") {
            err.smallBusiness = "Field cannot be empty";
            validData = false;
        }
        if (formData.street == "") {
            err["street"] = "Field cannot be empty";
            validData = false;
        }
        setError({ ...err });

        return validData;
    };
    const onSubmit = () => {
        if (validate()) {
            setLoading(true);

            axios({
                method: "put",
                url: "/dashboard/service-provider/updateSPGeneralDetails",
                data: {
                    ...formData,
                    smallBusiness: formData.smallBusiness == "YES" ? true : false,
                },
            })
                .then((res) => {
                    props.showAlert("Details Updated Succesfully");
                    props.getAllServiceProviders();
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    if (err && err.response) props.showAlert(err.response.data.error);
                    else props.showAlert("Something went wrong Try Again");

                    setLoading(false);
                });
        }
    };
    return (
        <div className={styles.container}>
            <Header type="GENERAL" />
            {loader ? (
                <div className={styles.loader}>
                    <CircularProgress />
                </div>
            ) : (
                <div className={styles.content}>
                    <p>Company Details</p>
                    <div className={styles.row}>
                        <FormControl className={styles.textField}>
                            <FormLabel>Legal Business Name</FormLabel>
                            <TextField
                                variant="outlined"
                                value={formData.legalBusinessName}
                                onChange={(e) => setFormData({ ...formData, legalBusinessName: e.target.value })}
                                error={error.legalBusinessName}
                                helperText={error.legalBusinessName}
                            />
                        </FormControl>
                        <FormControl className={styles.textField}>
                            <FormLabel>Company Website</FormLabel>
                            <TextField
                                variant="outlined"
                                value={formData.companyWebsite}
                                onChange={(e) => setFormData({ ...formData, companyWebsite: e.target.value })}
                                error={error.companyWebsite}
                                helperText={error.companyWebsite}
                            />
                        </FormControl>
                    </div>

                    <div className={styles.row}>
                        <FormControl className={styles.textField}>
                            <FormLabel>Account Email</FormLabel>
                            <TextField
                                variant="outlined"
                                value={formData.accountEmail}
                                onChange={(e) => setFormData({ ...formData, accountEmail: e.target.value })}
                                error={error.accountEmail}
                                helperText={error.accountEmail}
                            />
                        </FormControl>
                    </div>

                    <Divider />
                    <h1>Company Address</h1>
                    <div className={styles.row}>
                        <FormControl className={styles.textField}>
                            <FormLabel>Address</FormLabel>
                            <SearchAddress
                                setFormData={(key, val) => setFormData({ ...formData, street: val })}
                                value={formData.street}
                                error={error.street}
                                helperText={error.street}
                                country={formData.country}
                                color="white"
                            />
                        </FormControl>

                        <FormControl className={styles.textField}>
                            <FormLabel>Apartment suite etc</FormLabel>
                            <TextField
                                variant="outlined"
                                value={formData.address}
                                onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                                error={error.address}
                                helperText={error.address}
                            />
                        </FormControl>
                    </div>

                    <div className={styles.row}>
                        <FormControl className={styles.textField}>
                            <FormLabel>City</FormLabel>
                            <TextField
                                variant="outlined"
                                value={formData.city}
                                onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                                error={error.city}
                                helperText={error.city}
                            />
                        </FormControl>

                        <FormControl className={styles.textField}>
                            <FormLabel>Postal Zip code</FormLabel>
                            <TextField
                                variant="outlined"
                                value={formData.zipcode}
                                type="number"
                                error={error.zipcode}
                                helperText={error.zipcode}
                                onChange={(e) => {
                                    e.target.value.length <= 6 &&
                                        setFormData({ ...formData, zipcode: e.target.value.replace(/\D/, "") });
                                }}
                            />
                        </FormControl>
                    </div>

                    <div className={styles.row}>
                        <FormControl className={styles.textField}>
                            <FormLabel>Country</FormLabel>
                            <Country
                                value={formData.country}
                                onChange={(val) => setFormData({ ...formData, country: val })}
                                error={error.country}
                                variant="outlined"
                                helperText={error.country}
                                color="white"
                            />
                        </FormControl>

                        <FormControl className={styles.textField}>
                            <FormLabel>Phone</FormLabel>
                            <TextField
                                variant="outlined"
                                value={formData.phone}
                                error={error.phone}
                                helperText={error.phone}
                                onChange={(e) => {
                                    e.target.value.length <= 15 &&
                                        setFormData({ ...formData, phone: e.target.value.replace(/\D/, "") });
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            + {formData.country && formData.country.phone}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </div>

                    <div className={styles.row}>
                        <FormControl className={styles.textField} error={error.categories} variant="outlined">
                            <FormLabel>Choose Category</FormLabel>

                            <Select
                                value={formData.categories}
                                onChange={(e) => setFormData({ ...formData, categories: e.target.value })}
                                multiple
                            >
                                {props.experience &&
                                    props.experience.map((exp) => {
                                        return exp.categories.map((val) => {
                                            return (
                                                <MenuItem key={val._id} value={val._id}>
                                                    {val.categoryName}
                                                </MenuItem>
                                            );
                                        });
                                    })}
                            </Select>

                            {error.categories && <FormHelperText>{error.categories}</FormHelperText>}
                        </FormControl>
                        <FormControl className={styles.textField}>
                            <FormLabel>Commission Percentage (%)</FormLabel>
                            <TextField
                                variant="outlined"
                                // type="number"
                                value={formData.commissionPercentage}
                                error={error.commissionPercentage}
                                helperText={error.commissionPercentage}
                                onChange={(e) => {
                                    if (e.target.value <= 100 && e.target.value >= 0) {
                                        setFormData({
                                            ...formData,
                                            commissionPercentage: e.target.value,
                                        });
                                    }
                                }}
                            />
                        </FormControl>
                    </div>

                    <Divider />
                    <h1>Legal Information</h1>

                    <div className={styles.row}>
                        <FormControl className={styles.textField}>
                            <FormLabel>IBAN Number</FormLabel>
                            <TextField
                                variant="outlined"
                                // type="number"
                                value={formData.iban_Number}
                                error={error.iban_Number}
                                helperText={error.iban_Number}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        iban_Number: e.target.value,
                                    });
                                }}
                            />
                        </FormControl>

                        {error.smallBusiness && (
                            <span className={styles.error}>{"Select above small business checkbox"}</span>
                        )}
                        <FormControl className={styles.textField}>
                            <FormLabel>Tax Number</FormLabel>
                            <TextField
                                variant="outlined"
                                value={formData.taxNumber}
                                error={error.taxNumber}
                                helperText={error.taxNumber}
                                onChange={(e) => {
                                    e.target.value.length <= 11 &&
                                        setFormData({
                                            ...formData,
                                            taxNumber: e.target.value.replace(/[^a-zA-Z0-9]/, ""),
                                        });
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.flexAlignRow}>
                            <p>Are you a small business?</p>
                            <div style={{ margin: "0px 20px" }}>&nbsp;</div>
                            <p>YES</p>
                            <Radio
                                onChange={() => setFormData({ ...formData, smallBusiness: "YES" })}
                                value={formData.smallBusiness}
                                color="primary"
                                checked={formData.smallBusiness == "YES"}
                            />
                            &nbsp;
                            <p>NO</p>
                            <Radio
                                onChange={() =>
                                    setFormData({
                                        ...formData,
                                        smallBusiness: "NO",
                                    })
                                }
                                value={formData.smallBusiness}
                                color="primary"
                                checked={formData.smallBusiness == "NO"}
                            />
                            {error.smallBusiness && <span className={styles.error}>{"Field cannot be empty"}</span>}
                        </div>
                        <br />
                    </div>

                    <h1>Payment Information</h1>

                    <div className={styles.row}>
                        <FileUploader
                            value={formData.identificationDocumentFront}
                            onRemove={() => setFormData({ ...formData, identificationDocumentFront: "" })}
                            onUpload={() => {
                                setOpen(Math.random());
                                setModal("identificationDocumentFront");
                                setAllowedList("application/pdf");
                            }}
                            name={
                                formData.identificationDocumentFront
                                    ? getNameFromImage(formData.identificationDocumentFront)
                                    : "Identity Document Front"
                            }
                            styles={styles}
                            type="PDF"
                            error={error.identificationDocumentFront}
                            title="Identification Document Front"
                        />
                        <FileUploader
                            value={formData.identificationDocumentBack}
                            onRemove={() => setFormData({ ...formData, identificationDocumentBack: "" })}
                            onUpload={() => {
                                setOpen(Math.random());
                                setModal("identificationDocumentBack");
                                setAllowedList("application/pdf");
                            }}
                            name={
                                formData.identificationDocumentBack
                                    ? getNameFromImage(formData.identificationDocumentBack)
                                    : "Identity Document Back"
                            }
                            styles={styles}
                            type="PDF"
                            error={error.identificationDocumentBack}
                            title="Identification Document Back"
                        />
                    </div>

                    <div className={styles.row}>
                        <FileUploader
                            value={formData.proofOfAddressFront}
                            onRemove={() => setFormData({ ...formData, proofOfAddressFront: "" })}
                            onUpload={() => {
                                setOpen(Math.random());
                                setModal("proofOfAddressFront");
                                setAllowedList("application/pdf");
                            }}
                            name={
                                formData.proofOfAddressFront
                                    ? getNameFromImage(formData.proofOfAddressFront)
                                    : "Proof of Address Front"
                            }
                            styles={styles}
                            type="PDF"
                            error={error.proofOfAddressFront}
                            title="Proof of Address Front"
                        />
                        {/* <FileUploader
                            value={formData.proofOfAddressBack}
                            onRemove={() => setFormData({ ...formData, proofOfAddressBack: "" })}
                            onUpload={() => {
                                setOpen(Math.random());
                                setModal("proofOfAddressBack");
                                setAllowedList("application/pdf");
                            }}
                            name={
                                formData.proofOfAddressBack
                                    ? getNameFromImage(formData.proofOfAddressBack)
                                    : "Proof of Address Back"
                            }
                            styles={styles}
                            type="PDF"
                            error={error.proofOfAddressBack}
                            title="Proof of Address Back"
                        /> */}
                    </div>
                    <Divider />

                    <Divider />

                    <div className={styles.rowButton}>
                        {loading ? (
                            <Button
                                color="primary"
                                variant="contained"
                                startIcon={<CircularProgress color="inherit" size={20} />}
                            >
                                Loading ...
                            </Button>
                        ) : (
                            <Button
                                color="primary"
                                variant="contained"
                                startIcon={<UpdateRoundedIcon />}
                                onClick={onSubmit}
                            >
                                Save &amp; Update
                            </Button>
                        )}
                        &nbsp;
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => props.history.push("/user/service-provider/VIEW-SERVICE-PROVIDERS")}
                        >
                            Cancel
                        </Button>
                        &nbsp;
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() =>
                                props.history.push(
                                    `/user/service-provider/UPDATE-STORE-PROFILE?userId=${query.get("userId")}`,
                                )
                            }
                        >
                            Next
                        </Button>
                    </div>

                    <MediaHandler
                        open={open}
                        nameRequired={true}
                        isPrivate={true}
                        allowedSize={1024}
                        onClose={() => {
                            setOpen(false);
                            setAllowedList(false);
                        }}
                        allowedList={allowedList}
                        onSelectImage={(val) => {
                            setFormData({ ...formData, [modal]: val });
                            setOpen(false);
                            props.showAlert("File Added Successfully");
                            setAllowedList(false);
                        }}
                        folderName="service-provider/general/"
                    />
                    <br />
                    <br />
                    <br />
                </div>
            )}
        </div>
    );
};
const mapStateToProps = (state) => ({
    general: state.serviceProvider.general,
    experience: state.category.experiences,
});

export default withRouter(
    connect(mapStateToProps, { getAllServiceProviders, showAlert, getAllExperience, getSPGeneralDetails })(
        UpdateGeneral,
    ),
);
