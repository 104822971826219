import React from "react";
import styles from "./Action.module.css";
import axios from "axios";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";

import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import { showAlert } from "../../../../../containers/app/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateEventStatus } from "../../../../../containers/serviceProvider/actions";

const Action = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  let token = localStorage.getItem("token");

  const deleteNonActiveEvent = (eventId, deleteEvent) => {
    axios({
      method: "PUT",
      url: "/dashboard/service-provider/deleteNonActiveEvent",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        eventId: eventId,
        deleteEvent: deleteEvent,
        serviceProvider: props.eventId.serviceProvider,
      },
    })
      .then((res) => {
        if (res.data) {
          props.showAlert(res.data.message);
          window.location.reload(false);
        }
      })
      .catch((error) => {
        props.showAlert(
          "Service cannot be deleted as bookings have not been fulfilled"
        );
      });
  };

  return (
    <React.Fragment key={props.userId}>
      <IconButton
        onClick={(e) => {
          open ? setAnchorEl(null) : setAnchorEl(e.currentTarget);
        }}
      >
        <MoreVertRoundedIcon />
        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={() => setAnchorEl(null)}
          disableRestoreFocus
        >
          <div className={styles.popContent}>
            <p
              onClick={() =>
                props.history.push(
                  "/user/service-provider/ADD-STORE-SERVICE?eventId=" +
                    props.eventId._id +
                    "&type=DUPLICATE&userId=" +
                    props.userId
                )
              }
            >
              Duplicate Service
            </p>

            <p
              onClick={() =>
                props.history.push(
                  "/user/service-provider/EDIT-STORE-SERVICE?eventId=" +
                    props.eventId._id +
                    "&type=EDIT&userId=" +
                    props.userId
                )
              }
            >
              Edit Service
            </p>

            <p
              onClick={() =>
                props.updateEventStatus({
                  userId: props.userId,
                  active: !props.eventId.active,
                  eventId: props.eventId._id,
                })
              }
            >
              {props.eventId.active ? "Disable" : "Enable"} Service
            </p>

            <p onClick={() => deleteNonActiveEvent(props.eventId._id, true)}>
              Delete Service
            </p>
          </div>
        </Popover>
      </IconButton>
    </React.Fragment>
  );
};

export default withRouter(
  connect(null, { updateEventStatus, showAlert })(Action)
);
