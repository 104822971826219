import React from "react";
import styles from "../../../components/Transaction/ViewTransactions/ViewTransactions.module.css";

import TextField from "@material-ui/core/TextField";
import TablePagination from "@material-ui/core/TablePagination";

import AppLoader from "../../utils/AppLoader/AppLoader";
import DataTable from "react-data-table-component";
import BtnLoader from "../../utils/BtnLoader/BtnLoader";

import { connect } from "react-redux";
import { showAlert } from "../../../containers/app/actions";
import { getAllTransactions } from "../../../containers/transactions/actions";
import { withRouter } from "react-router-dom";

import Switch from "@material-ui/core/Switch";
import axios from "axios";
import moment from "moment";

import invoice from "../../../assets/svg/DownloadInvoice.svg";
import confirm from "../../../assets/svg/DownloadComp.svg";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core";
import ModalComp from "./Modal/Modal";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import ConfirmAlert from "../../utils/ConfirmAlert/ConfirmAlert";

const useStyles = makeStyles((theme) => ({
    selectitem: {
        height: "40px",
        minWidth: "130px",
    },
    iconColor: {
        color: "red",
    },
}));

const MyBooking = (props) => {
    const classes = useStyles();
    const [myBookings, setTransactions] = React.useState(props.transactions);
    const [searchVal, setSearchVal] = React.useState("");
    const [checkList, setCheckList] = React.useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [loading, setLoading] = React.useState(false);
    const [type, setType] = React.useState("ORDER");
    const [bookings, setBookings] = React.useState();
    const [modal, setModal] = React.useState(false);
    const [bookingId, setBookingId] = React.useState("");

    const redirectPage = (booking, e) => {
        if (e.target.id != "btn-copy" && e.target.id != "btn") {
            window.open(`${process.env.REACT_APP_CLIENT_URL}/cart/SHOW-APPOINTMENT?bookingId=${booking._id}`);
        }
    };
    const redirectVoucherPage = (voucher, e) => {
        if (e.target.id != "btn" && e.target.id != "btn-copy") {
            window.open(`${process.env.REACT_APP_CLIENT_URL}/cart/SHOW-VOUCHER?voucherId=${voucher._id}`);
        }
    };
    let token = localStorage.getItem("token");

    React.useEffect(() => {
        axios({
            method: "get",
            url: "/dashboard/service-provider/getAllBooking",

            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                setBookings(res.data.bookings);
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log(err);
                }
            });
    }, []);

    const handleChange = (e, id) => {
        if (e.target.value == "SETTLED") {
            setModal(true);
            setBookingId(id);
        }
    };

    const onCancel = (orderId) => {
        console.log(orderId);
        let URL =
            type === "ORDER"
                ? "/dashboard/service-provider/cancelUserBooking"
                : "/dashboard/service-provider/cancelVoucher";
        console.log(URL);
        axios({
            method: "put",
            url: URL,
            data: {
                orderId: orderId,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                console.log(res.data.message);
                props.showAlert(res.data.message);
                props.getAllTransactions(rowsPerPage, page + 1, searchVal, type);
            })
            .catch((err) => {
                if (err && err.response) {
                    console.log(err);
                }
            });
    };

    React.useEffect(() => {
        if (!props.transactions) {
            props.getAllTransactions(rowsPerPage, page + 1, searchVal, type);
        }
        setTransactions(props.transactions);
    }, [props.transactions]);

    const handleChangePage = (event, newPage) => {
        setCheckList([]);
        props.getAllTransactions(rowsPerPage, newPage + 1, searchVal, type);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setCheckList([]);
        props.getAllTransactions(event.target.value, page + 1, searchVal, type);
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchChange = (e) => {
        setCheckList([]);
        setPage(0);
        props.getAllTransactions(rowsPerPage, 1, e.target.value, type);
        setSearchVal(e.target.value);
    };

    const onSubmit = (e) => {
        setLoading(true);
        let url = type == "ORDER" ? "/dashboard/transactions/payOut" : "/dashboard/transactions/payOutVoucher";
        axios({
            url,
            method: "post",
            data: {
                checkList: checkList.filter((val) => val && val),
            },
        })
            .then((res) => {
                setLoading(false);
                setCheckList([]);
                props.getAllTransactions(rowsPerPage, 1, searchVal, type);
                props.showAlert("Orders Settled");
            })
            .catch((err) => {
                setLoading(false);
                props.showAlert("Something went wrong!Try Again");
            });
    };

    let isLoading = !myBookings;
    let showData = !isLoading;
    let rowData = [];

    !isLoading &&
        myBookings.length > 0 &&
        myBookings.forEach((order, index) => {
            rowData.push({
                sl: index + 1,
                Booking: order.uniqueId,

                customer: order.userId
                    ? order.userId.verificationType == "EMAIL"
                        ? order.userId.username
                        : order.userProfile && order.userProfile.firstName
                    : "NAN",
                customeremail: order.userId ? order.userId.email : "NAN",
                bookingDate: moment(order.createdAt).format("YYYY-MM-DD"),
                Service: order.serviceId ? order.serviceId.eventName : "NAN",

                serviceprovider: order.serviceProviderProfile ? order.serviceProviderProfile.storeName : "NAN",
                status: order.status,
                refundStatus: order.status === "CANCELLED" && (
                    <select
                        className={styles.select}
                        value={order.settlementStatus}
                        onChange={(e) => handleChange(e, order._id)}
                    >
                        <option value="PENDING">Pending</option>
                        <option value="SETTLED">Refunded</option>
                    </select>
                ),

                action: (
                    <React.Fragment>
                        {type == "ORDER" ? (
                            <Tooltip title="Download Booking">
                                <IconButton onClick={(e) => redirectPage(order, e)}>
                                    <img src={confirm} />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Download Voucher">
                                <IconButton onClick={(e) => redirectVoucherPage(order, e)}>
                                    <img src={confirm} />
                                </IconButton>
                            </Tooltip>
                        )}
                        {order.invoiceLink && (
                            <Tooltip title="Download Invoice">
                                <IconButton>
                                    <img src={invoice} />
                                </IconButton>
                            </Tooltip>
                        )}
                        {order.status !== "CANCELLED" && (
                            <ConfirmAlert
                                msg="Are you sure you wanna cancel this service ?"
                                onClickEvent={() => {
                                    onCancel(order._id);
                                }}
                            >
                                <Tooltip title="Cancel">
                                    <IconButton>
                                        <EventBusyIcon className={classes.iconColor} />
                                    </IconButton>
                                </Tooltip>
                            </ConfirmAlert>
                        )}
                    </React.Fragment>
                ),
            });
        });
    return (
        <div className={styles.container}>
            <ModalComp
                open={modal}
                type={type}
                bookingId={bookingId}
                setBookings={setBookings}
                onClose={() => setModal(false)}
                transactions={myBookings}
            />
            <div className={styles.header}>
                <div className={styles.leftHeader}>
                    <p></p>
                </div>

                <div className={styles.rightHeader}>
                    <div className={styles.switch}>
                        <p>Order</p>
                        <Switch
                            checked={type == "VOUCHER"}
                            onChange={() => {
                                setType(type == "ORDER" ? "VOUCHER" : "ORDER");
                                setCheckList([]);
                                setPage(0);
                                props.getAllTransactions(
                                    rowsPerPage,
                                    1,
                                    searchVal,
                                    type == "ORDER" ? "VOUCHER" : "ORDER",
                                );
                            }}
                        />
                        <p>Voucher</p>
                    </div>

                    <TextField
                        label="Search Here"
                        className={styles.search}
                        value={searchVal}
                        onChange={handleSearchChange}
                    />
                </div>
            </div>

            {isLoading && <AppLoader />}

            {showData && (
                <React.Fragment>
                    <DataTable
                        noHeader={true}
                        fixedHeader={true}
                        fixedHeaderScrollHeight={"calc(100vh - 380px)"}
                        columns={[
                            {
                                name: "Sl No",
                                selector: "sl",
                                sortable: true,
                                wrap: true,
                                maxWidth: "60px",
                            },
                            {
                                name: "Booking No.",
                                selector: "Booking",
                                sortable: true,
                                wrap: true,
                                minWidth: "150px",
                            },
                            {
                                name: "Customer",
                                selector: "customer",
                                sortable: true,
                                wrap: true,
                                minWidth: "150px",
                            },
                            {
                                name: "Customer Email",
                                selector: "customeremail",
                                sortable: true,
                                wrap: true,
                                minWidth: "220px",
                            },
                            {
                                name: "Booking Date",
                                selector: "bookingDate",
                                sortable: true,
                                wrap: true,
                                minWidth: "150px",
                            },
                            {
                                name: "Service",
                                selector: "Service",
                                sortable: true,
                                wrap: true,
                                minWidth: "190px",
                            },
                            {
                                name: "Service Provider",
                                selector: "serviceprovider",
                                sortable: true,
                                wrap: true,
                                minWidth: "140px",
                            },
                            {
                                name: "Status",
                                selector: "status",
                                sortable: true,
                                wrap: true,
                                minWidth: "180px",
                            },
                            {
                                name: "Refund Status",
                                selector: "refundStatus",
                                sortable: true,
                                wrap: true,
                                minWidth: "140px",
                            },
                            { name: "Action", selector: "action", minWidth: "250px" },
                        ]}
                        data={rowData}
                    />
                    <div className={styles.flexEnd}>
                        <TablePagination
                            component="div"
                            count={props.count}
                            page={page}
                            onChangePage={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </div>
                    {checkList.length > 0 && (
                        <div className={styles.btnCenter}>
                            {loading ? (
                                <BtnLoader className="btnGreen" />
                            ) : (
                                <button className="btnGreen" onClick={onSubmit}>
                                    Payout
                                </button>
                            )}
                        </div>
                    )}
                </React.Fragment>
            )}
        </div>
    );
};
const mapStateToProps = (state) => ({
    transactions: state.transaction.transactions,
    count: state.transaction.count,
});
export default withRouter(connect(mapStateToProps, { getAllTransactions, showAlert })(MyBooking));
