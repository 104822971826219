import React from 'react'
import styles from './StartBeing.module.css'

import AppLoader from '../../../utils/AppLoader/AppLoader'
 
import {connect} from 'react-redux' 
import {getAllCategory} from '../../../../containers/category/actions'

const StartBeing = (props) => {
    const nextState = "EDIT-PURPOSE-JOURNEY";

    React.useEffect(() => {
        if(!props.category) {
            props.getAllCategory();
        }
    },[]);
    return (
        <div className={styles.container}>
            <h1>Start being</h1>
            <p>creative, mindful, fulfilled in life and career or sustainable.</p>
            
            <div className={`${styles.content} customBackgroundBlur`}>
                <h6>Select one that suits your needs the most.</h6>

                <div className={styles.catItems}>
                    {!props.category && <AppLoader color="light"/>}
                    {props.category && props.category.map(cat => {
                        return (
                            <div className={styles.card} onClick={() => {
                                props.handleEditChanges({...props.journeyData,category: cat,subCategoryList: []});
                                props.setState(nextState);
                            }}>
                                <img src={cat.categoryImage} alt=""/>
                                <h2>{cat.categoryName}</h2>
                                <p>{cat.description}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({ 
    category: state.category.categoryList
})
export default connect(mapStateToProps,{getAllCategory})(StartBeing);