import React from 'react' 

import BtnLoader from '../../../utils/BtnLoader/BtnLoader'

import {connect} from 'react-redux';
import {showAlert,setAuth} from '../../../../containers/app/actions'
import {getBasicCartItems} from '../../../../containers/endUser/actions'
import axios from 'axios'
import {withRouter,useLocation} from 'react-router-dom'


const AddToCart = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);
     
    const [loading,setLoading] = React.useState(false);
 
    React.useEffect(() => {
        props.getBasicCartItems(query.get("userId"));
    },[])

    React.useEffect(() => {
        if(props.basicCart) {
            setLoading(false);
        }
    },[props.basicCart]);

    const addToCart = () => {
        let journeyId = query.get("journeyIndex") ? query.get("journeyIndex") : false
        setLoading(true);
        axios({
            method: "post",
            url: `/user/cart/addToCart?userId=${query.get("userId")}`,
            data: {
                serviceId: props.service._id,
                serviceProviderId: props.service.serviceProvider,
                quantity: props.quantity ? props.quantity  : 1,
                date: props.date,
                timeSlot: props.timeSlot,
                journeyId
            }
        }).then(res => { 
            props.showAlert("Added to cart Successfully")
            props.getBasicCartItems(query.get("userId"));
        }).catch(err => {
            if(err && err.response) 
                props.showAlert(err.response.data.error)
            else 
                props.showAlert('Something went wrong Try Again')

            setLoading(false);
        }) 
    }

    const isLoading = loading;
    const isAddedToCart = !isLoading && props.basicCart && props.basicCart.includes(props.service._id);
    const canAddToCart = !isLoading && !isAddedToCart;

    const disabled = props.date == "" || props.timeSlot == ""
    return (
        <div>
            {isLoading && <BtnLoader className="btnGreen" />}

            {isAddedToCart &&
                <button className="btnGreen" onClick={() => props.history.push(`/user/end-users/CART-ITEMS?userId=${query.get("userId")}`)}>Go To Cart</button>}

            {canAddToCart &&
                <button className={`btnGreen ${disabled && "disabled"}`} onClick={addToCart}
                    disabled={disabled}
                
                >Add To Cart</button>}   
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.app.auth,
    basicCart: state.endUser.basicCart
})
export default withRouter(connect(mapStateToProps,{showAlert,setAuth,getBasicCartItems})(AddToCart));