import React from 'react'; 
import CategoryManager from '../../components/Category/CategoryManager/CategoryManager';
import ServiceManager from '../../components/Category/ServiceManager/ServiceManager';
import SubCategoryManager from '../../components/Category/SubCategoryManager/SubCategoryManager';

import TopBar from '../../components/utils/TopBar/TopBar';
import styles from './Category.module.css';

import {connect} from 'react-redux'
import {getAllCategory} from './actions' 
import Experience from '../../components/Category/Experience/Experience';


const Category = (props) => {  
    return (
        <div className={styles.container}>
            <TopBar head="Category" /> 

            <div className={styles.catContainer}>
                <div className={styles.contentContainer}>
                    <Experience />
                </div>
                <div className={styles.contentContainer}>
                    <CategoryManager />
                </div>
                <div className={styles.contentContainer}>
                    <SubCategoryManager />
                </div>
                <div className={styles.contentContainer}>
                    <ServiceManager />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    category: state.category.category
})
export default connect(mapStateToProps,{getAllCategory})(Category);