import React from "react";
import styles from "./ServiceProvider.module.css";
import { useLocation } from "react-router-dom";

import BreadCrump from "../../components/utils/BreadCrump/BreadCrump";
import TopBar from "../../components/utils/TopBar/TopBar";

import ViewEndUser from "../../components/EndUser/ViewEndUser/ViewEndUser";
import Settings from "../../components/EndUser/ProfileSettings/Settings";
import PurposeJourney from "../../components/EndUser/PurposeJourney/PurposeJourney";
import UserJourneyComp from "../../components/EndUser/UserJourneyComp/UserJourneyComp";
import MyBooking from "../../components/EndUser/MyBooking/MyBooking";
import Subscription from "../../components/EndUser/Subscription/Subscription";
import ServiceList from "../../components/EndUser/ServiceList/ServiceList";
import ServicePage from "../../components/EndUser/ServicePage/Service";
import CartComp from "../../components/EndUser/Cart/CartComp";
import Survey from "../../components/EndUser/Survey/Survey";
import Notification from "../../components/EndUser/Notifications/Notification";
import Kpi from "../../components/EndUser/Kpi/Kpi";
import Rating from "../../components/EndUser/Rating/Rating";
import Promo from "../../components/EndUser/Promo/Promo";
import CompanyId from "../../components/EndUser/CompanyId/CompanyId";
import ViewB2BUser from "../../components/EndUser/ViewB2BUser/ViewB2BUser";

const EndUsers = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [state, setState] = React.useState("VIEW-END-USERS");

    React.useEffect(() => {
        if (Object.keys(navData).includes(props.match.params.type)) {
            setState(props.match.params.type);
        }
    }, [props.match.params.type]);

    const navData = {
        "VIEW-END-USERS": {
            name: "View End Users",
            path: "/user/end-users/VIEW-END-USERS",
        },
        Ratings: {
            name: "Ratings",
            path: "/user/end-users/RATING",
        },
        CompanyId: {
            name: "CompanyId",
            path: "/user/end-users/CompanyId",
        },
        PromoCode: {
            name: "PromoCode",
            path: "/user/end-users/PromoCode",
        },
        ViewB2BUser: {
            name: "ViewB2BUser",
            path: "/user/end-users/ViewB2BUser",
        },
        Survey: {
            name: "Survey",
            path: "/user/end-users/SURVEY",
        },
        Notification: {
            name: "Notification",
            path: "/user/end-users/Notification",
        },
        Kpi: {
            name: "",
            path: "/user/end-users/Notification",
        },
        SETTINGS: {
            name: "User Settings",
            path: `/user/end-users/SETTINGS?userId=${query.get("userId")}`,
        },
        "PURPOSE-JOURNEY": {
            name: "Purpose Journey",
            path: `/user/end-users/PURPOSE-JOURNEY?userId=${query.get("userId")}`,
        },
        "PURPOSE-JOURNEY-MAP": {
            name: "Purpose Journey Map",
            path: `/user/end-users/PURPOSE-JOURNEY-MAP?userId=${query.get("userId")}&journeyId=${query.get(
                "journeyId",
            )}&journeyType=${query.get("journeyType")}`,
        },
        "MY-BOOKINGS": {
            name: "User Service Bookings",
            path: `/user/end-users/MY-BOOKINGS?userId=${query.get("userId")}`,
        },
        "SERVICE-LIST": {
            name: "Service List",
            path: `/user/end-users/SERVICE-LIST?userId=${query.get("userId")}${
                query.get("journeyId") ? "&journeyId=" + query.get("journeyId") : ""
            }${query.get("journeyType") ? "&journeyType=" + query.get("journeyType") : ""}`,
        },
        "SERVICE-PAGE": {
            name: "Service Page",
            path: `/user/end-users/SERVICE-PAGE?userId=${query.get("userId")}&serviceId=${query.get(
                "serviceId",
            )}&serviceProvider=${query.get("serviceProvider")}`,
        },
        "CART-ITEMS": {
            name: "Cart Items",
            path: `/user/end-users/CART-ITEMS?userId=${query.get("userId")}`,
        },
        "VIEW-USERS-SUBSCRIPTION": {
            name: "Users Subscription",
            path: `/user/end-users/VIEW-USERS-SUBSCRIPTION`,
        },
    };

    return (
        <div className={styles.container}>
            <TopBar
                head={
                    state === "ViewB2BUser" || state === "CompanyId" || state === "PromoCode" ? "B2B User" : "EndUser"
                }
            />
            {state != "CompanyId" &&
                state != "ViewB2BUser" &&
                state != "Survey" &&
                state !== "Ratings" &&
                state != "Kpi" &&
                state != "Notification" && (
                    <BreadCrump
                        navItems={[
                            {
                                name: `${
                                    state === "ViewB2BUser" || state === "CompanyId" || state === "PromoCode"
                                        ? "B2B User"
                                        : "EndUser"
                                }`,
                                path: "/user/end-users/VIEW-END-USERS",
                            },
                            // ->PURPOSE-JOURNEY->PURPOSE-JOURNEY-MAP
                            state == "PURPOSE-JOURNEY-MAP" ? navData["PURPOSE-JOURNEY"] : false,

                            // ->MY-BOOKINGS->SERVICELIST
                            query.get("journeyId")
                                ? state == "SERVICE-LIST"
                                    ? navData["PURPOSE-JOURNEY-MAP"]
                                    : false
                                : state == "SERVICE-LIST"
                                ? navData["MY-BOOKINGS"]
                                : false,

                            // ->MY-BOOKINGS->SERVICELIST->SERVICE-PAGE
                            state == "SERVICE-PAGE" ? navData["MY-BOOKINGS"] : false,
                            state == "SERVICE-PAGE" ? navData["SERVICE-LIST"] : false,

                            // ->MY-BOOKINGS->SERVICELIST->CART_ITEMS
                            state == "CART-ITEMS" ? navData["MY-BOOKINGS"] : false,
                            state == "CART-ITEMS" ? navData["SERVICE-LIST"] : false,

                            navData[state],
                        ]}
                    />
                )}
            {state == "VIEW-END-USERS" && <ViewEndUser />}

            {state == "SETTINGS" && <Settings />}
            {state == "PURPOSE-JOURNEY" && <PurposeJourney />}
            {state == "PURPOSE-JOURNEY-MAP" && <UserJourneyComp />}
            {state == "MY-BOOKINGS" && <MyBooking />}
            {state == "SERVICE-LIST" && <ServiceList />}
            {state == "SERVICE-PAGE" && <ServicePage />}
            {state == "CART-ITEMS" && <CartComp />}
            {state == "VIEW-USERS-SUBSCRIPTION" && <Subscription />}
            {state == "Survey" && <Survey />}
            {state == "Notification" && <Notification />}
            {state == "Kpi" && <Kpi />}
            {state == "PromoCode" && <Promo />}
            {state == "Ratings" && <Rating />}
            {state === "CompanyId" && <CompanyId />}
            {state === "ViewB2BUser" && <ViewB2BUser />}
        </div>
    );
};

export default EndUsers;
