import * as actionTypes from './actionTypes'
import axios from 'axios'
 
export const getAllDiscountCodes = () => dispatch => {
    dispatch({
        type: actionTypes.SET_DISCOUNT_CODES,
        payload: false
    })

    axios({
        method: "get",
        url: "/dashboard/discount-code/getAllDiscountCodes"
    }).then(res => {
        dispatch({
            type: actionTypes.SET_DISCOUNT_CODES,
            payload: res.data.codes
        })
        let data={}
        res.data.codeUsed.map(val => {
            data[val._id.discountCodeId] = val.count;
        })
        dispatch({
            type: actionTypes.SET_DISCOUNT_CODE_USED,
            payload: data
        })

    }).catch(err => {
        dispatch({
            type: actionTypes.SET_DISCOUNT_CODES,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
} 