import React from 'react'; 
import styles from './InfoIcon.module.css'
 
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

export default function InfoIcon(props) { 
    return (
        <div className={`${styles.btnPoper} ${props.type =="left" && styles.leftPoper} ${props.type =="right" && styles.rightPoper }`}>
            <InfoRoundedIcon 
                className={`${props.className} ${styles.svg}`} 
                onClick={props.onClick} 
                style={{fill: 'var(--primary-color)'}}
            />
             
            <pre style={{width: props.width,height: props.height}}>{props.message}</pre> 
        </div>
    );
}
