import React from "react";
import styles from "./Modal.module.css";
import Modal from "@material-ui/core/Modal";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { makeStyles, Select, MenuItem } from "@material-ui/core";
import axios from "axios";
import { getAllEndUsers } from "../../../../containers/endUser/actions";
import { connect } from "react-redux";
import { auth } from "../../../../config/config";
import { showAlert } from "../../../../containers/app/actions";

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: 10,
    },
    password: {
        width: "300px",
        marginBottom: "95px",
        marginTop: "15px",
        "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
            height: "44px",
        },
    },
}));

const ModalPage = ({ open, onClose, tab, user, ...props }) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [newpassword, setNewPassword] = React.useState();
    const [formData, setFormData] = React.useState({
        email: "",
        password: "",
        firstName: "",
    });
    const [choice, setChoice] = React.useState("monthly");
    const [formError, setFormError] = React.useState({
        email: false,
        password: false,
        firstName: false,
    });
    let token = localStorage.getItem("token");
    const validate = () => {
        let value = true;
        let err = { email: false, password: false, username: false };
        let isEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        setFormError({ ...err });
        if (formData.email == "") {
            value = false;
            err.email = "Enter valid Email";
        }
        if (!isEmail.test(formData.email)) {
            value = false;
            err.email = "Enter valid Email";
        }
        if (formData.password.length < 8) {
            value = false;
            err.password = "Password should be at least 8 characters";
        }
        if (formData.firstName == "") {
            value = false;
            err.firstName = "Enter valid firstName";
        }
        if (formData.firstName.length > 22) {
            value = false;
            err.firstName = "Characters limit is 1~22";
        }

        setFormError({ ...err });
        return value;
    };

    const updateVerification = () => {
        axios({
            method: "get",
            url: `/dashboard/user/updateVerification?email=${formData.email}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                setLoading(false);
                props.getAllEndUsers();
                onClose();
                setFormData({ ...formData, email: "", password: "", firstName: "" });
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const getSubscriber = () => {
        setLoading(true);
        axios({
            method: "get",
            revenuecat: true,
            url: `https://api.revenuecat.com/v1/subscribers/${user._id}`,
            headers: {
                Authorization: `Bearer sk_pZqHDYDpUSdtuNnkhyHBTfHQcmCuP`,
            },
        })
            .then((res) => {
                setLoading(false);
                provideEntitlment();
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const provideEntitlment = () => {
        setLoading(true);
        axios({
            method: "get",
            url: `/dashboard/user/provideEntitlment?choice=${choice}&userId=${user._id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                console.log(res);
                setLoading(false);
                props.getAllEndUsers();
                onClose();
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    console.log(user);
    const addSubscriber = (userId) => {
        setLoading(true);
        axios({
            method: "get",
            url: `/dashboard/user/addRevenuecatUser?userId=${userId}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                updateVerification();
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    function updatePassword() {
        axios({
            method: "get",
            url: `/dashboard/user/updatepassword?email=${user.email}&newPassword=${newpassword}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                setLoading(false);
                props.showAlert("password is successfully changed");
                onClose();
            })
            .catch((err) => {
                setLoading(false);
            });
    }

    const onSubmit = () => {
        if (validate()) {
            setLoading(true);
            axios({
                method: "post",
                url: "/user/auth/registerWithEmail?language=" + "en",
                data: {
                    ...formData,
                    purposeJourney: false,
                    sendToken: false,
                },
            })
                .then((res) => {
                    auth.createUserWithEmailAndPassword(formData.email, formData.password)
                        .then(async (data) => {
                            setLoading(true);
                            addSubscriber(res.data.user._id);
                        })
                        .catch((err) => console.log(err));
                })
                .catch((err) => {
                    if (err && err.response) {
                        setLoading(false);
                    }
                });
        }
    };

    const deactivateSubscription = () => {
        axios({
            method: "get",
            url: `/user/utils/cancelSubscription?email=${user.email}`,
        })
            .then((res) => {
                props.getAllEndUsers();
                setLoading(false);
                onClose();
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    return (
        <Modal open={open ? true : false} onClose={onClose} className={styles.modal}>
            <div className={styles.container}>
                <div className={styles.closeButton}>
                    <IconButton onClick={onClose}>
                        <CloseRoundedIcon />
                    </IconButton>
                </div>

                <div className={styles.content}>
                    <div className={styles.infoHeader}>
                        <h5>{tab && tab}</h5>
                    </div>
                    {tab === "Add new user" && (
                        <>
                            <div className={styles.flex}>
                                <TextField
                                    variant="outlined"
                                    label="FirstName"
                                    className={styles.halfField}
                                    value={formData.firstName}
                                    onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                                    error={formError.firstName}
                                    helperText={formError.firstName}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Password"
                                    className={styles.halfField}
                                    value={formData.password}
                                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                    error={formError.password}
                                    helperText={formError.password}
                                />
                            </div>

                            <TextField
                                variant="outlined"
                                label="Email"
                                fullWidth
                                className={styles.textField}
                                value={formData.email}
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                error={formError.email}
                                helperText={formError.email}
                            />

                            <div className={styles.Button}>
                                <button
                                    onClick={() => {
                                        onSubmit();
                                    }}
                                >
                                    {loading ? "...Adding" : "Add"}
                                </button>
                            </div>
                        </>
                    )}

                    {tab === "Grant Entitlment" && (
                        <>
                            {console.log(choice)}
                            <Select
                                className={styles.select}
                                placeholder="Select entitlement type"
                                variant="outlined"
                                value={choice}
                                onChange={(e) => {
                                    setChoice(e.target.value);
                                }}
                                MenuProps={{
                                    classes: {
                                        paper: classes.paper,
                                    },
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                }}
                            >
                                <MenuItem
                                    value="monthly"
                                    style={{ display: "flex", justifyContent: "center", color: "#2D4141" }}
                                >
                                    Monthly
                                </MenuItem>
                                <MenuItem
                                    style={{ display: "flex", justifyContent: "center", color: "#2D4141" }}
                                    value="yearly"
                                >
                                    Annual
                                </MenuItem>
                            </Select>
                            <div className={styles.Button}>
                                <button onClick={() => getSubscriber()}>Apply</button>
                            </div>
                        </>
                    )}
                    {tab === "Change password" && (
                        <div>
                            <TextField
                                variant="outlined"
                                placeholder="Please enter new password"
                                className={classes.password}
                                value={newpassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <div className={styles.Button}>
                                <button onClick={() => updatePassword()}>Update</button>
                            </div>
                        </div>
                    )}
                    {!tab && (
                        <div className={styles.subscription}>
                            <h2>
                                Are you sure you want to deactivate <br /> this user’s subscription?
                            </h2>
                            <p>
                                By deactivating, this user will lose all access <br /> to any premium features
                            </p>
                            <div className={styles.Button}>
                                <button
                                    onClick={() => {
                                        deactivateSubscription();
                                        setLoading(true);
                                    }}
                                >
                                    {loading ? "...Deactivating" : "Yes"}
                                </button>{" "}
                                <br />
                                <br />
                                <span
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    Cancel
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};
export default connect(null, {
    getAllEndUsers,
    showAlert,
})(ModalPage);
