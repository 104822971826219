import React from 'react'
import styles from './ServiceList.module.css'

import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

import {connect} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {getAllServices} from '../../../containers/endUser/actions'

import ServiceBox from './ServiceBox/ServiceBox'
import AppLoader from '../../utils/AppLoader/AppLoader';

const ServiceList = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [searchVal,setSearchVal] = React.useState("");

    React.useEffect(() => {
        if(!props.services) {
            props.getAllServices();
        }
    },[]);
    let count = 0; 
    let renderServices = props.services && props.services.map(service => {
        let serviceProviderName = service.serviceProviderProfile ? service.serviceProviderProfile.storeName : "";

        if(query.get("categoryId")) {
            let catList = [];
            let subCatList = [];
            service.catList.forEach(cat => {
                catList.push(cat.categoryId._id);
                subCatList.push(cat.subCategoryId);
            });
 
            if(catList.includes(query.get("categoryId")) && (!query.get("subCategoryId") || subCatList.includes(query.get("subCategoryId")))) {
                if(service.eventName.toLowerCase().includes(searchVal.toLowerCase()) || serviceProviderName.toLowerCase().includes(searchVal.toLowerCase())) {
                    count ++;
                    return (
                        <div className={styles.serviceBox}>
                            <ServiceBox service={service} />
                        </div>
                    )
                }   
            }
        } else {
            if(service.eventName.toLowerCase().includes(searchVal.toLowerCase()) || serviceProviderName.toLowerCase().includes(searchVal.toLowerCase())) {
                count ++;
                return (
                    <div className={styles.serviceBox}>
                        <ServiceBox service={service} />
                    </div>
                )
            }
        }
    });
    const isLoading = !props.services;
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <TextField 
                        variant="outlined"
                        value={searchVal}
                        onChange={e => setSearchVal(e.target.value)}
                        InputProps={{
                            startAdornment: 
                                <InputAdornment position="start">
                                    <SearchRoundedIcon />
                                </InputAdornment>,
                        }}
                    />
                    {isLoading && <AppLoader />}
                    
                    {!isLoading &&
                        <React.Fragment>
                            {count == 0
                                ?
                            <div className={styles.alignCenter}>
                                No Services Found
                            </div>
                                :
                            <div className={styles.serviceList}>
                                {renderServices}
                            </div>}
                        </React.Fragment>}
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    services: state.endUser.services
})
export default connect(mapStateToProps, {getAllServices})(ServiceList);