import React from "react";
import styles from "./styles/app.module.css";

import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import axios from "axios";

import { backendUrl } from "./config/config";
import { theme } from "./theme/theme";

import { setAuth, showAlert } from "./containers/app/actions";
import { connect } from "react-redux";

import ShowAlert from "./components/utils/Alert/Alert";
import BackDropLoader from "./components/utils/BackDropLoader/BackDropLoader";

import Login from "./components/Auth/Login/Login";
import NavBar from "./components/Navbar/Navbar";

import Users from "./containers/users/Users";
import AdminRoute from "./routes/AdminRoute";
import SetAccount from "./components/Auth/SetAccount/SetAccount";
import ServiceProviderRequest from "./containers/serviceProviderRequest/ServiceProviderRequest";
import ServiceProvider from "./containers/serviceProvider/ServiceProvider";
import PrivateRoute from "./routes/PrivateRoute";
import TopBar from "./components/utils/TopBar/TopBar";
import Category from "./containers/category/Category";
import Mail from "./containers/mail/Mail";
import Translate from "./containers/translate/Translate";
import DiscountCode from "./containers/discountCode/DiscountCode";
import Transactions from "./containers/transactions/Transactions";
import LifeCoach from "./containers/lifeCoach/LifeCoach";
import LifeCoaches from "./containers/lifeCoaches/LifeCoach";
import Niv from "./containers/niv/Niv";
import General from "./containers/General/General";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import EndUsers from "./containers/endUser/EndUsers";
import Referral from "./components/EndUser/Referral/Referral";
import ServicePreview from "./components/ServicePage/Service";
import PartnerConf from "./containers/partner/PartnerConf";
import ViewPartnerAgreement from "./components/PartnerAgreement/ViewPartnerAgreement/ViewPartnerAgreement";
import ViewGiftCard from "./components/GiftCard/GiftCard/ViewGiftCard";

const hist = createBrowserHistory();

axios.interceptors.request.use(async (config) => {
    if (!config.revenuecat) {
        config.url = config.externalUrl ? config.url : backendUrl + config.url;
    }

    if (localStorage.token && !config.externalUrl && !config.revenuecat) {
        config.headers = {
            ...config.headers,
            Authorization: localStorage.getItem("token"),
        };
    }
    return config;
});

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const { status } = error && error.response;
        if (status === 401) {
            // localStorage.clear();
            // window.location.replace("/login")
        }
        return Promise.reject(error);
    },
);

const Home = () => {
    return (
        <div>
            <TopBar head="Home Page" />
            <p>Home page under developement</p>
        </div>
    );
};

const App = (props) => {
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener("beforeunload", function (e) {
            // localStorage.clear();
        });
    }, []);

    React.useEffect(() => {
        if (localStorage.token) {
            axios({
                method: "get",
                url: "/dashboard/user/getUserDetails",
            })
                .then((res) => {
                    if (
                        res.data.user.userType == "ADMIN" ||
                        res.data.user.userType == "SALES" ||
                        res.data.user.userType == "LIFECOACH"
                    ) {
                        props.setAuth({ ...res.data.user });
                    } else {
                        localStorage.clear();
                        props.showAlert("401: You don't have enough access");
                    }
                    setLoaded(true);
                })
                .catch((err) => {
                    console.log(err);
                    if (err && err.response) {
                        props.showAlert(err.response.data.error);
                        if (err.response.status == 401) {
                            localStorage.clear();
                        }
                    } else props.showAlert("Something went wrong Try Again");

                    setLoaded(true);
                });
        } else {
            setLoaded(true);
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className={styles.appContainer}>
                    <Router history={hist}>
                        {!loaded ? (
                            <BackDropLoader />
                        ) : (
                            <React.Fragment>
                                <ShowAlert />
                                <div className={styles.navContainer}>
                                    <NavBar />
                                </div>
                                <div className={styles.mainContainer}>
                                    <Switch>
                                        <Route exact path="/login" component={Login} />
                                        <Route exact path="/set-account" component={SetAccount} />

                                        <AdminRoute exact path="/admin/users/:type" component={Users} />
                                        <AdminRoute exact path="/admin/category/" component={Category} />
                                        <AdminRoute
                                            exact
                                            path="/admin/partner-agreement"
                                            component={ViewPartnerAgreement}
                                        />
                                        <AdminRoute
                                            exact
                                            path="/admin/gift-card/VIEW-GIFT-CARD"
                                            component={ViewGiftCard}
                                        />

                                        <PrivateRoute exact path="/user/home" component={Home} />
                                        <PrivateRoute exact path="/user/Referal" component={Referral} />
                                        <PrivateRoute
                                            exact
                                            path="/user/service-provider-request/:type"
                                            component={ServiceProviderRequest}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/user/service-provider/:type"
                                            component={ServiceProvider}
                                        />
                                        <PrivateRoute exact path="/user/end-users/:type" component={EndUsers} />
                                        <PrivateRoute exact path="/user/transactions/:type" component={Transactions} />
                                        <PrivateRoute exact path="/user/email-system" component={Mail} />
                                        <PrivateRoute exact path="/user/translation" component={Translate} />
                                        <PrivateRoute
                                            exact
                                            path="/user/service-preview"
                                            component={ServicePreview}
                                            nav={false}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/user/add-service-provider/:type"
                                            component={PartnerConf}
                                        />
                                        <PrivateRoute exact path="/user/life-coach-admin/:type" component={LifeCoach} />
                                        <PrivateRoute
                                            exact
                                            path="/user/life-coaches-admin/:type"
                                            component={LifeCoaches}
                                        />

                                        <PrivateRoute
                                            exact
                                            path="/user/discount-codes/:type"
                                            component={DiscountCode}
                                        />
                                        <PrivateRoute exact path="/user/life-coach/:type" component={Niv} />
                                        <PrivateRoute exact path="/user/General" component={General} />

                                        {/* <Redirect from="/" to="/user/home" /> */}
                                    </Switch>
                                </div>
                            </React.Fragment>
                        )}
                    </Router>
                </div>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};
export default connect(null, { setAuth, showAlert })(App);
