import React from 'react'
import styles from './Bookings.module.css'

import {connect} from 'react-redux'
import {getAllBookings} from '../../../containers/serviceProvider/actions'
import {getAllVouchers} from '../../../containers/serviceProvider/actions'

import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import AppLoader from '../../utils/AppLoader/AppLoader'
import BookingComp from './BookingComp/BookingComp'
import {useLocation} from 'react-router-dom'
import Header from '../Header/Header';
import VoucherComp from "./VoucherComp/VoucherComp";

const Bookings = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);
 
    const [bookings,setBookings] = React.useState(false);
    const [tab,setTab] = React.useState("ALL_BOOKINGS");
    const [searchVal,setSearchVal] = React.useState("");
    const [vouchers, setVouchers] = React.useState(false);

    React.useEffect(() => {
        props.getAllBookings(query.get("userId"));
    },[])
    React.useEffect(() => {
        setBookings(props.bookings)
    },[props.bookings]);



    React.useEffect(() => {
        
		if (!props.bookings) {
			props.getAllBookings(query.get("userId"));
		} else {
			setBookings(props.bookings);
		}
		if (!props.vouchers) {
			props.getAllVouchers(query.get("userId"));
		} else {
			setVouchers(props.vouchers);
		}
	}, [props.bookings, props.vouchers]);
    

    let bookingCount = 0;
    let bookingData = {};
    bookings &&
        bookings.map((booking) => {
            if (
                (booking?.serviceId?.eventName.toLowerCase().includes(searchVal.toLowerCase()) ||
                    (booking.uniqueId && booking.uniqueId.includes(searchVal))) &&
                ((tab == "ALL_BOOKINGS" && booking.status != "REFUND") ||
                    (tab == "CANCEL" && booking.status == "REFUND"))
            ) {
                bookingCount++;
                if (bookingData[booking?.serviceId?._id + booking.timeSlot.startDate]) {
                    bookingData[booking?.serviceId?._id + booking.timeSlot.startDate].push(booking);
                } else {
                    bookingData[booking?.serviceId?._id + booking.timeSlot.startDate] = [booking];
                }
            }
        });

    let voucherCount = 0;
	const renderVoucher =
		vouchers &&
		vouchers.map((voucher) => {
            if(voucher?.serviceId == null){
                console.log(voucher)
            }
			if (
				(voucher?.serviceId?.eventName
					.toLowerCase()
					.includes(searchVal.toLowerCase()) ||
					voucher.voucherCode.toLowerCase().includes(searchVal.toLowerCase())) &&
				tab == "COUPON"
			) {
				voucherCount++;
				return <VoucherComp voucher={voucher} />;
			}  
		});

    return (
        <div className={styles.container}>
            <Header type="BOOKING" />
            <div className={styles.content}>
                <h1>{"Bookings"}</h1>
                <p>{"Manage your Bookings and Orders"}</p>

                <div className={styles.header}>
                    <Tabs className={styles.tabs} value={tab} onChange={(e,val) => setTab(val)} indicatorColor="primary" textColor="primary">
                        <Tab label={"All Bookings"} value="ALL_BOOKINGS" />
                        <Tab label={"Cancellations"} value="CANCEL" />
                        <Tab label={"Coupon"} value="COUPON" />
                    </Tabs>

                    <TextField 
                        placeholder={"Search Booking"}
                        variant="outlined"
                        value={searchVal}
                        onChange={e => setSearchVal(e.target.value)}
                        InputProps={{
                            startAdornment: 
                            <InputAdornment position="start">
                                <SearchRoundedIcon />
                            </InputAdornment>,
                        }}
                    />
                </div>
                {(tab == "ALL_BOOKINGS" || tab == "CANCEL") && (
                <React.Fragment>
                <React.Fragment>  {!bookings && <AppLoader />}</React.Fragment>
                <React.Fragment>
                {bookings &&
                    <div className={styles.bookingContent}>
                    
                        <React.Fragment>
							{bookingCount == 0 && (
								<div className={styles.alignCenter}>
									<p>No Bookings Found</p>
								</div>
							)}
							<BookingComp data={Object.values(bookingData)}/>
					</React.Fragment>
                    </div>}
                    </React.Fragment>
                </React.Fragment>)}
                {tab == "COUPON" && (
					<React.Fragment>
						<React.Fragment>{!vouchers && <AppLoader />}</React.Fragment>

						<React.Fragment>
							{vouchers && (
								<div className={styles.bookingContent}>
									<div className={styles.overFlowContent}>
										<div className={styles.tableHead}>
											<h2>Customer</h2>
											<h2>Service</h2>
											<h2>Personnel</h2>
											<h2>Purchase Date</h2>
											<h2>&nbsp;</h2>
										</div>
										<React.Fragment>
											{voucherCount == 0 && (
												<div className={styles.alignCenter}>
                                                    <p>No Voucher Code Found</p>
												</div>
											)}
											{renderVoucher}
										</React.Fragment>
									</div>
								</div>
							)}
						</React.Fragment>
					</React.Fragment>
				)}
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    bookings: state.serviceProvider.bookings,
    vouchers: state.serviceProvider.vouchers,
})
export default connect(mapStateToProps, {getAllBookings,getAllVouchers})(Bookings);