import React from 'react'
import styles from './SettingNav.module.css'


import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import TuneRoundedIcon from '@material-ui/icons/TuneRounded';
import SecurityRoundedIcon from '@material-ui/icons/SecurityRounded';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';

const SettingNav = (props) => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.navItem} onClick={() => props.setState("PERSONAL_DETAILS")}>
                    <AccountCircleRoundedIcon style={{fontSize: '30px',color: 'var(--primary-color'}}/>
                    <div className={styles.infoContent}>
                        <h1>Personal Details</h1>
                        <p>Update your personal information</p>
                    </div>
                </div>

                <div className={styles.navItem} onClick={() => props.setState("PREFERENCES")}>
                    <TuneRoundedIcon style={{fontSize: '30px',color: 'var(--primary-color'}}/>
                    <div className={styles.infoContent}>
                        <h1>Preferences</h1>
                        <p>Change your language, currency and manage notifications</p>
                    </div>
                </div> 

                <div className={styles.navItem} onClick={() => props.setState("SECURITY")}>
                    <SecurityRoundedIcon style={{fontSize: '30px',color: 'var(--primary-color'}}/>
                    <div className={styles.infoContent}>
                        <h1>Security</h1>
                        <p>Manage your security settings</p>
                    </div>
                </div>

                <div className={styles.navItem} onClick={() => props.setState("REFERRAL")}>
                    <LinkRoundedIcon style={{fontSize: '30px',color: 'var(--primary-color'}}/>
                    <div className={styles.infoContent}>
                        <h1>Referral</h1>
                        <p>Access your referral link and check your referral credits</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SettingNav;