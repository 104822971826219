import * as actionTypes from './actionTypes'

const initialState = {
   lifecoaches:false,
   lifecoach:false,
   bookings:false,
   dates:false,
   lifecoachgeneral:false
}
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.SET_ALL_LIFE_COACHES:
            return { ...state, lifecoaches: action.payload } 
        case actionTypes.SET_LIFECOACH:
            return { ...state, lifecoach: action.payload } 
            case actionTypes.SET_BOOKINGS:
            return { ...state, bookings: action.payload }
         case actionTypes.GET_SCHEDULE_DATES:
            return { ...state, dates: action.payload }   
        case actionTypes.UPDATE_DATA:
            return { ...state, lifecoach: action.payload }
        case actionTypes.SET_LIFECOACH_GENERAL:
            return { ...state, lifecoachgeneral: action.payload }
        default:
            return state
    }
}

export default reducer;