import React from "react";
import styles from "./Bookings.module.css";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import QuestionFromUser from "./ModalComp/ModalComp";
import MeetingInfo from "./MeetingInfo/MeetingInfo";
import CancelComp from "./Cancel/CancelComp";
import { connect } from "react-redux";
import { showAlert } from "../../../containers/app/actions";
import { getAllBookings } from "../../../containers/niv/actions";
import AppLoader from "../../utils/AppLoader/AppLoader";
import moment from "moment";
import { CircularProgress, IconButton } from "@material-ui/core";
import { Mail } from "@material-ui/icons";
import ContactCustomer from "./ContactCustomer/ContactCustomer";

const Bookings = (props) => {
    const [questionModal, setQuestionModal] = React.useState(false);
    const [meetingModal, setMeetingModal] = React.useState(false);
    const [cancelModal, setCancelModal] = React.useState({ open: false, bookings: false });
    const [contactCustomerModal, setContactCustomerModal] = React.useState({ open: false, customer: false });

    React.useEffect(() => {
        props.getAllBookings(props.match.params.type);
    }, []);

    let obj = {};
    props.bookings &&
        props.bookings.forEach((val) => {
            let time = val.timings;
            if (obj[time.startDate + time.startTime + time.endTime]) {
                obj[time.startDate + time.startTime + time.endTime].push(val);
            } else {
                obj[time.startDate + time.startTime + time.endTime] = [val];
            }
        });

    return (
        <React.Fragment>
            <QuestionFromUser open={questionModal} onClose={() => setQuestionModal(false)} />
            <MeetingInfo
                getBookings={() => props.getAllBookings(props.match.params.type)}
                open={meetingModal}
                booking={meetingModal}
                showAlert={props.showAlert}
                onClose={() => setMeetingModal(false)}
            />
            <ContactCustomer
                open={contactCustomerModal.open}
                onClose={() => setContactCustomerModal({ open: false, customer: false })}
                customer={contactCustomerModal.customer}
            />
            <CancelComp
                open={cancelModal.open}
                onClose={() => setCancelModal({ open: false, bookings: false })}
                booking={cancelModal.bookings}
                category={props.match.params.type}
            />
            <div className={styles.container}>
                <div className={styles.content}>
                    {!props.bookings ? (
                        <AppLoader />
                    ) : (
                        <>
                            {props.bookings.length > 0 ? (
                                Object.values(obj).map((booking) => {
                                    let bookData = booking[0];

                                    return (
                                        <Accordion className={styles.accordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <div className={styles.head}>
                                                    <div className={styles.item}>
                                                        <span>Date</span>
                                                        <p>{moment(bookData.timings.startDate).format("YYYY-MM-DD")}</p>
                                                    </div>
                                                    <div className={styles.item}>
                                                        <span>Time Slot</span>
                                                        <p>
                                                            {bookData.timings.startTime} - {bookData.timings.endTime}
                                                        </p>
                                                    </div>
                                                    <div className={styles.item}>
                                                        <span>Service</span>
                                                        <p>Live With Coach</p>
                                                    </div>
                                                    <div className={styles.item}>
                                                        <span>No of Bookings</span>
                                                        <p>{booking.length}</p>
                                                    </div>
                                                    <div className={styles.item}>
                                                        <button
                                                            className={"btnGreen"}
                                                            onClick={() => {
                                                                setMeetingModal(booking);
                                                            }}
                                                        >
                                                            Meeting Link
                                                        </button>
                                                        <button
                                                            className={"btnRed"}
                                                            onClick={() =>
                                                                setCancelModal({ open: true, bookings: booking })
                                                            }
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails className={styles.bookSubDataDiv}>
                                                <div className={styles.bookingInfo}>
                                                    {booking.map((bookData) => {
                                                        let name =
                                                            bookData.userId && bookData.userId.userProfile
                                                                ? bookData.userId.userProfile.firstName
                                                                    ? bookData.userId?.userProfile.firstName
                                                                    : bookData.userId.username
                                                                : "User";
                                                        return (
                                                            <div className={styles.bookSubData}>
                                                                <div className={styles.item}>
                                                                    <span>Date</span>
                                                                    <p>
                                                                        {moment(bookData.timings.startDate).format(
                                                                            "YYYY-MM-DD",
                                                                        )}
                                                                    </p>
                                                                </div>
                                                                <div className={styles.item}>
                                                                    <span>Time Slot</span>
                                                                    <p>
                                                                        {bookData.timings.startTime} -{" "}
                                                                        {bookData.timings.endTime}
                                                                    </p>
                                                                </div>
                                                                <div className={styles.item}>
                                                                    <span>Customer</span>
                                                                    <p>{name}</p>
                                                                </div>
                                                                <div className={`${styles.itemIcon}`}>
                                                                    <span>Contact Customer</span>
                                                                    <div>
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={() =>
                                                                                setContactCustomerModal({
                                                                                    open: true,
                                                                                    customer: bookData.userId,
                                                                                })
                                                                            }
                                                                        >
                                                                            <Mail color="primary" fontSize="small" />
                                                                        </IconButton>
                                                                    </div>
                                                                </div>
                                                                <div className={styles.item}>
                                                                    <button
                                                                        className={"btnGreen"}
                                                                        onClick={() => setQuestionModal(bookData)}
                                                                    >
                                                                        View Questions
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                })
                            ) : (
                                <h4 className={styles.h4}>There are no records to display</h4>
                            )}
                        </>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};
const mapStateToProps = (state) => ({
    scheduledData: state.lifeCoach.scheduledData,
    bookings: state.lifeCoach.bookings,
});

export default connect(mapStateToProps, { showAlert, getAllBookings })(Bookings);
