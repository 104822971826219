import React from 'react'
import styles from './InfoBox.module.css'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import AlarmRoundedIcon from '@material-ui/icons/AlarmRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';

import DateFnsUtils from '@date-io/date-fns';
import {DatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
 
import AddToCart from '../AddToCart/AddToCart'

import {useLocation} from 'react-router-dom'
import moment from 'moment'

const defaultMaterialTheme = createMuiTheme({
    spacing: 0,
    overrides: {
        MuiPickersToolbar: {
          toolbar: {
            display: "none"
          },
        }, 
        MuiPickersCalendarHeader: {
            switchHeader: {
                backgroundColor: "#333",
                color: "#2D4141", 

                "& button": {
                    backgroundColor: "white !important" ,
                    color: "#2D4141 !important"
                }, 
                "& p": { 
                    color: "#2D4141 !important"
                }, 
            }, 
            daysHeader: {
                "& span": {
                    color: "#2D4141 !important"
                }
            }
        },
        MuiPickersDay: {
          day: {
            color: "#2D4141",
          },
          daySelected: {
            backgroundColor: "#2D4141",
            color: "white",

            "&:hover": {
                backgroundColor: "#2D4141",
                color: "white"
            }
          },
          dayDisabled: {
            color: "#2D4141",
            opacity: '0.5'
          },
          hover: {
            backgroundColor: "#2D4141",
            color: "white"
          },
        }, 
    },
});


const InfoBox = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const [dates,setDates] = React.useState({});

    
    const [activeTab,setActiveTab] = React.useState("Description");
    const [quantity, setQuantity] = React.useState(1);
    const [date,setDate] = React.useState("")
    const [timeSlot,setTimeSlot] = React.useState("")
    
    React.useEffect(() => {
        if(props.service && props.service.scheduleType != "REGULAR") {
            let obj = {};
            props.service.dates.forEach(val => {
                if(obj[val.startDate]) {
                    obj[val.startDate].push(val);
                } else {
                    obj[val.startDate] = [val]
                }
            })
            setDates({...obj})
        }
    },[props.service])

    const service = props.service;
    const serviceProvider = props.serviceProvider;
 
    console.log(timeSlot)
    return (
        <div className={styles.container}>
            <div className={styles.infoContent}>
                <Tabs value={activeTab} onChange={(e,newVal) => setActiveTab(newVal)} textColor="primary" indicatorColor="primary" className={styles.tabs}>
                    <Tab label="Description" value="Description" />
                    <Tab label="Company" value="Company" />
                    <Tab label="Reviews" value="Reviews" /> 
                </Tabs>


                {activeTab == "Description" &&
                    <div className={styles.content}> 
                        <span></span>

                        <p>{service.description}</p>
                        {service.headers["Good to Know"].active &&
                            <p><b>Good to Know: </b>{service.headers["Good to Know"].value}</p>}
                        {service.headers["Requirements"].active &&
                            <p><b>Requirements: </b>{service.headers["Requirements"].value}</p>}
                        {service.headers["What Is Included"].active &&
                            <p><b>What Is Included: </b>{service.headers["What Is Included"].value}</p>}

                    </div>
                }

                {activeTab == "Company" &&
                    <div className={styles.content}>
                        <div className={styles.topHeader}>
                            <h1>{serviceProvider.storeName}</h1>
                            <FavoriteRoundedIcon color="primary" />
                        </div>

                        <div className={styles.infoSection}>
                            <img src={serviceProvider.storeImage} alt=""/>

                            <div className={styles.infoSectionContent}>
                                <p>
                                    <LocationOnRoundedIcon />
                                    {service.location}
                                </p>
                                {serviceProvider.physicalOutlet && serviceProvider.physicalOutlet.map(outlet => {
                                    return (
                                        <p>
                                            <AlarmRoundedIcon />
                                            {outlet.outletName}:&nbsp;
                                            {outlet.days.map(day => {
                                                return (
                                                    <span>{day.day}&nbsp;</span>
                                                )
                                            })}
                                        </p>
                                    )
                                })}
                                <p>
                                    <FavoriteRoundedIcon color="primary" />
                                    Charitable Contributions
                                </p>
                            </div>
                        </div>

                        <h1>About Us</h1>
                        <p>{serviceProvider.description}</p>

                        <h1>Services</h1>
                        <div className={styles.catList}>
                            {service.tags.map(val => {
                                return (
                                    <span>{val}</span>
                                )
                            })} 
                        </div>
                    </div>
                }

                {activeTab == "Reviews" &&
                    <div className={styles.content}>
                        <h1>Reviews</h1>
                    </div>
                }

                {activeTab == "Cancellation Policy" &&
                    <div className={styles.content}>
                        <h1>Cancellation Policy</h1>
                    </div>
                }
            </div>
            <div className={styles.actionContent}>
                <h1>Book Experience</h1>

                <div className={styles.qtyContent}>
                    <h2>{service.eventName}</h2>

                    {!query.get("journeyId") &&
                        <div className={styles.qtyController}>
                            <RemoveRoundedIcon onClick={() => quantity > 1 && setQuantity(quantity - 1)} />
                            <input value={quantity} onChange={(e) => e.target.value > 0 && setQuantity(e.target.value.replace(/\D/,''))} type="number" />
                            <AddRoundedIcon  onClick={() => setQuantity(quantity + 1)}/> 
                        </div>}

                    <h3>{service.pricePerPerson * quantity} €</h3>
                </div>
                
                <div className={styles.dateMapper}>
                    <div className={styles.datePicker}>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker  
                                    variant="static"
                                    openTo="date"
                                    color="primary"
                                    onChange={(date) => {
                                        setDate(moment(date).format("yyyy-MM-DD"))
                                        service.scheduleType == "REGULAR" && setTimeSlot("");
                                    }} 
                                    className={styles.datField}
                                    value={date}
                                    disablePast
                                    shouldDisableDate={day => { 
                                        if(service.scheduleType == "REGULAR") {
                                            let key = new Date(day).toString().substr(0,3);
                                            
                                            return !service.weekSlots[key].active;
                                        } else { 
                                            return !Object.keys(dates).includes(moment(day).format("yyyy-MM-DD"))
                                        }
                                    }} 
                                />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </div>

                    <div className={styles.timeSlots}>
                        {service.scheduleType == "REGULAR"
                            ?
                        <React.Fragment>
                            {date && service.weekSlots[new Date(date).toString().substr(0,3)].timeSlots.map(slot => {
                                return (
                                    <div className={`${styles.timeSlot} ${slot.start == timeSlot.startTime && slot.end == timeSlot.endTime && styles.activeTimeSlot}`} onClick={() => setTimeSlot({
                                        startDate: date,
                                        endDate:date,
                                        startTime: slot.start,
                                        endTime: slot.end
                                    })}>
                                        <span>{slot.start} : {slot.end}</span>
                                    </div>
                                )
                            })}
                        </React.Fragment>
                            :
                        <React.Fragment>
                            {dates[date] && dates[date].map(slot => {
                                
                                return (
                                    <div className={`${styles.timeSlot} ${slot == timeSlot && styles.activeTimeSlot}`} onClick={() => setTimeSlot(slot)}>
                                        <span>{slot.startTime} : {slot.endTime}</span>
                                    </div>
                                )
                            })}
                        </React.Fragment>}
                    </div>
                </div>
                <div className={styles.btnContainer}>
                    <AddToCart quantity={quantity} service={service} date={date} timeSlot={timeSlot} />
                </div>
            </div>
        </div>
    )
}

export default InfoBox;