
import * as actionTypes from './actionTypes'

const initialState = {
    serviceProviders: false, 
}
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.SET_CREATED_SERVICE_PROVIDERS:
            return { ...state, serviceProviders: action.payload }
        default:
            return state
    }
}

export default reducer;