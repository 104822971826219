import React from "react";
import styles from "./ViewB2BUser.module.css";
import TextField from "@material-ui/core/TextField";
import DataTable from "react-data-table-component";
import AppLoader from "../../utils/AppLoader/AppLoader";
import { connect } from "react-redux";
import { getAllB2BUsers } from "../../../containers/endUser/actions";
import { withRouter } from "react-router-dom";
import Action from "./Action/Action";
import Modal from "./Modal/Modal";
import axios from "axios";
const ViewEndUser = (props) => {
    const [users, setUsers] = React.useState(props.users);
    const [searchVal, setSearchVal] = React.useState("");
    const [openPage, setOpenPage] = React.useState({
        open: false,
        tab: false,
    });

    const downloadCsv = () => {
        axios({
            method: "get",
            url: `/dashboard/end-users/getAllB2BUsers?csv=true`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "B2BUser.csv"); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    React.useEffect(() => {
        if (!props.users) {
            props.getAllB2BUsers();
        }
        setUsers(props.users);
    }, [props.users]);

    let isLoading = !users;
    let showData = !isLoading;
    let rowData = [];

    !isLoading &&
        users.forEach((user, index) => {
            if (
                // (user&&user.username.toLowerCase().includes(searchVal.toLowerCase())) ||
                (user && user.email.toLowerCase().includes(searchVal.toLowerCase())) ||
                (user && user.firstName && user.firstName.toLowerCase().includes(searchVal.toLowerCase())) ||
                (user && user.companyId && user.companyId.toLowerCase().includes(searchVal.toLowerCase())) ||
                (user && user.lastName && user.lastName.toLowerCase().includes(searchVal.toLowerCase()))
            ) {
                rowData.push({
                    sl_no: index + 1,
                    ...user,
                    actions: (
                        <div className="actions">
                            <Action userId={user} isDeactivated={user.isDeactivated} />
                        </div>
                    ),
                });
            }
        });

    return (
        <div className={styles.container}>
            <Modal open={openPage.open} onClose={() => setOpenPage({ ...openPage, open: false })} tab={openPage.tab} />
            <div className={styles.header}>
                <div className={styles.leftHeader}>&nbsp;</div>

                <div className={styles.rightHeader}>
                    <TextField
                        label="Search Here"
                        className={styles.search}
                        value={searchVal}
                        onChange={(e) => setSearchVal(e.target.value)}
                    />
                    <button className={styles.export} onClick={() => downloadCsv()}>
                        Export
                    </button>
                </div>
            </div>

            {isLoading && <AppLoader />}

            {showData && (
                <DataTable
                    noHeader={true}
                    fixedHeader={true}
                    pagination={true}
                    fixedHeaderScrollHeight={"calc(100vh - 320px)"}
                    columns={[
                        { name: "Sl No", selector: "sl_no", sortable: true },
                        { name: "First Name", selector: "firstName", sortable: true },
                        { name: "Last Name", selector: "lastName", sortable: true },
                        { name: "Email", selector: "email", sortable: true },
                        // { name: "Status", selector: "status", sortable: true },
                        {
                            name: "Status",
                            selector: (row) => (row.isDeactivated ? "Inactive" : "Active"),
                            // selector: "status",
                            sortable: true,
                        },
                        {
                            name: "Premium Status",
                            selector: (row) => (row.isPremium ? "Active" : "Inactive"),
                            sortable: true,
                        },
                        { name: "CompanyId", selector: "companyId", sortable: true },
                        { name: "Date Of Joining", selector: "doj", sortable: true },
                        { name: "No Of Plans", selector: "noOfJourney", sortable: true },
                        { name: "No Of Habit", selector: "noOfHabit", sortable: true },
                        { name: "Action", selector: "actions" },
                    ]}
                    data={rowData}
                />
            )}
        </div>
    );
};
const mapStateToProps = (state) => ({
    users: state.endUser.b2bUser,
});
export default withRouter(connect(mapStateToProps, { getAllB2BUsers })(ViewEndUser));
