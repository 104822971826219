import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styles from "./Features.module.css"; 
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    accordian: {
        background: "#484d4c",
        "&.MuiAccordion-rounded:first-child": {
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
        },
        "& .MuiAccordionSummary-root": {
            minHeight: 55,
        },
        "&.MuiAccordion-rounded:last-child": {
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
        },
        "&.MuiAccordion-root:before": {
            background: "#a4a49b",
        },
    },
    icon: {
        color: "black",
        background: "#e6e1d1",
        borderRadius: "50%",
    },
}));

export function Features({ service }) {
    const classes = useStyles(); 
    const { headers } = service || {};
    const whatYouGet = headers?.["Good to Know"] || { value: "" };
    const requirements = headers?.["Requirements"] || { value: "" };
    const whatIsIncluded = headers?.["What Is Included"] || { value: "" };

    return (
        <div className={styles.root}>
            <Grid container>
                <Grid item xs={12}>
                    {whatIsIncluded.active ? (
                        <Accordion className={classes.accordian}>
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon fontSize="small" className={classes.icon} />
                                }
                            >
                                <div className={styles.Topic}>
                                    <h4>Was Du Bekommst</h4>
                                </div>
                            </AccordionSummary>
                            <pre className={styles.Content}>{whatIsIncluded.value}</pre>
                        </Accordion>
                    ) : null}
                    {requirements.active ? (
                        <Accordion className={classes.accordian}>
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon fontSize="small" className={classes.icon} />
                                }
                            >
                                <div className={styles.Topic}>
                                    <h4>Voraussetzungen</h4>
                                </div>
                            </AccordionSummary>
                            <pre className={styles.Content}>{requirements.value}</pre>
                        </Accordion>
                    ) : null}

                    {whatYouGet.active ? (
                        <Accordion className={classes.accordian}>
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon fontSize="small" className={classes.icon} />
                                }
                            >
                                <div className={styles.Topic}>
                                    <h4>Gut Zu Wissen</h4>
                                </div>
                            </AccordionSummary>
                            <pre className={styles.Content}>{whatYouGet.value}</pre>
                        </Accordion>
                    ) : null}
                </Grid>
            </Grid>
        </div>
    );
}
