import * as actionTypes from './actionTypes'

const initialState = { 
  transactions: false, 
  count: false
} 
const reducer =  (state = initialState, action={}) => {
    switch (action.type) { 
        case actionTypes.SET_TRANSACTION_COUNT:
            return {...state,count: action.payload}
        case actionTypes.SET_TRANSACTIONS:
            return {...state,transactions: action.payload}
        default:
            return state
    }
}

export default reducer;