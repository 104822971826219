import React from 'react'
import styles from './ErrorPage.module.css'

import Modal from '@material-ui/core/Modal'; 

const ErrorPage = (props) => {  
 
    return ( 
        <Modal open={props.open} onClose={props.onClose} className={styles.modal}>
            <React.Fragment>
                {props.open == "CATEGORY" &&
                <div className={styles.container}>
                    <p>You have already selected a maximum of one activity categories.</p>

                    <span>To add a category, you could either:</span>
                    <span>1. Remove one of your existing selection</span>
                    <span>2. Create another journey to focus on a third category.</span>

                    <button onClick={props.onClose}>OKAY</button>
                </div>}

                {props.open == "EMPTY" &&
                <div className={styles.container}>
                    <p>Please Select Atleast one sub category</p>
 

                    <button onClick={props.onClose}>OKAY</button>
                </div>}

                {props.open == "SUB_CATEGORY" &&
                <div className={styles.container}>
                    <p>You have already selected a maximum of two sub category.</p>

                    <span>To add a sub category, you could either:</span>
                    <span>1. Remove one of your existing selection</span>
                    <span>2. Create another journey to focus on a other sub category.</span>

                    <button onClick={props.onClose}>OKAY</button>
                </div>}
            </React.Fragment>
        </Modal> 
    )
}
export default ErrorPage;