import React from 'react'
import styles from './SubCategoryManager.module.css'

import {connect} from 'react-redux'
import {withRouter,useLocation} from 'react-router-dom'

import {getAllExperience,onSubCategoryDelete} from '../../../containers/category/actions' 
 
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
 
import ConfirmAlert from '../../utils/ConfirmAlert/ConfirmAlert'
import ConfSubCategory from './ConfSubCategory/ConfSubCategory'


const SubCategoryManager = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [experiences,setExperiences] = React.useState(false);
    const [activeCat,setActiveCat] = React.useState(false);
    const [activeExp,setActiveExp] = React.useState(false);


    React.useEffect(() => {
        if(!props.experienceMap) {
            props.getAllExperience();
        }
        setExperiences(props.experienceMap);
    },[props.experienceMap]);

    React.useEffect(() => {
        if(query.get("categoryId") && query.get("experienceId")) {
            setActiveCat(query.get("categoryId"))
            setActiveExp(query.get("experienceId"))
        } else {
            setActiveCat(false)
            setActiveExp(false)
        }
    },[props.location.search]);

    let isActive = !activeCat;
    let showData = !isActive && experiences;
 
    return (
        <div className={styles.container}>
            {isActive &&
                <div className={styles.contentCenter}>
                    <HourglassEmptyRoundedIcon />
                    <p>Select a Category</p>
                </div>}

            {showData &&
            <div className={styles.listItems}>
                {Object.keys(experiences[activeExp].categories).includes(activeCat) && Object.keys(experiences[activeExp].categories[activeCat].subCategory).length > 0
                    ?
                <React.Fragment>
                    {Object.keys(experiences[activeExp].categories[activeCat].subCategory).map((key, index) => {
                        let category = experiences[activeExp].categories;
                        return (
                            <div 
                                key={index} 
                                className={query.get("subCategoryId") && query.get("subCategoryId") == key ? styles.activeListItem : styles.listItem} 
                                onClick={() => props.history.push("/admin/category?experienceId="+ activeExp +"&categoryId=" + activeCat + "&subCategoryId=" + key)}
                            >
                                <p>{category[activeCat].subCategory[key].subCategoryName}</p>
                                 
                                <div className="actions">
                                    <div className={styles.hoverItems}>
                                        <ConfSubCategory subCategory={category[activeCat].subCategory[key]} categoryId={activeCat} experienceId={activeExp} type="EDIT" />

                                        <ConfirmAlert msg="Are you sure you wanna delete this sub category" onClickEvent={() => {
                                            props.onSubCategoryDelete(key)
                                        }}>
                                            <Tooltip title="Delete Category">
                                                <IconButton>
                                                    <DeleteRoundedIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </ConfirmAlert>
                                    </div> 
                                </div>
                            </div>
                        )
                    })}
                </React.Fragment>
                    :
                <div className={styles.contentCenter}>
                    <HourglassEmptyRoundedIcon />
                    <p>No Sub Category Found</p>
                </div>}
                
                <br/>
                {activeCat && <ConfSubCategory experienceId={activeExp} categoryId={activeCat} type="ADD" />}
            </div>}
        </div>
    )
}

const mapStateToProps = state => ({
    experienceMap: state.category.experienceMap
})
export default withRouter(connect(mapStateToProps, {getAllExperience, onSubCategoryDelete})(SubCategoryManager));