import React from 'react'
import styles from './HeaderTab.module.css'

import {withRouter} from 'react-router-dom'

const HeaderTab = (props) => {
    const [state,setState] = React.useState("PURPOSE_COLLECTIONS"); 

    React.useEffect(() => { 
        setState(props.match.params.type)
    },[props.match.params.type]);


    const handleRedirect = (path) => {
        props.history.push("/user/" + path);
    }
    return (
        <div className={styles.header}>
            <h4 className={state == "PURPOSE_COLLECTIONS" && styles.activeHeader} onClick={() => handleRedirect("PURPOSE_COLLECTIONS")} >My Purpose Journey</h4>
            <h4 className={state == "MY_CHARITABLE_PROJECTS" && styles.activeHeader} onClick={() => handleRedirect("MY_CHARITABLE_PROJECTS")}>My Charitable Projects</h4>
        </div>
    )
}

export default withRouter(HeaderTab);