import React from "react";
import styles from "./ViewAddedServiceProvider.module.css";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import DataTable from "react-data-table-component";
import AppLoader from "../../utils/AppLoader/AppLoader";
import axios from "axios";
import { connect } from "react-redux";
import { showAlert } from "../../../containers/app/actions";
import { getAllServiceProviders } from "./../../../containers/partner/actions";
import { withRouter } from "react-router-dom";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { IconButton, Tooltip } from "@material-ui/core";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import RefreshRoundedIcon from "@material-ui/icons/RefreshRounded";
import ConfirmAlert from "../../utils/ConfirmAlert/ConfirmAlert";

const ViewAddedServiceProvider = (props) => {
    const [searchVal, setSearchVal] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [serviceProviders, setRequests] = React.useState(props.serviceProviders);

    React.useEffect(() => {
        if (!props.serviceProviders) {
            props.getAllServiceProviders();
        }
        setRequests(props.serviceProviders);
    }, [props.serviceProviders]);

    let isLoading = !serviceProviders;
    let showData = !isLoading;
    let rowData = [];

    let token = localStorage.getItem("token");

    const resendEmail = (serviceProviderEmail) => {
        setLoading("RESEND");
        axios({
            method: "post",
            url: "/dashboard/create-service-provider/resendmailTopartner",
            data: {
                serviceProviderEmail,
            },
            headers: {
                Authorization: `bearer ${token}`,
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                props.showAlert("Email sent Successfully");
            })
            .catch((err) => {
                setLoading(false);
                if (err && err.response && err.response.data && err.response.data.error) {
                    props.showAlert(err.response.data.error);
                } else {
                    props.showAlert("Something went wrong ! Try Again");
                }
            });
    };
    const deleteServiceProvider = (_id) => {
        axios({
            method: "put",
            url: "/dashboard/create-service-provider/deleteServiceProvider",
            data: {
                _id,
            },
            headers: {
                Authorization: `bearer ${token}`,
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                props.showAlert(res.data.message);
                props.getAllServiceProviders();
            })
            .catch((err) => {
                setLoading(false);
                if (err && err.response && err.response.data && err.response.data.error) {
                    props.showAlert(err.response.data.error);
                } else {
                    props.showAlert("Something went wrong ! Try Again");
                }
            });
    };
    !isLoading &&
        serviceProviders.forEach((serviceProvider, index) => {
            if (
                serviceProvider.storeName.toLowerCase().includes(searchVal.toLowerCase()) ||
                serviceProvider.serviceProviderEmail.toLowerCase().includes(searchVal.toLowerCase())
            ) {
                let displayDate = "";
                if (new Date(serviceProvider.createdAt).getDate() == new Date().getDate()) {
                    if (new Date().getHours() - new Date(serviceProvider.createdAt).getHours() == 0) {
                        displayDate =
                            new Date().getMinutes() - new Date(serviceProvider.createdAt).getMinutes() + " minutes ago";
                    } else {
                        displayDate =
                            new Date().getHours() - new Date(serviceProvider.createdAt).getHours() + " hour ago";
                    }
                } else {
                    displayDate = new Date(serviceProvider.createdAt).toString().substring(0, 15).slice(3);
                }

                rowData.push({
                    sl: index + 1,
                    name: serviceProvider.name,
                    storeName: serviceProvider.storeName,
                    tag: (
                        <div className={styles.newContent}>
                            {serviceProvider.status == "DRAFT" || serviceProvider.status == "PENDING" ? (
                                <span className={styles.new}>{serviceProvider.status}</span>
                            ) : serviceProvider.status == "SENT" ? (
                                <span className={styles.old}>SENT</span>
                            ) : (
                                <span className={styles.old}>ACCEPTED</span>
                            )}
                        </div>
                    ),
                    email: serviceProvider.serviceProviderEmail,
                    eventName: serviceProvider.eventName,
                    date: displayDate,
                    action: (
                        <React.Fragment>
                            <Tooltip title="Edit">
                                <IconButton
                                    onClick={() =>
                                        props.history.push(
                                            "/user/add-service-provider/EDIT-SERVICE-PROVIDER?serviceId=" +
                                                serviceProvider._id +
                                                "&type=EDIT",
                                        )
                                    }
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Preview">
                                <IconButton
                                    onClick={() => {
                                        let link = `${
                                            process.env.REACT_APP_CLIENT_URL
                                        }/product/${serviceProvider.eventName.split(" ").join("-")}?serviceId=${
                                            serviceProvider._id
                                        }&preview=PREVIEW&source=service-provider-creator&displayFooter=NO`;
                                        window.open(link);
                                    }}
                                >
                                    <VisibilityRoundedIcon />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Copy Link">
                                <IconButton
                                    onClick={() => {
                                        let link = `${
                                            process.env.REACT_APP_CLIENT_URL
                                        }/service/${serviceProvider.eventName.split(" ").join("-")}?serviceId=${
                                            serviceProvider._id
                                        }&preview=PREVIEW&source=service-provider-creator&displayFooter=NO`;
                                        navigator.clipboard.writeText(link).then(
                                            function () {
                                                props.showAlert("Copied");
                                            },
                                            function (err) {
                                                props.showAlert("Could not copy text");
                                            },
                                        );
                                    }}
                                >
                                    <FileCopyRoundedIcon />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Resend Email">
                                <IconButton
                                    onClick={() => resendEmail(serviceProvider.serviceProviderEmail)}
                                    disabled={
                                        serviceProvider.status === "ACCEPTED" || serviceProvider.status == "DRAFT"
                                    }
                                >
                                    <RefreshRoundedIcon />
                                </IconButton>
                            </Tooltip>

                            {serviceProvider.status === "PENDING" && (
                                <ConfirmAlert
                                    msg={`Are you sure you want to delete?`}
                                    onClickEvent={() => deleteServiceProvider(serviceProvider._id)}
                                >
                                    <Tooltip title="Delete">
                                        <IconButton>
                                            <DeleteOutline style={{ color: "red" }} />
                                        </IconButton>
                                    </Tooltip>
                                </ConfirmAlert>
                            )}
                        </React.Fragment>
                    ),
                });
            }
        });
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.leftHeader}>&nbsp;</div>

                <div className={styles.rightHeader}>
                    <TextField
                        label="Search Here"
                        className={styles.search}
                        value={searchVal}
                        onChange={(e) => setSearchVal(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                            props.history.push(
                                "/user/add-service-provider/ADD-SERVICE-PROVIDER?eventId=" +
                                    Math.random() +
                                    "&type=ADD",
                            )
                        }
                    >
                        Add Service Provider
                    </Button>
                </div>
            </div>

            {isLoading && <AppLoader />}

            {showData && (
                <DataTable
                    noHeader={true}
                    fixedHeader={true}
                    pagination={true}
                    fixedHeaderScrollHeight={"calc(100vh - 320px)"}
                    columns={[
                        {
                            name: "Sl No",
                            selector: "sl",
                            sortable: true,
                            wrap: true,
                            maxWidth: "60px",
                        },
                        {
                            name: "Name",
                            selector: "name",
                            sortable: true,
                            wrap: true,
                            minWidth: "350px",
                        },
                        {
                            name: "Store Name",
                            selector: "storeName",
                            sortable: true,
                            wrap: true,
                            minWidth: "200px",
                        },
                        {
                            name: "Status",
                            selector: "tag",
                            sortable: true,
                            wrap: true,
                            minWidth: "120px",
                        },
                        {
                            name: "Email",
                            selector: "email",
                            sortable: true,
                            wrap: true,
                            minWidth: "200px",
                        },
                        {
                            name: "Service Name",
                            selector: "eventName",
                            sortable: true,
                            wrap: true,
                            minWidth: "200px",
                        },
                        {
                            name: "Created At",
                            selector: "date",
                            sortable: true,
                            wrap: true,
                            minWidth: "140px",
                        },
                        { name: "Action", selector: "action", minWidth: "300px" },
                    ]}
                    data={rowData}
                />
            )}
        </div>
    );
};
const mapStateToProps = (state) => ({
    serviceProviders: state.createdServiceProvider.serviceProviders,
});
export default withRouter(connect(mapStateToProps, { getAllServiceProviders, showAlert })(ViewAddedServiceProvider));
