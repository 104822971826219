import React from 'react'
import styles from './ConfUser.module.css'

import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { connect } from 'react-redux'
import { showAlert } from '../../../containers/app/actions'
import { getAllUsers } from '../../../containers/users/actions'
import { withRouter, useLocation } from 'react-router-dom'
import axios from 'axios'

const ConfUser = (props) => {
    let { search } = useLocation();
    const query = new URLSearchParams(search);

    const [formData, setFormData] = React.useState({
        name: "",
        email: "",
        userType: "SALES",
        password: Math.random()
    });

    const [error, setError] = React.useState({
        name: false,
        email: false,
        userType: false,
    });

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (props.type == "EDIT") {
            if (props.users) {
                if (query.get("userId")) {
                    props.users.forEach(user => {
                        if (user._id == query.get("userId")) {
                            setFormData({ ...formData, ...user, userId: user._id })
                        }
                    })
                } else {
                    props.showAlert("Data Not Found")
                    props.history.push("/admin/users/VIEW-USERs")
                }
            } else {
                props.getAllUsers();
            }
        }
    }, [props.users]);

    const validate = () => {
        const err = { name: false, email: false, userType: false };
        let validData = true;
        setError({ ...err });
        Object.keys(err).forEach(key => {
            if (formData[key] == "") {
                err[key] = `Field cannot be empty`
                validData = false;
            }
        })

        setError({ ...err });
        return validData;
    }

    const onSubmit = () => {
        if (validate()) {
            setLoading(true);
            const url = props.type == "ADD" ? "/dashboard/conf-user/addUser" : "/dashboard/conf-user/editUser"
            const method = props.type == "ADD" ? "post" : "put";
            axios({
                method,
                url,
                data: {
                    ...formData
                }
            }).then(res => {
                setLoading(false);
                props.showAlert(`User ${props.type == "ADD" ? "Added" : "Updated"} Succesfully`);
                props.getAllUsers()
                props.history.push("/admin/users/VIEW-USERS")
            }).catch(err => {
                setLoading(false);
                if (err && err.response && err.response.data && err.response.data.error) {
                    props.showAlert(err.response.data.error)
                } else {
                    props.showAlert("Something went wrong ! Try Again")
                }
            })
        }
    }

    return (
        <div className={styles.container}>
            <Paper variant="outlined" className={styles.paper}>
                <h1>{props.type == "ADD" ? "Add User" : "Edit User"}</h1>

                <div className={styles.row}>
                    <TextField
                        label="Name"
                        className={styles.halfWidth}
                        value={formData.name}
                        onChange={e => setFormData({ ...formData, name: e.target.value.trimStart() })}
                        error={error.name}
                        helperText={error.name}
                    />
                    <TextField
                        label="Email"
                        type="email"
                        className={styles.halfWidth}
                        value={formData.email}
                        onChange={e => setFormData({ ...formData, email: e.target.value.trimStart() })}
                        error={error.email}
                        helperText={error.email}
                    />
                </div>

                <div className={styles.row}>
                    <FormControl error={error.userType} className={styles.halfWidth}>
                        <InputLabel>User Type</InputLabel>
                        <Select value={formData.userType} onChange={e => setFormData({ ...formData, userType: e.target.value })}>
                            <MenuItem value="SALES">Sales</MenuItem>
                            <MenuItem value="ADMIN">Admin</MenuItem>
                            <MenuItem value="LIFECOACH">LIFE COACH</MenuItem>
                        </Select>

                        {error.userType && <FormHelperText>
                            {error.userType}
                        </FormHelperText>}
                    </FormControl>
                </div>

                <div className={styles.row}>
                    {loading
                        ?
                        <Button color="primary" variant="contained" startIcon={<CircularProgress color="inherit" size={20} />}>Loading ...</Button>
                        :
                        <Button color="primary" variant="contained" startIcon={<AddRoundedIcon />} onClick={onSubmit}>{props.type == "ADD" ? "Add" : "Update"} User</Button>}
                </div>
            </Paper>
        </div>
    )
}
const mapStateToProps = state => ({
    users: state.users.users
})
export default withRouter(connect(mapStateToProps, { showAlert, getAllUsers })(ConfUser));