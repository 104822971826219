import React from 'react';
import styles from './Action.module.css'

import Popover from '@material-ui/core/Popover'; 
import IconButton from '@material-ui/core/IconButton' 

import SettingsApplicationsRoundedIcon from '@material-ui/icons/SettingsApplicationsRounded';

import {connect} from 'react-redux'
import {showAlert} from '../../../../containers/app/actions'
import {withRouter} from 'react-router-dom'

const Action = (props) => { 
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    return (
        <React.Fragment key={props.userId}>  
             
            <IconButton  onClick={e => {
                open ? setAnchorEl(null) : setAnchorEl(e.currentTarget)
            }}> 
                <SettingsApplicationsRoundedIcon/>
                <Popover
                    id="mouse-over-popover"  
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    onClose={() => setAnchorEl(null)}
                    disableRestoreFocus
                >
                    <div className={styles.popContent}> 
                        <p onClick={() => props.history.push("/user/email-system?email=" + props.userId.email)}>Contact Service Provider</p>

                        <p onClick={() => props.history.push("/user/service-provider/UPDATE-GENERAL?userId="+ props.userId._id)}>General Details</p>

                        <p onClick={() => props.history.push("/user/service-provider/UPDATE-STORE-PROFILE?userId="+ props.userId._id)}>Store Profile</p>

                        <p onClick={() => props.history.push("/user/service-provider/VIEW-STORE-SERVICES?userId="+ props.userId._id)}>View Services</p>

                        <p onClick={() => props.history.push("/user/service-provider/VIEW-BOOKINGS?userId="+ props.userId._id)}>View Bookings</p>
                    </div>
                </Popover>
            </IconButton>  
        </React.Fragment>
    )
}

export default withRouter(connect(null,{showAlert})(Action));