import React from "react";
import {
    TimerRounded,
    LocalParkingRounded,
    CheckCircleOutline,
    CommuteRounded,
    PersonRounded,
    Group,
} from "@material-ui/icons";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import deImg from "../../../assets/img/de.png";
import enImg from "../../../assets/img/en.png";
import childrenIcon from "../../../assets/svg/children-yellow.svg";
import plusIcon from "../../../assets/svg/18-yellow.svg";
import seniourIcon from "../../../assets/svg/seniour-yellow.svg";
import allAgesIcon from "../../../assets/svg/allAges-yellow.svg";
import beginnerIcon from "../../../assets/svg/beginner-yellow.svg";
import intermediateIcon from "../../../assets/svg/intermediate-yellow.svg";
import advancedIcon from "../../../assets/svg/advanced-yellow.svg";

import { Features } from "./FeaturesSection/Features";
import { Location } from "./LocationSection/LocationSection";
import { specImgYellow } from "../../../config/specificationImageYellow";
import styles from "./ServiceBenefits.module.css";
import AmenitiesModal from "../ServiceSpecs/AmenitiesModal/Amenities";

export function ServiceBenefits({ service }) {
    const iconsYellow = specImgYellow();
    const [open, setOpen] = React.useState(false);
    const onClose = () => setOpen(false);
    const difficultyLevel = service?.specifications?.difficultyLevel.map((item) => {
        if (item === "NOT_APPLICABLE") {
            return "NOT_APPLICABLE_LEVEL";
        } else return item;
    });

    const accessibility = service?.specifications?.accessibility ? service?.specifications?.accessibility : [];
    const size = service?.specifications?.size ? service?.specifications?.size : [];
    const age = service?.specifications?.age ? service?.specifications?.age : [];
    const gender = service?.specifications?.gender && service?.specifications?.gender;

    const specificationArray = [...accessibility, ...size, ...age, ...difficultyLevel, gender];
    return (
        <>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.tags}>
                        <div className={styles.tagGroup}>
                            <div className={styles.iconContainer}>
                                <TimerRounded />
                            </div>
                            <span className={styles.tag}>
                                <React.Fragment>
                                    {service.serviceDuration &&
                                        service.serviceDuration.days != 0 &&
                                        service.serviceDuration.days + " Tage"}
                                    &nbsp;
                                    {service.serviceDuration &&
                                        service.serviceDuration.hours != 0 &&
                                        service.serviceDuration.hours + " Stunde"}
                                    &nbsp;
                                    {service.serviceDuration &&
                                        service.serviceDuration.minutes != 0 &&
                                        service.serviceDuration.minutes + " Minuten"}
                                </React.Fragment>
                            </span>
                        </div>
                        {service?.specifications?.language?.includes("DEUTSCH") && (
                            <div className={styles.tagGroup}>
                                <div className={styles.iconContainer}>
                                    <img src={deImg} alt="de" />
                                </div>
                                <span className={styles.tag}>Deutsch</span>
                            </div>
                        )}
                        {service?.specifications?.language?.includes("ENGLISH") && (
                            <div className={styles.tagGroup}>
                                <div className={styles.iconContainer}>
                                    <img src={enImg} alt="de" />
                                </div>
                                <span className={styles.tag}>Englisch</span>
                            </div>
                        )}
                        <div className={styles.tagGroup}>
                            <div className={styles.iconContainer}>
                                <CheckCircleOutline />
                            </div>
                            <span className={styles.tag}>21Done Verifiziert</span>
                        </div>

                        {specificationArray.length > 0 &&
                            specificationArray.map(
                                (item, index) =>
                                    (service?.specifications?.language.length === 0 ? index < 4 : index < 3) &&
                                    iconsYellow[item] && (
                                        <div key={index} className={styles.tagGroup}>
                                            <div className={styles.iconContainer}>
                                                {item === "SUITABLE_FOR_ALL_AGES" ? (
                                                    <CheckCircleOutline />
                                                ) : (
                                                    <img src={iconsYellow[item]?.icon} alt="de" />
                                                )}
                                            </div>
                                            <span className={styles.tag}>{iconsYellow[item]?.text}</span>
                                        </div>
                                    ),
                            )}
                    </div>
                    {Number(service?.specifications?.accessibility?.length) +
                        Number(service?.specifications?.age?.length) +
                        Number(service?.specifications?.difficultyLevel?.length) +
                        Number(service?.specifications?.language?.length) +
                        Number(service?.specifications?.size?.length) +
                        Number(service?.specifications?.gender?.length) >
                    4 ? (
                        <div className={styles.buttonGroup}>
                            <button
                                onClick={() => {
                                    setOpen(true);
                                }}
                                className={styles.amenitiesButton}
                            >
                                Alle Annehmlichkeiten Anzeigen
                            </button>
                        </div>
                    ) : null}
                    <AmenitiesModal open={open} service={service} onClose={onClose} />
                    <Features service={service} />
                </div>
            </div>
            <Location service={service} />
        </>
    );
}
