import React from 'react';
import styles from './LifeCoach.module.css';

import TopBar from '../../components/utils/TopBar/TopBar'; 
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs'; 
import TextField from '@material-ui/core/TextField'
import ViewSchedule from '../../components/LifeCoach/ViewSchedule/ViewSchedule';
import ManageSchedule from '../../components/LifeCoach/ManageSchedule/ManageSchedule';
import Bookings from '../../components/LifeCoach/Bookings/Bookings';

const LifeCoach = (props) => {
    const [tab, setTab] = React.useState("VIEW-SCHEDULE");
    const [search, setSearch] = React.useState("");
  
    React.useEffect(() => { 
        setTab(props.match.params.type)
    }, [props.match.params.type]);
    return (
        <div className={styles.container}>
            <TopBar head="Life Coach Admin" /> 
            <div className={styles.content}>
                <div className={styles.tabs}> 
                    <Tabs
                        value={tab} 
                        onChange={(e,val) => setTab(val)}
                        indicatorColor="secondary" 
                    >
                        <Tab label="Bookings" value="BOOKINGS"  />
                        <Tab label="Manage-Schedule" value="MANAGE-SCHEDULE"  />
                        <Tab label="View-Schedule" value="VIEW-SCHEDULE"  />
                    </Tabs>  
                </div>

                {tab == "BOOKINGS" && <Bookings search={search} />}
                {tab == "MANAGE-SCHEDULE" && <ManageSchedule />}
                {tab == "VIEW-SCHEDULE" && <ViewSchedule />}

            </div>
        </div>
    )
}

export default LifeCoach;