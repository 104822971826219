import React from 'react'
import styles from './Applicable.module.css'

import Autocomplete from '@material-ui/lab/Autocomplete';
import Radio from '@material-ui/core/Radio'
import TextField from '@material-ui/core/TextField'

import {getAllCategory} from '../../../../containers/category/actions'
import {getBasicServiceList} from '../../../../containers/app/actions'
import {connect} from 'react-redux';
 

const Applicable = (props) => {
    React.useEffect(() => {
        if(!props.basicServiceList) {
            props.getBasicServiceList();
        }
    },[props.basicServiceList]);

    React.useEffect(() => {
        if(!props.category) {
            props.getAllCategory();
        }
    },[props.category]);

    const formData = props.formData;
    const error = props.error;
    return (
        <div className={styles.compContent}>
            <h3>Applicable for: </h3>

            <div className={styles.formGroup}>
                <div className={styles.selectors}>
                    <div className={styles.radio}>
                        <p>All Products</p>
                        <Radio
                            checked={formData.applicableFor == "ALL-PRODUCTS"}
                            onChange={e => props.setFormData({...formData,applicableFor: "ALL-PRODUCTS"})}
                        />
                    </div>

                    <div className={styles.radio}>
                        <p>Specific Products</p>
                        <Radio
                            checked={formData.applicableFor == "SPECIFIC-PRODUCTS"}
                            onChange={e => props.setFormData({...formData,applicableFor: "SPECIFIC-PRODUCTS"})}
                        />
                    </div>

                    <div className={styles.radio}>
                        <p>Specific Category</p>
                        <Radio
                            checked={formData.applicableFor == "SPECIFIC-CATEGORY"}
                            onChange={e => props.setFormData({...formData,applicableFor: "SPECIFIC-CATEGORY",})}
                        />
                    </div>
                </div> 

                {formData.applicableFor == "SPECIFIC-PRODUCTS" &&
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        options={props.basicServiceList ? props.basicServiceList : []}
                        getOptionLabel={(option) => option.eventName}
                        className={styles.textField}
                        value={formData.applicaleProductList}
                        onChange={(e,val) => props.setFormData({...formData,applicaleProductList: val})}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Select Product"
                                placeholder="Select Product"
                                error={error.applicaleProductList}
                                helperText={error.applicaleProductList}
                            />
                        )}
                    />}  

                {formData.applicableFor == "SPECIFIC-CATEGORY" &&
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        options={props.category ? props.category : []}
                        getOptionLabel={(option) => option.categoryName}
                        className={styles.textField}
                        value={formData.applicableCategoryList}
                        onChange={(e,val) => props.setFormData({...formData,applicableCategoryList: val})}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Select Category"
                                placeholder="Select Category"
                                error={error.applicableCategoryList}
                                helperText={error.applicableCategoryList}
                            />
                        )}
                    />}  
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    category: state.category.categoryList,
    basicServiceList: state.app.basicServiceList
})
export default connect(mapStateToProps,{getAllCategory,getBasicServiceList})(Applicable);