import React from 'react'
import styles from './DiscountType.module.css';


import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox'

import Country from '../../../utils/Country/Country'

const DiscountType  = (props) => {
    const [err,setErr] = React.useState(false)
    const formData = props.formData;
    const error = props.error;
    return (
        <div className={styles.compContent}>
            <h3>Select Discount Type</h3>

            <div className={styles.formGroup}>
                <div className={styles.selectors}>
                    <div className={styles.radio}>
                        <p>Percentage</p>
                        <Radio
                            checked={formData.discountType == "PERCENTAGE"}
                            onChange={e => props.setFormData({...formData,discountType: "PERCENTAGE",discountTypeValue: ""})}
                        />
                    </div>

                    <div className={styles.radio}>
                        <p>Fixed Value</p>
                        <Radio
                            checked={formData.discountType == "FIXED-VALUE"}
                            onChange={e => props.setFormData({...formData,discountType: "FIXED-VALUE",discountTypeValue: ""})}
                        />
                    </div>

                    {/* <div className={styles.radio}>
                        <p>Free Shipping discount</p>
                        <Radio
                            checked={formData.discountType == "FREE-SHIPPING"}
                            onChange={e => props.setFormData({...formData,discountType: "FREE-SHIPPING",discountTypeValue: "ALL-COUNTRIES"})}
                        />
                    </div> */}
                </div> 

                {(formData.discountType == "PERCENTAGE" || formData.discountType == "FIXED-VALUE") &&
                    <TextField
                        label="Discount Type Value"
                        variant="outlined" 
                        value={formData.discountTypeValue}
                        onChange={e =>  {
                            setErr(false)
                            if(/^\d+$/.test(e.target.value) || e.target.value == "") {    
                                formData.discountType == "PERCENTAGE" && e.target.value <=100 && props.setFormData({...formData,discountTypeValue: e.target.value.replace(/^0+/, '')})
                                formData.discountType == "FIXED-VALUE" && props.setFormData({...formData,discountTypeValue: e.target.value.replace(/^0+/, '')})
                            } else {
                                setErr("You can only type in numbers")
                            }
                        }}
                        error={error.discountTypeValue || err}
                        helperText={error.discountTypeValue || err}
                        className={styles.textField}

                        InputProps={{
                            endAdornment: 
                            <InputAdornment position="start">
                                {formData.discountType == "PERCENTAGE" && "%"}
                                {formData.discountType == "FIXED-VALUE" && "€"} 
                            </InputAdornment>,
                        }}
                    />}

                {formData.discountType == "FREE-SHIPPING" &&
                <div className={styles.selectorContainer}>
                    <div className={styles.selectorsTemp}>
                        <h3>Free Shipping discount:</h3>
                        &nbsp;
                        <div className={styles.radio}>
                            <p>All Countries</p>
                            <Radio
                                checked={formData.discountTypeValue == "ALL-COUNTRIES"}
                                onChange={e => props.setFormData({...formData,discountTypeValue: "ALL-COUNTRIES"})}
                            />
                        </div>

                        <div className={styles.radio}>
                            <p>Selected Countries</p>
                            <Radio
                                checked={formData.discountTypeValue == "SELECTED-COUNTRIES"}
                                onChange={e => props.setFormData({...formData,discountTypeValue: "SELECTED-COUNTRIES"})}
                            />
                        </div> 
                            

                        {formData.discountTypeValue == "SELECTED-COUNTRIES" && 
                            <Country
                                multiple
                                className={styles.textField}
                                variant="outlined"
                                onChange={arr => props.setFormData({...formData,selectedCountry: arr })}
                                value={formData.selectedCountry} 
                                error={error.selectedCountry}
                                helperText={error.selectedCountry}
                            />
                        }
                    </div>

                    <div className={styles.selectorsTemp}>
                        <h3>
                            <Checkbox
                                checked={formData.excludeShippingTraffic}
                                onChange={() => props.setFormData({...formData,excludeShippingTraffic: !formData.excludeShippingTraffic})}
                            />
                            Exclude shipping tariffs above a certain amount.:</h3>
                        &nbsp; 
                        {formData.excludeShippingTraffic && 
                            <TextField   
                                label="Exclude Shipping Tariffs Value"
                                variant="outlined" 
                                value={formData.excludeShippingTrafficValue}
                                onChange={e => props.setFormData({...formData,excludeShippingTrafficValue: e.target.value.replace(/\D/,'')})}
                                error={error.excludeShippingTrafficValue}
                                helperText={error.excludeShippingTrafficValue}
                                className={styles.textField}
                            />
                        }
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default DiscountType;