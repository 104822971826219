import * as actionTypes from './actionTypes'
import axios from 'axios' 
import AWS from 'aws-sdk';
import {s3Config} from '../../config/config'

export const showAlert = (msg) => dispatch => {
    dispatch({
        type: actionTypes.SHOW_ALERT,
        payload: msg
    })

    setTimeout(() => {
        dispatch({
            type: actionTypes.SHOW_ALERT,
            payload: false
        });
    },5000)
}
 

export const setAuth = (data) => dispatch => {
    dispatch({
        type: actionTypes.SET_AUTH,
        payload: data
    })
}

export const getAllImages = (userId) => dispatch => {
    AWS.config.update({
        accessKeyId: 'AKIAZJEMCUILH5234CMU',
        secretAccessKey: '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',
        region: 'us-east-2',
    });
    const s3 = new AWS.S3();

    const params = {
      Bucket: '21done-dev',
      Delimiter: '',
      Prefix: 'public/' + userId,
    };

    const req = s3.listObjectsV2(params);
    req.httpRequest.headers = {
      ...req.httpRequest.headers,
      'Access-Control-Allow-Origin': true, 
    } 
    req.send((err, data) => {
      if(err) {
        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
      } else {  
        data.Contents.forEach(val=> {
            dispatch(addImage(s3Config.bucketUrl + val.Key))
        })
      }
    });  
}

export const addImage = (url) => dispatch => {
    dispatch({
        type: actionTypes.ADD_IMAGE,
        payload: url
    })
}

export const getBasicEndUserList = () => dispatch => {
    dispatch({
        type: actionTypes.SET_BASIC_USER_LIST,
        payload: false
    }) 
    axios({
        method: "get",
        url: "/dashboard/user/getBasicEndUserList"
    }).then(res => { 
        dispatch({
            type: actionTypes.SET_BASIC_USER_LIST,
            payload: res.data.basicUserList
        }) 
    }).catch(err => {
        dispatch({
            type: actionTypes.SET_BASIC_USER_LIST,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}
export const getAllGiftCards = () => dispatch => {
    dispatch({
        type: actionTypes.SET_GIFT_CARDS,
        payload: false
    })
    axios({
        method: "get",
        url: "/dashboard/gift-cards/getAllGiftCards"
    }).then(res => {
        dispatch({
            type: actionTypes.SET_GIFT_CARDS,
            payload: res.data.giftCards
        })
    }).catch(err => {
        dispatch({
            type: actionTypes.SET_GIFT_CARDS,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}

export const getBasicServiceList = () => dispatch => {
    dispatch({
        type: actionTypes.SET_BASIC_SERVICE_LIST,
        payload: false
    })

    axios({
        method: "get",
        url: "/dashboard/user/getBasicServiceList"
    }).then(res => { 
        dispatch({
            type: actionTypes.SET_BASIC_SERVICE_LIST,
            payload: res.data.basicServiceList
        }) 
    }).catch(err => {
        dispatch({
            type: actionTypes.SET_BASIC_SERVICE_LIST,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}
export const getAllCustomerGroup = () => dispatch => {
    dispatch({
        type: actionTypes.SET_CUSTOMER_GROUP,
        payload: false
    })

    axios({
        method: "get",
        url: "/dashboard/customer-group/getAllCustomerGroup"
    }).then(res => { 
        dispatch({
            type: actionTypes.SET_CUSTOMER_GROUP,
            payload: res.data.groups
        }) 
    }).catch(err => {
        dispatch({
            type: actionTypes.SET_CUSTOMER_GROUP,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}

export const getAllPartnerAgreement = () => dispatch => {
    dispatch({
        type: actionTypes.SET_PARTNER_AGREEMENTS,
        payload: false
    })

    axios({
        method: "get",
        url: "/dashboard/partner-agreement/getAllPartnerAgreement"
    }).then(res => {
        dispatch({
            type: actionTypes.SET_PARTNER_AGREEMENTS,
            payload: res.data.partnerAgreement
        })
    }).catch(err => {
        dispatch({
            type: actionTypes.SET_PARTNER_AGREEMENTS,
            payload: []
        })

        dispatch({
            type: "SHOW_ALERT",
            payload: "Something went Wrong! Try Again"
        })
    })
}